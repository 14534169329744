import { useEffect, useRef } from 'react';
import { FeatureGroup, Marker, Polyline, Tooltip, useMap } from 'react-leaflet';
import { black } from 'constants/theme.constants';
import { PointRedIcon } from 'constants/map.constants';
import { LatLongType } from 'types/aoi.types';

interface RenderPointsProps {
  startPoint: LatLongType;
  endPoint: LatLongType;
  isShowBeeLine: boolean;
}

const RenderPoints = ({ startPoint, endPoint, isShowBeeLine }: RenderPointsProps) => {
  const map = useMap();
  const pointLayer: any = useRef(null);

  useEffect(() => {
    if (!pointLayer.current) return;

    const bounds = pointLayer.current.getBounds();
    map.fitBounds(bounds);
  }, [pointLayer.current]);

  return (
    <FeatureGroup ref={pointLayer}>
      <Marker
        zIndexOffset={1000}
        icon={PointRedIcon}
        position={{
          lat: Number(startPoint.lat),
          lng: Number(startPoint.lng)
        }}
      >
        <Tooltip offset={[-43, -3]} className="points-tooltip" permanent>
          Start Point
        </Tooltip>
      </Marker>
      <Marker
        zIndexOffset={1000}
        icon={PointRedIcon}
        position={{
          lat: Number(endPoint.lat),
          lng: Number(endPoint.lng)
        }}
      >
        <Tooltip offset={[-39, -3]} className="points-tooltip" permanent>
          End Point
        </Tooltip>
      </Marker>
      {startPoint && endPoint && isShowBeeLine && (
        <Polyline
          pane="markerPane"
          positions={[
            [Number(startPoint.lat), Number(startPoint.lng)],
            [Number(endPoint.lat), Number(endPoint.lng)]
          ]}
          pathOptions={{
            color: `${black}`,
            weight: 2
          }}
        />
      )}
    </FeatureGroup>
  );
};

export default RenderPoints;
