import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email('Required').required('Required'),
  password: yup.string().required('Required')
});

export const addMemberSchema = yup.object().shape({
  email: yup.string().email('Required').required('Required'),
  members: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().email('Required')
      })
    )
    .required('Required')
});

export const adminDetailSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  emp_id: yup.string().required('Required'),
  phone_no: yup
    .string()
    .test('len', 'Enter only 10 digits', (val) => val?.length === 10)
    .matches(/^\d+$/, 'Enter Only Number')
    .required('Required')
    .typeError('Enter Only Number'),
  userEmail: yup.string().email('Required').required('Required')
});

export const ManagerMemberDetailSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  emp_id: yup.string().required('Required'),
  department: yup.string().required('Required'),
  phone_no: yup
    .string()
    .test('len', 'Enter only 10 digits', (val) => val?.length === 10)
    .matches(/^\d+$/, 'Enter Only Number')
    .required('Required')
    .typeError('Enter Only Number'),
  userEmail: yup.string().email('Required').required('Required')
});

export const createProjectSchema = yup.object().shape({
  project_title: yup.string().required('Required').typeError('Required'),
  project_member_ids: yup.array().of(yup.string()).required('Required')
});

export const organizationSchema = yup.object().shape({
  organisation_name: yup.string().required('Required'),
  admin_firstname: yup.string().required('Required'),
  admin_lastname: yup.string().required('Required'),
  admin_email: yup.string().email('Required').required('Required')
});

export const fileRepositorySchema = yup.object().shape({
  category_id: yup.string().required('Required'),
  comment: yup.string().required('Required')
});

export const editFileRepoSchema = yup.object().shape({
  filename: yup.string().required('Required'),
  category_id: yup.string().required('Required'),
  comment: yup.string().required('Required')
});

export const coordSchema = yup.object().shape({
  lat: yup.string().required('Required'),
  lng: yup.string().required('Required')
});
