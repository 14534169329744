import { Card, Col, Row, Typography } from 'antd';
import { upperFirst } from 'lodash';
import DownloadIcon from 'assets/svg/DownloadIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import { USER_ROLE } from 'constants/common.constant';
import { RepositoryFile } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { getStorageValue } from 'utils/localStorage';

interface RouteListProps {
  route: RepositoryFile;
  handleShowHideRoute: Function;
  selectedRoutesIds: number[];
}

const { Text } = Typography;

const RouteItemCard = ({ route, handleShowHideRoute, selectedRoutesIds }: RouteListProps) => {
  const userRole = getStorageValue(USER_ROLE);
  const firstname = upperFirst(decryptValue(route.first_name));
  const lastname = upperFirst(decryptValue(route.last_name));

  const handleShowMap = (routeId: number) => {
    handleShowHideRoute(routeId);
  };

  return (
    <Card
      className={`route-card ${selectedRoutesIds.includes(route.id) ? 'route-card-enable' : ''}`}
      style={
        selectedRoutesIds.includes(route.id)
          ? { border: `1px solid ${route.color}`, borderLeft: `5px solid ${route.color}` }
          : { border: `1px solid ${route.color}`, borderLeft: `1px solid ${route.color}` }
      }
    >
      <Row className="inner-card-content">
        <Col span={16} className="inner-card-content-col">
          <div>
            <Text>Route {route.id}</Text>
            <Text>( {route.length} KM )</Text>
          </div>
          {route.user_role === UserRole.SuperAdmin ? (
            <Text className="upload-name">
              Uploaded by <Text>SatSure</Text>
            </Text>
          ) : (
            <Text className="upload-name">
              Uploaded by{' '}
              <Text>
                {firstname} {lastname}
              </Text>
            </Text>
          )}
        </Col>
        <Col
          span={7}
          className={`inner-card-content-icon ${
            userRole === UserRole.SuperAdmin && 'remove-download'
          }`}
        >
          <div>
            {userRole !== UserRole.SuperAdmin && (
              <a href={route.url}>
                <DownloadIcon height={18} width={18} />
              </a>
            )}
            {/* <a onClick={() => handleShowMap(isSelectedRoute, route.id)}>
              <img src={isSelectedRoute ? eyeOpen : eyeClose} alt="view" />
            </a> */}
            <Text onClick={() => handleShowMap(route.id)}>
              {selectedRoutesIds.includes(route.id) ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </Text>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default RouteItemCard;
