import { memo, useMemo, useState } from 'react';
import { Row } from 'antd';
import { MapContainer, ScaleControl } from 'react-leaflet';
import { includes, isEmpty, remove, uniq } from 'lodash';
import { defaultCenter } from 'constants/common.constant';
import ControlMapCenter from 'components/Map/ControlMapCenter';
import LayerControl from 'components/Map/LayerControl';
import MapLayers from 'components/Map/MapLayers';
import MapActionControl from 'components/Map/MapActionControl';
import RenderAoiLayer from 'components/Map/RenderAoiLayer';
import RenderVectorLayers from 'components/Map/RenderVectorLayers';
import LULCTileLayers from 'components/Map/LULCTileLayers';
import AOILength from 'components/Map/AOILength';
import { useAppSelector } from 'hooks/useReduxHook';
import { FilesCategory, RepositoryFile } from 'store/repositoryfile/types';
import { AoiPointsCords, AoiSelectionResponse } from 'store/aoi/types';
import { getVectorFiles } from 'store/aoi';
import { getLULCRepoFile, getLulcStyleLayers } from 'store/repositoryfile';
import { BothPointLatLngType, LatLongType } from 'types/aoi.types';
import StartEndPoints from './StartEndPoints';
import './styles.less';

interface AoiMapProps {
  points: BothPointLatLngType | null;
  aoiPoints: BothPointLatLngType | null;
  selectedAOI: RepositoryFile | null;
  isApproved: boolean;
  handleStartPoints: Function;
  handleEndPoints: Function;
  aoiData: AoiSelectionResponse | null;
  finalAoi: RepositoryFile | null;
  aoiPointsCoords: AoiPointsCords | null;
  selectedAoiIds: Array<number> | [];
  allAoiFiles: RepositoryFile[] | [];
}

const AoiMap = ({
  points,
  aoiPoints,
  selectedAOI,
  isApproved,
  handleStartPoints,
  handleEndPoints,
  aoiData,
  finalAoi,
  aoiPointsCoords,
  selectedAoiIds,
  allAoiFiles
}: AoiMapProps) => {
  const vectorRepoFiles: Array<RepositoryFile> = useAppSelector(getVectorFiles);
  const lulcRepoFile: RepositoryFile = useAppSelector(getLULCRepoFile);
  const geoserverLayerStyles = useAppSelector((state) => getLulcStyleLayers(state));
  const [vectorLayerIds, setVectorLayerIds] = useState<number[]>([]);
  const [selectedLulcLayer, setSelectedLulcLayer] = useState<string[]>([]);
  const [isShowBeeLine, setShowBeeLine] = useState<boolean>(true);
  const [isShowFinalizedAoi, setShowFinalizedAoi] = useState<boolean>(true);

  const handleChangePoint = (latLng: LatLongType, type: FilesCategory) => {
    if (type === FilesCategory.Start) {
      handleStartPoints(latLng);
    } else if (type === FilesCategory.End) {
      handleEndPoints(latLng);
    }
  };

  const handleVectorLayerIds = (fileId: number) => {
    if (includes(vectorLayerIds, fileId)) {
      remove(vectorLayerIds, (route: number) => route === fileId);
    } else {
      vectorLayerIds.push(fileId);
    }

    setVectorLayerIds([...vectorLayerIds]);
  };

  const handleShowLULCLayerOnMap = (selectedStyle: string) => {
    if (includes(selectedLulcLayer, selectedStyle)) {
      remove(selectedLulcLayer, (style: string) => style === selectedStyle);
    } else {
      selectedLulcLayer.push(selectedStyle);
    }

    setSelectedLulcLayer([...selectedLulcLayer]);
  };

  const handleShowBeeLineOnMap = () => {
    setShowBeeLine(!isShowBeeLine);
  };

  const handleShowFinalizedAoi = () => {
    setShowFinalizedAoi(!isShowFinalizedAoi);
  };

  const mapContainer = useMemo(() => {
    return (
      <MapContainer
        center={{ lat: Number(defaultCenter.lat), lng: Number(defaultCenter.lng) }}
        zoom={5}
        maxZoom={20}
        zoomControl={false}
        style={{ width: '100%', height: '100%' }}
        className="map-container"
      >
        <StartEndPoints
          points={points}
          isApproved={isApproved}
          handlePoint={handleChangePoint}
          isShowBeeLine={isShowBeeLine}
        />
        {!points && (
          <StartEndPoints
            points={aoiPoints}
            isApproved={isApproved}
            handlePoint={handleChangePoint}
            isShowBeeLine={isShowBeeLine}
          />
        )}
        {vectorLayerIds && vectorRepoFiles.length > 0 && (
          <RenderVectorLayers vectorRepoFiles={vectorRepoFiles} vectorLayerIds={vectorLayerIds} />
        )}
        <RenderAoiLayer
          selectedAOI={selectedAOI}
          selectedAoiIds={uniq(selectedAoiIds)}
          allAoiFiles={allAoiFiles}
          isShowFinalizedAoi={isShowFinalizedAoi}
        />
        {!isEmpty(lulcRepoFile) && geoserverLayerStyles.length > 0 && (
          <LULCTileLayers
            selectedLulcLayer={selectedLulcLayer}
            geoserverLayerStyles={geoserverLayerStyles}
            lulcRepoFile={lulcRepoFile}
          />
        )}
        <MapActionControl points={aoiPointsCoords} />
        <MapLayers />
        <ControlMapCenter zoomLevel={5} center={points?.start} />
        <ScaleControl position="bottomright" />
      </MapContainer>
    );
  }, [
    points,
    aoiPoints,
    selectedAOI,
    vectorLayerIds,
    aoiData,
    selectedLulcLayer,
    aoiPointsCoords,
    finalAoi,
    isShowBeeLine,
    selectedAoiIds,
    allAoiFiles,
    geoserverLayerStyles,
    isShowFinalizedAoi
  ]);

  return (
    <Row className="aoi-content-row">
      {mapContainer}
      <AOILength points={points} aoiPointsCoords={aoiPointsCoords} finalAoi={finalAoi} />
      <LayerControl
        mayType="aoi"
        aoiPointsCoords={aoiPointsCoords}
        vectorLayerIds={vectorLayerIds}
        selectedLulcLayer={selectedLulcLayer}
        isShowBeeLine={isShowBeeLine}
        isShowFinalizedAoi={isShowFinalizedAoi}
        finalAoi={finalAoi}
        handleVectorLayerIds={handleVectorLayerIds}
        handleShowLULCLayerOnMap={handleShowLULCLayerOnMap}
        handleShowBeeLineOnMap={handleShowBeeLineOnMap}
        handleShowFinalizedAoi={handleShowFinalizedAoi}
      />
    </Row>
  );
};

export default memo(AoiMap);
