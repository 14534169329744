import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Form, Row, Col, Input, Steps, Typography, Modal } from 'antd';
import { organizationSteps } from 'constants/organization.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addOrganization } from 'store/organizations/actions';
import { organizationSync } from 'utils/yupsync';
import getCroppedImg from 'utils/cropImage';
import { encryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
// import './styles.less';

const { Step } = Steps;
const { Text } = Typography;

interface AddOrganizationModalProps {
  setCreateOrgnizationModal: Function;
}

const AddOrganizationModal = ({ setCreateOrgnizationModal }: AddOrganizationModalProps) => {
  const dispatch = useAppDispatch();
  const { isLoadingAddOrganization } = useAppSelector((state) => state.organizations);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<number>(0);
  const [isOpenCropModal, setOpenCropModal] = useState<boolean>(false);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(0.55);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>();

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCroppedImage = useCallback(async () => {
    const croppedImage: any = await getCroppedImg(file, croppedAreaPixels, rotation);
    // const cropFile = new File([croppedImage], form.getFieldValue('organisation_name'));
    setCroppedImage(croppedImage);
    setOpenCropModal(false);
  }, [croppedAreaPixels, rotation]);

  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setSelectedFile(e.target.files[0]);
    setOpenCropModal(true);
  };

  const handleCancelCropped = () => {
    setOpenCropModal(false);
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setCrop({ x: 0, y: 0 });
    setZoom(0.5);
  };

  const handleNext = () => {
    setCurrent(1);
  };

  const handlePrev = () => {
    setCurrent(0);
  };

  const handleCancel = () => {
    form.resetFields();
    setCurrent(0);
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setCreateOrgnizationModal(false);
  };

  const handleCallback = () => {
    setCreateOrgnizationModal(false);
  };

  const onSubmitForm = () => {
    let payload = form.getFieldsValue([
      'organisation_name',
      'admin_firstname',
      'admin_lastname',
      'admin_email'
    ]);

    payload = {
      organisation_name: payload.organisation_name,
      admin_firstname: encodeURIComponent(encryptValue(payload.admin_firstname)),
      admin_lastname: encodeURIComponent(encryptValue(payload.admin_lastname)),
      admin_email: encodeURIComponent(encryptValue(payload.admin_email))
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addOrganization(payload, token, handleCallback, selectedFile));
    });
  };

  return (
    <>
      <Steps current={current} type="navigation" className="formSteps" size="small">
        {organizationSteps.map((item, index) => (
          <Step
            key={item.id}
            title={
              <a onClick={() => handlePrev()}>
                <Text
                  className={`'formStep' ${current === index && 'activeStep'} ${
                    current === 1 && index === 0 ? 'completeStep' : null
                  }`}
                >
                  {item.title}
                </Text>
              </a>
            }
            className="formTitle"
            icon="null"
          />
        ))}
      </Steps>
      <div className="addOrganizationRow">
        <div className="mainContentCol">
          <Form
            className="mainOrgContentForm"
            form={form}
            onFinish={onSubmitForm}
            name="member_detail_form"
            autoComplete="off"
            layout="vertical"
          >
            <div>
              {current === 0 && (
                <div className="mainOrgFormDiv" key={1}>
                  <Form.Item
                    name="organisation_name"
                    label="Name of the Organization"
                    rules={[organizationSync]}
                  >
                    <Input placeholder="Enter Name of Organization" />
                  </Form.Item>
                  <div className="inlineContent">
                    <Form.Item
                      name="admin_firstname"
                      label="Name of Admin"
                      className="inputFirstName"
                      rules={[organizationSync]}
                    >
                      <Input placeholder="Enter first Name" />
                    </Form.Item>
                    <Form.Item
                      name="admin_lastname"
                      label=""
                      className="inputFirstName"
                      rules={[organizationSync]}
                    >
                      <Input placeholder="Enter last Name" />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="admin_email"
                    label="Email address of Admin"
                    rules={[organizationSync]}
                  >
                    <Input type="email" placeholder="Enter email address of admin" />
                  </Form.Item>
                  <Row justify="end">
                    <Button style={{ margin: '0 8px' }} className="formBtn" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Form.Item shouldUpdate className="addMemberBtn">
                      {() => (
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={() => handleNext()}
                          disabled={
                            !form.isFieldsTouched(true) ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                          }
                        >
                          Next
                        </Button>
                      )}
                    </Form.Item>
                  </Row>
                </div>
              )}
              {current === 1 && (
                <div key={2}>
                  <div className="mainOrgFormDiv">
                    <Text className="mainHeading">Upload Organization Logo</Text>
                    <div className="uplodeLogoSection">
                      <Row>
                        <Col xxl={12} xl={12} lg={12}>
                          <div className="uplodeLogo">
                            {croppedImage ? (
                              <img className="logo-image" src={croppedImage} alt="logo" />
                            ) : (
                              ''
                            )}
                          </div>
                        </Col>
                        <Col xxl={12} xl={12} lg={12}>
                          <div className="uplodeLogoContent">
                            <Text className="mainHeading">Name of the Organization</Text>

                            <div className="companyName">
                              <Text>{form.getFieldValue('organisation_name')}</Text>
                            </div>
                            <div className="uplodeFileText">
                              <Text>Upload file from system</Text>
                            </div>
                            <div className="uplodeFileBtnDiv">
                              <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                                className="image-uplode-btn"
                                onChange={handleChange}
                              />
                              <Button className="uplodeFileBtn" size="large">
                                Upload file
                              </Button>
                              <Modal
                                key={1}
                                open={isOpenCropModal}
                                centered
                                width={700}
                                title="Crop Picture"
                                closable
                                className="crop-image-modal"
                                footer={[
                                  <input
                                    key={22}
                                    type="range"
                                    value={zoom}
                                    min={0}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e: any) => {
                                      setZoom(e.target.value);
                                    }}
                                    className="zoom-range"
                                  />,
                                  <div className="crop-modal-btn" key={33}>
                                    <Button type="ghost" onClick={handleCancelCropped}>
                                      Cancel
                                    </Button>
                                    <Button type="primary" onClick={handleCroppedImage}>
                                      Save
                                    </Button>
                                  </div>
                                ]}
                              >
                                <div className="cropper-content" key={11}>
                                  <Cropper
                                    image={file}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    transform={`translate(${crop.x}px, ${crop.y}px) scale(${zoom})`}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                  />
                                </div>
                              </Modal>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row justify="end">
                      <Button
                        style={{ margin: '0 8px' }}
                        className="formBtn"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Form.Item shouldUpdate className="addMemberBtn">
                        {() => (
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoadingAddOrganization}
                          >
                            Add organization
                          </Button>
                        )}
                      </Form.Item>
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddOrganizationModal;
