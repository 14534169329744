import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const UpArrowIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 0.333425C5.15202 0.333425 5.30335 0.384758 5.42669 0.488091L9.42669 3.82143C9.70935 4.05676 9.74802 4.47743 9.51202 4.76009C9.27669 5.04276 8.85669 5.08076 8.57335 4.84543L4.99269 1.86142L1.41802 4.73809C1.13135 4.96876 0.711353 4.92342 0.480687 4.63676C0.25002 4.35009 0.295353 3.93076 0.58202 3.69942L4.58202 0.480758C4.70402 0.382758 4.85202 0.333425 5.00002 0.333425"
        fill={color}
      />
    </svg>
  );
};
UpArrowIcon.defaultProps = {
  color: white,
  height: 5,
  width: 10
};

export default UpArrowIcon;
