import { useEffect, useMemo, useRef, useState } from 'react';
import { FeatureGroup, Marker, Polyline, useMap, Popup, Tooltip } from 'react-leaflet';
import { IoArrowUndoOutline } from 'react-icons/io5';
import { black, white } from 'constants/theme.constants';
import { PointRedIcon, PointLightRedIcon } from 'constants/map.constants';
import { FilesCategory } from 'store/repositoryfile/types';
import { BothPointLatLngType } from 'types/aoi.types';
import { MdDone } from 'react-icons/md';

interface StartEndPointsProps {
  points: BothPointLatLngType | null;
  isApproved: boolean;
  handlePoint: Function;
  isShowBeeLine: boolean;
}

const StartEndPoints = ({
  points,
  isApproved,
  handlePoint,
  isShowBeeLine
}: StartEndPointsProps) => {
  const map = useMap();
  const pointRef: any = useRef(null);
  const startMarkerRef = useRef(null);
  const endMarkerRef = useRef(null);
  const [isBound, setBound] = useState<boolean>(false);
  const [dragStartPoint, setDragStartPoint] = useState<any | null>(null);
  const [dragEndPoint, setDragEndPoint] = useState<any | null>(null);

  useEffect(() => {
    if (isBound) return;
    if (!points || !points?.start || !points?.end) return;

    if (pointRef.current) {
      setTimeout(() => {
        map.fitBounds(pointRef.current.getBounds());
      }, 300);
      setBound(true);
    }
  }, [points, isBound]); // eslint-disable-line

  // Start point
  const startEventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = startMarkerRef.current;
        marker.openPopup();
        if (marker != null) {
          setDragStartPoint(marker.getLatLng());
        }
      }
    }),
    []
  );

  const handleUndoStartPoint = () => {
    setDragStartPoint(null);
  };

  const handleStartPointChangeDone = () => {
    if (dragStartPoint) {
      handlePoint(dragStartPoint, FilesCategory.Start);
    }
    setTimeout(() => {
      setDragStartPoint(null);
    }, 0);
  };

  // End point
  const endEventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = endMarkerRef.current;
        marker.openPopup();
        if (marker != null) {
          setDragEndPoint(marker.getLatLng());
        }
      }
    }),
    []
  );

  const handleUndoEndPoint = () => {
    setDragEndPoint(null);
  };

  const handleEndPointChangeDone = () => {
    if (dragEndPoint) {
      handlePoint(dragEndPoint, FilesCategory.End);
    }
    setTimeout(() => {
      setDragEndPoint(null);
    }, 0);
  };

  if (!points) return null;

  return (
    <FeatureGroup ref={pointRef}>
      {points?.start && (
        <Marker
          zIndexOffset={1000}
          draggable={!isApproved}
          eventHandlers={startEventHandlers}
          icon={dragStartPoint ? PointLightRedIcon : PointRedIcon}
          position={{
            lat: Number(points.start.lat),
            lng: Number(points.start.lng)
          }}
          ref={startMarkerRef}
        >
          <Tooltip offset={[-43, -3]} className="points-tooltip" permanent>
            Start Point
          </Tooltip>
        </Marker>
      )}
      {points?.end && (
        <Marker
          zIndexOffset={1000}
          draggable={!isApproved}
          eventHandlers={endEventHandlers}
          icon={dragEndPoint ? PointLightRedIcon : PointRedIcon}
          position={{
            lat: Number(points.end.lat),
            lng: Number(points.end.lng)
          }}
          ref={endMarkerRef}
        >
          <Tooltip offset={[-39, -3]} className="points-tooltip" permanent>
            End Point
          </Tooltip>
        </Marker>
      )}
      {dragStartPoint && (
        <Marker
          zIndexOffset={1000}
          draggable={!isApproved}
          eventHandlers={startEventHandlers}
          icon={PointRedIcon}
          position={{
            lat: Number(dragStartPoint.lat),
            lng: Number(dragStartPoint.lng)
          }}
          ref={startMarkerRef}
        >
          <Popup className="points-popup" offset={[0, 10]}>
            <IoArrowUndoOutline
              size={16}
              color={white}
              style={{ cursor: 'pointer' }}
              onClick={handleUndoStartPoint}
            />
            <MdDone
              size={16}
              color={white}
              style={{ cursor: 'pointer' }}
              onClick={handleStartPointChangeDone}
            />
          </Popup>
        </Marker>
      )}
      {dragEndPoint && (
        <Marker
          zIndexOffset={1000}
          draggable={!isApproved}
          eventHandlers={endEventHandlers}
          icon={PointRedIcon}
          position={{
            lat: Number(dragEndPoint.lat),
            lng: Number(dragEndPoint.lng)
          }}
          ref={endMarkerRef}
        >
          <Popup className="points-popup" offset={[0, 10]}>
            <IoArrowUndoOutline
              size={16}
              color={white}
              style={{ cursor: 'pointer' }}
              onClick={handleUndoEndPoint}
            />
            <MdDone
              size={16}
              color={white}
              style={{ cursor: 'pointer' }}
              onClick={handleEndPointChangeDone}
            />
          </Popup>
        </Marker>
      )}
      {points?.start && points?.end && isShowBeeLine && (
        <Polyline
          pane="markerPane"
          positions={[
            [Number(points.start.lat), Number(points.start.lng)],
            [Number(points.end.lat), Number(points.end.lng)]
          ]}
          pathOptions={{
            color: black,
            weight: 2
          }}
        />
      )}
    </FeatureGroup>
  );
};

export default StartEndPoints;
