export type User = {
  accessToken: string;
  refreshToken: string;
  email: string;
  id: string;
  phone: string | null;
  firstname: string | null;
  lastname: string | null;
  empid: string | null;
  department: string | null;
  organisationid: number | null;
};

export type Rsa = {
  rsa: string;
};

export type LoginPayload = {
  email: string;
  password: string;
};

export type UserDetailPayload = {
  user_id: string;
  first_name: string | null;
  last_name: string | null;
  phone_no: string | null;
  department: string | null;
  emp_id: string | null;
};

export enum UserRole {
  SuperAdmin = 'super_admin',
  Admin = 'admin',
  Manager = 'manager'
}

export type UserState = {
  isLoadingRSA: boolean;
  rsaKey: string | null;
  isLoadingLogin: boolean;
  user: User | null;
  isAddUserDetaiRequest: boolean;
  isLoadingLogout: boolean;
  isRefreshToken: boolean;
};
