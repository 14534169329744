import { Input } from 'antd';

interface ProjectFilterType {
  onFilter: any;
  filterText: string;
}

const ProjectFilter = ({ onFilter, filterText }: ProjectFilterType) => (
  <Input
    id="search"
    type="text"
    placeholder="Search by Project ID or Project Name"
    value={filterText}
    onChange={onFilter}
    className="filter-input"
  />
);

export default ProjectFilter;
