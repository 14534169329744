import { primary } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const DownloadIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94832 2.70801C8.30364 2.70801 7.76587 3.2007 7.70958 3.84291C7.59187 5.18565 7.56209 6.53437 7.62031 7.88045C7.55003 7.88516 7.47976 7.89007 7.40951 7.89519L6.16825 7.98567C5.37488 8.04351 4.93138 8.92814 5.3597 9.59846C6.27317 11.0281 7.45022 12.2709 8.82808 13.2607L9.32537 13.6179C9.72855 13.9075 10.2716 13.9075 10.6748 13.6179L11.1721 13.2607C12.5499 12.2709 13.727 11.0281 14.6405 9.59846C15.0688 8.92814 14.6253 8.04351 13.8319 7.98567L12.5906 7.89519C12.5204 7.89007 12.4501 7.88516 12.3798 7.88045C12.4381 6.53437 12.4083 5.18565 12.2906 3.84291C12.2343 3.2007 11.6965 2.70801 11.0518 2.70801H8.94832ZM8.90131 8.42861C8.80655 6.93926 8.82425 5.44482 8.95428 3.95801H11.0459C11.1759 5.44482 11.1936 6.93926 11.0988 8.42861C11.0882 8.59559 11.145 8.75985 11.2564 8.88463C11.3679 9.00942 11.5247 9.08431 11.6918 9.09254C11.9613 9.10582 12.2306 9.12226 12.4998 9.14188L13.4012 9.2076C12.6016 10.3853 11.6004 11.4139 10.4428 12.2454L10.0001 12.5635L9.55736 12.2454C8.39977 11.4139 7.39854 10.3853 6.59892 9.2076L7.50039 9.14188C7.76954 9.12226 8.03888 9.10582 8.30833 9.09254C8.47544 9.08431 8.63227 9.00942 8.74372 8.88463C8.85517 8.75985 8.91194 8.59559 8.90131 8.42861Z"
        fill={color}
      />
      <path
        d="M4.79167 14.1663C4.79167 13.8212 4.51185 13.5413 4.16667 13.5413C3.82149 13.5413 3.54167 13.8212 3.54167 14.1663V15.833C3.54167 16.6384 4.19459 17.2913 5.00001 17.2913H15C15.8054 17.2913 16.4583 16.6384 16.4583 15.833V14.1663C16.4583 13.8212 16.1785 13.5413 15.8333 13.5413C15.4882 13.5413 15.2083 13.8212 15.2083 14.1663V15.833C15.2083 15.9481 15.1151 16.0413 15 16.0413H5.00001C4.88495 16.0413 4.79167 15.9481 4.79167 15.833V14.1663Z"
        fill={color}
      />
    </svg>
  );
};
DownloadIcon.defaultProps = {
  color: primary,
  height: 20,
  width: 20
};

export default DownloadIcon;
