import { Button, Form, Input, Row } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { addOrganizationMembers } from 'store/organizations/actions';
import { AddOrganizationMemberPayload } from 'store/organizations/types';
import { encryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

interface AddOrganizationMemberModalProps {
  setNewProjectMemberModal: Function;
}

const AddOrganizationMemberModal = ({
  setNewProjectMemberModal
}: AddOrganizationMemberModalProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { selectedOrganization, isNewOrganizationMember } = useAppSelector(
    (state) => state.organizations
  );

  const handleSubmitForm = (values: AddOrganizationMemberPayload) => {
    const emails = values.members.map((item) => encryptValue(item.email));

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        addOrganizationMembers(
          {
            members_email_list: emails,
            organisation_id: selectedOrganization?.id!
          },
          selectedOrganization?.id!,
          token
        )
      );
    });
    setNewProjectMemberModal(false);
    form.resetFields();
  };

  return (
    <>
      <p className="titleContent">Team Onboarding</p>
      <Form
        className="mainContentForm"
        form={form}
        name="add_member_form"
        onFinish={handleSubmitForm}
        initialValues={{ members: [{ email: '' }] }}
        autoComplete="off"
      >
        <div className="mainFormDiv">
          <h3>Add Team Members to Organization</h3>
          <Form.List name="members">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row key={field.key} justify="space-between" className="inputMainContent">
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      rules={[{ required: true, message: 'Required' }]}
                      className="emailInputContent"
                    >
                      <Input
                        type="email"
                        placeholder="Email address of member"
                        className="innerInputContent"
                      />
                    </Form.Item>
                    {fields.length > 1 && (
                      <AiOutlineMinusCircle onClick={() => remove(field.name)} />
                    )}
                  </Row>
                ))}

                <Form.Item className="memberBtn">
                  <Button onClick={() => add()} icon={<AiOutlinePlusCircle />}>
                    Add another Member
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row justify="end">
            <Form.Item className="addMemberBtn">
              <Button loading={isNewOrganizationMember} type="primary" htmlType="submit">
                Add Members
              </Button>
            </Form.Item>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default AddOrganizationMemberModal;
