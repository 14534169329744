import { Button, Divider, Form, InputNumber, Typography } from 'antd';
import {
  costFeatureNotShowParameter,
  FeatureParameter,
  towerObjectKeys,
  towerParameter
} from 'constants/analytics.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { capitalize, difference, intersection, isEmpty, mapKeys, omitBy } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { addAnalyticsUnitCost, editAnalyticsUnitCost } from 'store/analytics/actions';
import { setRequestingRefreshToken } from 'store/users';
import { getAsyncStorageValue } from 'utils/localStorage';
import { analyticsFormKey, fromateFeatureKey } from 'utils/util';

const { Text } = Typography;

const UnitCostForm = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { analyticsData, isRequestingAddAnalyticsCost, isRequestingEditAnalyticsCost } =
    useAppSelector((state) => state.analytics);
  const [refreshForm, setRefreshForm] = useState(1);
  const [featureFormItems, setFeatureFormItems] = useState<string[]>([]);
  const [towerFormItems, setTowerFormItems] = useState<string[]>([]);
  const [featureRouteKeys, setFeatureRouteKeys] = useState<string[]>([]);
  const [unitCostkeyObj, setUnitCostkeyObj] = useState<any>({});

  useEffect(() => {
    if (analyticsData && analyticsData.unit_cost) {
      const unitCost: any = analyticsData.unit_cost;
      form.setFieldsValue(unitCost);
    }

    if (analyticsData && !isEmpty(analyticsData.routes_analytics)) {
      const routeKeys = Object.keys(analyticsData.routes_analytics);
      if (routeKeys.length > 0) {
        const routeData = analyticsData.routes_analytics[routeKeys[0]];
        const routeObjKeys = Object.keys(routeData);
        const fromatedKeys = routeObjKeys.map((key) => fromateFeatureKey(key));
        setFeatureRouteKeys(fromatedKeys);

        const formatedRouteData = mapKeys(routeData, (_, key: string) => analyticsFormKey(key));
        const formNameKeys = Object.keys(formatedRouteData);
        setUnitCostkeyObj(formNameKeys);

        const featureFormItems: string[] = difference(fromatedKeys, costFeatureNotShowParameter);
        setFeatureFormItems(featureFormItems);

        const visibleTowerParameter: string[] = intersection(fromatedKeys, towerParameter);
        setTowerFormItems(visibleTowerParameter);
      }
    }
  }, [analyticsData]);

  const handleCallback = () => {
    setRefreshForm(refreshForm + 1);
  };

  const handleAddUnitCost = (values: any) => {
    const result = omitBy(values, (v) => v === undefined || v === null);
    const body: any = {};

    // eslint-disable-next-line
    for (const key in result) {
      body[unitCostkeyObj[key]] = result[key];
    }

    if (!isEmpty(body)) {
      dispatch(setRequestingRefreshToken(true));
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(setRequestingRefreshToken(false));
        dispatch(addAnalyticsUnitCost(result, selectedProject.id, token, handleCallback));
      });
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleUpdateUnitCost = (values: any) => {
    const result = omitBy(values, (v) => v === undefined || v === null);
    const body: any = {};

    // eslint-disable-next-line
    for (const key in result) {
      body[unitCostkeyObj[key]] = result[key];
    }

    if (!isEmpty(body)) {
      dispatch(setRequestingRefreshToken(true));
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(setRequestingRefreshToken(false));
        dispatch(editAnalyticsUnitCost(result, selectedProject.id, token, handleCallback));
      });
    } else {
      toast.error('Something went wrong!');
    }
  };

  return (
    <Form
      key={refreshForm}
      form={form}
      onFinish={
        analyticsData && Boolean(!isEmpty(analyticsData.unit_cost))
          ? handleUpdateUnitCost
          : handleAddUnitCost
      }
      className="soft-cost-form"
      autoComplete="off"
    >
      <div className="unit-soft-cost-row">
        <Text className="soft-cost-title">SOFT COST - ROW</Text>
      </div>
      <div className="soft-cost-feature">
        <div className="soft-cost-feature-title">
          <Text>Features</Text>
          <Text>Unit cost / km</Text>
        </div>
        <Divider />
        <div className="soft-cost-form-div">
          {featureFormItems.length > 0 &&
            featureFormItems.map((f: string) => (
              <Form.Item
                key={f}
                label={capitalize(f)}
                name={f ? analyticsFormKey(f) : ''}
                className="soft-cost-item"
                initialValue={0}
              >
                <InputNumber min={0} />
              </Form.Item>
            ))}
        </div>
      </div>
      <div className="unit-soft-cost-row">
        <Text className="soft-cost-title">TECHNICAL COST</Text>
      </div>
      <div className="soft-cost-feature">
        <div className="soft-cost-feature-title">
          <Text>Tower Type</Text>
          <Text>Unit cost / tower</Text>
        </div>
        <Divider />
        <div className="soft-cost-form-div">
          <Text>{towerObjectKeys[FeatureParameter.AngularTowers]}</Text>
          {towerFormItems.length > 0 &&
            towerFormItems.map((t: string) => {
              return (
                <Form.Item
                  key={t}
                  label={towerObjectKeys[t]}
                  name={t ? analyticsFormKey(t) : ''}
                  className="soft-cost-item tower-type"
                  initialValue={0}
                >
                  <InputNumber min={0} />
                </Form.Item>
              );
            })}
          {featureRouteKeys &&
            featureRouteKeys.length > 0 &&
            featureRouteKeys.includes(FeatureParameter.SpecialTowers) && (
              <Form.Item
                label={capitalize(FeatureParameter.SpecialTowers)}
                name="special_towers"
                initialValue={0}
                className="soft-cost-item"
              >
                <InputNumber min={0} />
              </Form.Item>
            )}
          {featureRouteKeys &&
            featureRouteKeys.length > 0 &&
            featureRouteKeys.includes(FeatureParameter.SpanTowers) && (
              <Form.Item
                label={capitalize(FeatureParameter.SpanTowers)}
                name="span_towers"
                initialValue={0}
                className="soft-cost-item"
              >
                <InputNumber min={0} />
              </Form.Item>
            )}
        </div>
      </div>
      {featureRouteKeys &&
        featureRouteKeys.length > 0 &&
        featureRouteKeys.includes(FeatureParameter.PowerlineCables) && (
          <>
            <div className="unit-soft-cost-row">
              <Text className="soft-cost-title">Cable</Text>
            </div>
            <div className="soft-cost-feature">
              <div className="soft-cost-feature-title">
                <Text>Cable</Text>
                <Text>Unit cost / cable</Text>
              </div>
              <Divider />
              <div className="soft-cost-form-div">
                <Form.Item
                  label={capitalize(FeatureParameter.PowerlineCables)}
                  name="powerline_cable"
                  initialValue={0}
                  className="soft-cost-item"
                >
                  <InputNumber min={0} />
                </Form.Item>
              </div>
            </div>
          </>
        )}
      <Divider style={{ background: 'black', height: '2px' }} />
      <div className="form-submit-btn">
        <Button
          type="primary"
          htmlType="submit"
          loading={
            analyticsData && Boolean(!isEmpty(analyticsData.unit_cost))
              ? isRequestingEditAnalyticsCost
              : isRequestingAddAnalyticsCost
          }
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default UnitCostForm;
