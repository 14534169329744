import { useMemo, useState } from 'react';
import { Row } from 'antd';
import { includes, isEmpty, remove } from 'lodash';
import { MapContainer, ScaleControl } from 'react-leaflet';
import { defaultCenter } from 'constants/common.constant';
import LayerControl from 'components/Map/LayerControl';
import MapActionControl from 'components/Map/MapActionControl';
import MapLayers from 'components/Map/MapLayers';
import RenderRouteLayer from 'components/Map/RenderRouteLayer';
import RenderVectorLayers from 'components/Map/RenderVectorLayers';
import RenderPoints from 'components/Map/RenderPoints';
import RenderApprovedAoiLayer from 'components/Map/RenderApprovedAoiLayer';
import LULCTileLayers from 'components/Map/LULCTileLayers';
import AOILength from 'components/Map/AOILength';
import DEMTileLayer from 'components/Map/DEMTileLayer';
import DSMTileLayer from 'components/Map/DSMTileLayer';
import SateliteImageTileLayer from 'components/Map/SateliteImageTileLayer';
import { useAppSelector } from 'hooks/useReduxHook';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getVectorFiles } from 'store/aoi';
import { AoiPointsCords, AoiSelectionResponse } from 'store/aoi/types';
import {
  getDEMRepoFile,
  getDSMRepoFile,
  getHighResolutionSataliteImagesRepoFile,
  getLULCRepoFile,
  getLulcStyleLayers
} from 'store/repositoryfile';
import RouteAnalyticsData from './RouteAnalyticsData';
import DemLegend from './DemLegend';
import DsmLegend from './DsmLegend';
import './styles.less';

interface RouteMapProps {
  selectedRouteFilesIds: Array<number> | [];
  routeFileList: Array<RepositoryFile>;
  aoiPointsCoords: AoiPointsCords | null;
  approvedAoi: AoiSelectionResponse | null;
  finalAoi: RepositoryFile | null;
}

const RouteMap = ({
  selectedRouteFilesIds,
  routeFileList,
  aoiPointsCoords,
  approvedAoi,
  finalAoi
}: RouteMapProps) => {
  const vectorRepoFiles: Array<RepositoryFile> = useAppSelector(getVectorFiles);
  const lulcRepoFile: RepositoryFile = useAppSelector(getLULCRepoFile);
  const demRepoFile: RepositoryFile = useAppSelector(getDEMRepoFile);
  const dsmRepoFile: RepositoryFile = useAppSelector(getDSMRepoFile);
  const highResolutionSataliteImagesRepoFile: RepositoryFile = useAppSelector(
    getHighResolutionSataliteImagesRepoFile
  );
  const geoserverLayerStyles = useAppSelector((state) => getLulcStyleLayers(state));
  const [vectorLayerIds, setVectorLayerIds] = useState<number[]>([]);
  const [selectedLulcLayer, setSelectedLulcLayer] = useState<string[]>([]);
  const [selectedDEMStyle, setSelectedDEMStyle] = useState<string | null>(null);
  const [isShowBeeLine, setShowBeeLine] = useState<boolean>(true);
  const [isShowFinalizedAoi, setShowFinalizedAoi] = useState<boolean>(true);
  const [isShowDEMLegend, setShowDEMLegend] = useState<boolean>(false);
  const [isShowDSMLegend, setShowDSMLegend] = useState<boolean>(false);
  const [isShowhandleSatelliteImage, setShowhandleSatelliteImage] = useState<boolean>(false);
  const [selectedAnalyticsRouteData, setSelectedAnalyticsRouteData] =
    useState<RepositoryFile | null>(null);

  const handleVectorLayerIds = (fileId: number) => {
    if (includes(vectorLayerIds, fileId)) {
      remove(vectorLayerIds, (route: number) => route === fileId);
    } else {
      vectorLayerIds.push(fileId);
    }

    setVectorLayerIds([...vectorLayerIds]);
  };

  const handleShowLULCLayerOnMap = (selectedStyle: string) => {
    if (includes(selectedLulcLayer, selectedStyle)) {
      remove(selectedLulcLayer, (style: string) => style === selectedStyle);
    } else {
      selectedLulcLayer.push(selectedStyle);
    }

    setSelectedLulcLayer([...selectedLulcLayer]);
  };

  const handleShowBeeLineOnMap = () => {
    setShowBeeLine(!isShowBeeLine);
  };

  const handleShowFinalizedAoi = () => {
    setShowFinalizedAoi(!isShowFinalizedAoi);
  };

  const handleClickRoute = (file: RepositoryFile | null) => {
    setSelectedAnalyticsRouteData(file);
  };

  const handleCloseRouteData = () => {
    setSelectedAnalyticsRouteData(null);
  };

  const handleDEMLegend = (isShow: boolean) => {
    setShowDEMLegend(isShow);
    setShowDSMLegend(false);
    setSelectedDEMStyle(null);
  };

  const handleDSMLegend = (isShow: boolean) => {
    setShowDSMLegend(isShow);
    setShowDEMLegend(false);
    setSelectedDEMStyle(null);
  };

  const handleDemStyle = (demStyle: string) => {
    if (selectedDEMStyle === demStyle) {
      setSelectedDEMStyle(null);
    } else {
      setSelectedDEMStyle(demStyle);
    }
  };

  const handleSatelliteImage = (isShow: boolean) => {
    setShowhandleSatelliteImage(isShow);
  };

  const mapContainer = useMemo(() => {
    return (
      <MapContainer
        center={{ lat: Number(defaultCenter.lat), lng: Number(defaultCenter.lng) }}
        zoom={5}
        maxZoom={20}
        zoomControl={false}
        style={{ width: '100%', height: '100%' }}
        className="map-container"
      >
        {!isEmpty(lulcRepoFile) && geoserverLayerStyles.length > 0 && (
          <LULCTileLayers
            selectedLulcLayer={selectedLulcLayer}
            geoserverLayerStyles={geoserverLayerStyles}
            lulcRepoFile={lulcRepoFile}
          />
        )}
        {!isEmpty(demRepoFile) && isShowDEMLegend && (
          <DEMTileLayer repoFile={demRepoFile} selectedDEMStyle={selectedDEMStyle} />
        )}
        {!isEmpty(dsmRepoFile) && isShowDSMLegend && (
          <DSMTileLayer repoFile={dsmRepoFile} selectedDSMStyle={selectedDEMStyle} />
        )}
        {!isEmpty(highResolutionSataliteImagesRepoFile) && isShowhandleSatelliteImage && (
          <SateliteImageTileLayer repoFile={highResolutionSataliteImagesRepoFile} />
        )}
        {aoiPointsCoords &&
          aoiPointsCoords.start_point &&
          aoiPointsCoords.end_point &&
          aoiPointsCoords.start_point.coordinates &&
          aoiPointsCoords.end_point.coordinates && (
            <RenderPoints
              startPoint={aoiPointsCoords.start_point.coordinates}
              endPoint={aoiPointsCoords.end_point.coordinates}
              isShowBeeLine={isShowBeeLine}
            />
          )}
        {vectorLayerIds && vectorRepoFiles.length > 0 && (
          <RenderVectorLayers vectorRepoFiles={vectorRepoFiles} vectorLayerIds={vectorLayerIds} />
        )}
        {routeFileList && (
          <RenderRouteLayer
            routeFileList={routeFileList}
            selectedRouteFilesIds={selectedRouteFilesIds}
            handleOnClickRoute={handleClickRoute}
          />
        )}
        {approvedAoi && (
          <RenderApprovedAoiLayer
            approvedAoi={approvedAoi}
            isShowFinalizedAoi={isShowFinalizedAoi}
          />
        )}
        <MapLayers />
        <MapActionControl points={aoiPointsCoords} />
        <ScaleControl position="bottomright" />
      </MapContainer>
    );
  }, [
    selectedRouteFilesIds,
    vectorRepoFiles,
    vectorLayerIds,
    selectedLulcLayer,
    finalAoi,
    aoiPointsCoords,
    isShowBeeLine,
    isShowFinalizedAoi,
    demRepoFile,
    isShowDEMLegend,
    isShowDSMLegend,
    selectedDEMStyle,
    isShowhandleSatelliteImage,
    geoserverLayerStyles
  ]);

  return (
    <Row className="route-content-row">
      {mapContainer}
      {selectedAnalyticsRouteData && (
        <RouteAnalyticsData
          selectedAnalyticsRouteData={selectedAnalyticsRouteData}
          handleCloseRouteData={handleCloseRouteData}
        />
      )}
      <AOILength aoiPointsCoords={aoiPointsCoords} finalAoi={finalAoi} />
      {isShowDEMLegend && (
        <DemLegend
          handleDemStyle={handleDemStyle}
          selectedDEMStyle={selectedDEMStyle}
          handleDEMLegend={handleDEMLegend}
        />
      )}
      {isShowDSMLegend && (
        <DsmLegend
          handleDemStyle={handleDemStyle}
          selectedDEMStyle={selectedDEMStyle}
          handleDSMLegend={handleDSMLegend}
        />
      )}
      <LayerControl
        mayType="route"
        aoiPointsCoords={aoiPointsCoords}
        vectorLayerIds={vectorLayerIds}
        selectedLulcLayer={selectedLulcLayer}
        isShowBeeLine={isShowBeeLine}
        isShowFinalizedAoi={isShowFinalizedAoi}
        finalAoi={finalAoi}
        handleVectorLayerIds={handleVectorLayerIds}
        handleShowLULCLayerOnMap={handleShowLULCLayerOnMap}
        handleShowBeeLineOnMap={handleShowBeeLineOnMap}
        handleShowFinalizedAoi={handleShowFinalizedAoi}
        handleDEMLegend={handleDEMLegend}
        isShowDEMLegend={isShowDEMLegend}
        handleDSMLegend={handleDSMLegend}
        isShowDSMLegend={isShowDSMLegend}
        handleSatelliteImage={handleSatelliteImage}
        isShowhandleSatelliteImage={isShowhandleSatelliteImage}
      />
    </Row>
  );
};

export default RouteMap;
