import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { find, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import PointFormModal from 'components/Modal/AOIpoints/PointFormModal';
import {
  endPointInputOption,
  startPointInputOption,
  beelinePointInputOption
} from 'constants/map.constants';
import { primary } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import SendForApprovalModal from 'components/Modal/AoiApproveModal/SendForApprovalModal';
import RequestForSatsureApprovalModal from 'components/Modal/AoiApproveModal/RequestApprovalModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { UserRole } from 'store/users/types';
import {
  Category,
  RepositoryFile,
  FilesCategory,
  UploadFilePayload
} from 'store/repositoryfile/types';
import { selectBeeRepoFile, selectEndRepoFile, selectStartRepoFile } from 'store/repositoryfile';
import {
  addAoiPointsCoords,
  deleteAoiPointsCoords,
  getBeeLinePointCoords,
  getCoordsByUploadBeeLinePointFile,
  getCoordsByUploadEndPointFile,
  getCoordsByUploadStartPointFile,
  getEndPointCoords,
  getStartPointCoords,
  updateAoiPointsCoords
} from 'store/aoi/actions';
import { AoiPayload } from 'store/aoi/types';
import { addProjectStageChangeStatus } from 'store/projects/actions';
import { ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { LatLongType, BothPointLatLngType, PointTypeValue } from 'types/aoi.types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { asyncFileUpload } from 'utils/connect';
import RequiredLabel from '../core/RequiredLabel';
import PointInputDownload from './PointInputDownload';
import PointInputSelection from './PointInputSelection';
import BothPointInputSelection from './BothPointInputSelection';
import PointsRequestDetail from './PointsRequestDetail';

interface PointsTabProps {
  handlePointsData: (data: BothPointLatLngType) => void;
}

const { Text } = Typography;

const PointsTab = forwardRef(({ handlePointsData }: PointsTabProps, ref) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = getStorageValue(USER_ROLE);
  const { user } = useAppSelector((state) => state.user);
  const { selectedProject, project } = useAppSelector((state) => state.projects);
  const { categories } = useAppSelector((state) => state.repofiles);
  const {
    isRequestingStartPoint,
    isRequestingEndPoint,
    isRequestingBeePoint,
    aoiPointsCoords,
    isRequestingaoiPointsCoords,
    isRequestingDeleteAoiPointsCoords,
    isRequestingUpdateAoiPointsCoords
  } = useAppSelector((state) => state.aoi);
  const selectedStartRepoFile: RepositoryFile[] = useAppSelector(selectStartRepoFile);
  const selectedEndRepoFile: RepositoryFile[] = useAppSelector(selectEndRepoFile);
  const selectedBeeRepoFile: RepositoryFile[] = useAppSelector(selectBeeRepoFile);
  const [isOpenStartPointModal, setOpenStarPointModal] = useState(false);
  const [isOpenEndPointModal, setOpenEndPointModal] = useState(false);
  const [startRepoFileId, setStartRepoFileId] = useState<number | null>(null);
  const [endRepoFileId, setEndRepoFileId] = useState<number | null>(null);
  const [beeLineRepoFileId, setBeeLineRepoFileId] = useState<number | null>(null);
  const [startLatLng, setStartLatLng] = useState<LatLongType | null>(null);
  const [endLatLng, setEndLatLng] = useState<LatLongType | null>(null);
  const [bothPointLatLng, setBothPointLatLng] = useState<BothPointLatLngType | null>(null);
  const [finalPoints, setFinalPoints] = useState<BothPointLatLngType | null>(null);
  const [startValue, setStartValue] = useState<PointTypeValue | null>(null);
  const [endValue, setEndValue] = useState<PointTypeValue | null>(null);
  const [beeValue, setBeeLineValue] = useState<PointTypeValue | null>(null);
  const [startPointFile, setStartPointFile] = useState<File | null>(null);
  const [endPointFile, setEndPointFile] = useState<File | null>(null);
  const [beeLinePointFile, setBeeLinePointFile] = useState<File | null>(null);
  const [isOpenApproveModal, setApproveModal] = useState<boolean>(false);
  const [isOpenDeclineModal, setDeclineModal] = useState<boolean>(false);
  const [isOpenApproveConfirmModal, setApproveConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (aoiPointsCoords && aoiPointsCoords.bee_line) {
      setBothPointLatLng({
        start: aoiPointsCoords.start_point.coordinates,
        end: aoiPointsCoords.end_point.coordinates,
        is_bee_line: true
      });
    } else {
      if (aoiPointsCoords && aoiPointsCoords.start_point) {
        setStartLatLng(aoiPointsCoords.start_point.coordinates);
      }
      if (aoiPointsCoords && aoiPointsCoords.end_point) {
        setEndLatLng(aoiPointsCoords.end_point.coordinates);
      }
    }
  }, [aoiPointsCoords]);

  useImperativeHandle(ref, () => ({
    handleReverseChangeStartPoint: (latLng: LatLongType) => {
      if (bothPointLatLng && bothPointLatLng.is_bee_line) {
        setBothPointLatLng({
          start: latLng,
          end: bothPointLatLng?.end!,
          is_bee_line: true
        });
        const updatedFinalpoint = { start: latLng, end: bothPointLatLng?.end!, is_bee_line: true };
        setFinalPoints(updatedFinalpoint);
        handlePointsData(updatedFinalpoint);
      } else {
        setStartLatLng(latLng);
        const updatedFinalpoint = { start: latLng, end: endLatLng, is_bee_line: false };
        setFinalPoints(updatedFinalpoint);
        handlePointsData(updatedFinalpoint);
      }
    },
    handleReverseChangEndPoint: (latLng: LatLongType) => {
      if (bothPointLatLng && bothPointLatLng.is_bee_line) {
        setBothPointLatLng({
          start: bothPointLatLng?.start!,
          end: latLng,
          is_bee_line: true
        });
        const updatedFinalpoint = {
          start: bothPointLatLng?.start!,
          end: latLng,
          is_bee_line: true
        };
        setFinalPoints(updatedFinalpoint);
        handlePointsData(updatedFinalpoint);
      } else {
        setEndLatLng(latLng);
        const updatedFinalpoint = { start: startLatLng, end: latLng, is_bee_line: false };
        setFinalPoints(updatedFinalpoint);
        handlePointsData(updatedFinalpoint);
      }
    }
  }));

  const handleChangeProjectStagesStatus = (
    stageName: ProjectStagesList,
    projectStageStatus: ProposedTimelineStatus
  ) => {
    const payload = {
      stage: stageName,
      project_id: selectedProject.id.toString(),
      status: projectStageStatus
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addProjectStageChangeStatus(payload, token));
    });
  };

  const handleClearStartPoint = () => {
    setStartLatLng(null);
    setStartValue(null);
    setStartPointFile(null);
    if (finalPoints && startLatLng) {
      const updatedFinalpoint = { start: null, end: finalPoints.end, is_bee_line: false };
      setFinalPoints(updatedFinalpoint);
      handlePointsData(updatedFinalpoint);
    }
  };

  const handleClearEndPoints = () => {
    setEndLatLng(null);
    setEndValue(null);
    setEndPointFile(null);
    if (finalPoints && endLatLng) {
      const updatedFinalpoint = { start: finalPoints.start, end: null, is_bee_line: false };
      setFinalPoints(updatedFinalpoint);
      handlePointsData(updatedFinalpoint);
    }
  };

  const handleClearBeelinePoints = () => {
    setBothPointLatLng(null);
    setBeeLineValue(null);
    setBeeLinePointFile(null);
    if (finalPoints && bothPointLatLng) {
      const updatedFinalpoint = { start: null, end: null, is_bee_line: true };
      setFinalPoints(updatedFinalpoint);
      handlePointsData(updatedFinalpoint);
    }
  };

  const handleUploadNewRepoFile = (file: File, category: FilesCategory) => {
    const categoryData: Category = find(categories, (o: Category) => o.category_name === category);
    const payload: UploadFilePayload = {
      comment: 'Null',
      category_id: categoryData.id,
      buffer: '0',
      user_id: user.id,
      project_id: Number(selectedProject?.id)
    };

    return asyncFileUpload(payload, file);
  };

  const handleChangeStartPointSelection = (value: string) => {
    if (value) {
      if (value === PointTypeValue.UploadFile) {
        setStartValue(value);
      }
      if (value === PointTypeValue.Start) {
        setStartValue(value);
        setOpenStarPointModal(true);
      }
      if (value === PointTypeValue.StartFromFile) {
        const handleGetLatLng = (data: LatLongType | null, id: number) => {
          if (data) {
            setStartLatLng(data);
            setStartValue(value);
            setStartRepoFileId(id);
            handleClearBeelinePoints();
          } else {
            setStartValue(null);
            setStartLatLng(null);
            setStartRepoFileId(0);
          }
        };
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(getStartPointCoords(selectedStartRepoFile, token, handleGetLatLng));
        });
      }
    }
  };

  const handleSubmitStartPoint = () => {
    if (!startLatLng) return;
    const updatedFinalpoint = { start: startLatLng, end: null, is_bee_line: false };
    setFinalPoints(updatedFinalpoint);
    handlePointsData(updatedFinalpoint);
    handleChangeProjectStagesStatus(
      ProjectStagesList.DefiningCoordinates,
      ProposedTimelineStatus.OnGoing
    );
  };

  const handleChangeEndPointSelection = (value: string) => {
    if (value) {
      if (value === PointTypeValue.UploadFile) {
        setEndValue(value);
      }
      if (value === PointTypeValue.End) {
        setEndValue(value);
        setOpenEndPointModal(true);
      }
      if (value === PointTypeValue.EndFromFile) {
        const handleGetLatLng = (data: LatLongType | null, id: number) => {
          if (data) {
            setEndValue(value);
            setEndLatLng(data);
            setEndRepoFileId(id);
            handleClearBeelinePoints();
          } else {
            setEndValue(null);
            setEndLatLng(null);
            setEndRepoFileId(0);
          }
        };
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(getEndPointCoords(selectedEndRepoFile, token, handleGetLatLng));
        });
      }
    }
  };

  const handleSubmitEndPoint = () => {
    if (!endLatLng) return;
    const updatedFinalpoint = { start: startLatLng, end: endLatLng, is_bee_line: false };
    setFinalPoints(updatedFinalpoint);
    handlePointsData(updatedFinalpoint);
    handleChangeProjectStagesStatus(
      ProjectStagesList.DefiningCoordinates,
      ProposedTimelineStatus.OnGoing
    );
  };

  const handleChangeBeelinePointSelection = (value: string) => {
    if (value === PointTypeValue.UploadFile) {
      setBeeLineValue(value);
    }
    if (value === PointTypeValue.BeelineFromFile) {
      const handleGetLatLng = (data: BothPointLatLngType | null | any, id: number) => {
        if (data) {
          setBeeLineValue(value);
          setBothPointLatLng(data);
          setBeeLineRepoFileId(id);
          handleClearStartPoint();
          handleClearEndPoints();
        } else {
          setBeeLineValue(null);
          setBothPointLatLng(null);
          setBeeLineRepoFileId(0);
        }
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getBeeLinePointCoords(selectedBeeRepoFile, token, handleGetLatLng));
      });
    }
  };

  const handleSubmitBeelinePoint = () => {
    if (!bothPointLatLng || !bothPointLatLng?.start || !bothPointLatLng?.end) return;
    const updatedFinalpoint = {
      start: bothPointLatLng?.start,
      end: bothPointLatLng?.end,
      is_bee_line: true
    };
    setFinalPoints(updatedFinalpoint);
    handlePointsData(updatedFinalpoint);
    handleChangeProjectStagesStatus(
      ProjectStagesList.DefiningCoordinates,
      ProposedTimelineStatus.OnGoing
    );
  };

  const handleChangeStartLatLng = (value: LatLongType) => {
    setStartLatLng(value);
    handleClearBeelinePoints();
    setOpenStarPointModal(false);
  };

  const handleChangeEndLatLng = (value: LatLongType) => {
    setEndLatLng(value);
    handleClearBeelinePoints();
    setOpenEndPointModal(false);
  };

  const handleStartModalClose = () => {
    if (startLatLng) {
      setOpenStarPointModal(false);
    } else {
      setStartLatLng(null);
      setOpenStarPointModal(false);
      setStartValue(null);
    }
  };

  const handleEndModalClose = () => {
    if (endLatLng) {
      setOpenEndPointModal(false);
    } else {
      setEndLatLng(null);
      setOpenEndPointModal(false);
      setEndValue(null);
    }
  };

  const handleStartPointFileUpload = (file: File) => {
    setStartPointFile(file);
    const handleGetLatLng = (data: LatLongType | null) => {
      if (data) {
        setStartValue(null);
        setStartLatLng(data);
        handleClearBeelinePoints();
      } else {
        setStartValue(null);
        setStartLatLng(null);
      }
    };
    dispatch(getCoordsByUploadStartPointFile(file, handleGetLatLng));
  };

  const handleEndPointFileUpload = (file: File) => {
    setEndPointFile(file);
    const handleGetLatLng = (data: LatLongType | null) => {
      if (data) {
        setEndValue(null);
        setEndLatLng(data);
        handleClearBeelinePoints();
      } else {
        setEndValue(null);
        setEndLatLng(null);
      }
    };
    dispatch(getCoordsByUploadEndPointFile(file, handleGetLatLng));
  };

  const handleBeelinePointFileUpload = (file: File) => {
    setBeeLinePointFile(file);
    const handleGetLatLng = (data: BothPointLatLngType | null) => {
      if (data) {
        setEndValue(null);
        setBothPointLatLng(data);
        handleClearStartPoint();
        handleClearEndPoints();
      } else {
        setEndValue(null);
        setBothPointLatLng(null);
      }
    };
    dispatch(getCoordsByUploadBeeLinePointFile(file, handleGetLatLng));
  };

  const handleStartInput = () => {
    setOpenStarPointModal(true);
  };

  const handleEndInput = () => {
    setOpenEndPointModal(true);
  };

  const handleCallBackClearInputs = () => {
    handleClearStartPoint();
    handleClearEndPoints();
    handleClearBeelinePoints();
  };

  const handleApproveConfirmPointNo = () => {
    setApproveConfirmModal(false);
  };

  const handleCallBackClearData = () => {
    handleCallBackClearInputs();
    handleApproveConfirmPointNo();
    setApproveModal(true);
    setTimeout(() => {
      setApproveModal(false);
      navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
    }, 2000);
  };

  const handleFileIdPayload = (payload: AoiPayload) => {
    if (startRepoFileId) {
      payload = {
        ...payload,
        start_point_file_id: startRepoFileId
      };
    }
    if (endRepoFileId) {
      payload = {
        ...payload,
        end_point_file_id: endRepoFileId
      };
    }
    if (beeLineRepoFileId) {
      payload = {
        ...payload,
        beeline_file_id: beeLineRepoFileId
      };
    }

    return payload;
  };

  const handleFileUploadPayload = async (
    payload: AoiPayload
  ): Promise<{ payload: AoiPayload; isFileUploaded: boolean }> => {
    let isStartFileUploded = false;
    let isEndFileUploded = false;
    let isBeeFileUploded = false;
    if (!isEmpty(startPointFile)) {
      const startFileResult = await handleUploadNewRepoFile(startPointFile, FilesCategory.Start);

      handleClearStartPoint();
      if (startFileResult) {
        isStartFileUploded = true;
        payload = {
          ...payload,
          start_point_file_id: startFileResult?.file_id
        };
      }
    }

    if (!isEmpty(endPointFile)) {
      const endFileResult = await handleUploadNewRepoFile(endPointFile, FilesCategory.End);

      handleClearEndPoints();
      if (endFileResult) {
        isEndFileUploded = true;
        payload = {
          ...payload,
          end_point_file_id: endFileResult?.file_id
        };
      }
    }

    if (!isEmpty(beeLinePointFile)) {
      const beeLineFileResult = await handleUploadNewRepoFile(beeLinePointFile, FilesCategory.Bee);
      handleClearBeelinePoints();
      if (beeLineFileResult) {
        isBeeFileUploded = true;
        payload = {
          ...payload,
          beeline_file_id: beeLineFileResult?.file_id
        };
      }
    }

    const isFileUploaded = (isStartFileUploded && isEndFileUploded) || isBeeFileUploded;

    return { payload, isFileUploaded };
  };

  const handleSendForApproval = async () => {
    let payload: AoiPayload = {
      start_point: `${finalPoints?.start?.lat}, ${finalPoints?.start?.lng}`,
      start_point_name: '',
      end_point: `${finalPoints?.end?.lat}, ${finalPoints?.end?.lng}`,
      end_point_name: ''
    };

    const fileUploadResponse = await handleFileUploadPayload(payload);
    payload = fileUploadResponse.payload;
    payload = handleFileIdPayload(payload);

    if (fileUploadResponse.isFileUploaded) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(addAoiPointsCoords(payload, selectedProject.id, token, handleCallBackClearInputs));
      });
      setApproveModal(true);
      setTimeout(() => {
        setApproveModal(false);
        navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
      }, 2000);
    } else if (finalPoints && !isEmpty(finalPoints?.start) && !isEmpty(finalPoints?.end)) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(addAoiPointsCoords(payload, selectedProject.id, token, handleCallBackClearInputs));
      });
      setApproveModal(true);
      setTimeout(() => {
        setApproveModal(false);
        navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
      }, 2000);
    } else {
      toast.error('Something went wrong.');
    }
  };

  const handleUpdateCordinate = async () => {
    let payload: AoiPayload | null = {};

    if (finalPoints && finalPoints?.start && finalPoints?.start?.lat) {
      payload = {
        ...payload,
        start_point: `${finalPoints?.start?.lat}, ${finalPoints?.start?.lng}`,
        start_point_name: ''
      };
    }

    if (finalPoints && finalPoints?.end && finalPoints?.end?.lat) {
      payload = {
        ...payload,
        end_point: `${finalPoints?.end?.lat}, ${finalPoints?.end?.lng}`,
        end_point_name: ''
      };
    }
    const fileUploadResponse = await handleFileUploadPayload(payload);
    payload = fileUploadResponse.payload;
    payload = handleFileIdPayload(payload);

    if (fileUploadResponse.isFileUploaded) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          updateAoiPointsCoords(payload, selectedProject.id, token, handleCallBackClearData)
        );
      });
      setApproveModal(true);
      setTimeout(() => {
        setApproveModal(false);
        navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
      }, 2000);
    } else if (finalPoints && (!isEmpty(finalPoints?.start) || !isEmpty(finalPoints?.end))) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          updateAoiPointsCoords(payload, selectedProject.id, token, handleCallBackClearData)
        );
      });
      setApproveModal(true);
      setTimeout(() => {
        setApproveModal(false);
        navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
      }, 2000);
    } else {
      toast.error('Something went wrong.');
    }
  };

  const handleDeclinePointNo = () => {
    setDeclineModal(false);
  };

  const handleDeclineCallback = () => {
    handleCallBackClearInputs();
    handleDeclinePointNo();
  };

  const handleDeclineCordinate = () => {
    setDeclineModal(true);
  };

  const handleDeclinePointYes = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(deleteAoiPointsCoords(selectedProject.id, token, handleDeclineCallback));
    });
  };

  const handleApproveConfirmPointYes = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(updateAoiPointsCoords(null, selectedProject.id, token, handleCallBackClearData));
    });
  };

  const handleApproveCordinate = () => {
    setApproveConfirmModal(true);
  };

  return (
    <>
      <div
        className={`main-points-tab-content ${
          userRole === UserRole.SuperAdmin ? 'full-height' : ''
        }`}
      >
        <div className="start-point-content">
          <RequiredLabel
            label={userRole === UserRole.SuperAdmin ? 'START point' : 'Enter START point'}
            isRequired
          />
          {userRole === UserRole.SuperAdmin && aoiPointsCoords && (
            <PointInputDownload point={aoiPointsCoords.start_point} />
          )}
          {userRole === UserRole.SuperAdmin && !aoiPointsCoords && <PointInputDownload />}
          {userRole !== UserRole.SuperAdmin && (
            <>
              <PointInputSelection
                isLoading={isRequestingStartPoint}
                coordinate={startLatLng}
                isDisabledButton={!startLatLng}
                isDisabledInput={!!bothPointLatLng}
                aoiPoints={aoiPointsCoords}
                dropDownValue={startValue}
                pointInputSelection={startPointInputOption}
                onInputClick={handleStartInput}
                handleFile={handleStartPointFileUpload}
                handleChange={handleChangeStartPointSelection}
                handleSubmit={handleSubmitStartPoint}
                handleClearPoints={handleClearStartPoint}
              />
              {!isEmpty(aoiPointsCoords) && aoiPointsCoords.start_point && (
                <PointsRequestDetail point={aoiPointsCoords.start_point} />
              )}
            </>
          )}
        </div>
        <div className="end-point-content">
          <RequiredLabel
            label={userRole === UserRole.SuperAdmin ? 'END point' : 'Enter END point'}
            isRequired
          />
          {userRole === UserRole.SuperAdmin && aoiPointsCoords && (
            <PointInputDownload point={aoiPointsCoords.end_point} />
          )}
          {userRole === UserRole.SuperAdmin && !aoiPointsCoords && <PointInputDownload />}
          {userRole !== UserRole.SuperAdmin && (
            <>
              <PointInputSelection
                isLoading={isRequestingEndPoint}
                coordinate={endLatLng}
                dropDownValue={endValue}
                aoiPoints={aoiPointsCoords}
                isDisabledButton={!endLatLng}
                isDisabledInput={
                  (isEmpty(aoiPointsCoords) && !finalPoints?.start) || !!bothPointLatLng
                }
                pointInputSelection={endPointInputOption}
                onInputClick={handleEndInput}
                handleFile={handleEndPointFileUpload}
                handleChange={handleChangeEndPointSelection}
                handleSubmit={handleSubmitEndPoint}
                handleClearPoints={handleClearEndPoints}
              />
              {!isEmpty(aoiPointsCoords) && aoiPointsCoords.end_point && (
                <PointsRequestDetail point={aoiPointsCoords.end_point} />
              )}
            </>
          )}
        </div>
        <Text className="or-content">OR</Text>
        <div className="start-end-point-content">
          <RequiredLabel label="Start, End &amp; Bee Line" />

          {userRole === UserRole.SuperAdmin && aoiPointsCoords && (
            <PointInputDownload point={aoiPointsCoords.bee_line} />
          )}
          {userRole === UserRole.SuperAdmin && !aoiPointsCoords && <PointInputDownload />}
          {userRole !== UserRole.SuperAdmin && (
            <>
              <BothPointInputSelection
                isLoading={isRequestingBeePoint}
                coordinate={bothPointLatLng}
                dropDownValue={beeValue}
                isDisabledButton={!bothPointLatLng}
                isDisabledInput={!!startLatLng || !!endLatLng}
                pointInputSelection={beelinePointInputOption}
                aoiPoints={aoiPointsCoords}
                handleFile={handleBeelinePointFileUpload}
                handleChange={handleChangeBeelinePointSelection}
                handleSubmit={handleSubmitBeelinePoint}
                handleClearPoints={handleClearBeelinePoints}
              />
              {!isEmpty(aoiPointsCoords) && aoiPointsCoords.bee_line && (
                <PointsRequestDetail point={aoiPointsCoords.bee_line} />
              )}
            </>
          )}
        </div>
      </div>
      <div
        className={`start-end-point-submit-btn${
          userRole === UserRole.SuperAdmin ? ' zero-height' : ''
        }${
          userRole !== UserRole.SuperAdmin &&
          !isEmpty(aoiPointsCoords) &&
          aoiPointsCoords.is_approved
            ? ' approved-points'
            : ''
        }`}
      >
        {userRole === UserRole.Admin &&
          finalPoints &&
          !isEmpty(aoiPointsCoords) &&
          !aoiPointsCoords?.is_approved && (
            <Button
              className="start-point-btn"
              type="primary"
              loading={isRequestingaoiPointsCoords}
              onClick={handleUpdateCordinate}
            >
              Approve
            </Button>
          )}
        {userRole === UserRole.Admin && isEmpty(aoiPointsCoords) && (
          <Button
            className="start-point-btn"
            type="primary"
            loading={isRequestingaoiPointsCoords}
            disabled={!finalPoints?.start || !finalPoints?.end || isEmpty(project?.stages)}
            onClick={handleSendForApproval}
          >
            Approve
          </Button>
        )}
        {userRole === UserRole.Admin &&
          isEmpty(finalPoints) &&
          !isEmpty(aoiPointsCoords) &&
          !aoiPointsCoords?.is_approved && (
            <>
              <Button
                className="start-point-btn decline-btn"
                type="ghost"
                onClick={handleDeclineCordinate}
                loading={isRequestingDeleteAoiPointsCoords}
              >
                Decline
              </Button>
              <Button
                className="start-point-btn approve-btn admin"
                htmlType="button"
                type="primary"
                onClick={handleApproveCordinate}
              >
                Approve
              </Button>
            </>
          )}
        {userRole === UserRole.Manager && isEmpty(aoiPointsCoords) && (
          <Button
            disabled={!finalPoints?.start || !finalPoints?.end || isEmpty(project?.stages)}
            className="start-point-btn manager"
            type="primary"
            onClick={handleSendForApproval}
          >
            Send for Approval
          </Button>
        )}
        {userRole !== UserRole.SuperAdmin &&
          !isEmpty(aoiPointsCoords) &&
          aoiPointsCoords.is_approved && (
            <>
              <BsDot size={20} color={primary} />
              <Text className="admin-approve" italic>
                Coordinates approved by Admin
              </Text>
            </>
          )}
      </div>
      <PointFormModal
        coordinate={startLatLng}
        isOpenModal={isOpenStartPointModal}
        handleCloseModal={handleStartModalClose}
        handleChangeLatLng={handleChangeStartLatLng}
      />
      <PointFormModal
        coordinate={endLatLng}
        isOpenModal={isOpenEndPointModal}
        handleCloseModal={handleEndModalClose}
        handleChangeLatLng={handleChangeEndLatLng}
      />
      {userRole === UserRole.Manager && <SendForApprovalModal isOpen={isOpenApproveModal} />}
      {userRole === UserRole.Admin && (
        <RequestForSatsureApprovalModal isOpen={isOpenApproveModal} />
      )}
      <Modal
        title=""
        className="approve-modal"
        width={722}
        closable={false}
        open={isOpenDeclineModal}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title">Do you want to decline points?</Text>
          <div className="modal-content-btn">
            <Button
              type="primary"
              onClick={handleDeclinePointYes}
              loading={isRequestingDeleteAoiPointsCoords}
            >
              Yes
            </Button>
            <Button type="ghost" onClick={handleDeclinePointNo}>
              No
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        className="approve-modal"
        width={722}
        closable={false}
        open={isOpenApproveConfirmModal}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title">Do you want to approve points?</Text>
          <div className="modal-content-btn">
            <Button
              type="primary"
              onClick={handleApproveConfirmPointYes}
              loading={isRequestingUpdateAoiPointsCoords}
            >
              Yes
            </Button>
            <Button type="ghost" onClick={handleApproveConfirmPointNo}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default PointsTab;
