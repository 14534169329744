import { UserRole } from 'store/users/types';

export enum FilesCategory {
  Start = 'Start Point',
  End = 'End Point',
  Bee = 'Bee-Line',
  AOI = 'Area of Interest (AOI)',
  LULC = 'LULC',
  Routes = 'Routes',
  NoGoZone = 'Others - No Go Zone',
  Buffer = 'Others - Buffer Zone',
  TL1200KV = '1200kV Transmission Lines',
  TL765KV = '765kV Transmission Lines',
  TL400KV = '400kV Transmission Lines',
  TL220KV = '220kV Transmission Lines',
  TL132KV = '132kV Transmission Lines',
  DistributionLines = 'Distribution Lines',
  Telecommunication = 'Telecommunication Lines',
  TelecomTowers = 'Telecom Towers',
  Other = 'Others',
  Airports = 'Airports',
  DistributionTowers = 'Distribution Towers',
  Forests = 'Forests',
  Railways = 'Railways',
  Roadways = 'Roadways',
  RoutesFeatureList = 'Routes Feature List',
  SolarPlant = 'Solar Plant',
  TransmissionTowers = 'Transmission Towers',
  WindTurbines = 'Wind Turbines',
  WaterBodies = 'Water Bodies',
  WildlifeSanctuary = 'Wildlife Sanctuary',
  DEM = 'DEM',
  DSM = 'DSM',
  HighResolutionSataliteImages = 'High Resolution Satellite Images'
}

export interface RepositoryFile {
  buffer: number;
  category: string;
  comment: string;
  created_at: string;
  created_by: string;
  employee_id: string;
  enabled_for_map: boolean;
  file_name: string;
  first_name: string;
  id: number;
  last_name: string;
  url: string;
  user_email: string;
  user_role: UserRole;
  wms_layer_name: string | null;
  modified_at: string | null;
  color?: string;
  length?: number;
  coordinates?: Array<Array<[number, number]>>;
  shapType?: 'line' | 'circle' | 'polygon';
}

export interface UploadRepoFile {
  category_id: number;
  buffer: string;
  comment: string;
}

export interface UploadFilePayload extends UploadRepoFile {
  user_id: string;
  project_id: number;
}

export interface FileEditPayload {
  filename: string;
  category_id: number;
  buffer: string;
  comment: string;
}

export interface FilePayload extends FileEditPayload {
  user_id: string;
}

export interface FileMapAccessPayload {
  user_id: string;
  enable: boolean;
}

export interface Category {
  category_name: string;
  id: number;
}

export interface UploadFileResponse {
  file_id: number;
}

export interface DownloadFileResponse {
  file_name: string;
  url: string;
}

export interface DeleteRepositoryFile {
  user_id: string;
  category_id: number;
}

export interface RepositoryFileState {
  isRequestingFiles: boolean;
  repositoryFiles: Array<RepositoryFile>;
  isRequestingEditRepoFile: boolean;
  isRequestingAddRepoFile: boolean;
  isRequestingFileMapAccess: boolean;
  isRequestingCategory: boolean;
  isRequestingDownloadFile: boolean;
  isRequestingDeleteFile: boolean;
  categories: Category[];
  isRequestingGeoserverLayerStyles: boolean;
  geoserverLayerStyles: string[];
}
