import { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { remove } from 'lodash';
import { Button, Checkbox, Form, Input, Select, Typography } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { MemberT, Organization } from 'store/organizations/types';
import { addProject } from 'store/projects/actions';
import { createProjectSync } from 'utils/yupsync';
import { getAsyncStorageValue } from 'utils/localStorage';

interface AddProjectModalProps {
  allOrganizationMember: Array<MemberT>;
  handleCloseAddProjectModal: Function;
  selectedOrganization: Organization;
}

const { Option } = Select;
const { Text } = Typography;

const AddProjectModal = ({
  allOrganizationMember,
  handleCloseAddProjectModal,
  selectedOrganization
}: AddProjectModalProps) => {
  const dispatch = useAppDispatch();
  const { isAddProjectRequesting } = useAppSelector((state) => state.projects);
  const [form] = Form.useForm();
  const [selectedMember, setSelectedMember] = useState<string[]>([]);

  const handleSelectProjectMember = (value: string[]) => {
    setSelectedMember(value);
  };

  const handleSubmitCallback = () => {
    handleCloseAddProjectModal();
    allOrganizationMember = [];
    form.resetFields();
    setSelectedMember([]);
  };

  const handleProjectSubmit = () => {
    const payload = form.getFieldsValue(['project_title']);
    payload.project_member_ids = selectedMember;
    payload.admin_id = selectedOrganization.admin_id;
    payload.organisation_id = selectedOrganization.id;
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addProject(payload, token, handleSubmitCallback));
    });
  };

  const handleRemoveMember = (item: string) => {
    remove(selectedMember, (member) => {
      return member === item;
    });

    setSelectedMember([...selectedMember]);
  };

  const handleCloseModal = () => {
    handleCloseAddProjectModal();
    form.resetFields();
  };

  return (
    <div className="modalFormContent">
      <p>Project Creation</p>
      <Form layout="vertical" className="addmember-form" form={form} onFinish={handleProjectSubmit}>
        <Form.Item
          name="project_title"
          label={
            <Text>
              Enter name of Project<Text>*</Text>
            </Text>
          }
          className="formField"
          rules={[createProjectSync]}
        >
          <Input placeholder="Enter name of project" className="formInputField" />
        </Form.Item>
        <Form.Item
          name="project_member_ids"
          label="Assign team members to project"
          className="formField form-select-content"
        >
          <Select
            showArrow
            mode="multiple"
            onChange={handleSelectProjectMember}
            style={{ width: '100%' }}
            className="formSelectField"
            value={selectedMember}
          >
            {allOrganizationMember?.map((item: MemberT, index: number) => {
              return (
                <Option
                  key={index}
                  className={
                    selectedMember.includes(item.value)
                      ? 'member-dropdown selected-member'
                      : 'member-dropdown'
                  }
                  value={item.value}
                >
                  <Checkbox checked={selectedMember.includes(item.value)}>{item.label}</Checkbox>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="selected-list">
          {allOrganizationMember &&
            allOrganizationMember
              .filter((m) => selectedMember.includes(m.value))
              .map((item: MemberT, index: number) => {
                return (
                  <Text key={index} className="member-list">
                    <Text>{item.label}</Text>
                    <IoMdClose onClick={() => handleRemoveMember(item.value)} />
                  </Text>
                );
              })}
        </div>
        <div className="formButton">
          <Button type="ghost" className="formCancelbtn" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            loading={isAddProjectRequesting}
            type="primary"
            className="formCreatebtn"
            htmlType="submit"
          >
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProjectModal;
