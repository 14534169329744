import { useEffect, useState } from 'react';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import { AnalyticsColumnType } from 'store/analytics/types';

const FeaturesTab = ({ analyticsData, analytics }: any) => {
  const [columns, setColumns] = useState<ColumnsType<AnalyticsColumnType>>([]);

  useEffect(() => {
    if (isEmpty(analytics) || isEmpty(analytics.routes_analytics)) return;
    const routeKeys = Object.keys(analytics.routes_analytics);

    if (routeKeys.length > 0) {
      const tColumns: any = [
        {
          title: 'Parameters',
          dataIndex: 'parameter'
        }
      ];

      // eslint-disable-next-line
      for (const key of routeKeys) {
        tColumns.push({
          title: key.trim(),
          dataIndex: key.split(' ').join('').toLowerCase().trim(),
          align: 'center'
        });
      }

      setColumns(tColumns);
    }
  }, [analytics]);

  if (columns.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Table
      key="refresh"
      showHeader={!isEmpty(analyticsData)}
      className={`feature-table${isEmpty(analyticsData) ? ' no-feature-data' : ''}`}
      dataSource={analyticsData}
      columns={columns}
      pagination={false}
      expandable={{
        defaultExpandAllRows: true
      }}
      expandIcon={() => null}
      indentSize={20}
      scroll={{ y: '88%' }}
      rowKey={(row) => row.parameter}
    />
  );
};

export default FeaturesTab;
