import { createSelector } from '@reduxjs/toolkit';
import {
  FeatureParameter,
  towerParameter,
  fixedParameter,
  lengthParameter,
  specialtowerParameter,
  transmissionLineParameter,
  towerObjectKeys
} from 'constants/analytics.constants';
import { capitalize, difference, intersection, isEmpty, mapKeys, orderBy, pick } from 'lodash';
import { fromateFeatureKey } from 'utils/util';
import { RootState } from '..';
import { analyticsSlice } from './slice';

export const {
  setRequestingAnalytics,
  setAnalytics,
  setRequestingAddAnalyticsCost,
  setRequestingEditAnalyticsCost
} = analyticsSlice.actions;
export default analyticsSlice.reducer;

const selectedAnalyticsData = (state: RootState) => state.analytics.analyticsData;

const getRouteDataFromObj = (routeKeys: string[], feature: string, analyticsData: any) => {
  const routeDataObj: any = {};

  // eslint-disable-next-line
  for (const rkey of routeKeys) {
    const routeData = analyticsData?.routes_analytics?.[rkey];
    const formatedRouteData = mapKeys(routeData, (_, key: string) => fromateFeatureKey(key));
    const ckey = rkey.split(' ').join('').toLowerCase().trim();
    routeDataObj[ckey] = formatedRouteData[feature];
  }

  return routeDataObj;
};

export const getAnalyticsData = createSelector([selectedAnalyticsData], (analyticsData: any) => {
  if (!analyticsData || isEmpty(analyticsData.routes_analytics)) return [];

  const routeKeys = Object.keys(analyticsData.routes_analytics);
  if (routeKeys.length === 0) return [];

  const routeObj = analyticsData?.routes_analytics?.[routeKeys[0]];
  const allRouteKeys = Object.keys(routeObj);
  const fromatedKeys = allRouteKeys.map((key) => fromateFeatureKey(key));
  const dynamicRouteParameter: string[] = difference(fromatedKeys, fixedParameter);
  const visibleLengthParameter: string[] = intersection(fromatedKeys, lengthParameter);
  const transmissionLParameter: string[] = intersection(fromatedKeys, transmissionLineParameter);
  const visibleTowerParameter: string[] = intersection(fromatedKeys, towerParameter);
  const exraTowerParameter: any = pick(routeObj, specialtowerParameter);
  const featureRoutes: any[] = [];
  const towersData: any[] = [];
  const lineData: any[] = [];

  const data = getRouteDataFromObj(routeKeys, FeatureParameter.TotalLength, analyticsData);
  featureRoutes.push({ parameter: capitalize(FeatureParameter.TotalLength), ...data });

  // eslint-disable-next-line
  for (const feature of visibleLengthParameter) {
    const data = getRouteDataFromObj(routeKeys, feature, analyticsData);
    featureRoutes.push({ parameter: capitalize(feature), ...data });
  }

  // eslint-disable-next-line
  for (const towerParameterKey in exraTowerParameter) {
    const data = getRouteDataFromObj(routeKeys, towerParameterKey, analyticsData);
    featureRoutes.push({ parameter: capitalize(towerParameterKey), ...data });
  }

  // eslint-disable-next-line
  for (const tlParameter of transmissionLParameter) {
    const data = getRouteDataFromObj(routeKeys, tlParameter, analyticsData);
    lineData.push({ parameter: capitalize(tlParameter), ...data });
  }

  // eslint-disable-next-line
  for (const dynamicFeature of dynamicRouteParameter) {
    const data = getRouteDataFromObj(routeKeys, dynamicFeature, analyticsData);
    featureRoutes.push({ parameter: capitalize(dynamicFeature), ...data });
  }

  // eslint-disable-next-line
  for (const tower of visibleTowerParameter) {
    const data = getRouteDataFromObj(routeKeys, tower, analyticsData);
    towersData.push({ parameter: towerObjectKeys[tower], ...data });
  }

  const analyticsResult = featureRoutes.map((route: any) => {
    if (route.parameter === towerObjectKeys[FeatureParameter.AngularTowers]) {
      route.children = orderBy(towersData, ['parameter'], ['asc']);
    } else if (
      route.parameter === capitalize(FeatureParameter.TlTotal) ||
      route.parameter === capitalize(FeatureParameter.TlcTotal)
    ) {
      route.children = orderBy(lineData, ['parameter'], ['asc']);
    }

    return route;
  });

  return analyticsResult;
});

const routeId = (state: RootState, id: number | undefined) => id;

export const getSelectedAnalyticsRouteData = createSelector(
  [selectedAnalyticsData, routeId],
  (selectedAnalyticsRouteData, selectedRouteId) => {
    if (!selectedRouteId) return null;
    if (!selectedAnalyticsRouteData || isEmpty(selectedAnalyticsRouteData.routes_analytics))
      return null;

    const selectedRouteKey: string = `Route ${selectedRouteId}`;
    const routeKeys = Object.keys(selectedAnalyticsRouteData.routes_analytics);
    if (routeKeys.length === 0) return null;

    const routeData = selectedAnalyticsRouteData?.routes_analytics?.[selectedRouteKey];
    if (!routeData) return null;
    return mapKeys(routeData, (_, key: string) => fromateFeatureKey(key));
  }
);
