import { toast } from 'react-toastify';
import { includes } from 'lodash';
import { vectorCategories } from 'constants/map.constants';
import { vectorColors, circleShapVectors, polygonShapVectors } from 'constants/common.constant';
import { RepositoryFile } from 'store/repositoryfile/types';
import { ReformatPoints } from 'types/project.types';
import { reformatCoordinates } from 'utils/xml';
import { getRepoFileCoordinates } from 'utils/connect';
import { AppDispatch, RootState } from '..';
import { setRequestingVectorFiles, setVectorFiles } from '.';

export const getExtractVectorFiles =
  (token: string) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch(setRequestingVectorFiles(true));

      const state = getState();
      const { repositoryFiles } = state.repofiles;
      const { project } = state.projects;
      const files = repositoryFiles.filter(
        (file: RepositoryFile) => includes(vectorCategories, file.category) && file.enabled_for_map
      );
      const routes: RepositoryFile[] = [];

      // eslint-disable-next-line
      for await (const [i, file] of files.entries()) {
        // const fileFromUrl = await getFileFromUrl(file.url);
        // const extractFile = await readFileAsyncAsText(fileFromUrl);
        // const coordinates = await extractCoordinatesFromKML(extractFile);
        const response = await getRepoFileCoordinates(project.id, file.id, token);
        let coordinate: ReformatPoints | null = null;

        if (response.length > 0) {
          coordinate = await reformatCoordinates(response);
        }

        let shapType = 'line';
        if (circleShapVectors.includes(file.category)) {
          shapType = 'circle';
        } else if (polygonShapVectors.includes(file.category)) {
          shapType = 'polygon';
        }

        routes.push({
          ...file,
          coordinates: coordinate?.points || [],
          shapType,
          color: vectorColors[file.category]
        });
      }

      dispatch(setVectorFiles(routes));
      dispatch(setRequestingVectorFiles(false));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingVectorFiles(false));
    }
  };
