import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Spin,
  Typography,
  Upload,
  UploadProps
} from 'antd';
import { filter, find, isEmpty, upperFirst } from 'lodash';
import { toast } from 'react-toastify';
import { greyShed, lightGrey, primary, red, white } from 'constants/theme.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { BsDot } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import { getUserFromId } from 'store/aoi';
import { deleteAOISelectionForApproval, getExtractAOIFiles } from 'store/aoi/actions';
import { ProposedTimelineStatus, ProjectStage, ProjectStagesList } from 'store/projects/types';
import { getRepositoryFiles } from 'store/repositoryfile/actions';
import {
  Category,
  RepositoryFile,
  FilesCategory,
  UploadFilePayload
} from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';
import { asyncFileUpload } from 'utils/connect';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { decryptValue } from 'utils/decodeEncodeData';
import AoiItemCard from './AoiItemCard';

const { Text } = Typography;
const { Option } = Select;

interface AoiTabProps {
  handleApprove: Function;
  handleApproveAoiByAdmin: Function;
  loading: boolean;
  handleShowHideSelectedAoi: Function;
  selectedAoiIds: number[];
  allAois: RepositoryFile[];
  superAdminAOIs: RepositoryFile[];
  adminManagerAOIs: RepositoryFile[];
  handleAoiSelect: (data: RepositoryFile | null) => void;
}

const AOITab = ({
  loading,
  handleApprove,
  handleApproveAoiByAdmin,
  handleShowHideSelectedAoi,
  selectedAoiIds,
  allAois,
  superAdminAOIs,
  adminManagerAOIs,
  handleAoiSelect
}: AoiTabProps) => {
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { selectedProject, project } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.user);
  const { categories } = useAppSelector((state) => state.repofiles);
  const { aoi, isRequestingDeclineAOISelection } = useAppSelector((state) => state.aoi);
  const aoiSubmitedUser = useAppSelector((state) => getUserFromId(state, aoi.submitted_by));
  const [value, setValue] = useState<number | null>(null);
  const [isStageDisabled, setStageStartedDisabled] = useState(true);
  const [repoFile, setRepoFile] = useState<File | null>(null);
  const [isOpenDeclineModal, setDeclineModal] = useState<boolean>(false);

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      setRepoFile(file);
      return false;
    }
  };

  useEffect(() => {
    if (!isEmpty(aoi)) {
      setValue(aoi.aoi_file_id);
      const hasAoifile = find(allAois, (file: RepositoryFile) => file.id === aoi.aoi_file_id);
      if (hasAoifile) handleAoiSelect(hasAoifile);
    }
  }, [aoi]);

  useEffect(() => {
    if (project && !isEmpty(project.stages)) {
      const stages = filter(
        project.stages,
        (stage: ProjectStage) =>
          stage.stage_name === ProjectStagesList.AOIGeneration ||
          stage.stage_name === ProjectStagesList.LULCLow
      );
      if (!isEmpty(stages)) {
        setStageStartedDisabled(
          stages[0].status === null ||
            stages[1].status === null ||
            stages[1].status === ProposedTimelineStatus.OnGoing
        );
      }
    }
  }, [value]);

  const handleUploadNewRepoFile = (file: File) => {
    const categoryData: Category = find(
      categories,
      (o: Category) => o.category_name === FilesCategory.AOI
    );

    const payload: UploadFilePayload = {
      comment: 'Null',
      category_id: categoryData.id,
      buffer: '0',
      user_id: user.id,
      project_id: Number(selectedProject?.id)
    };

    return asyncFileUpload(payload, file);
  };

  const handleSendForApprovalAoi = () => {
    if (value) handleApprove(value);
  };

  const handleApprovalAoiByAdmin = () => {
    if (value) handleApproveAoiByAdmin(value);
  };

  const handleClearAoiFile = () => {
    setRepoFile(null);
  };

  const handleChangeAOI = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    const selectedAoi = allAois.find((aoi: RepositoryFile) => aoi.id === e.target.value);
    if (selectedAoi) handleAoiSelect(selectedAoi);
  };

  const handleDeclineAoiNo = () => {
    setDeclineModal(false);
  };

  const handleDeclineAoiYes = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        deleteAOISelectionForApproval(selectedProject?.id, token, (status: boolean) => {
          if (status) {
            setDeclineModal(false);
          } else {
            toast.error('Something went wrong');
          }
        })
      );
    });
  };

  const handleDeclineAoi = () => {
    setDeclineModal(true);
  };

  const handleSubmitSelectedAoi = async () => {
    if (repoFile) {
      const aoiFileResult = await handleUploadNewRepoFile(repoFile);
      handleClearAoiFile();

      if (aoiFileResult) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            getRepositoryFiles(selectedProject.id, token, () => {
              dispatch(getExtractAOIFiles(token));
            })
          );
        });
      }
    }
  };

  return (
    <>
      <div className="aoi-tab-content">
        <div className="aoi-title">
          {userRole !== UserRole.SuperAdmin ? (
            <Text className="aoi-title-inr">Select AOI</Text>
          ) : (
            <Text className="aoi-title-inr">Upload AOI</Text>
          )}
        </div>
        {(superAdminAOIs.length > 0 || adminManagerAOIs.length > 0) && (
          <Spin
            size="large"
            tip="Loading..."
            spinning={loading}
            indicator={<LoadingOutlined color={red} style={{ fontSize: 24 }} spin />}
          >
            <Radio.Group className="radio-group-content" onChange={handleChangeAOI} value={value}>
              <Space direction="vertical" className="space-aoi-content">
                {superAdminAOIs?.map((item) => (
                  <>
                    <AoiItemCard
                      key={item.id}
                      data={item}
                      handleShowHideSelectedAoi={handleShowHideSelectedAoi}
                      selectedAoiIds={selectedAoiIds}
                      selectedAoi={aoi}
                    />
                    {aoi && aoi.aoi_file_id === item.id && (
                      <div className="approve-dwnld approve-aoi-selection">
                        <BsDot size={20} color={primary} />
                        {userRole === UserRole.Admin && (
                          <Text className="approved" italic>
                            Selected by {upperFirst(decryptValue(aoiSubmitedUser.first_name))}{' '}
                            {upperFirst(decryptValue(aoiSubmitedUser.last_name))} for approval
                          </Text>
                        )}
                        {userRole === UserRole.SuperAdmin && (
                          <Text className="approved" italic>
                            Selected by {upperFirst(decryptValue(aoiSubmitedUser.first_name))}{' '}
                            {upperFirst(decryptValue(aoiSubmitedUser.last_name))}
                          </Text>
                        )}
                        {userRole === UserRole.Manager && aoi.is_approved && (
                          <Text className="approved" italic>
                            Selected by Admin
                          </Text>
                        )}
                        {userRole === UserRole.Manager && !aoi.is_approved && (
                          <Text className="approved" italic>
                            Waiting for approval
                          </Text>
                        )}
                      </div>
                    )}
                  </>
                ))}
                {superAdminAOIs.length > 0 && adminManagerAOIs.length > 0 && (
                  <Divider
                    style={{ borderTop: `1px solid ${greyShed}`, margin: '6px 0px 4px 0px' }}
                  />
                )}
                {adminManagerAOIs?.map((item) => {
                  return (
                    <>
                      <AoiItemCard
                        key={item.id}
                        data={item}
                        handleShowHideSelectedAoi={handleShowHideSelectedAoi}
                        selectedAoiIds={selectedAoiIds}
                        selectedAoi={aoi}
                      />
                      {aoi && aoi.aoi_file_id === item.id && (
                        <div
                          className="approve-dwnld approve-aoi-selection"
                          key={`text-${item.id}`}
                        >
                          <BsDot size={20} color={primary} />
                          {userRole === UserRole.Admin && (
                            <Text className="approved" italic>
                              Selected by {upperFirst(decryptValue(aoiSubmitedUser.first_name))}{' '}
                              {upperFirst(decryptValue(aoiSubmitedUser.last_name))} for approval
                            </Text>
                          )}
                          {userRole === UserRole.SuperAdmin && (
                            <Text className="approved" italic>
                              Selected by {upperFirst(decryptValue(aoiSubmitedUser.first_name))}{' '}
                              {upperFirst(decryptValue(aoiSubmitedUser.last_name))}
                            </Text>
                          )}
                          {userRole === UserRole.Manager && aoi.is_approved && (
                            <Text className="approved" italic>
                              Selected by Admin
                            </Text>
                          )}
                          {userRole === UserRole.Manager && !aoi.is_approved && (
                            <Text className="approved" italic>
                              Waiting for approval
                            </Text>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </Space>
            </Radio.Group>
          </Spin>
        )}
        <div
          className="aoi-select-submit-btn"
          style={{
            paddingTop:
              superAdminAOIs.length === 0 && adminManagerAOIs.length === 0 ? '10px' : '20px'
          }}
        >
          {isEmpty(repoFile) ? (
            <Select className="aoi-tab-select" placeholder="Select" allowClear>
              <Option
                style={{
                  color: primary,
                  borderTop: `1px solid ${lightGrey}`,
                  backgroundColor: `${white}`
                }}
                value={0}
              >
                <Upload {...uploadProps} showUploadList={false}>
                  <Button className="upload-file-btn">Upload File</Button>
                </Upload>
              </Option>
            </Select>
          ) : (
            <Input
              allowClear
              readOnly
              style={{ width: '100%' }}
              value={repoFile.name || 'New Updated AOI.kml'}
              className="aoi-upload-file"
              suffix={
                <IoIosClose
                  onClick={() => handleClearAoiFile()}
                  size={16}
                  style={{ cursor: 'pointer', fill: primary }}
                />
              }
            />
          )}
          <Button
            disabled={!repoFile}
            className="start-point-btn"
            type="primary"
            onClick={handleSubmitSelectedAoi}
          >
            Submit
          </Button>
        </div>
      </div>

      <div
        className={`aoi-tab-submit-btn${
          userRole !== UserRole.SuperAdmin && !isEmpty(aoi) && aoi.is_approved
            ? ' approved-aoi'
            : ''
        }`}
      >
        {userRole === UserRole.Admin && !isEmpty(aoi) && !aoi.is_approved && (
          <>
            <Button className="start-point-btn" type="ghost" onClick={handleDeclineAoi}>
              Decline
            </Button>
            <Button
              className="start-point-btn"
              htmlType="button"
              type="primary"
              disabled={isStageDisabled}
              onClick={() => handleApprovalAoiByAdmin()}
            >
              Approve
            </Button>
          </>
        )}
        {userRole === UserRole.Admin && isEmpty(aoi) && (
          <Button
            disabled={isStageDisabled || !isEmpty(aoi)}
            className="start-point-btn aoi-selection-mng"
            type="primary"
            onClick={() => handleSendForApprovalAoi()}
          >
            Send For Approval
          </Button>
        )}
        {userRole === UserRole.Manager && isEmpty(aoi) && (
          <Button
            disabled={!value || isStageDisabled || !isEmpty(aoi)}
            className="start-point-btn aoi-selection-mng"
            type="primary"
            onClick={() => handleSendForApprovalAoi()}
          >
            Send For Approval
          </Button>
        )}
        {userRole !== UserRole.SuperAdmin && !isEmpty(aoi) && aoi.is_approved && (
          <>
            <BsDot size={20} color={primary} />
            <Text className="admin-approve" italic>
              AOI approved by Admin
            </Text>
          </>
        )}
      </div>
      <Modal
        title=""
        className="approve-modal"
        width={722}
        closable={false}
        open={isOpenDeclineModal}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title">Do you want to decline points?</Text>
          <div className="modal-content-btn">
            <Button
              type="primary"
              onClick={handleDeclineAoiYes}
              loading={isRequestingDeclineAOISelection}
            >
              Yes
            </Button>
            <Button type="ghost" onClick={handleDeclineAoiNo}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AOITab;
