import { Typography } from 'antd';
import './styles.less';

interface MonochromeLegendProps {
  elevetion: number[];
}

const { Text } = Typography;

const MonochromeLegend = ({ elevetion }: MonochromeLegendProps) => {
  return (
    <>
      <div className="dem-monochrome-linear" />
      <div className="reading">
        {elevetion.map((elv: number, i: number) => (
          <Text key={i}>{elv.toFixed(2)} m</Text>
        ))}
      </div>
    </>
  );
};

export default MonochromeLegend;
