import { createSelector } from '@reduxjs/toolkit';
import { projectStages, projectStagesPayload } from 'constants/project.constants';
import { filter, orderBy, upperFirst } from 'lodash';
import { MemberT, OrganizationMember } from 'store/organizations/types';
import {
  AvailableProjectMember,
  Project,
  ProjectActivityData,
  ProjectStage,
  ProjectStatus,
  TeamMember
} from 'store/projects/types';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { RootState } from '..';
import { projectSlice } from './slice';

export const {
  setRequestingProjects,
  setProjects,
  setAddProjectRequesting,
  setSelectedProject,
  setRequestProject,
  setProject,
  setProjectMemberRequest,
  setAvailableProjectMemberRequesting,
  setAvailableProjectMembers,
  setDeadlineStageUploadRequesting,
  setEditDeadlineStageUploadRequesting,
  setRequestingProjectStageChangeStatus,
  setRequestingRemoveMemberFromProject,
  setRequestingRemoveMemberFromOrganization,
  setRequestingProjectActivityLogs,
  setProjectActivityLogs,
  setRequestCloseProject
} = projectSlice.actions;
export default projectSlice.reducer;

export const getTeamMembers = (members: TeamMember[]): Array<MemberT> => {
  return members
    ?.filter((m) => m.role !== UserRole.Admin)
    .map((member: TeamMember) => {
      if (member.first_name && member.last_name) {
        const firstname = upperFirst(decryptValue(member.first_name));
        const lastname = upperFirst(decryptValue(member.last_name));

        return {
          label: upperFirst(`${firstname} ${lastname}`),
          value: member.id,
          role: member.role
        };
      }

      return {
        label: decryptValue(member.email),
        value: member.id,
        role: member.role
      };
    });
};

export const getMember = (member: OrganizationMember | TeamMember): MemberT => {
  if (member.first_name && member.last_name) {
    const firstname = upperFirst(decryptValue(member.first_name));
    const lastname = upperFirst(decryptValue(member.last_name));

    return {
      label: upperFirst(`${firstname} ${lastname}`),
      value: member.id,
      role: member.role
    };
  }

  return {
    label: decryptValue(member.email),
    value: member.id,
    role: member.role
  };
};

export const getTeamMember = (member: OrganizationMember | TeamMember) => {
  if (member.first_name && member.last_name) {
    return {
      label: upperFirst(`${member.first_name} ${member.last_name}`),
      value: member.id,
      role: member.role
    };
  }

  return {
    label: member.email,
    value: member.id,
    role: member.role
  };
};

export const selectAvailableProjectMembers = (state: RootState) => {
  const { availableProjectMembers } = state.projects;

  return availableProjectMembers
    ?.filter((m: AvailableProjectMember) => m.role !== UserRole.Admin)
    .map((member: AvailableProjectMember) => {
      if (member.first_name && member.last_name) {
        const firstname = upperFirst(decryptValue(member.first_name));
        const lastname = upperFirst(decryptValue(member.last_name));
        return {
          label: upperFirst(`${firstname} ${lastname}`),
          value: member.id,
          role: member.role
        };
      }

      return {
        label: decryptValue(member.email),
        value: member.id,
        role: member.role
      };
    });
};

export const projectStagesList = (state: RootState) => {
  const { project } = state.projects;
  const stages = project?.stages;
  if (project?.stages.length > 0) {
    const obj: any = {};

    stages.forEach((stage: ProjectStage) => {
      const key = projectStages[stage.stage_name];
      obj[key] = stage.deadline;
    });

    return obj;
  }

  return projectStagesPayload;
};

export const getProjectCountByStatus = (projects: Array<Project>, status: ProjectStatus) => {
  if (projects?.length > 0) {
    return projects.filter((obj) => obj.status === status).length;
  }

  return 0;
};

export const getProjectCountByManager = (projects: Array<Project>, status: ProjectStatus) => {
  if (projects?.length > 0) {
    return filter(projects, { access: true, status }).length;
  }

  return 0;
};

const activityLogs = (state: RootState) => state.projects.projectActivityLogs;
const selectUserId = (state: RootState, id: string | undefined | null) => id;

export const getProjectActivityData = createSelector(
  [activityLogs, selectUserId],
  (activityLog, userId) => {
    if (!userId) return orderBy(activityLog, ['created_at'], ['desc']);
    const filterData = activityLog.filter(
      (activity: ProjectActivityData) => activity.user_id === userId
    );
    return orderBy(filterData, ['created_at'], ['desc']);
  }
);
