import { useEffect, useState } from 'react';
import { includes, remove } from 'lodash';
import RouteMap from 'components/RouteMap/RouteMap';
import RouteTab from 'components/RouteSidebar/RouteTab';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getGeoserverLayerStyle, getRepositoryFiles } from 'store/repositoryfile/actions';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getAdminManagerRouteFiles, getRouteFiles, getSuperAdminRouteFiles } from 'store/route';
import {
  getDemElevetionValue,
  getDsmElevetionValue,
  getExtractRouteFiles
} from 'store/route/actions';
import { getExtractVectorFiles } from 'store/vectorfile/actions';
import { getFinalizedAoiFile } from 'store/aoi';
import { getProjectAnalytics } from 'store/analytics/actions';
import { getAOISelection, getExtractAOIFiles } from 'store/aoi/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const RoutePage = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { aoiPointsCoords, aoi } = useAppSelector((state) => state.aoi);
  const { isRequestingRouteFiles } = useAppSelector((state) => state.route);
  const finalAoi: RepositoryFile | null = useAppSelector(getFinalizedAoiFile);
  const superAdminRoutes: Array<RepositoryFile> = useAppSelector(getSuperAdminRouteFiles);
  const adminRoutes: Array<RepositoryFile> = useAppSelector(getAdminManagerRouteFiles);
  const allRoutes: Array<RepositoryFile> = useAppSelector(getRouteFiles);
  const [selectedRoutesIds, setSelectedRoutesIds] = useState<number[]>([]);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getAOISelection(selectedProject?.id, token));
      dispatch(
        getRepositoryFiles(selectedProject?.id, token, () => {
          dispatch(getExtractRouteFiles(token));
          dispatch(getExtractAOIFiles(token));
          dispatch(getExtractVectorFiles(token));
          dispatch(getDemElevetionValue(selectedProject.id, token));
          dispatch(getDsmElevetionValue(selectedProject.id, token));
          dispatch(getGeoserverLayerStyle(token));
        })
      );
      dispatch(getProjectAnalytics(selectedProject.id, token));
    });
  }, []);

  useEffect(() => {
    if (allRoutes.length > 0) {
      const routeIds = allRoutes.map((r) => r.id);
      setSelectedRoutesIds([...routeIds]);
    }
  }, [allRoutes]);

  const handleShowHideSelectedRoute = (fileId: number) => {
    if (includes(selectedRoutesIds, fileId)) {
      remove(selectedRoutesIds, (route: number) => route === fileId);
    } else {
      selectedRoutesIds.push(fileId);
    }

    setSelectedRoutesIds([...selectedRoutesIds]);
  };

  return (
    <div className="main-route-content">
      <RouteTab
        loading={isRequestingRouteFiles}
        superAdminRoutes={superAdminRoutes}
        adminRoutes={adminRoutes}
        selectedRoutesIds={selectedRoutesIds}
        handleSelectedRoute={handleShowHideSelectedRoute}
      />
      <RouteMap
        approvedAoi={aoi}
        selectedRouteFilesIds={selectedRoutesIds}
        routeFileList={allRoutes}
        aoiPointsCoords={aoiPointsCoords}
        finalAoi={finalAoi}
      />
    </div>
  );
};

export default RoutePage;
