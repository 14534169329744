export interface DEMDataType {
  title: string;
  value: string;
}

export const DSM_MONOCHROME = 'monochrome';
export const DSM_HILL_SHADE = 'hs';
export const DSM_JET = 'jet';
export const DEM_MONOCHROME = 'monochrome';
export const DEM_HILL_SHADE = 'hs';
export const DEM_JET = 'jet';

export const demStyleLayers: DEMDataType[] = [
  { title: 'Monochrome', value: DEM_MONOCHROME },
  { title: 'Jet', value: DEM_JET },
  { title: 'Hill Shade', value: DEM_HILL_SHADE }
];

export const dsmStyleLayers: DEMDataType[] = [
  { title: 'Monochrome', value: DSM_MONOCHROME },
  { title: 'Jet', value: DSM_JET },
  { title: 'Hill Shade', value: DSM_HILL_SHADE }
];
