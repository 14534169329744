import { useEffect, useState } from 'react';
import { Button, Empty, Modal, Tabs, Typography, Upload, UploadProps } from 'antd';
import { isEmpty } from 'lodash';
import CostTab from 'components/Analytics/CostTab';
import FeaturesTab from 'components/Analytics/FeaturesTab';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getProjectAnalytics } from 'store/analytics/actions';
import { getAnalyticsData } from 'store/analytics';
import { Category, FilesCategory, UploadFilePayload } from 'store/repositoryfile/types';
import { addRepositoryFile } from 'store/repositoryfile/actions';
import { UserRole } from 'store/users/types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const Analytics = () => {
  const userRole = getStorageValue(USER_ROLE);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { categories, isRequestingCategory } = useAppSelector((state) => state.repofiles);
  const { analyticsData } = useAppSelector((state) => state.analytics);
  const analyticsRouteData: any = useAppSelector((state) => getAnalyticsData(state));
  const [isOpenUploadCsv, setOpenUploadCsv] = useState<boolean>(false);
  const [uploadCsvFile, setUploadCsvFile] = useState<File | null>(null);
  const [uploadCsvFileName, setUploadCsvFileName] = useState<string | null>(null);

  const uploadProps: UploadProps = {
    showUploadList: false,
    beforeUpload: (file) => {
      setUploadCsvFile(file);
      setUploadCsvFileName(file.name);
      setOpenUploadCsv(true);
      return false;
    }
  };

  useEffect(() => {
    if (!selectedProject) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProjectAnalytics(selectedProject.id, token));
    });
  }, [selectedProject]);

  const handleUploadCallback = () => {
    setUploadCsvFile(null);
    setOpenUploadCsv(false);
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProjectAnalytics(selectedProject.id, token));
    });
  };

  const handleConfirmUploadCsv = () => {
    if (uploadCsvFile) {
      const payload: UploadFilePayload = {
        comment: 'Null',
        category_id: categories.find(
          (category: Category) => category.category_name === FilesCategory.RoutesFeatureList
        ).id,
        buffer: '0',
        user_id: user.id,
        project_id: selectedProject?.id
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(addRepositoryFile(payload, token, handleUploadCallback, uploadCsvFile));
      });
    }
  };

  const handleCancelUploadCsv = () => {
    setUploadCsvFile(null);
    setOpenUploadCsv(false);
  };

  return (
    <>
      <div className="main-analytics">
        <Tabs defaultActiveKey="1" className="main-tab-content">
          <Tabs.TabPane
            tab={<Text className="tab-title-inr">features</Text>}
            key="1"
            className="feature-tab"
          >
            <FeaturesTab analyticsData={analyticsRouteData} analytics={analyticsData} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text className="tab-title-inr">cost</Text>}
            key="2"
            className={`cost-tab${
              analyticsData && isEmpty(analyticsData.routes_analytics)
                ? ' empty-analytics-main'
                : ''
            } `}
          >
            {analyticsData && !isEmpty(analyticsData.routes_analytics) ? (
              <CostTab />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Tabs.TabPane>
        </Tabs>
        {userRole === UserRole.SuperAdmin && (
          <Upload {...uploadProps} disabled={!isEmpty(analyticsData?.routes_analytics)}>
            <Button className="upload-csv" disabled={!isEmpty(analyticsData?.routes_analytics)}>
              Upload CSV
            </Button>
          </Upload>
        )}
      </div>
      {uploadCsvFile && (
        <Modal
          title=""
          className="approve-modal"
          width={722}
          closable={false}
          open={isOpenUploadCsv}
          footer={null}
          centered
        >
          <div className="approve-modal-content">
            <Text className="modal-title">
              Do you want to upload &quot;{uploadCsvFileName}&quot;?
            </Text>
            <div className="modal-content-btn">
              <Button
                type="primary"
                onClick={handleConfirmUploadCsv}
                disabled={isRequestingCategory}
                loading={isRequestingCategory}
              >
                Yes
              </Button>
              <Button type="ghost" onClick={handleCancelUploadCsv}>
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Analytics;
