import { createSlice } from '@reduxjs/toolkit';
import { ProjectState } from './types';

const initialState: ProjectState = {
  isLoadingProjects: false,
  projects: [],
  project: null,
  isAddProjectRequesting: false,
  selectedProject: null,
  isRequestProject: false,
  isAddProjectMemberRequest: false,
  isAvailableProjectMemberRequesting: false,
  availableProjectMembers: [],
  isDeadlineStageUpload: false,
  isEditDeadlineStageUpload: false,
  isRequestingProjectStageChangeStatus: false,
  isRequestingRemoveMemberFromProject: false,
  isRequestingRemoveMemberFromOrganization: false,
  isRequestingProjectActivityLogs: false,
  isRequestingCloseProject: false,
  projectActivityLogs: []
};

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setRequestingProjects: (state, action) => {
      state.isLoadingProjects = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setAddProjectRequesting: (state, action) => {
      state.isAddProjectRequesting = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setRequestProject: (state, action) => {
      state.isRequestProject = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setProjectMemberRequest: (state, action) => {
      state.isAddProjectMemberRequest = action.payload;
    },
    setAvailableProjectMemberRequesting: (state, action) => {
      state.isAvailableProjectMemberRequesting = action.payload;
    },
    setAvailableProjectMembers: (state, action) => {
      state.availableProjectMembers = action.payload;
    },
    setDeadlineStageUploadRequesting: (state, action) => {
      state.isDeadlineStageUpload = action.payload;
    },
    setEditDeadlineStageUploadRequesting: (state, action) => {
      state.isEditDeadlineStageUpload = action.payload;
    },
    setRequestingProjectStageChangeStatus: (state, action) => {
      state.isRequestingProjectStageChangeStatus = action.payload;
    },
    setRequestingRemoveMemberFromProject: (state, action) => {
      state.isRequestingRemoveMemberFromProject = action.payload;
    },
    setRequestingRemoveMemberFromOrganization: (state, action) => {
      state.isRequestingRemoveMemberFromOrganization = action.payload;
    },
    setRequestingProjectActivityLogs: (state, action) => {
      state.isRequestingProjectActivityLogs = action.payload;
    },
    setProjectActivityLogs: (state, action) => {
      state.projectActivityLogs = action.payload;
    },
    setRequestCloseProject: (state, action) => {
      state.isRequestingCloseProject = action.payload;
    }
  }
});
