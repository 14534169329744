import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { RepositoryFile } from 'store/repositoryfile/types';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';
import 'scripts/wmts';

interface WMTSLULCTileLayerProps {
  selectedLulcLayer: string[];
  lulcRepoFile: RepositoryFile;
  env: string;
}

const WMTSLULCTileLayer = ({ env, selectedLulcLayer, lulcRepoFile }: WMTSLULCTileLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    if (selectedLulcLayer.includes(env)) return;

    const wmtsParams = {
      layer: `skies_htl:${lulcRepoFile.wms_layer_name}`,
      env: `${env}:1`,
      format: 'image/png8',
      transparent: true,
      maxZoom: 15,
      pane: 'overlayPane',
      opacity: 0.7,
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);
    currentLayer.current = wmsLayerGroup;

    // eslint-disable-next-line
    return () => {
      currentLayer.current.clearLayers();
    };
  }, [selectedLulcLayer]);

  return null;
};

export default WMTSLULCTileLayer;
