import { createSlice } from '@reduxjs/toolkit';
import { OrganizationState } from './types';

export const initialState: OrganizationState = {
  selectedOrganization: null,
  isLoadingOrganization: false,
  isLoadingOrganizations: false,
  organizations: [],
  isLoadingAddOrganization: false,
  isLoadingOrganizationMember: false,
  orginsationMembers: [],
  isNewOrganizationMember: false,
  isRequestingOrganizationsMapView: false,
  isRequestingEnableDisableUser: false,
  organizationsMapViewData: []
};

export const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setRequestingOrganizations: (state, action) => {
      state.isLoadingOrganizations = action.payload;
    },
    setRequestingOrganization: (state, action) => {
      state.isLoadingOrganization = action.payload;
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setRequestingAddOrganization: (state, action) => {
      state.isLoadingAddOrganization = action.payload;
    },
    setRequestingOrganizationMember: (state, action) => {
      state.isLoadingOrganizationMember = action.payload;
    },
    setOrganizationMembers: (state, action) => {
      state.orginsationMembers = action.payload;
    },
    setNewRequestOrganizationMembers: (state, action) => {
      state.isNewOrganizationMember = action.payload;
    },
    setRequestingOrganizationsMapView: (state, action) => {
      state.isRequestingOrganizationsMapView = action.payload;
    },
    setOrganizationsMapView: (state, action) => {
      state.organizationsMapViewData = action.payload;
    },
    setRequestingEnableDisableUser: (state, action) => {
      state.isRequestingEnableDisableUser = action.payload;
    }
  }
});
