import { ACCESS_TOKEN, aoiColors, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { filter, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { getProjectInSilentMode } from 'store/projects/actions';
import { setSelectedProject } from 'store/projects';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { BothPointLatLngType, LatLongType } from 'types/aoi.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { PostResponse, Response } from 'types/common.types';
import { ReformatPoints } from 'types/project.types';
import {
  extractAreaFromCoords,
  extractBeeLinePoint,
  extractEndPoint,
  extractStartPoint,
  getBeePointFromData,
  getEndPointFromData,
  getStartPointFromData,
  reformatCoordinates
} from 'utils/xml';
import { AOI_COORDS_URL, AOI_SELECTION_URL } from 'utils/apiUrls';
import { apiCall, getRepoFileCoordinates } from 'utils/connect';
import { AppDispatch, RootState } from '..';
import { AoiPayload, AoiPointsCords, AoiSelectionPayload, AoiSelectionResponse } from './types';
import {
  setAoiPointsCoords,
  setRequestingAddAOISelection,
  setRequestingAoiPointsCoords,
  setRequestingBeePoint,
  setRequestingDeleteAoiPointsCoords,
  setRequestingEndPoint,
  setRequestingGetApprovedAoi,
  setRequestingStartPoint,
  setRequestingUpdateAoiPointsCoords,
  setRequestingUpdateAoiSelection,
  setRequestingDeclineAoiSelection,
  setAoi,
  setRequestingExtractAoiFiles,
  setExtractAoiFiles
} from '.';

export const getStartPointCoords =
  (
    repoFile: RepositoryFile[],
    token: string,
    callback: (data: LatLongType | null, id: number) => void
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    dispatch(setRequestingStartPoint(true));
    if (!isEmpty(repoFile)) {
      const state = getState();
      const { project } = state.projects;
      // const url = repoFile.map((o: RepositoryFile) => o.url)[0];
      const id = repoFile.map((o: RepositoryFile) => o.id)[0];

      if (id) {
        const response = await getRepoFileCoordinates(project.id, id, token);

        if (response.length > 0) {
          const result = await getStartPointFromData(response);
          callback(result, id);
          dispatch(setRequestingStartPoint(false));
        } else {
          callback(null, 0);
          dispatch(setRequestingStartPoint(false));
        }

        // const dataFile = await getFileFromUrl(url);
        // const fileReader = new FileReader();
        // fileReader.onload = async (e: any) => {
        //   const result = await extractStartPoint(e.target.result);
        //   callback(result, id);
        //   dispatch(setRequestingStartPoint(false));
        // };
        // fileReader.readAsText(dataFile);
      } else {
        toast.error('File does not exists');
        callback(null, 0);
        dispatch(setRequestingStartPoint(false));
      }
    } else {
      toast.error('File does not exists');
      callback(null, 0);
      dispatch(setRequestingStartPoint(false));
    }
  };

export const getCoordsByUploadStartPointFile =
  (file: File, callback: (data: LatLongType | null) => void) => async (dispatch: AppDispatch) => {
    dispatch(setRequestingStartPoint(true));
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = async (e: any) => {
        const result = await extractStartPoint(e.target.result);
        callback(result);
        dispatch(setRequestingStartPoint(false));
      };
      fileReader.readAsText(file);
    } else {
      toast.error('Something went wrong');
      callback(null);
      dispatch(setRequestingStartPoint(false));
    }
  };

export const getEndPointCoords =
  (
    repoFile: RepositoryFile[],
    token: string,
    callback: (data: LatLongType | null, id: number) => void
  ) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    dispatch(setRequestingEndPoint(true));
    if (repoFile) {
      const state = getState();
      const { project } = state.projects;
      // const url = repoFile.map((o: any) => o.url)[0];
      const id = repoFile.map((o: RepositoryFile) => o.id)[0];
      if (id) {
        const response = await getRepoFileCoordinates(project.id, id, token);

        if (response.length > 0) {
          const result = await getEndPointFromData(response);
          callback(result, id);
          dispatch(setRequestingEndPoint(false));
        } else {
          callback(null, 0);
          dispatch(setRequestingEndPoint(false));
        }
        // const dataFile = await getFileFromUrl(url);
        // const fileReader = new FileReader();
        // fileReader.onload = async (e: any) => {
        //   const result = await extractEndPoint(e.target.result);
        //   callback(result, id);
        //   dispatch(setRequestingEndPoint(false));
        // };
        // fileReader.readAsText(dataFile);
      } else {
        toast.error('File does not exists');
        callback(null, 0);
        dispatch(setRequestingEndPoint(false));
      }
    } else {
      toast.error('File does not exists');
      callback(null, 0);
      dispatch(setRequestingEndPoint(false));
    }
  };

export const getCoordsByUploadEndPointFile =
  (file: File, callback: (data: LatLongType | null) => void) => async (dispatch: AppDispatch) => {
    dispatch(setRequestingEndPoint(true));
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = async (e: any) => {
        const result = await extractEndPoint(e.target.result);
        callback(result);
        dispatch(setRequestingEndPoint(false));
      };
      fileReader.readAsText(file);
    } else {
      toast.error('Something went wrong');
      callback(null);
      dispatch(setRequestingEndPoint(false));
    }
  };

export const getBeeLinePointCoords =
  (repoFile: RepositoryFile[], token: string, callback: (data: any, id: number) => void) =>
  async (dispatch: AppDispatch, getState: RootState) => {
    dispatch(setRequestingBeePoint(true));
    if (repoFile) {
      const state = getState();
      const { project } = state.projects;
      // const url = repoFile.map((o: any) => o.url)[0];
      const id = repoFile.map((o: RepositoryFile) => o.id)[0];
      if (id) {
        const response = await getRepoFileCoordinates(project.id, id, token);

        if (response.length > 0) {
          const result = await getBeePointFromData(response);
          callback(result, id);
          dispatch(setRequestingBeePoint(false));
        } else {
          callback(null, 0);
          dispatch(setRequestingBeePoint(false));
        }
        // const dataFile = await getFileFromUrl(url);
        // const fileReader = new FileReader();
        // fileReader.onload = async (e: any) => {
        //   const result = await extractBeeLinePoint(e.target.result);
        //   callback(result, id);
        //   dispatch(setRequestingBeePoint(false));
        // };
        // fileReader.readAsText(dataFile);
      } else {
        toast.error('File does not exists');
        callback(null, 0);
        dispatch(setRequestingBeePoint(false));
      }
    } else {
      toast.error('File does not exists');
      callback(null, 0);
      dispatch(setRequestingBeePoint(false));
    }
  };

export const getCoordsByUploadBeeLinePointFile =
  (file: File, callback: (data: BothPointLatLngType | null | any) => void) =>
  async (dispatch: AppDispatch) => {
    dispatch(setRequestingBeePoint(true));
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = async (e: any) => {
        const result = await extractBeeLinePoint(e.target.result);
        callback(result);
        dispatch(setRequestingBeePoint(false));
      };
      fileReader.readAsText(file);
    } else {
      toast.error('Upload file something wrong');
      callback(null);
      dispatch(setRequestingBeePoint(false));
    }
  };

export const getAoiPointsCoords =
  (projectId: number, token: string) => (dispatch: AppDispatch, getState: RootState) => {
    try {
      const state = getState();
      dispatch(setRequestingAoiPointsCoords(true));

      const onSuccess = (response: Response<AoiPointsCords>) => {
        if (!isEmpty(response.data) && response.data?.is_approved) {
          dispatch(
            setSelectedProject({
              ...state.projects.selectedProject,
              start_point: response.data.start_point,
              end_point: response.data.end_point
            })
          );
        }
        dispatch(setAoiPointsCoords(response.data));
        dispatch(getProjectInSilentMode(projectId, token));
        dispatch(setRequestingAoiPointsCoords(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getAoiPointsCoords(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAoiPointsCoords(false));
        }
      };

      apiCall('GET', AOI_COORDS_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingAoiPointsCoords(false));
    }
  };

export const addAoiPointsCoords =
  (payload: AoiPayload, projectId: number, token: string, callback: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAoiPointsCoords(true));

      const onSuccess = (response: Response<PostResponse>) => {
        dispatch(getAoiPointsCoords(projectId, token));
        dispatch(setRequestingAoiPointsCoords(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addAoiPointsCoords(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAoiPointsCoords(false));
        }
      };

      apiCall('POST', AOI_COORDS_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingAoiPointsCoords(false));
      toast.error(error.message);
    }
  };

export const deleteAoiPointsCoords =
  (projectId: number, token: string, callback?: () => void) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDeleteAoiPointsCoords(true));

      const onSuccess = () => {
        toast.success('Declined successfully');
        dispatch(getAoiPointsCoords(projectId, token));
        dispatch(setRequestingDeleteAoiPointsCoords(false));
        if (callback) {
          callback();
        }
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(deleteAoiPointsCoords(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDeleteAoiPointsCoords(false));
        }
      };

      apiCall('DELETE', AOI_COORDS_URL(projectId), {}, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingDeleteAoiPointsCoords(false));
      toast.error(error.message);
    }
  };

export const updateAoiPointsCoords =
  (
    payload: AoiPayload | null,
    projectId: number,
    token: string,
    callback: (status: boolean) => void
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUpdateAoiPointsCoords(true));

      const onSuccess = (response: Response<any>) => {
        dispatch(getAoiPointsCoords(projectId, token));
        dispatch(setRequestingUpdateAoiPointsCoords(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(updateAoiPointsCoords(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingUpdateAoiPointsCoords(false));
        }
      };
      const bodypayload = payload || {};

      apiCall('PUT', AOI_COORDS_URL(projectId), bodypayload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingUpdateAoiPointsCoords(false));
      toast.error(error.message);
    }
  };

export const getAOISelection = (projectId: number, token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingGetApprovedAoi(true));

    const onSuccess = (response: Response<AoiSelectionResponse>) => {
      dispatch(setAoi(response.data));
      dispatch(setRequestingGetApprovedAoi(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getAOISelection(projectId, reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingGetApprovedAoi(false));
      }
    };

    apiCall('GET', AOI_SELECTION_URL(projectId), '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingGetApprovedAoi(false));
    toast.error(error.message);
  }
};

export const addAOISelectionForApproval =
  (payload: AoiSelectionPayload, projectId: number, token: string, callback: (res: any) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAddAOISelection(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getAOISelection(projectId, token));
        dispatch(setRequestingAddAOISelection(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addAOISelectionForApproval(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAddAOISelection(false));
        }
      };

      apiCall('POST', AOI_SELECTION_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingAddAOISelection(false));
      toast.error(error.message);
    }
  };

export const updateAOISelectionForApproval =
  (payload: AoiSelectionPayload, projectId: number, token: string, callback: (res: any) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingUpdateAoiSelection(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getAOISelection(projectId, token));
        callback(response.status);
        dispatch(setRequestingUpdateAoiSelection(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(updateAOISelectionForApproval(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingUpdateAoiSelection(false));
        }
      };

      apiCall('PUT', AOI_SELECTION_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingUpdateAoiSelection(false));
      toast.error(error.message);
    }
  };

export const deleteAOISelectionForApproval =
  (projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDeclineAoiSelection(true));

      const onSuccess = (response: any) => {
        dispatch(getAOISelection(projectId, token));
        dispatch(setRequestingDeclineAoiSelection(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(deleteAOISelectionForApproval(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDeclineAoiSelection(false));
        }
      };

      apiCall('DELETE', AOI_SELECTION_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingDeclineAoiSelection(false));
      toast.error(error.message);
    }
  };

export const getExtractAOIFiles =
  (token: string) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch(setRequestingExtractAoiFiles(true));

      const state = getState();
      const { repositoryFiles } = state.repofiles;
      const { project } = state.projects;
      const files = filter(repositoryFiles, {
        category: FilesCategory.AOI,
        enabled_for_map: true
      });
      const aoiFiles: RepositoryFile[] = [];

      // eslint-disable-next-line
      for await (const [i, file] of files.entries()) {
        // const fileFromUrl = await getFileFromUrl(file.url);
        // const extractFile = await readFileAsyncAsText(fileFromUrl);
        // const area = await extractAreaFromKML(extractFile);
        // const coordinates = await extractCoordinatesFromKML(extractFile);
        const response = await getRepoFileCoordinates(project.id, file.id, token);
        let coordinate: ReformatPoints | null = null;
        let area = 0;

        if (response.length > 0) {
          coordinate = await reformatCoordinates(response);
          area = await extractAreaFromCoords(coordinate);
        }

        aoiFiles.push({
          ...file,
          length: (area / 1000000).toFixed(2),
          color: aoiColors[i],
          coordinates: coordinate?.points || []
        });
      }

      dispatch(setExtractAoiFiles(aoiFiles));
      dispatch(setRequestingExtractAoiFiles(false));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingExtractAoiFiles(false));
    }
  };
