import moment from 'moment';
import { Col, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { DateFormat4 } from 'constants/common.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import './styles.less';

const { Text } = Typography;

const SubHeader = () => {
  const { selectedProject } = useAppSelector((state) => state.projects);
  const startPoint = selectedProject?.start_point;
  const endPoint = selectedProject?.end_point;

  return (
    <Row className="projectHeaderRow" justify="space-between">
      <Col xxl={2} xl={3} lg={4} md={4} className="project-title-main">
        <div>
          <Text className="project-title">PROJECT ID</Text>
          <Text className="project-id">{selectedProject !== null ? selectedProject.id : '-'}</Text>
        </div>
      </Col>
      <Col xxl={4} xl={4} lg={4} md={4}>
        <Text className="project-title">PROJECT NAME</Text>
        <div className="project-name">
          {selectedProject !== null && selectedProject?.name ? selectedProject.name : '-'}
        </div>
      </Col>
      <Col xxl={5} xl={5} lg={4} md={4}>
        <Text className="project-title">START POINT</Text>
        <Text className="project-start-point">
          {!isEmpty(startPoint) ? (
            <div className="project-point">
              {startPoint.name && <Text className="start-point-name">{startPoint.name}</Text>}
              {!isEmpty(startPoint.coordinates) ? (
                <Text className="start-point-coord start-point-name">{`${startPoint.coordinates.lat.toFixed(
                  6
                )} , ${startPoint.coordinates.lng.toFixed(6)}`}</Text>
              ) : (
                <Text className="not-defined">Not Defined</Text>
              )}
            </div>
          ) : (
            <Text className="not-defined">Not Defined</Text>
          )}
        </Text>
      </Col>
      <Col xxl={6} xl={6} lg={4} md={4}>
        <Text className="project-title">END POINT</Text>
        <Text>
          {!isEmpty(endPoint) ? (
            <div className="project-point">
              {endPoint.name && <Text className="start-point-name">{endPoint.name}</Text>}
              {!isEmpty(endPoint.coordinates) ? (
                <Text className="start-point-coord start-point-name">{`${endPoint.coordinates.lat.toFixed(
                  6
                )} , ${endPoint.coordinates.lng.toFixed(6)}`}</Text>
              ) : (
                <Text className="not-defined">Not Defined</Text>
              )}
            </div>
          ) : (
            <Text className="not-defined">Not Defined</Text>
          )}
        </Text>
      </Col>
      <Col xxl={6} xl={6} lg={7} md={6}>
        <Text className="project-title">LAST UPDATED</Text>
        {selectedProject !== null && selectedProject?.last_updated ? (
          <Text className="last-updated-date">
            {moment(selectedProject?.last_updated).format(DateFormat4)} IST
          </Text>
        ) : (
          <Text className="not-defined">Not Defined</Text>
        )}
      </Col>
    </Row>
  );
};

export default SubHeader;
