import { useMemo, useState } from 'react';
import { Collapse, Dropdown, Switch, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { BiLayer } from 'react-icons/bi';
import DownArrowIcon from 'assets/svg/DownArrowIcon';
import UpArrowIcon from 'assets/svg/UpArrowIcon';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import { LulcDataType } from 'constants/map.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getVectorFiles } from 'store/aoi';
import {
  getDEMRepoFile,
  getDSMRepoFile,
  getHighResolutionSataliteImagesRepoFile,
  getLULCRepoFile,
  getLulcStyleLayers
} from 'store/repositoryfile';
import { AoiPointsCords } from 'store/aoi/types';
import './styles.less';
import { black, white } from 'constants/theme.constants';

interface LayerControlProps {
  mayType: 'route' | 'aoi';
  vectorLayerIds: number[];
  selectedLulcLayer: string[];
  isShowBeeLine: boolean;
  finalAoi: RepositoryFile | null;
  isShowFinalizedAoi: boolean;
  handleVectorLayerIds: (id: number) => void;
  handleShowLULCLayerOnMap: (id: string) => void;
  handleShowBeeLineOnMap: () => void;
  aoiPointsCoords: AoiPointsCords | null;
  handleShowFinalizedAoi: () => void;
  handleDEMLegend?: (isShow: boolean) => void;
  isShowDEMLegend?: boolean;
  handleDSMLegend?: (isShow: boolean) => void;
  isShowDSMLegend?: boolean;
  handleSatelliteImage?: (isShow: boolean) => void;
  isShowhandleSatelliteImage?: boolean;
}

const { Text } = Typography;
const { Panel } = Collapse;

const LayerControl = ({
  mayType,
  vectorLayerIds,
  selectedLulcLayer,
  isShowBeeLine,
  isShowFinalizedAoi,
  handleVectorLayerIds,
  handleShowLULCLayerOnMap,
  handleShowBeeLineOnMap,
  aoiPointsCoords,
  finalAoi,
  handleShowFinalizedAoi,
  handleDEMLegend,
  isShowDEMLegend,
  handleDSMLegend,
  isShowDSMLegend,
  handleSatelliteImage,
  isShowhandleSatelliteImage
}: LayerControlProps) => {
  const lulcRepoFile: RepositoryFile = useAppSelector(getLULCRepoFile);
  const lulcStyleLayers: LulcDataType[] = useAppSelector(getLulcStyleLayers);
  const vectorRepoFiles: Array<RepositoryFile> = useAppSelector(getVectorFiles);
  const isRequestingVectorFiles: RepositoryFile = useAppSelector(
    (state) => state.vectorfile.isRequestingVectorFiles
  );
  const demRepoFile: RepositoryFile = useAppSelector(getDEMRepoFile);
  const dsmRepoFile: RepositoryFile = useAppSelector(getDSMRepoFile);
  const highResolutionSataliteImagesRepoFile: RepositoryFile = useAppSelector(
    getHighResolutionSataliteImagesRepoFile
  );

  const [isOpen, setOpen] = useState(false);

  const handleLayerDown = () => {
    setOpen(!isOpen);
  };

  const handleLayerUp = () => {
    setOpen(false);
  };

  const handleShowVectorLayerOnMap = (fileId: number) => {
    handleVectorLayerIds(fileId);
  };

  const layerControlDropDown = () => {
    return <div />;
  };

  const demOption = useMemo(() => {
    return (
      <div className="vector-layer-content">
        <Text>DEM</Text>
        <Switch
          checked={isShowDEMLegend}
          size="small"
          onChange={(check) => handleDEMLegend && handleDEMLegend(check)}
        />
      </div>
    );
  }, [isShowDEMLegend]);

  const dsmOption = useMemo(() => {
    return (
      <div className="vector-layer-content">
        <Text>DSM</Text>
        <Switch
          checked={isShowDSMLegend}
          size="small"
          onChange={(check) => handleDSMLegend && handleDSMLegend(check)}
        />
      </div>
    );
  }, [isShowDSMLegend]);

  return (
    <>
      <div className="custom-layer">
        <Dropdown
          open={isOpen}
          overlay={layerControlDropDown}
          trigger={['click']}
          placement="bottomRight"
        >
          <a className="layer-dd-content" onClick={isOpen ? handleLayerUp : handleLayerDown}>
            <BiLayer color={white} size={16} />
            {isOpen ? <UpArrowIcon /> : <DownArrowIcon />}
          </a>
        </Dropdown>
      </div>
      {isOpen && (
        <Collapse defaultActiveKey={['1', '2']} className="vector-layer" expandIconPosition="end">
          <Panel
            className="uploaded-layer upload-layer-content"
            style={{ paddingTop: '5px' }}
            header="Uploaded Layers"
            key="1"
          >
            {!isEmpty(vectorRepoFiles) ? (
              vectorRepoFiles.map((file: RepositoryFile) => {
                return (
                  <div className="vector-layer-content" key={file.id}>
                    <div>
                      <div
                        className="box-color"
                        style={
                          file.color
                            ? { backgroundColor: `${file.color}` }
                            : { backgroundColor: `${black}` }
                        }
                      />
                      <Text
                        className={`layer-content-title ${
                          vectorLayerIds.includes(file.id) ? 'active-layer' : ''
                        }`}
                      >
                        {file.category}
                      </Text>
                    </div>
                    <Text onClick={() => handleShowVectorLayerOnMap(file.id)}>
                      {vectorLayerIds.includes(file.id) ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    </Text>
                  </div>
                );
              })
            ) : (
              <Text className="empty-layers">No layers data found</Text>
            )}
            {isRequestingVectorFiles && <Text className="empty-layers">Loading...</Text>}
          </Panel>
          <Panel className="uploaded-layer satsure-layer" header="Satsure Layers" key="2">
            {!isEmpty(demRepoFile) && mayType === 'route' && demOption}
            {!isEmpty(dsmRepoFile) && mayType === 'route' && dsmOption}
            {!isEmpty(highResolutionSataliteImagesRepoFile) && mayType === 'route' && (
              <div className="vector-layer-content">
                <Text>Satellite Image</Text>
                <Switch
                  checked={isShowhandleSatelliteImage}
                  size="small"
                  onChange={(check) => handleSatelliteImage && handleSatelliteImage(check)}
                />
              </div>
            )}

            <div className="satsure-layer-inr">
              {!isEmpty(aoiPointsCoords) && (
                <div className="vector-layer-content">
                  <div>
                    <div className="box-color-bee" />
                    <Text className={`layer-content-title ${isShowBeeLine ? 'active-layer' : ''}`}>
                      Bee Line
                    </Text>
                  </div>
                  <Text onClick={() => handleShowBeeLineOnMap()}>
                    {isShowBeeLine ? <EyeOpenIcon /> : <EyeCloseIcon />}
                  </Text>
                </div>
              )}
              {!isEmpty(finalAoi) && (
                <div className="vector-layer-content">
                  <div>
                    <div
                      className="box-color"
                      style={
                        finalAoi.color
                          ? { backgroundColor: `${finalAoi.color}` }
                          : { backgroundColor: `${black}` }
                      }
                    />
                    <Text
                      className={`layer-content-title ${isShowFinalizedAoi ? 'active-layer' : ''}`}
                    >
                      Finalized AOI
                    </Text>
                  </div>
                  <Text onClick={() => handleShowFinalizedAoi()}>
                    {isShowFinalizedAoi ? <EyeOpenIcon /> : <EyeCloseIcon />}
                  </Text>
                </div>
              )}
              {!isEmpty(lulcRepoFile) &&
                lulcStyleLayers.map((style: LulcDataType) => {
                  return (
                    <div className="vector-layer-content" key={style.value}>
                      <div>
                        <div
                          className="box-color"
                          style={
                            style.color
                              ? { backgroundColor: `${style.color}` }
                              : { backgroundColor: `${black}` }
                          }
                        />
                        <Text
                          className={`layer-content-title ${
                            !selectedLulcLayer.includes(style.value) ? 'active-layer' : ''
                          }`}
                        >
                          {style.title}
                        </Text>
                      </div>
                      <Text onClick={() => handleShowLULCLayerOnMap(style.value)}>
                        {!selectedLulcLayer.includes(style.value) ? (
                          <EyeOpenIcon />
                        ) : (
                          <EyeCloseIcon />
                        )}
                      </Text>
                    </div>
                  );
                })}
            </div>
            {isEmpty(aoiPointsCoords) && isEmpty(lulcRepoFile) && (
              <Text className="empty-layers">No layers data found</Text>
            )}
          </Panel>
        </Collapse>
      )}
    </>
  );
};

LayerControl.defaultProps = {
  handleDEMLegend: () => {},
  isShowDEMLegend: false,
  handleDSMLegend: () => {},
  isShowDSMLegend: false,
  handleSatelliteImage: () => {},
  isShowhandleSatelliteImage: false
};

export default LayerControl;
