import { useState } from 'react';
import { find, isEmpty } from 'lodash';
import { Button, Divider, Input, Select, Space, Spin, Typography, Upload, UploadProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdOutlineClear } from 'react-icons/md';
import { IoIosClose } from 'react-icons/io';
import { greyShed, lightGrey, primary, white } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  Category,
  RepositoryFile,
  FilesCategory,
  UploadFilePayload
} from 'store/repositoryfile/types';
import { getRepositoryFiles } from 'store/repositoryfile/actions';
import { getExtractRouteFiles } from 'store/route/actions';
import { asyncFileUpload } from 'utils/connect';
import { getAsyncStorageValue } from 'utils/localStorage';
import RouteItemCard from './RouteItemCard';

interface RouteTabProps {
  loading: boolean;
  adminRoutes: Array<RepositoryFile> | [];
  superAdminRoutes: Array<RepositoryFile> | [];
  selectedRoutesIds: number[] | [];
  handleSelectedRoute: Function;
}

const { Text } = Typography;
const { Option } = Select;

const RouteTab = ({
  loading,
  adminRoutes,
  superAdminRoutes,
  selectedRoutesIds,
  handleSelectedRoute
}: RouteTabProps) => {
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector((state) => state.repofiles);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.user);
  const [repoFile, setRepoFile] = useState<File | null>(null);

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      setRepoFile(file);
      return false;
    }
  };

  const handleUploadNewRepoFile = (file: File) => {
    const categoryData: Category = find(
      categories,
      (o: Category) => o.category_name === FilesCategory.Routes
    );

    const payload: UploadFilePayload = {
      comment: 'Null',
      category_id: categoryData.id,
      buffer: '0',
      user_id: user.id,
      project_id: Number(selectedProject?.id)
    };

    return asyncFileUpload(payload, file);
  };

  const handleClearRouteFile = () => {
    setRepoFile(null);
  };

  const handleSubmitSelectedRoute = async () => {
    if (repoFile) {
      const routeFileResult = await handleUploadNewRepoFile(repoFile);
      handleClearRouteFile();

      if (routeFileResult) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            getRepositoryFiles(selectedProject.id, token, (files: any) => {
              if (!isEmpty(files)) {
                dispatch(getExtractRouteFiles(token));
              }
            })
          );
        });
      }
    }
  };

  return (
    <div className="route-tab">
      <div
        className="route-title"
        style={{
          marginBottom: superAdminRoutes?.length === 0 && adminRoutes?.length === 0 ? '10px' : '0px'
        }}
      >
        <Text className="route-title-inr">Routes</Text>
      </div>
      {(superAdminRoutes?.length > 0 || adminRoutes?.length > 0) && (
        <div className="route-tab-content">
          <Spin
            size="large"
            tip="Loading..."
            spinning={loading}
            indicator={<LoadingOutlined color="red" style={{ fontSize: 24 }} spin />}
          >
            <Space direction="vertical" className="space-route-content">
              {superAdminRoutes &&
                superAdminRoutes.map((item: RepositoryFile) => (
                  <RouteItemCard
                    route={item}
                    key={item.id}
                    handleShowHideRoute={handleSelectedRoute}
                    selectedRoutesIds={selectedRoutesIds}
                  />
                ))}
              {superAdminRoutes.length > 0 && adminRoutes.length > 0 && (
                <Divider
                  style={{ borderTop: `1px solid ${greyShed}`, margin: '2px 0px 4px 0px' }}
                />
              )}
              {adminRoutes &&
                adminRoutes.map((item: RepositoryFile) => (
                  <RouteItemCard
                    route={item}
                    key={item.id}
                    handleShowHideRoute={handleSelectedRoute}
                    selectedRoutesIds={selectedRoutesIds}
                  />
                ))}
            </Space>
          </Spin>
        </div>
      )}
      <div className="route-select-submit-btn">
        {isEmpty(repoFile) ? (
          <Select
            className="route-tab-select"
            placeholder="Select"
            allowClear
            clearIcon={<MdOutlineClear color={primary} style={{ height: '15px', width: '15px' }} />}
          >
            <Option
              style={{
                color: primary,
                borderTop: `1px solid ${lightGrey}`,
                backgroundColor: `${white}`
              }}
              value={0}
            >
              <Upload showUploadList={false} maxCount={1} {...uploadProps}>
                <Button className="upload-file-btn">Upload File</Button>
              </Upload>
            </Option>
          </Select>
        ) : (
          <Input
            allowClear
            readOnly
            style={{ width: '100%' }}
            value={repoFile.name || 'New Updated Route.kml'}
            className="route-upload-file"
            suffix={
              <IoIosClose
                onClick={() => handleClearRouteFile()}
                size={16}
                style={{ cursor: 'pointer', fill: primary }}
              />
            }
          />
        )}

        <Button
          disabled={!repoFile}
          className="start-point-btn"
          type="primary"
          onClick={handleSubmitSelectedRoute}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default RouteTab;
