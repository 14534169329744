import { useCallback, useState } from 'react';
import { Button, Col, Divider, Form, Input, Modal, PageHeader, Row, Typography } from 'antd';
import Cropper from 'react-easy-crop';
import { BsArrowLeft, BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { addOrganization } from 'store/organizations/actions';
import getCroppedImg, { dataURLtoFile } from 'utils/cropImage';
import { organizationSync } from 'utils/yupsync';
import { encryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const AddOrganization = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingAddOrganization } = useAppSelector((state) => state.organizations);
  const [form] = Form.useForm();
  const [isOpenCropModal, setOpenCropModal] = useState<boolean>(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [file, setFile] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [croppedImage, setCroppedImage] = useState<string>('');
  const [orgName, setOrgName] = useState<string>('');

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCroppedImage = useCallback(async () => {
    const croppedImage: any = await getCroppedImg(file, croppedAreaPixels);
    const filename = orgName ? orgName.replace(/[^a-zA-Z]/g, '') : 'neworganization';
    const cropFile = await dataURLtoFile(croppedImage, filename);

    setSelectedFile(cropFile);
    setCroppedImage(croppedImage);
    setOpenCropModal(false);
  }, [croppedAreaPixels]);

  const handleChange = (e: any) => {
    if (e.target.files) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setOpenCropModal(true);
    }

    setOrgName(form.getFieldValue('organisation_name'));
    e.target.value = '';
  };

  const handleCancelCropped = () => {
    setOpenCropModal(false);
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const handleCancel = () => {
    form.resetFields();
    setFile('');
    setSelectedFile(undefined);
    setCroppedImage('');
    setOpenCropModal(false);
    navigate(routes.Organization, { replace: true });
  };

  const handleSubmitAddOrganization = () => {
    form.submit();
  };

  const handleCallback = () => {
    form.resetFields();
    navigate(routes.Organization, { replace: true });
  };

  const handleSubmitForm = () => {
    let payload = form.getFieldsValue([
      'organisation_name',
      'admin_firstname',
      'admin_lastname',
      'admin_email'
    ]);

    payload = {
      ...payload,
      admin_firstname: encodeURIComponent(encryptValue(payload.admin_firstname)),
      admin_lastname: encodeURIComponent(encryptValue(payload.admin_lastname)),
      admin_email: encodeURIComponent(encryptValue(payload.admin_email))
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addOrganization(payload, token, handleCallback, selectedFile));
    });
  };

  const handleGoBack = () => {
    navigate(routes.Organization, { replace: true });
  };

  return (
    <>
      <HeaderLayout />
      <div className="main-org-content">
        <PageHeader
          className="main-title-content"
          onBack={handleGoBack}
          backIcon={<BsArrowLeft size={20} className="backIcon" />}
          ghost={false}
          title={<Text className="page-title">Add Organization</Text>}
        />

        <Row className="main-add-org-content">
          <Col className="add-org-form-col" xxl={24} xl={24} lg={24}>
            <Row>
              <Col className="add-org-form-col" xxl={16} xl={16} lg={16}>
                <Form
                  className="main-orgcontent-form"
                  form={form}
                  onFinish={handleSubmitForm}
                  name="member_detail_form"
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    name="organisation_name"
                    label="Name of the Organization"
                    rules={[organizationSync]}
                    className="org-form-item"
                  >
                    <Input
                      className="org-input"
                      size="large"
                      placeholder="Enter Name of Organization"
                    />
                  </Form.Item>
                  <div className="inlineContent">
                    <Form.Item
                      name="admin_firstname"
                      label="Name of Admin"
                      rules={[organizationSync]}
                      className="inputFirstName org-form-item"
                    >
                      <Input className="org-input" placeholder="Enter first Name" />
                    </Form.Item>
                    <Form.Item
                      name="admin_lastname"
                      rules={[organizationSync]}
                      label={<Text className="last-name-label">s</Text>}
                      className="inputFirstName org-form-item"
                    >
                      <Input className="org-input" placeholder="Enter last Name" />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="admin_email"
                    rules={[organizationSync]}
                    label="Email address of Admin"
                    className="org-form-item"
                  >
                    <Input
                      className="org-input"
                      type="email"
                      placeholder="Enter email address of admin"
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col className="divider-col" xxl={1} xl={1} lg={1}>
                <Divider type="vertical" />
              </Col>
              <Col className="upload-file-col" xxl={6} xl={6} lg={6}>
                <Text className="upload-title">Upload Organization Logo</Text>
                {croppedImage ? (
                  <div className="upload-main">
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                      className="selected-image-btn"
                      onChange={handleChange}
                    />
                    <img className="logo-image" src={croppedImage} alt="logo" />
                  </div>
                ) : (
                  <div className="upload-main">
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                      className="image-uplode-btn"
                      onChange={handleChange}
                    />
                    <div className="add-image-icon">
                      <BsPlusCircle size={25} />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xxl={24} xl={24} lg={24} className="btn-col">
            <Row>
              <Col xxl={11} xl={11} lg={11}>
                <Button className="cancel-btn" type="ghost" size="large" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
              <Col xxl={12} xl={12} lg={12}>
                <Button
                  className="add-btn"
                  htmlType="submit"
                  type="primary"
                  size="large"
                  loading={isLoadingAddOrganization}
                  onClick={handleSubmitAddOrganization}
                >
                  Add organization
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        key={1}
        open={isOpenCropModal}
        centered
        width={700}
        title="Crop Picture"
        closable
        className="crop-image-modal"
        footer={[
          <input
            key={22}
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e: any) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />,
          <div className="crop-modal-btn" key={33}>
            <Button type="ghost" onClick={handleCancelCropped}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleCroppedImage}>
              Save
            </Button>
          </div>
        ]}
      >
        <div className="cropper-content" key={11}>
          <div className="crop-container">
            <Cropper
              image={file}
              crop={crop}
              zoom={zoom}
              maxZoom={3}
              aspect={16 / 9}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddOrganization;
