import { combineReducers } from '@reduxjs/toolkit';
import user from './users';
import organizations from './organizations';
import projects from './projects';
import repofiles from './repositoryfile';
import aoi from './aoi';
import route from './route';
import analytics from './analytics';
import vectorfile from './vectorfile';

const rootReducer = combineReducers({
  user,
  organizations,
  projects,
  repofiles,
  aoi,
  route,
  analytics,
  vectorfile
});

export default rootReducer;
