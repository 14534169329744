import { Modal, Typography } from 'antd';

interface SendForApprovalModalProps {
  isOpen: boolean;
}
const { Text } = Typography;

const SendForApprovalModal = ({ isOpen }: SendForApprovalModalProps) => {
  return (
    <Modal
      className="aoi-approve-modal"
      width={510}
      closable={false}
      open={isOpen}
      footer={null}
      centered
    >
      <div className="aoi-approve-content">
        <Text className="notify-title">Sent for Approval</Text>
        <Text className="notify-msg">We will notify to admin for approval</Text>
      </div>
    </Modal>
  );
};

export default SendForApprovalModal;
