import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes as routeName } from 'constants/pageRoutes.constants';
import SidebarLayout from 'layouts/SidebarLayout';
import NotFound from 'pages/404/NotFound';
import Login from 'pages/Authentication/Login';
import Projects from 'pages/Projects/Projects';
import TeamOnboarding from 'pages/TeamOrganization/TeamOnboarding';
import ProjectTimeLine from 'pages/ProjectTimeline/ProjectTimeLine';
import UserManagement from 'pages/UserManagement/UserManagement';
import FileRepository from 'pages/FileRepository/FileRepository';
import MemberDetail from 'pages/MemberDetail/MemberDetail';
import Organization from 'pages/SuperAdmin/Organization/Organization';
import AddOrganization from 'pages/SuperAdmin/AddOrganization/AddOrganization';
import EditOrganization from 'pages/SuperAdmin/AddOrganization/EditOrganization';
import ManagerDetail from 'pages/Manager/ManagerDetail/ManagerDetail';
import Aoi from 'pages/AOI/Aoi';
import RoutePage from 'pages/Route/Route';
import Analytics from 'pages/Analytics/Analytics';
// import Message from 'pages/Messages/Message';
import MapView from 'pages/Map/MapView';
import AdminMapView from 'pages/Map/AdminMapView';
import { UserRole } from 'store/users/types';
import PrivateRoute from './PrivateRoute';

export const Navigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routeName.LoginUrl} element={<Login />} />
        <Route
          path={routeName.ProjectList}
          element={
            <PrivateRoute>
              <Projects />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.Organization}
          element={
            <PrivateRoute roles={[UserRole.SuperAdmin]}>
              <Organization />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.AddOrganization}
          element={
            <PrivateRoute roles={[UserRole.SuperAdmin]}>
              <AddOrganization />
            </PrivateRoute>
          }
        />
        <Route
          path={`${routeName.EditOrganization}/:id`}
          element={
            <PrivateRoute roles={[UserRole.SuperAdmin]}>
              <EditOrganization />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.MapPage}
          element={
            <PrivateRoute roles={[UserRole.SuperAdmin]}>
              <MapView />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.AdminMapPage}
          element={
            <PrivateRoute roles={[UserRole.Admin]}>
              <AdminMapView />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.MemberDetail}
          element={
            <PrivateRoute roles={[UserRole.Admin]}>
              <MemberDetail />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.TeamOnboarding}
          element={
            <PrivateRoute roles={[UserRole.Admin]}>
              <TeamOnboarding />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.UserManagement}
          element={
            <PrivateRoute roles={[UserRole.SuperAdmin, UserRole.Admin]}>
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.ManagerMemberDetail}
          element={
            <PrivateRoute roles={[UserRole.Manager]}>
              <ManagerDetail />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.Projects}
          element={
            <PrivateRoute>
              <SidebarLayout />
            </PrivateRoute>
          }
        >
          <Route
            path={routeName.ProjectTimelineUrl}
            element={
              <PrivateRoute>
                <ProjectTimeLine />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.FileRepository}
            element={
              <PrivateRoute>
                <FileRepository />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.AOI}
            element={
              <PrivateRoute>
                <Aoi />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.Routes}
            element={
              <PrivateRoute>
                <RoutePage />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.Analytics}
            element={
              <PrivateRoute>
                <Analytics />
              </PrivateRoute>
            }
          />
          {/* <Route
            path={routeName.Message}
            element={
              <PrivateRoute>
                <Message />
              </PrivateRoute>
            }
          /> */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
