import { useMemo } from 'react';
import { Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { Project } from 'store/projects/types';
import AvatarList from 'components/Avatar/AvatarList';
import { statusClass } from 'utils/util';

interface SuperAdminProjectManagementProps {
  projects: Array<Project>;
  isLoading: boolean;
  handleAddProjectMemberCallback: Function;
}

const { Text } = Typography;

const SuperAdminProjectManagement = ({
  projects,
  isLoading,
  handleAddProjectMemberCallback
}: SuperAdminProjectManagementProps) => {
  const projectColumns: ColumnsType<Project> = useMemo(
    () => [
      {
        title: 'Project ID',
        dataIndex: 'project_id',
        key: 'id',
        render: (_, record) => <Text key={record.id}>{record.id}</Text>
      },
      {
        title: 'Name of the project',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => <Text key={record.id}>{record.name}</Text>
      },
      {
        title: 'Assigned team members',
        dataIndex: 'team_members',
        key: 'team_members',
        render: (_, record) => (
          <AvatarList
            className="p-r-8"
            record={record}
            handleAddProjectMemberForm={handleAddProjectMemberCallback}
          />
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <Text key={record.id} className={statusClass(record.status)}>
            {record.status}
          </Text>
        )
      },
      {
        title: 'Project Stage',
        dataIndex: 'in_progress_stage',
        key: 'in_progress_stage',
        render: (_, record) => (
          <Text key={record.id}>{record.in_progress_stage ? record.in_progress_stage : '-'}</Text>
        )
      }
    ],
    []
  );
  return (
    <Table
      loading={isLoading}
      columns={projectColumns}
      dataSource={projects}
      pagination={false}
      className="userMangementTabel"
      showHeader
      sticky
      rowKey={(row: Project) => row.id}
    />
  );
};

export default SuperAdminProjectManagement;
