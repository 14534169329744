import { greyShed3 } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const SettingIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.07004L5.52908 2.59195C5.37252 2.75394 5.15689 2.84542 4.93161 2.84542H2.84541V4.93161C2.84541 5.15689 2.75394 5.37252 2.59195 5.52908L1.07004 7L2.59195 8.47092C2.75394 8.62749 2.84541 8.84311 2.84541 9.06839V11.1546H4.93161C5.15689 11.1546 5.37251 11.2461 5.52908 11.408L7 12.93L8.47092 11.408C8.62748 11.2461 8.84311 11.1546 9.06839 11.1546H11.1546V9.06839C11.1546 8.84311 11.2461 8.62748 11.4081 8.47092L12.93 7L11.4081 5.52908C11.2461 5.37252 11.1546 5.15689 11.1546 4.93161V2.84542H9.06839C8.84311 2.84542 8.62748 2.75394 8.47092 2.59195L7 1.07004ZM6.40253 0.253463C6.72916 -0.0844881 7.27084 -0.0844875 7.59747 0.253463L9.13889 1.84831H11.3208C11.7797 1.84831 12.1517 2.22033 12.1517 2.67923V4.86111L13.7465 6.40253C14.0845 6.72916 14.0845 7.27084 13.7465 7.59747L12.1517 9.13889V11.3208C12.1517 11.7797 11.7797 12.1517 11.3208 12.1517H9.13889L7.59747 13.7465C7.27084 14.0845 6.72916 14.0845 6.40253 13.7465L4.86111 12.1517H2.67923C2.22033 12.1517 1.84831 11.7797 1.84831 11.3208V9.13889L0.253463 7.59747C-0.0844873 7.27084 -0.084488 6.72916 0.253463 6.40253L1.84831 4.86111V2.67923C1.84831 2.22033 2.22033 1.84831 2.67923 1.84831H4.86111L6.40253 0.253463Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84252 7C3.84252 5.25617 5.25617 3.84252 7 3.84252C8.74383 3.84252 10.1575 5.25617 10.1575 7C10.1575 8.74383 8.74383 10.1575 7 10.1575C5.25617 10.1575 3.84252 8.74383 3.84252 7ZM7 4.83962C5.80685 4.83962 4.83962 5.80685 4.83962 7C4.83962 8.19315 5.80685 9.16039 7 9.16039C8.19315 9.16039 9.16038 8.19315 9.16038 7C9.16038 5.80685 8.19315 4.83962 7 4.83962Z"
        fill={color}
      />
    </svg>
  );
};
SettingIcon.defaultProps = {
  color: greyShed3,
  height: 14,
  width: 14
};

export default SettingIcon;
