interface RedDotProps {
  Top?: string | null;
  Right?: string | null;
  Bottom?: string | null;
  Left?: string | null;
}

const RedDot = ({ Top, Right, Bottom, Left }: RedDotProps) => {
  const dotStyle: any = {
    top: '',
    right: '',
    bottom: '',
    left: ''
  };
  if (Top) {
    dotStyle.top = Top;
  }
  if (Right) {
    dotStyle.right = Right;
  }
  if (Bottom) {
    dotStyle.bottom = Bottom;
  }
  if (Left) {
    dotStyle.left = Left;
  }

  return <div className="dot-point" style={dotStyle} />;
};
RedDot.defaultProps = {
  Top: null,
  Right: null,
  Bottom: null,
  Left: null
};

export default RedDot;
// r-11 t8
