/* eslint-disable */
import { useEffect, useMemo, useRef } from 'react';
import { Polygon, useMap } from 'react-leaflet';
import L from 'leaflet';
import { RepositoryFile } from 'store/repositoryfile/types';
import { AoiSelectionResponse } from 'store/aoi/types';
import { concat } from 'lodash';
import { useAppSelector } from 'hooks/useReduxHook';
import { getAdminMangerAoiFiles, getSuperAdminAoifiles } from 'store/aoi';

interface RenderApprovedAoiLayerProps {
  approvedAoi: AoiSelectionResponse | null;
  isShowFinalizedAoi: boolean;
}

const RenderApprovedAoiLayer = ({
  approvedAoi,
  isShowFinalizedAoi
}: RenderApprovedAoiLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const aoiLayerGroup = L.featureGroup();
  const superAdminAOIFiles: RepositoryFile[] = useAppSelector(getSuperAdminAoifiles);
  const adminManagerAOIFiles: RepositoryFile[] = useAppSelector(getAdminMangerAoiFiles);
  const aoiFiles = concat(superAdminAOIFiles, adminManagerAOIFiles);
  const appAOIFile = aoiFiles.find(
    (aoiFiles: RepositoryFile) => aoiFiles.id === approvedAoi?.aoi_file_id
  );

  useEffect(() => {
    // if (currentLayer.current) currentLayer.current.clearLayers();
    // if (!appAOIFile) return;
    // fetch(appAOIFile.url)
    //   .then((res) => res.text())
    //   .then((kmltext) => {
    //     const parser = new DOMParser();
    //     const kml = parser.parseFromString(kmltext, 'text/xml');
    //     // @ts-ignore
    //     const track = new L.KML(kml);
    //     aoiLayerGroup.addLayer(track);
    //     track.setStyle({
    //       color: appAOIFile.color,
    //       fillColor: appAOIFile.color,
    //       fillOpacity: 0.3,
    //       weight: 1
    //     });
    //     const bounds = track.getBounds();
    //     map.fitBounds(bounds);
    //   })
    //   .then(() => {
    //     aoiLayerGroup.addTo(map);
    //   });
    // currentLayer.current = aoiLayerGroup;
  }, [appAOIFile]); // eslint-disable-line

  const approveAOILayer = useMemo(() => {
    if (!appAOIFile || !isShowFinalizedAoi) return null;

    return (
      <Polygon
        positions={appAOIFile?.coordinates ? appAOIFile?.coordinates : []}
        pathOptions={{
          color: appAOIFile.color,
          fillColor: appAOIFile.color,
          fillOpacity: 0.3,
          weight: 1
        }}
        pane="tilePane"
      />
    );
  }, [appAOIFile, isShowFinalizedAoi]);

  return <>{approveAOILayer}</>;
};

export default RenderApprovedAoiLayer;
