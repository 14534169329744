import { filter, sortBy } from 'lodash';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';
import { RootState } from '..';
import { routeSlice } from './slice';

export const {
  setRequestingRouteFiles,
  setRoutes,
  setRequestingDemElevetion,
  setDemElevetion,
  setRequestingDsmElevetion,
  setDsmElevetion
} = routeSlice.actions;
export default routeSlice.reducer;

export const getRouteFiles = (state: RootState) => {
  const { routes } = state.route;

  return sortBy(
    filter(routes, {
      category: FilesCategory.Routes,
      enabled_for_map: true
    }),
    ['id']
  );
};

export const getSuperAdminRouteFiles = (state: RootState) => {
  const { routes } = state.route;

  return sortBy(
    filter(routes, {
      category: FilesCategory.Routes,
      enabled_for_map: true,
      user_role: UserRole.SuperAdmin
    }),
    ['id']
  );
};

export const getAdminManagerRouteFiles = (state: RootState) => {
  const { routes } = state.route;

  return sortBy(
    filter(
      routes,
      (file: RepositoryFile) =>
        file.category === FilesCategory.Routes &&
        file.enabled_for_map &&
        file.user_role !== UserRole.SuperAdmin
    ),
    ['id']
  );
};

export const selectDsmElevetion = (state: RootState) => {
  const { dsmElevetion } = state.route;
  if (!dsmElevetion) {
    return [0, 50, 100, 200];
  }
  const min = dsmElevetion?.min;
  const max = dsmElevetion?.max;
  const diff = max - min;
  const step = diff / 3;
  const secondValue = min + step;
  const thirdValue = secondValue + step;

  return [min, secondValue, thirdValue, max];
};

export const selectDemElevetion = (state: RootState) => {
  const { demElevetion } = state.route;
  if (!demElevetion) {
    return [0, 50, 100, 200];
  }
  const min = demElevetion?.min;
  const max = demElevetion?.max;
  const diff = max - min;
  const step = diff / 3;
  const secondValue = min + step;
  const thirdValue = secondValue + step;

  return [min, secondValue, thirdValue, max];
};
