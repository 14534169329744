import XMLParser from 'react-xml-parser';
import { computeArea, computeLength, LatLng } from 'spherical-geometry-js';
import { lineString, length as pLength } from '@turf/turf';
import { flatten } from 'lodash';
import { BothPointLatLngType, LatLongType } from 'types/aoi.types';
import { CoordinateType } from 'types/common.types';
import { ReformatPoints } from 'types/project.types';

export const extractStartPoint = (plainText: any): LatLongType => {
  const xml = new XMLParser().parseFromString(plainText);
  const data = xml.getElementsByTagName('coordinates');
  const startPoint = data[0].value;
  const points = startPoint.split(',');

  return { lat: points[1].toString(), lng: points[0].toString() };
};

export const getStartPointFromData = (data: any): LatLongType => {
  const points = data[0].coordinates;
  return { lat: points[1].toString(), lng: points[0].toString() };
};

export const extractEndPoint = (plainText: any): LatLongType => {
  const xml = new XMLParser().parseFromString(plainText);
  const data = xml.getElementsByTagName('coordinates');
  const index = data.length === 2 ? 1 : 0;
  const startPoint = data[index].value;
  const points = startPoint.split(',');

  return { lat: points[1].toString(), lng: points[0].toString() };
};

export const getEndPointFromData = (data: any): LatLongType => {
  const index = data.length > 1 ? 1 : 0;
  const points = data[index].coordinates;
  return { lat: points[1].toString(), lng: points[0].toString() };
};

export const extractBeeLinePoint = (plainText: any): BothPointLatLngType => {
  const xml = new XMLParser().parseFromString(plainText);
  const data = xml.getElementsByTagName('coordinates');
  const points = data[0].value.split(' ');
  const latlngs = points.map((p: string) => {
    const points = p.split(',');
    return [Number(points[1]), Number(points[0])];
  });

  return {
    start: { lat: latlngs[0][0].toString(), lng: latlngs[0][1].toString() },
    end: { lat: latlngs[1][0].toString(), lng: latlngs[1][1].toString() },
    is_bee_line: true
  };
};

export const getBeePointFromData = (data: any): BothPointLatLngType => {
  const startPoint = data[0].coordinates[0];
  const endPoint = data[0].coordinates[1];

  return {
    start: { lat: startPoint[0].toString(), lng: startPoint[1].toString() },
    end: { lat: endPoint[0].toString(), lng: endPoint[1].toString() },
    is_bee_line: true
  };
};

export const extractLengthFromKML = (plainText: any): number => {
  const xml = new XMLParser().parseFromString(plainText);
  const data = xml.getElementsByTagName('SimpleData');
  const length = data.find((d: any) => d.attributes.name === 'Length');

  if (length) return length.value;

  const latlngData = xml.getElementsByTagName('coordinates');
  const points = latlngData[0].value.split(' ');
  const latlngs = points.map((p: string) => {
    const points = p.split(',');
    return [Number(points[0]), Number(points[1])];
  });
  const line = lineString(latlngs);
  const lineLength = pLength(line, { units: 'meters' });

  return lineLength;
};

export const extractAreaFromKML = (plainText: any): number => {
  const xml = new XMLParser().parseFromString(plainText);
  const data = xml.getElementsByTagName('coordinates');
  const points = data[0].value.split(' ');
  const latlngs = points.map((p: string) => {
    const points = p.split(',');
    return [Number(points[0]), Number(points[1])];
  });

  const newLatLngs = latlngs.map((coord: any) => new LatLng(coord[1], coord[0]));
  const area = computeArea(newLatLngs);

  return area;
};

export const extractCoordinatesFromKML = (plainText: any): Array<Array<number>> => {
  const xml = new XMLParser().parseFromString(plainText);
  const data = xml.getElementsByTagName('coordinates');

  const coords = data.map((d: any) => {
    const points = d.value.split(' ');
    const latlngs = points.map((p: string) => {
      const points = p.split(',');
      return [Number(points[1]), Number(points[0])];
    });

    return latlngs;
  });

  return coords;
};

export const extractAreaFromCoords = (coords: ReformatPoints): number => {
  if (coords.type === CoordinateType.MultiPolygon) {
    const newLatLngs = flatten(coords.points)[0].map((coord: any) => {
      return coord.map((p: any) => {
        return new LatLng(p[0], p[1]);
      });
    });

    return computeArea(flatten(newLatLngs));
  }

  const newLatLngs = coords.points[0].map((coord: any) => {
    return coord.map((p: any) => {
      return new LatLng(p[0], p[1]);
    });
  });

  return computeArea(flatten(newLatLngs));
};

export const extractLengthFromCoords = (coords: ReformatPoints): number => {
  if (coords.type === CoordinateType.MultilineString) {
    const newLatLngs = flatten(coords.points)[0].map(
      (coord: any) => new LatLng(coord[0], coord[1])
    );

    return computeLength(newLatLngs);

    // @ts-ignore
    // const line = lineString(flatten(coords.points)[0]);
    // return pLength(line, { units: 'meters' });
  }

  const newLatLngs = coords.points[0].map((coord: any) => new LatLng(coord[0], coord[1]));
  // const line = lineString(coords.points[0]);
  // return pLength(line, { units: 'meters' });
  return computeLength(newLatLngs);
};

export const reformatCoordinates = (coords: any): ReformatPoints => {
  let type = CoordinateType.LineString;
  let length = 0;
  const points: any = coords.map((coord: any) => {
    const coordPoints = coord.coordinates;
    type = coord.type;
    length = coord.length;

    if (coord.type === CoordinateType.MultiPolygon) {
      return coordPoints.map((points: any) => {
        return points.map((point: any) => {
          return point.map((p: any) => {
            return [Number(p[1]), Number(p[0])];
          });
        });
      });
    }

    if (coord.type === CoordinateType.MultilineString || coord.type === CoordinateType.Polygon) {
      return coordPoints.map((points: any) => {
        return points.map((point: any) => {
          return [Number(point[1]), Number(point[0])];
        });
      });
    }

    return coordPoints.map((point: any) => {
      return [Number(point[1]), Number(point[0])];
    });
  });

  return {
    points,
    type,
    length
  };
};

export const getFileFromUrl = async (url: any) => {
  const filename = url.split('/').pop().split('?')[0];
  const response = await fetch(url);
  const data = await response.blob();
  const file = new File([data], filename);
  return file;
};
