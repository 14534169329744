export interface LatLongType {
  lat: string;
  lng: string;
}

export interface LatLongNumberType {
  lat: number;
  lng: number;
}

export interface BothPointLatLngType {
  start: LatLongType | null;
  end: LatLongType | null;
  is_bee_line: boolean;
}

export interface PointInputOptionType {
  label: string;
  value: string;
}

export enum PointTypeValue {
  Start = 'start-coord',
  StartFromFile = 'start-point-from-file-repository',
  End = 'end-coord',
  EndFromFile = 'end-point-from-file-repository',
  BeelineFromFile = 'start-end-point-bee-line-from-file-repository',
  UploadFile = 'upload-file'
}
