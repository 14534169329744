/* eslint-disable */
import { useEffect, useMemo, useRef } from 'react';
import { Polyline, Tooltip, useMap } from 'react-leaflet';
import { filter } from 'lodash';
import L from 'leaflet';
import { RepositoryFile } from 'store/repositoryfile/types';

interface RenderRouteLayerProps {
  routeFileList: Array<RepositoryFile> | [];
  selectedRouteFilesIds: number[];
  handleOnClickRoute: (file: RepositoryFile) => void;
}

const RenderRouteLayer = ({
  selectedRouteFilesIds,
  routeFileList,
  handleOnClickRoute
}: RenderRouteLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const routeLayerGroup = L.featureGroup();

  useEffect(() => {
    // if (currentLayer.current) currentLayer.current.clearLayers();
    // if (routeFileList.length === 0) return;
    // const routeLayers = filter(routeFileList, (file: RepositoryFile) =>
    //   selectedRouteFilesIds.includes(file.id)
    // );
    // routeLayers.forEach((layer) => {
    //   fetch(layer.url)
    //     .then((res) => res.text())
    //     .then((kmltext) => {
    //       const parser = new DOMParser();
    //       const kml = parser.parseFromString(kmltext, 'text/xml');
    //       // @ts-ignore
    //       const track = new L.KML(kml);
    //       routeLayerGroup.addLayer(track);
    //       track.setStyle({ color: layer.color, fillColor: layer.color });
    //       track.bindPopup(`<b>Route ${layer.id}</b><br />${layer.length} km`);
    //     })
    //     .then(() => {
    //       routeLayerGroup.addTo(map);
    //     });
    // });
    // currentLayer.current = routeLayerGroup;
  }, [selectedRouteFilesIds]); // eslint-disable-line

  const allRouteLayers = useMemo(() => {
    if (routeFileList.length === 0) return null;

    const routeLayers = filter(routeFileList, (file: RepositoryFile) =>
      selectedRouteFilesIds.includes(file.id)
    );

    const handlePolylineOver = (e: any) => {
      e.target.bringToFront();
      e.target.setStyle({ weight: 6 });
    };

    const handlePolylineOut = (e: any) => {
      e.target.setStyle({ weight: 3 });
    };

    return routeLayers.map((file, i) => {
      return (
        <Polyline
          key={i}
          pane="shadowPane"
          positions={file.coordinates ? file.coordinates : []}
          pathOptions={{ color: file.color, fillColor: file.color }}
          eventHandlers={{
            click: () => handleOnClickRoute(file),
            mouseover: (e) => handlePolylineOver(e),
            mouseout: (e) => handlePolylineOut(e)
          }}
        >
          <Tooltip sticky direction="top">
            <b>Route {file.id}</b>
            <br />
            {file.length} km
          </Tooltip>
        </Polyline>
      );
    });
  }, [selectedRouteFilesIds, routeFileList]);

  return <>{allRouteLayers}</>;
};

export default RenderRouteLayer;
