import { useEffect, useState } from 'react';
import { Progress, Typography } from 'antd';
import { taglineColors } from 'constants/project.constants';
import { green, orange, primary, red } from 'constants/theme.constants';
import { Project, ProjectStatus } from 'store/projects/types';
import './styles.less';

interface ProgressBarProps {
  percent: number;
  statusText: string;
  project: Project;
  handleOnClick: (project: Project) => void;
}
const { Text } = Typography;

const ProgressBar = ({ percent, statusText, project, handleOnClick }: ProgressBarProps) => {
  const [updateClass, setUpdateClass] = useState('');
  const [updatePBColor, setUpdatePBColor] = useState('');

  useEffect(() => {
    if (percent === 0) {
      setUpdateClass('mainNotStarted');
      setUpdatePBColor(red);
    } else if (percent > 0 && percent < 100) {
      setUpdatePBColor(orange);
      setUpdateClass('mainInProgress');
    } else if (statusText === 'Closed' && percent === 100) {
      setUpdatePBColor(primary);
      setUpdateClass('mainClosed');
    } else if (percent === 100) {
      setUpdatePBColor(green);
      setUpdateClass('mainCompleted');
    }
  }, [percent]);

  const handleRowClick = () => {
    handleOnClick(project);
  };

  return (
    <div className={updateClass} onClick={handleRowClick}>
      <div className="statusText">
        <Text>{statusText}</Text>
        {statusText !== ProjectStatus.Closed && <Text>{percent}%</Text>}
      </div>
      <Progress percent={percent} showInfo={false} strokeColor={updatePBColor} />
      <div style={{ color: taglineColors[project.tagline || 'default'] }}>
        {project.tagline && project.access ? project.tagline : ''}
      </div>
    </div>
  );
};

export default ProgressBar;
