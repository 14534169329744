import { createSlice } from '@reduxjs/toolkit';
import { AnalyticsState } from './types';

export const initialState: AnalyticsState = {
  isRequestingAnalytics: false,
  analyticsData: null,
  isRequestingAddAnalyticsCost: false,
  isRequestingEditAnalyticsCost: false
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setRequestingAnalytics: (state, action) => {
      state.isRequestingAnalytics = action.payload;
    },
    setAnalytics: (state, action) => {
      state.analyticsData = action.payload;
    },
    setRequestingAddAnalyticsCost: (state, action) => {
      state.isRequestingAddAnalyticsCost = action.payload;
    },
    setRequestingEditAnalyticsCost: (state, action) => {
      state.isRequestingEditAnalyticsCost = action.payload;
    }
  }
});
