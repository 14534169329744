import { useEffect, useState } from 'react';
import { Button, Col, Modal, Progress, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { lightGrey, primary } from 'constants/theme.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import AdminProposedTimeline from 'components/ProposedTimeline/AdminProposedTimeline';
import SuperAdminProposedTimeline from 'components/ProposedTimeline/SuperAdminProposedTimeline';
import ManagerProposedTimeline from 'components/ProposedTimeline/ManagerPrposedTimeline';
import EditProjectStagesModal from 'components/Modal/SuperAdmin/ProposedTimeline/EditProjectStagesModal';
import TeamMemberAndRecentActivity from 'components/ProposedTimeline/TeamMemberAndActivity';
import OpenCloseProjectModal from 'components/ProposedTimeline/CloseProjectModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  closeProject,
  getAvailableProjectMembers,
  getProject,
  getProjectActivityLogs,
  openProject
} from 'store/projects/actions';
import { UserRole } from 'store/users/types';
import { ProjectStatus } from 'store/projects/types';
import { getAoiPointsCoords, getAOISelection } from 'store/aoi/actions';
import { getCategories, getRepositoryFiles } from 'store/repositoryfile/actions';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { statusClass, strokeColor } from 'utils/util';
import './styles.less';

const { Text } = Typography;

const ProjectTimeLine = () => {
  const dispatch = useAppDispatch();
  const {
    selectedProject,
    project,
    isRequestProject,
    isRequestingProjectActivityLogs,
    isRequestingCloseProject
  } = useAppSelector((state) => state.projects);
  const { aoiPointsCoords, aoi } = useAppSelector((state) => state.aoi);
  const userRole = getStorageValue(USER_ROLE);
  const [isOpenEditProjectStagesModal, setEditProjectStagesModal] = useState(false);
  const [isOpenCloseProjectModal, setOpenClosedProjectModal] = useState(false);
  const [closeProjectId, setCloseProjectId] = useState<number | null>(null);
  const [openProjectId, setOpenProjectId] = useState<number | null>(null);

  useEffect(() => {
    if (selectedProject) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getProject(selectedProject.id, token));
        dispatch(getProjectActivityLogs(selectedProject.id, token));
        dispatch(getAOISelection(selectedProject.id, token));
        dispatch(getAoiPointsCoords(selectedProject.id, token));
        dispatch(getRepositoryFiles(selectedProject.id, token));
        dispatch(getCategories(token));
        if (userRole !== UserRole.Manager) {
          dispatch(getAvailableProjectMembers(selectedProject.id, token));
        }
      });
    }
  }, []);

  const handleOpenEditProjectStages = () => {
    setEditProjectStagesModal(true);
  };

  const handleCloseEditProjectStageModal = () => {
    setEditProjectStagesModal(false);
  };

  const handleEditTimelineClose = () => {
    setEditProjectStagesModal(false);
  };
  const handleCloseProject = (id: number) => {
    setCloseProjectId(id);
    setOpenClosedProjectModal(true);
  };
  const handleOpenProject = (id: number) => {
    setOpenProjectId(id);
    setOpenClosedProjectModal(true);
  };

  const handleCallBack = (status: boolean) => {
    if (status) {
      setCloseProjectId(null);
      setOpenProjectId(null);
      setOpenClosedProjectModal(false);
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleOpenCloseProject = () => {
    if (closeProjectId) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(closeProject(selectedProject.id, token, handleCallBack));
      });
    } else if (openProjectId) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(openProject(selectedProject.id, token, handleCallBack));
      });
    }
  };

  const handleCancelOpenCloseProject = () => {
    setCloseProjectId(null);
    setOpenProjectId(null);
    setOpenClosedProjectModal(false);
  };

  return (
    <>
      <Row justify="end" className="mainTimelineContent">
        <Col xxl={18} xl={18} lg={24} className="mainTableTLCol">
          <div className="mainProgressBar">
            <div>
              <Text>Project Status</Text>
              <div>
                <Text
                  className={!isEmpty(selectedProject) ? statusClass(selectedProject.status) : ''}
                >
                  {!isEmpty(selectedProject) && selectedProject.status}
                </Text>
                <Text
                  className={!isEmpty(selectedProject) ? statusClass(selectedProject.status) : ''}
                >
                  {!isEmpty(selectedProject) && selectedProject.status_percentage}%
                </Text>
              </div>
            </div>

            <Progress
              percent={!isEmpty(selectedProject) && selectedProject.status_percentage}
              strokeWidth={10}
              strokeColor={!isEmpty(selectedProject) ? strokeColor(selectedProject.status) : ''}
              showInfo={false}
              trailColor={lightGrey}
            />

            {!isEmpty(selectedProject) &&
              ProjectStatus.Completed === selectedProject.status &&
              userRole !== UserRole.Manager && (
                <div className="close-project-btn">
                  <Button
                    type="primary"
                    onClick={() => handleCloseProject(selectedProject.id)}
                    loading={isRequestingCloseProject}
                  >
                    Close the project
                  </Button>
                </div>
              )}
            {!isEmpty(selectedProject) &&
              selectedProject.is_closed &&
              userRole === UserRole.SuperAdmin && (
                <div className="close-project-btn">
                  <Button
                    type="primary"
                    onClick={() => handleOpenProject(selectedProject.id)}
                    loading={isRequestingCloseProject}
                  >
                    Reopen the project
                  </Button>
                </div>
              )}
          </div>
          <div className="proposed-timeline">
            <Text>Proposed Timeline</Text>
            {userRole === UserRole.SuperAdmin &&
            !isEmpty(project) &&
            !isEmpty(project.stages) &&
            (project.status !== ProjectStatus.Completed ||
              project.status !== ProjectStatus.Closed) ? (
              <Text
                underline
                style={{ cursor: 'pointer', color: primary, fontSize: '14px', fontWeight: 600 }}
                onClick={handleOpenEditProjectStages}
              >
                Edit Timeline
              </Text>
            ) : (
              ''
            )}
          </div>

          {userRole === UserRole.SuperAdmin && project && (
            <SuperAdminProposedTimeline isRequestProject={isRequestProject} project={project} />
          )}
          {userRole === UserRole.Admin && project && (
            <AdminProposedTimeline
              isRequestProject={isRequestProject}
              project={project}
              aoiPointsCoords={aoiPointsCoords}
              selectedAoi={aoi}
            />
          )}
          {userRole === UserRole.Manager && project && (
            <ManagerProposedTimeline
              isRequestProject={isRequestProject}
              project={project}
              aoiPointsCoords={aoiPointsCoords}
              selectedAoi={aoi}
            />
          )}
        </Col>
        <Col xxl={6} xl={6} lg={24} className="mainTeamMemberCol">
          <TeamMemberAndRecentActivity
            selectedProject={selectedProject}
            loading={isRequestingProjectActivityLogs}
          />
        </Col>
      </Row>
      <Modal
        open={isOpenEditProjectStagesModal}
        className="add-timeline-modal"
        title="Edit Timeline"
        centered
        width={837}
        footer={null}
        onCancel={handleCloseEditProjectStageModal}
      >
        <EditProjectStagesModal handleEditTimelineClose={handleEditTimelineClose} />
      </Modal>
      <Modal
        open={isOpenCloseProjectModal}
        className="closed-project-modal"
        title=""
        centered
        width={722}
        footer={null}
        closable={false}
      >
        <OpenCloseProjectModal
          isClose={Boolean(selectedProject?.is_closed)}
          handleConfirm={handleOpenCloseProject}
          handleCancel={handleCancelOpenCloseProject}
          isLoading={isRequestingCloseProject}
        />
      </Modal>
    </>
  );
};

export default ProjectTimeLine;
