import { createSelector } from '@reduxjs/toolkit';
import { filter, find, isEmpty } from 'lodash';
import { TeamMember } from 'store/projects/types';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';
import { RootState } from '..';
import { aoiSlice } from './slice';

export const {
  setRequestingStartPoint,
  setRequestingEndPoint,
  setRequestingBeePoint,
  setRequestingAoiPointsCoords,
  setAoiPointsCoords,
  setRequestingDeleteAoiPointsCoords,
  setRequestingUpdateAoiPointsCoords,
  setRequestingAddAOISelection,
  setRequestingGetApprovedAoi,
  setRequestingUpdateAoiSelection,
  setRequestingDeclineAoiSelection,
  setAoi,
  setRequestingExtractAoiFiles,
  setExtractAoiFiles
} = aoiSlice.actions;
export default aoiSlice.reducer;

const selectedFileRepository = (state: RootState) => state.repofiles.repositoryFiles;
const repoFileId = (state: RootState, id: number | undefined) => id;

export const getFileFromId = createSelector(
  [selectedFileRepository, repoFileId],
  (repoFileList, fileId) => {
    if (!fileId) return null;

    return find(repoFileList, (file: RepositoryFile) => file.id === fileId);
  }
);

const selectedProject = (state: RootState) => state.projects.selectedProject;
const selectUserId = (state: RootState, id: string | undefined) => id;

export const getUserFromId = createSelector([selectedProject, selectUserId], (project, userId) => {
  if (!userId) return null;
  const teamMembers = project?.team_members;
  if (teamMembers && teamMembers.length === 0) return null;

  return find(teamMembers, (member: TeamMember) => member.id === userId);
});

export const getSuperAdminAoifiles = (state: RootState) => {
  const { aoiFiles } = state.aoi;

  return filter(aoiFiles, {
    category: FilesCategory.AOI,
    enabled_for_map: true,
    user_role: UserRole.SuperAdmin
  });
};

export const getAdminMangerAoiFiles = (state: RootState) => {
  const { aoiFiles } = state.aoi;
  if (isEmpty(aoiFiles)) return [];

  return filter(
    aoiFiles,
    (file: RepositoryFile) =>
      file.category === FilesCategory.AOI &&
      file.enabled_for_map &&
      file.user_role !== UserRole.SuperAdmin
  );
};

export const getAllAoiFiles = (state: RootState) => {
  const { aoiFiles } = state.aoi;
  if (isEmpty(aoiFiles)) return [];

  return filter(aoiFiles, {
    category: FilesCategory.AOI,
    enabled_for_map: true
  });
};

export const getAoiFileFromId = createSelector(
  [selectedFileRepository, repoFileId],
  (repoFileList, fileId) => {
    if (!fileId) return null;

    const aoiFile = find(
      repoFileList,
      (file: RepositoryFile) => file.id === fileId && file.category === FilesCategory.AOI
    );

    return aoiFile;
  }
);

export const getVectorFiles = (state: RootState) => {
  const { vectorFiles } = state.vectorfile;
  return vectorFiles;
};

export const getFinalizedAoiFile = (state: RootState) => {
  const { aoi, aoiFiles } = state.aoi;

  if (isEmpty(aoi) || !aoi?.is_approved) return null;
  return aoiFiles.find((file: RepositoryFile) => file.id === aoi.aoi_file_id);
};
