import { useEffect } from 'react';
import { ACCESS_TOKEN } from 'constants/common.constant';
import FileRepositoryForm from 'components/FileRepositaryForm/FileRepositaryForm';
import FileReposataryTable from 'components/FileRepositaryTable/FileReposataryTable';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getCategories, getRepositoryFiles } from 'store/repositoryfile/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const FileRepository = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getCategories(token));
      dispatch(getRepositoryFiles(selectedProject?.id, token));
    });
  }, []);

  return (
    <div className="fileRepository">
      <FileRepositoryForm />
      <FileReposataryTable />
    </div>
  );
};

export default FileRepository;
