import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col, Input } from 'antd';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { addUserDetail } from 'store/users/actions';
import { decryptValue, encryptValue } from 'utils/decodeEncodeData';
import { adminSync } from 'utils/yupsync';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const MemberDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isAddUserDetaiRequest } = useAppSelector((state) => state.user);
  const [form] = Form.useForm();

  useEffect(() => {
    if (user?.email && user?.firstname && user?.lastname) {
      form.setFieldsValue({
        userEmail: decryptValue(user?.email),
        first_name: decryptValue(user?.firstname),
        last_name: decryptValue(user?.lastname)
      });
    }
  }, []);

  const handleCallback = () => {
    navigate(routes.TeamOnboarding);
  };

  const onSubmitForm = () => {
    let payload = form.getFieldsValue(['first_name', 'last_name', 'emp_id', 'phone_no']);
    payload.user_id = user?.id;
    payload.department = '';
    payload = {
      ...payload,
      first_name: encryptValue(payload.first_name),
      last_name: encryptValue(payload.last_name),
      phone_no: encryptValue(payload.phone_no)
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addUserDetail(payload, token, handleCallback));
    });
  };

  return (
    <>
      <HeaderLayout />
      <Row justify="center">
        <Col xxl={8} xl={10} lg={12} className="mainContentDiv">
          <p className="titleContent">Enter Your Details</p>

          <Form
            className="mainContentForm"
            form={form}
            onFinish={onSubmitForm}
            name="member_detail_form"
            autoComplete="off"
          >
            <div className="mainFormDiv">
              <h3>
                Enter Details <span>*</span>
              </h3>
              <div className="inlineContent">
                <Form.Item name="first_name" className="inputFirstName" rules={[adminSync]}>
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item name="last_name" className="inputFirstName" rules={[adminSync]}>
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>
              <Form.Item name="emp_id" rules={[adminSync]}>
                <Input placeholder="Employee ID" />
              </Form.Item>
              <Form.Item name="userEmail" rules={[adminSync]}>
                <Input disabled type="email" placeholder="Email" />
              </Form.Item>
              <div className="inlineContent">
                <Form.Item name="phone_no" className="inputPhone" rules={[adminSync]}>
                  <Input placeholder="Phone Number" maxLength={10} />
                </Form.Item>
              </div>
              <Row justify="end">
                <Form.Item className="addMemberBtn">
                  <Button type="primary" htmlType="submit" loading={isAddUserDetaiRequest}>
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default MemberDetail;
