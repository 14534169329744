import { Typography } from 'antd';
import {
  costFeatureNotShowParameter,
  FeatureParameter,
  towerParameter
} from 'constants/analytics.constants';
import { difference, intersection, isEmpty, mapKeys, sum } from 'lodash';
import { useEffect, useState } from 'react';
import { analyticsFormKey, fromateFeatureKey, getCost, getNumber } from 'utils/util';
import AnalyticsRouteItemHeader from './AnalyticsRouteItemHeader';
import CableCostTable from './CableCostTable';
import SoftCostTable from './SoftCostTable';
import TechnicalCostTable from './TechnicalCostTable';

interface AnalyticsRouteItemProps {
  analyticsRoute: any;
  unitCost: any;
  analyticsRouteKey: any;
}

const { Text } = Typography;

const AnalyticsRouteItem = ({
  analyticsRoute,
  unitCost,
  analyticsRouteKey
}: AnalyticsRouteItemProps) => {
  const [totalSoftCost, setTotalSoftCost] = useState<number>(0);
  const [totalTowerCost, setTotalTowerCost] = useState(0);
  const [totalPowerCost, setTotalPowerCost] = useState(0);
  const [totalRouteCost, setTotalRouteCost] = useState(0);
  const [totalRouteCostPrKm, setTotalRouteCostPrKm] = useState(0);
  const allRouteKeys = Object.keys(analyticsRoute);
  const formatedRouteData = mapKeys(analyticsRoute, (_, key: string) => fromateFeatureKey(key));
  const fromatedKeys = allRouteKeys.map((key) => fromateFeatureKey(key));
  const softCostKey: string[] | [] = !isEmpty(formatedRouteData)
    ? difference(fromatedKeys, costFeatureNotShowParameter)
    : [];
  const visibleTowerParameter: string[] = intersection(fromatedKeys, towerParameter);

  useEffect(() => {
    if (!isEmpty(unitCost)) {
      const totalSoftFeatureCost = softCostKey.map((parameter: string) => {
        const unitCostKey: string = analyticsFormKey(parameter);

        return Number(getCost(unitCost[unitCostKey], formatedRouteData[parameter]));
      });
      const totalSoftFeatureCostSum: number =
        totalSoftFeatureCost.length > 0 ? sum(totalSoftFeatureCost) : 0;
      setTotalSoftCost(totalSoftFeatureCostSum);

      const totalTowerCost = visibleTowerParameter.map((parameter: string) => {
        const towerCostKey: string = analyticsFormKey(parameter);

        return Number(getCost(unitCost[towerCostKey], formatedRouteData[parameter]));
      });

      const totalTowerCostSum: number = totalTowerCost.length > 0 ? sum(totalTowerCost) : 0;
      const totalTechnicalTowerCost: any =
        totalTowerCostSum +
        (!isEmpty(formatedRouteData[FeatureParameter.SpecialTowers])
          ? getCost(unitCost.special_towers, formatedRouteData[FeatureParameter.SpecialTowers])
          : 0) +
        (!isEmpty(formatedRouteData[FeatureParameter.SpanTowers])
          ? getCost(unitCost.span_towers, formatedRouteData[FeatureParameter.SpanTowers])
          : 0);

      setTotalTowerCost(totalTechnicalTowerCost);

      const totalTechnicalPowerCost: any = getCost(
        unitCost.powerline_cable,
        formatedRouteData[FeatureParameter.PowerlineCables]
      );
      setTotalPowerCost(totalTechnicalPowerCost);

      setTotalRouteCost(
        totalSoftFeatureCostSum + totalTechnicalTowerCost + totalTechnicalPowerCost
      );

      setTotalRouteCostPrKm(
        (totalSoftFeatureCostSum + totalTechnicalTowerCost + totalTechnicalPowerCost) /
          getNumber(formatedRouteData[FeatureParameter.TotalLength])
      );
    }
  }, [unitCost]);

  return (
    <div>
      <AnalyticsRouteItemHeader
        analyticsRouteKey={analyticsRouteKey}
        analyticsRoute={formatedRouteData}
        totalCost={totalRouteCost.toFixed(2)}
        costPRkm={totalRouteCostPrKm}
      />
      <div className="scroll-content">
        <div className="route1-soft-cost-row">
          <Text>SOFT COST - ROW</Text>
          <Text>
            TOTAL SOFT COST : <Text>{totalSoftCost.toFixed(2)} INR</Text>
          </Text>
        </div>
        <div className="route1-soft-cost">
          <SoftCostTable
            featureCostRoute={formatedRouteData}
            unitCost={unitCost}
            softCostKey={softCostKey}
          />
        </div>
        <div className="total-feature-cost">
          <Text>
            TOTAL FEATURE COST - <Text>{totalSoftCost.toFixed(2)} INR</Text>
          </Text>
        </div>
        <div className="route1-technical-cost-row">
          <Text>TECHNICAL COST</Text>
          <Text>
            TOTAL TECHNICAL COST : <Text>{(totalTowerCost + totalPowerCost).toFixed(2)} INR</Text>
          </Text>
        </div>
        <div className="route1-technical-cost">
          <TechnicalCostTable
            technicalCostRoute={formatedRouteData}
            unitCost={unitCost}
            towerKeys={visibleTowerParameter}
          />
        </div>
        <div className="total-tower-cost">
          <Text>
            TOTAL TOWER COST - <Text>{totalTowerCost.toFixed(2)} INR</Text>
          </Text>
        </div>
        {fromatedKeys.includes(FeatureParameter.PowerlineCables) && (
          <>
            <div className="route1-cable-cost">
              <CableCostTable cableCostRoute={formatedRouteData} unitCost={unitCost} />
            </div>
            <div className="total-cable-cost">
              <Text>
                TOTAL CABLE COST - <Text>{totalPowerCost.toFixed(2)} INR</Text>
              </Text>
            </div>
          </>
        )}
        <div className="total-route1-cost">
          <Text>
            TOTAL ROUTE COST - <Text>{totalRouteCost.toFixed(2)} INR</Text>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsRouteItem;
