import { Typography } from 'antd';
import { upperFirst } from 'lodash';
import { BsDot } from 'react-icons/bs';
import { primary } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { getFileFromId, getUserFromId } from 'store/aoi';
import { AoiPointsStartEnd } from 'store/aoi/types';
import { decryptValue } from 'utils/decodeEncodeData';

const { Text } = Typography;

interface PointsRequestDetailProps {
  point: AoiPointsStartEnd;
}

const PointsRequestDetail = ({ point }: PointsRequestDetailProps) => {
  const selectedFile = useAppSelector((state) => getFileFromId(state, point.file_id));
  const selectedUser = useAppSelector((state) => getUserFromId(state, point.uploaded_by));

  return (
    <>
      {selectedFile && <Text className="aoicoord-filename">{selectedFile.file_name}</Text>}
      {selectedUser && (
        <div className="approve-dwnld">
          <BsDot size={20} color={primary} />
          <Text className="approved" italic>
            Uploaded by {upperFirst(decryptValue(selectedUser.first_name))}{' '}
            {upperFirst(decryptValue(selectedUser.last_name))} for approval
          </Text>
        </div>
      )}
    </>
  );
};

export default PointsRequestDetail;
