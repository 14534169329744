import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { PageHeader, Select, Spin, Typography } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { MapContainer, Marker, Popup } from 'react-leaflet';
import DownArrowIcon from 'assets/svg/DownArrowIcon';
import { primary } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN, defaultCenter } from 'constants/common.constant';
import { PointYellowIcon } from 'constants/map.constants';
import MapLayers from 'components/Map/MapLayers';
import MapActionControl from 'components/Map/MapActionControl';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { getOrganizationMapViewByOrg } from 'store/organizations/actions';
import { MapViewResponse } from 'store/organizations/types';
import { getMapviewDataByProject } from 'store/organizations';
import { getAsyncStorageValue } from 'utils/localStorage';
import { getDistance } from 'utils/geoCode';
import './styles.less';

const { Text } = Typography;
const { Option } = Select;

const AdminMapView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isRequestingOrganizationsMapView, selectedOrganization, organizationsMapViewData } =
    useAppSelector((state) => state.organizations);
  const [selectedProjectId, setSelectedProjectId] = useState(0);
  const mapViewData = useAppSelector((state) => getMapviewDataByProject(state, selectedProjectId));

  useEffect(() => {
    if (!isEmpty(selectedOrganization)) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getOrganizationMapViewByOrg(selectedOrganization?.id, token));
      });
    }
  }, [selectedOrganization]);

  const handleGoBack = () => {
    navigate(routes.ProjectList, { replace: true });
  };

  const handleOnChange = (value: number) => {
    setSelectedProjectId(value);
  };

  const mapContainer = useMemo(() => {
    return (
      <MapContainer
        center={{ lat: Number(defaultCenter.lat), lng: Number(defaultCenter.lng) }}
        zoom={5}
        zoomControl={false}
        style={{ width: '100%', height: '100%' }}
        className="map-container"
      >
        {mapViewData &&
          mapViewData.length > 0 &&
          mapViewData.map((data: MapViewResponse) => {
            return (
              <Marker
                icon={PointYellowIcon}
                key={data.proj_id}
                position={[Number(data.s_lat), Number(data.s_lng)]}
              >
                {data.proj_name && (
                  <Popup className="project-popup">
                    <div className="pop-up-info">
                      <Text>
                        <Text>Name of Organisation</Text>
                        <Text> - {data.org_name}</Text>
                      </Text>
                      <Text>
                        <Text>Name of Project</Text>
                        <Text> - {data.proj_name}</Text>
                      </Text>
                      <Text>
                        <Text>Start Point</Text>
                        <Text> - {`${data.s_lat}, ${data.s_lng}`}</Text>
                      </Text>
                      <Text>
                        <Text>Bee Line length </Text>
                        <Text>
                          - {getDistance(data.s_lat, data.s_lng, data.e_lat, data.e_lng)}km
                        </Text>
                      </Text>
                    </div>
                  </Popup>
                )}
              </Marker>
            );
          })}
        <MapLayers />
        <MapActionControl isShowRecenter={false} />
      </MapContainer>
    );
  }, [mapViewData]);

  return (
    <>
      <HeaderLayout />
      <div className="mainOrganizationContent">
        <PageHeader
          className="main-title-content main-title-map-view"
          onBack={handleGoBack}
          backIcon={<BsArrowLeft size={20} className="backIcon" />}
          ghost={false}
          title={<Text className="page-title">Map View</Text>}
          extra={[
            <Select
              placeholder="All"
              className="project-name-list"
              key="1"
              showArrow
              suffixIcon={<DownArrowIcon color={primary} />}
              disabled={isRequestingOrganizationsMapView}
              onChange={handleOnChange}
            >
              <Option value={0}>All</Option>
              {organizationsMapViewData &&
                organizationsMapViewData.map((data: MapViewResponse) => (
                  <Option value={data.proj_id} key={data.proj_id}>
                    {data.proj_name}
                  </Option>
                ))}
            </Select>
          ]}
        />
      </div>
      <Spin spinning={isRequestingOrganizationsMapView}>
        <div className="main-map-view">{mapContainer}</div>
      </Spin>
    </>
  );
};

export default AdminMapView;
