/* eslint-disable */
import { useEffect, useMemo, useRef } from 'react';
import { Polygon, useMap } from 'react-leaflet';
import L from 'leaflet';
import { filter } from 'lodash';
import { RepositoryFile } from 'store/repositoryfile/types';

interface RenderAoiLayerProps {
  selectedAOI: RepositoryFile | null;
  selectedAoiIds: Array<number>;
  allAoiFiles: Array<RepositoryFile> | [];
  isShowFinalizedAoi: boolean;
}

const RenderAoiLayer = ({
  selectedAOI,
  selectedAoiIds,
  allAoiFiles,
  isShowFinalizedAoi
}: RenderAoiLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const aoiLayerGroup = L.featureGroup();

  useEffect(() => {
    // if (currentLayer.current) currentLayer.current.clearLayers();
    // if (allAoiFiles.length === 0) return;
    // const aoiLayers = filter(allAoiFiles, (file: RepositoryFile) =>
    //   selectedAoiIds.includes(file.id)
    // );
    // aoiLayers.forEach((layer) => {
    //   fetch(layer.url)
    //     .then((res) => res.text())
    //     .then((kmltext) => {
    //       const parser = new DOMParser();
    //       const kml = parser.parseFromString(kmltext, 'text/xml');
    //       // @ts-ignore
    //       const track = new L.KML(kml);
    //       aoiLayerGroup.addLayer(track);
    //       if (selectedAOI && selectedAOI.id === layer.id) {
    //         track.setStyle({
    //           color: selectedAOI.color,
    //           fillColor: selectedAOI.color,
    //           fillOpacity: 0.3,
    //           weight: 1
    //         });
    //         const bounds = track.getBounds();
    //         map.fitBounds(bounds);
    //       } else {
    //         track.setStyle({
    //           color: layer.color,
    //           fillColor: layer.color,
    //           fillOpacity: 0.3,
    //           weight: 1,
    //           stroke: selectedAoiIds.includes(layer.id)
    //         });
    //       }
    //     })
    //     .then(() => {
    //       aoiLayerGroup.addTo(map);
    //     });
    // });
    // currentLayer.current = aoiLayerGroup;
  }, [selectedAOI, selectedAoiIds]); // eslint-disable-line

  const allAOILayer = useMemo(() => {
    if (allAoiFiles.length === 0 || !isShowFinalizedAoi) return null;

    const aoiLayers = filter(allAoiFiles, (file: RepositoryFile) =>
      selectedAoiIds.includes(file.id)
    );

    return aoiLayers.map((file, i) => {
      let pathOptions: any = {
        color: file.color,
        fillColor: file.color,
        fillOpacity: 0.3,
        weight: 1,
        stroke: selectedAoiIds.includes(file.id)
      };

      if (selectedAOI && selectedAOI.id === file.id) {
        pathOptions = {
          color: selectedAOI.color,
          fillColor: selectedAOI.color,
          fillOpacity: 0.3,
          weight: 1
        };
      }

      return (
        <Polygon
          key={i}
          positions={file.coordinates ? file.coordinates : []}
          pathOptions={pathOptions}
          pane="tilePane"
        />
      );
    });
  }, [selectedAOI, selectedAoiIds, isShowFinalizedAoi]);

  const selectedAOILayer = useMemo(() => {
    if (!selectedAOI || selectedAoiIds.includes(selectedAOI.id)) return null;

    const pathOptions: any = {
      color: selectedAOI.color,
      weight: 2,
      fillOpacity: 0,
      dashArray: '5, 10'
    };

    return (
      <Polygon
        positions={selectedAOI.coordinates ? selectedAOI.coordinates : []}
        pathOptions={pathOptions}
        pane="tilePane"
      />
    );
  }, [selectedAOI, selectedAoiIds]);

  return (
    <>
      {allAOILayer}
      {selectedAOILayer}
    </>
  );
};

export default RenderAoiLayer;
