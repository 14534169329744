import { Modal, Typography } from 'antd';

interface RequestApprovalModalProps {
  isOpen: boolean;
}
const { Text } = Typography;

const RequestApprovalModal = ({ isOpen }: RequestApprovalModalProps) => {
  return (
    <Modal
      className="satsure-approve-modal"
      width={510}
      closable={false}
      open={isOpen}
      footer={null}
      centered
    >
      <div className="satsure-approve-content">
        <Text className="notify-title">Request sent to SatSure !</Text>
      </div>
    </Modal>
  );
};

export default RequestApprovalModal;
