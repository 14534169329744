import { useEffect, useState } from 'react';
import { capitalize, isEmpty, startCase, upperFirst } from 'lodash';
import { Col, Dropdown, Menu, Row, Tooltip, Button, Avatar, Typography } from 'antd';
import { FaRegUser, FaRegBell } from 'react-icons/fa';
import { RiQuestionMark } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { BiLogOutCircle } from 'react-icons/bi';
import { TbBuildingFactory } from 'react-icons/tb';
import SatSureSkies from 'assets/svg/SatsureLogoWhite.svg';
import SettingIcon from 'assets/svg/SettingIcon';
import { white } from 'constants/theme.constants';
import { userRoleText } from 'constants/organization.constants';
import { routes } from 'constants/pageRoutes.constants';
import { USER_ROLE } from 'constants/common.constant';
import TooltipTitle from 'components/core/TooltipTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { userLogout } from 'store/users/actions';
import { UserRole } from 'store/users/types';
import { shortLabel } from 'utils/util';
import { getStorageValue } from 'utils/localStorage';
import { decryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;

const MainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { user } = useAppSelector((state) => state.user);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [compressName, setCompressName] = useState<string | null>(null);
  const [role, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(user)) {
      setUserRole(userRoleText[userRole]);
      if (user.firstname && user.lastname) {
        setFirstName(upperFirst(decryptValue(user.firstname)));
        setLastName(upperFirst(decryptValue(user.lastname)));
        setCompressName(
          shortLabel(`${decryptValue(user.firstname)} ${decryptValue(user.lastname)}`)
        );
      } else {
        setFirstName(upperFirst(decryptValue(user.email)));
        setCompressName(capitalize(user.email.charAt(0)));
      }
    }
  }, []);

  const handleNavigate = () => {
    if (userRole === UserRole.SuperAdmin) {
      navigate(routes.Organization, { replace: true });
    } else if (userRole === UserRole.Admin) {
      if (!user?.firstname || !user?.lastname || !user?.phone) {
        navigate(routes.MemberDetail);
      } else {
        navigate(routes.ProjectList, { replace: true });
      }
    } else if (userRole === UserRole.Manager) {
      if (!user?.firstname || !user?.lastname || !user?.phone) {
        navigate(routes.ManagerMemberDetail);
      } else {
        navigate(routes.ProjectList, { replace: true });
      }
    }
  };

  const handleLogoutCallback = () => {
    navigate(routes.LoginUrl, { replace: true });
    window.location.reload();
  };

  const handleClickMenu = ({ key }: any) => {
    if (key === 'logout') {
      dispatch(userLogout(handleLogoutCallback));
    }
  };

  return (
    <Row className="mainHeaderRow">
      <Col xxl={6} className="mainLogoCol">
        <a onClick={handleNavigate}>
          <img src={SatSureSkies} alt="SatsureSkies" height={60} width={60} />
        </a>
      </Col>
      <Col xxl={12} className="mainTitleCol">
        {selectedOrganization &&
          (selectedOrganization?.logo_url ? (
            <img
              src={selectedOrganization?.logo_url}
              height={50}
              loading="lazy"
              alt={selectedOrganization?.name || 'org-logo'}
            />
          ) : (
            <TbBuildingFactory size={50} />
          ))}
        <span>
          {selectedOrganization
            ? startCase(selectedOrganization?.name)
            : 'Skies - High Transmission Line'}
        </span>
      </Col>
      <Col xxl={6} className="mainIconCol">
        <Tooltip
          placement="bottomLeft"
          title={<TooltipTitle title="Notifications" />}
          color={white}
          key="Notifications"
        >
          <Button type="text" icon={<FaRegBell className="tooltipIcon" />} />
        </Tooltip>
        <Tooltip title={<TooltipTitle title="Help" />} color={white} placement="bottom" key="Help">
          <Button type="text" icon={<RiQuestionMark className="tooltipIcon" />} />
        </Tooltip>
        <Tooltip
          title={<TooltipTitle title="Account" />}
          color={white}
          placement="bottom"
          key="Account"
        >
          <Dropdown
            className="user-dropDown"
            overlay={
              <Menu onClick={handleClickMenu}>
                <Menu.Item key="profile" className="user-menu-item" style={{ cursor: 'default' }}>
                  <div className="menu-title">
                    <Avatar className="name-avatar">{compressName}</Avatar>
                    <div>
                      <Text>{`${firstName} ${lastName}`}</Text>
                      <Text>{role}</Text>
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item key="setting">
                  <div className="menuTitle">
                    <SettingIcon />
                    <Text>Settings</Text>
                  </div>
                </Menu.Item>
                <Menu.Item key="logout">
                  <div className="menuTitle">
                    <BiLogOutCircle />
                    <Text>Log out</Text>
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <Button type="text" icon={<FaRegUser className="tooltipIcon" />} />
          </Dropdown>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default MainHeader;
