import { Fragment, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Tooltip, Typography } from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { take, reverse, xor, isEmpty } from 'lodash';
import { lightGrey, white } from 'constants/theme.constants';
import { USER_ROLE } from 'constants/common.constant';
import MemberListModal from 'components/Modal/MemberList/MemberListModal';
import { useAppSelector } from 'hooks/useReduxHook';
import { UserRole } from 'store/users/types';
import type { Project, TeamMember } from 'store/projects/types';
import { getMember, getTeamMembers } from 'store/projects';
import { MemberT } from 'store/organizations/types';
import { shortLabel } from 'utils/util';
import { getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

interface AvatarListProps {
  record: Project;
  handleAddProjectMemberForm: Function;
  className?: string;
}

const AvatarList = ({ record, handleAddProjectMemberForm, className }: AvatarListProps) => {
  const { user } = useAppSelector((state) => state.user);
  const userRole = getStorageValue(USER_ROLE);
  const currentUser = user.id;
  const [isOpenProjectMemberListModal, setOpenProjectMemberListModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const teamMembers = record.team_members;
  const foundAdminMember: TeamMember | null | undefined = teamMembers.find(
    (item) => item.role === UserRole.Admin
  );
  const managers = getTeamMembers(teamMembers);
  let memberNum = 2;
  let adminMember: MemberT | null | undefined = null;
  let hasMoreMembers: Array<MemberT> | [] = [];

  if (userRole !== UserRole.Admin) {
    if (!isEmpty(managers) && managers.length > 4) {
      memberNum = 3;
    } else {
      memberNum = 4;
    }
  }

  const managerMembers = take(managers, memberNum);
  if (foundAdminMember) {
    adminMember = getMember(foundAdminMember);
  }

  if (managers && managers.length > 0) {
    hasMoreMembers = xor(managers, managerMembers);
  }

  const handleMoreMembers = (project: Project) => {
    setOpenProjectMemberListModal(true);
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setOpenProjectMemberListModal(false);
    setSelectedProject(null);
  };

  return (
    <div className={`avatars ${className}`}>
      {userRole === UserRole.Admin && (
        <Tooltip
          title="Add members to project"
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip"
        >
          <Text className="avatar" onClick={() => handleAddProjectMemberForm(record)}>
            <div className="last-div">
              <Text>
                <BiPlus />
              </Text>
            </div>
          </Text>
        </Tooltip>
      )}
      {!isEmpty(hasMoreMembers) && (
        <Tooltip
          title={`Show more member in this ${record.name}`}
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip"
        >
          <Text className="avatar" onClick={() => handleMoreMembers(record)}>
            <div className="last-div">
              <Text>
                <BsThreeDots color={lightGrey} size={19} />
              </Text>
            </div>
          </Text>
        </Tooltip>
      )}
      {managerMembers?.length > 0 &&
        reverse(managerMembers).map((item: MemberT, i: number) => {
          return (
            <Fragment key={i}>
              <Tooltip
                title={
                  <div className="avtars-name">
                    <Text>{item.label}</Text>
                    <Text>{item.role}</Text>
                  </div>
                }
                color={white}
                placement="bottom"
                overlayClassName="avtar-tooltip"
              >
                <Text className="avatar">
                  <div className={item.value === currentUser ? 'first-div' : ''}>
                    <Text>{shortLabel(item.label)}</Text>
                  </div>
                </Text>
              </Tooltip>
            </Fragment>
          );
        })}
      {adminMember && (
        <Tooltip
          title={
            <div className="avtars-name">
              <Text>{adminMember ? adminMember.label : ''}</Text>
              <Text>{adminMember ? adminMember.role : ''}</Text>
            </div>
          }
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip"
        >
          <Text className="avatar">
            <div
              className={
                adminMember.value === currentUser || userRole === UserRole.SuperAdmin
                  ? 'first-div'
                  : ''
              }
            >
              <Text>{adminMember ? shortLabel(adminMember.label) : 'A'}</Text>
            </div>
          </Text>
        </Tooltip>
      )}
      <MemberListModal
        isOpen={isOpenProjectMemberListModal}
        selectedProject={selectedProject}
        adminMember={adminMember}
        managers={managers}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

AvatarList.defaultProps = {
  className: ''
};

export default AvatarList;
