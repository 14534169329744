import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { Button, Layout, PageHeader, Tabs, Typography, Modal, Switch } from 'antd';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { routes } from 'constants/pageRoutes.constants';
import { black } from 'constants/theme.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import AddProjectMember from 'components/Modal/AddProjectMember/AddProjectMemberModal';
import ProjectMemberModal from 'components/Modal/AddMember/AddOrganizationMemberModal';
import RemoveProjectMember from 'components/Modal/RemoveProjectMember/RemoveProjectMemberModal';
import RemoveMemberOrganization from 'components/Modal/RemoveMemberOrganization/RemoveMemberOrganization';
import ProjectManagement from 'components/UserManagement/ProjectManagement';
import SuperAdminProjectManagement from 'components/UserManagement/SuperAdminProjectManagement';
import TeamManagement from 'components/UserManagement/TeamManagement';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import type { Project, TeamMember } from 'store/projects/types';
import { UserRole } from 'store/users/types';
import {
  getAvailableProjectMembers,
  getProjects,
  removeAddMemberFromOrganization,
  removeMemberFromProject
} from 'store/projects/actions';
import { enableDisableUser, getOrganizationMembers } from 'store/organizations/actions';
import { EnableDisableUser, OrganizationMember } from 'store/organizations/types';
import { getOrganizationTeamMembers } from 'store/organizations';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Content } = Layout;
const { Text } = Typography;

const UserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { selectedOrganization, isNewOrganizationMember, isRequestingEnableDisableUser } =
    useAppSelector((state) => state.organizations);
  const orginsationMembers = useAppSelector(getOrganizationTeamMembers);
  const {
    projects,
    isLoadingProjects,
    isRequestingRemoveMemberFromOrganization,
    isRequestingRemoveMemberFromProject
  } = useAppSelector((state) => state.projects);
  const [isOpenAddProjectMember, setAddProjectMemberModal] = useState<boolean>(false);
  const [isOpenAddMembersModal, setNewProjectMemberModal] = useState<boolean>(false);
  const [removeMemberModal, setRemoveMemberModal] = useState<boolean>(false);
  const [isOpenRemoveOrgMember, setOpenRemoveOrgMember] = useState<boolean>(false);
  const [isShowAddMemberButton, setShowAddMemberButton] = useState<boolean>(true);
  const [isEnableDisableAllUser, setEnableDisableAllUser] = useState<boolean>(true);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectMembers, setProjectMembers] = useState<TeamMember[]>([]);
  const [selectedMember, setSelectedMember] = useState<OrganizationMember | null>(null);
  const [selectedProjectMember, setRemoveSelectedProjectMember] = useState<string[]>([]);
  const [projectId, setProjectId] = useState<number | null>(null);

  useEffect(() => {
    if (selectedOrganization) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getProjects(selectedOrganization?.id, token));
        dispatch(getOrganizationMembers(selectedOrganization?.id, token));
      });
      setEnableDisableAllUser(selectedOrganization.is_active);
    }
  }, [selectedOrganization]);

  const handleShowMemberButton = (key: string) => {
    if (key === 'project_management') {
      setShowAddMemberButton(false);
    } else {
      setShowAddMemberButton(true);
    }
  };

  const handleRemoveMemberOrganizationCallback = (res: boolean) => {
    setSelectedMember(null);
    setOpenRemoveOrgMember(!isOpenRemoveOrgMember);
    if (!res) toast.error('Something Went Wrong');
  };

  const handleAddProjectMemberForm = (row: Project) => {
    if (userRole !== UserRole.Manager) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(row.id.toString(), token));
      });
    }
    setSelectedProject(row);
    setAddProjectMemberModal(!isOpenAddProjectMember);
  };

  const handleRemoveProjectMember = (project: Project) => {
    setProjectId(project.id);
    setRemoveMemberModal(!isOpenAddProjectMember);
    setProjectMembers(project.team_members);
  };

  const handleRemoveTeamMemberOrg = (record: OrganizationMember) => {
    setOpenRemoveOrgMember(!isOpenRemoveOrgMember);
    setSelectedMember(record);
  };

  const handleAddMemberOrganizationCallback = (response: boolean) => {
    if (!response) toast.error('somthing went wrong');
  };

  const handleAddTeamMemberOrg = (record: OrganizationMember) => {
    const payload = {
      is_active: true
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        removeAddMemberFromOrganization(
          payload,
          record.id,
          selectedOrganization?.id,
          token,
          handleAddMemberOrganizationCallback
        )
      );
    });
  };

  const handleChangeAdmin = () => {};

  const backToProjects = () => {
    navigate(routes.ProjectList, { replace: true });
  };

  const handleRemoveMemberProjectCallback = () => {
    setProjectId(null);
    setRemoveSelectedProjectMember([]);
    setRemoveMemberModal(!removeMemberModal);
  };

  const handleRemoveProjectMemberSubmit = (members: string[]) => {
    setRemoveSelectedProjectMember(members);
  };

  const handleRemoveProjectMemberOk = () => {
    if (!isEmpty(selectedProjectMember) && projectId) {
      const payload = {
        member_list: selectedProjectMember
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          removeMemberFromProject(
            payload,
            projectId,
            selectedOrganization?.id,
            token,
            handleRemoveMemberProjectCallback
          )
        );
      });
    } else {
      toast.error('Something Went Wrong');
      handleRemoveMemberProjectCallback();
    }
  };

  const handleRemoveProjectMemberCancel = () => {
    handleRemoveMemberProjectCallback();
  };

  const handleRemoveMemberOrganizationOk = () => {
    if (!isEmpty(selectedMember)) {
      const payload = {
        is_active: false
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          removeAddMemberFromOrganization(
            payload,
            selectedMember.id,
            selectedOrganization?.id,
            token,
            handleRemoveMemberOrganizationCallback
          )
        );
      });
    } else {
      handleRemoveMemberOrganizationCallback(false);
    }
  };

  const handleRemoveMemberOrganizationCancel = () => {
    setSelectedMember(null);
    setOpenRemoveOrgMember(!isOpenRemoveOrgMember);
  };

  const handleCloseAddMemberModal = () => {
    setAddProjectMemberModal(false);
  };

  const handleOpenAddMemberModal = () => {
    setNewProjectMemberModal(true);
  };

  const handleCloseAddProjectMemberModal = () => {
    setAddProjectMemberModal(false);
    setSelectedProject(null);
  };

  const handleDisableEnableUserChange = (isChecked: boolean) => {
    setEnableDisableAllUser(isChecked);
    const payload: EnableDisableUser = {
      is_active: isChecked
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(enableDisableUser(payload, selectedOrganization?.id, isChecked, token));
    });
  };

  return (
    <>
      <HeaderLayout />
      <Layout className="userMangementLayout">
        <PageHeader
          ghost={false}
          className="mainTitleContent"
          onBack={backToProjects}
          title={<Text>User Mangement</Text>}
          backIcon={<BsArrowLeft className="backIcon" />}
          extra={
            isShowAddMemberButton
              ? [
                  <Button
                    key="add member btn"
                    className="addMemberBtn"
                    type="primary"
                    onClick={handleOpenAddMemberModal}
                  >
                    Add Member
                  </Button>
                ]
              : []
          }
        />
        <Content className="site-layout">
          <div className="user-mangement-content">
            {userRole === UserRole.SuperAdmin && (
              <div className="user-disable-btn">
                {isEnableDisableAllUser ? 'Enable' : 'Disable'} all users
                <Switch checked={isEnableDisableAllUser} onChange={handleDisableEnableUserChange} />
              </div>
            )}
            <Tabs defaultActiveKey="team_management" onTabClick={handleShowMemberButton}>
              <Tabs.TabPane tab="TEAM MANAGEMENT" key="team_management">
                <TeamManagement
                  // @ts-ignore
                  orginsationMembers={orginsationMembers}
                  addLoading={
                    isRequestingRemoveMemberFromOrganization ||
                    isNewOrganizationMember ||
                    isRequestingEnableDisableUser
                  }
                  handleRemoveTeamMemberCallback={handleRemoveTeamMemberOrg}
                  handleAddTeamMemberCallback={handleAddTeamMemberOrg}
                  handleChangeTeamMemberCallback={handleChangeAdmin}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="PROJECT MANAGEMENT" key="project_management">
                {userRole === UserRole.Admin && (
                  <ProjectManagement
                    isLoading={isLoadingProjects || isRequestingEnableDisableUser}
                    projects={projects}
                    handleAddProjectMemberCallback={handleAddProjectMemberForm}
                    handleRemoveProjectMemberCallback={handleRemoveProjectMember}
                  />
                )}
                {userRole === UserRole.SuperAdmin && (
                  <SuperAdminProjectManagement
                    isLoading={isLoadingProjects}
                    projects={projects}
                    handleAddProjectMemberCallback={handleAddProjectMemberForm}
                  />
                )}
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Content>
      </Layout>
      {selectedProject && (
        <Modal
          open={isOpenAddProjectMember}
          okText="Create"
          cancelText="Cancel"
          centered
          width={800}
          closable
          onCancel={handleCloseAddMemberModal}
          footer={null}
          className="newModal"
        >
          <AddProjectMember
            handleCloseAddProjectMemberModal={handleCloseAddProjectMemberModal}
            selectedRow={selectedProject}
            selectedOrganization={selectedOrganization}
          />
        </Modal>
      )}
      <Modal
        open={isOpenAddMembersModal}
        okText="Create"
        cancelText="Cancel"
        centered
        width={800}
        closable
        onCancel={() => setNewProjectMemberModal(false)}
        footer={null}
        className="newModal"
      >
        <ProjectMemberModal setNewProjectMemberModal={setNewProjectMemberModal} />
      </Modal>
      <Modal
        open={removeMemberModal}
        title={<Text>Remove team member from project</Text>}
        okText="Remove"
        cancelText="Cancel"
        centered
        width={650}
        closable
        closeIcon={
          <AiOutlineClose size={16} color={black} onClick={handleRemoveProjectMemberCancel} />
        }
        confirmLoading={isRequestingRemoveMemberFromProject}
        onOk={handleRemoveProjectMemberOk}
        onCancel={handleRemoveProjectMemberCancel}
        className="remove-member-modal"
      >
        <RemoveProjectMember
          projectMembers={projectMembers}
          handleRemoveMembersCallback={handleRemoveProjectMemberSubmit}
        />
      </Modal>
      <Modal
        open={isOpenRemoveOrgMember}
        title={<Text>Remove team member from organization</Text>}
        okText="Remove"
        cancelText="Cancel"
        centered
        width={600}
        closable
        closeIcon={
          <AiOutlineClose size={16} color={black} onClick={handleRemoveMemberOrganizationCancel} />
        }
        confirmLoading={isRequestingRemoveMemberFromOrganization}
        onOk={handleRemoveMemberOrganizationOk}
        onCancel={handleRemoveMemberOrganizationCancel}
        className="remove-member-modal remove-member-org-modal"
      >
        {selectedMember ? <RemoveMemberOrganization selectedMember={selectedMember} /> : ''}
      </Modal>
    </>
  );
};

export default UserManagement;
