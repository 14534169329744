import { Typography } from 'antd';
import { FeatureParameter } from 'constants/analytics.constants';

interface AnalyticsRouteItemHeaderProps {
  analyticsRouteKey: any;
  analyticsRoute: any;
  totalCost: any;
  costPRkm: any;
}

const { Text } = Typography;

const AnalyticsRouteItemHeader = ({
  analyticsRoute,
  analyticsRouteKey,
  totalCost,
  costPRkm
}: AnalyticsRouteItemHeaderProps) => {
  return (
    <div className="route1-tab-title">
      <Text className="route1-tab-title-inr">{analyticsRouteKey}</Text>
      <Text className="route1-tab-cost">
        TOTAL ROUTE COST : <Text>{totalCost} INR</Text>
      </Text>
      <Text className="route1-tab-length">
        TOTAL LENGTH :{' '}
        <Text>
          {analyticsRoute[FeatureParameter.TotalLength]
            ? analyticsRoute[FeatureParameter.TotalLength]
            : '0 km'}
        </Text>
      </Text>
      <Text className="route1-tab-cost-km">
        COST PER km : <Text>{costPRkm.toFixed(2)} INR</Text>
      </Text>
    </div>
  );
};

export default AnalyticsRouteItemHeader;
