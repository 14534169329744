import { useEffect } from 'react';
import { Form, Input, InputNumber, Modal, Select, Typography } from 'antd';
import { Category, FileEditPayload, RepositoryFile, FilePayload } from 'store/repositoryfile/types';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { editRepositoryFile } from 'store/repositoryfile/actions';
import { editFileRepoSync } from 'utils/yupsync';
import { find, orderBy } from 'lodash';
import { getAsyncStorageValue } from 'utils/localStorage';

interface EditFileModalProps {
  projectId: string;
  isOpenModal: boolean;
  handleEditFileCloseModal: Function;
  selectedRow: RepositoryFile;
  repoCategory: Array<Category>;
  isRequestingEditRepoFile: boolean;
}
const { Text } = Typography;
const { Option } = Select;

const EditFileModal = ({
  projectId,
  isOpenModal,
  handleEditFileCloseModal,
  selectedRow,
  repoCategory,
  isRequestingEditRepoFile
}: EditFileModalProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      filename: selectedRow.file_name,
      buffer: selectedRow.buffer,
      comment: selectedRow.comment,
      category_id: find(repoCategory, (cat: Category) => cat.category_name === selectedRow.category)
        ?.id
    });
  }, []);

  const handleSubmitRepofile = () => {
    form.submit();
  };

  const handleSuccess = () => {
    form.resetFields();
    handleEditFileCloseModal();
  };

  const handleEditFileSubmit = (values: FileEditPayload) => {
    const payload: FilePayload = {
      ...values,
      filename: values.filename,
      category_id: values.category_id,
      buffer: values.buffer ? values.buffer.toString() : '0',
      user_id: user.id!
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(editRepositoryFile(payload, projectId, selectedRow?.id, token, handleSuccess));
    });
  };

  return (
    <Modal
      open={isOpenModal}
      title={<Text>Edit File</Text>}
      okText="Save"
      cancelText="Cancel"
      confirmLoading={isRequestingEditRepoFile}
      onCancel={() => {
        handleEditFileCloseModal();
      }}
      onOk={handleSubmitRepofile}
      centered
      width={650}
      closable
      className="edit-modal"
    >
      <div className="edit-content">
        <Form
          form={form}
          layout="vertical"
          name="edit_repofile_form"
          className="edit-form"
          autoComplete="off"
          onFinish={handleEditFileSubmit}
        >
          <Form.Item
            name="filename"
            label="NAME OF FILE"
            className="formField"
            rules={[editFileRepoSync]}
          >
            <Input className="formInputField" />
          </Form.Item>
          <Form.Item
            name="category_id"
            label="CATEGORY"
            className="formField"
            rules={[editFileRepoSync]}
          >
            <Select
              style={{ width: '100%' }}
              showArrow
              className="formSelectField edit-form-select"
            >
              {repoCategory &&
                repoCategory.length > 0 &&
                orderBy(repoCategory, ['category_name'], ['asc']).map((cat: Category) => {
                  return (
                    <Option key={cat.id} value={cat.id}>
                      {cat.category_name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="buffer" label="BUFFER" className="formField">
            <InputNumber className="buffer-field" size="large" addonAfter={<Text>m</Text>} />
          </Form.Item>
          <Form.Item
            name="comment"
            label="COMMENT"
            className="formField"
            rules={[editFileRepoSync]}
          >
            <Input className="formInputField" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditFileModal;
