import { upperFirst } from 'lodash';
import { lightGrey, primary } from 'constants/theme.constants';
import { ProjectStatus } from 'store/projects/types';

export const shortLabel = (string: string) => {
  const label = string
    .split(' ')
    .map((word) => upperFirst(word[0]))
    .join('');

  return label;
};

export const analyticsFormKey = (string: string) => {
  return string
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(' ')
    .join('_')
    .toLowerCase();
};

export const fromateFeatureKey = (string: string) => {
  return string.replace('  ', ' ').replace('( ', '(').toLowerCase().trim();
};

export const statusClass = (status: ProjectStatus) => {
  let classname = 'in-progress';

  switch (status) {
    case ProjectStatus.NotStarted:
      classname = 'not-started';
      break;
    case ProjectStatus.InProgress:
      classname = 'in-progress';
      break;
    case ProjectStatus.Completed:
      classname = 'completed';
      break;
    case ProjectStatus.Closed:
      classname = 'closed';
      break;
    default:
      classname = 'in-progress';
      break;
  }

  return classname;
};

export const strokeColor = (status: ProjectStatus) => {
  let color = lightGrey;

  switch (status) {
    case ProjectStatus.NotStarted:
      color = lightGrey;
      break;
    case ProjectStatus.InProgress:
      color = '#FF8719';
      break;
    case ProjectStatus.Completed:
      color = '#10AF46';
      break;
    case ProjectStatus.Closed:
      color = primary;
      break;
    default:
      color = '#FF8719';
      break;
  }

  return color;
};

export const readFileAsyncAsText = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsText(file);
  });
};

export const getNumber = (length: any) => {
  if (!length) return 0;
  const num = length.toString().split(' ');
  return Number(num[0]);
};

export const getCost = (cost: any, length: any) => {
  if (!cost || !length) return 0;

  const costRoute = Number(cost) * Number(getNumber(length));
  return Number(costRoute);
};

export const numberWithCommas = (x: number) => {
  const cost = x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return Number(cost);
};
