import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { addDeadlineStagesUpload } from 'store/projects/actions';
import { ProjectStagePayload, ProjectStagesList } from 'store/projects/types';
import { projectStagesPayload } from 'constants/project.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { getAsyncStorageValue } from 'utils/localStorage';
import ProjectStage from './ProjectStage';
import './styles.less';

interface AddTimeLineModalProps {
  handleTimelineCancel: Function;
}

const AddTimeLineModal = ({ handleTimelineCancel }: AddTimeLineModalProps) => {
  const dispatch = useAppDispatch();
  const { selectedProject, isDeadlineStageUpload } = useAppSelector((state) => state.projects);
  const projectCreatedDate = selectedProject?.created_at;
  const [stagesValue, setStagesValue] = useState<ProjectStagePayload>(projectStagesPayload);

  useEffect(() => {
    setStagesValue({
      ...projectStagesPayload,
      creation_date: moment(projectCreatedDate).format('YYYY-MM-DD')
    });
  }, []);

  const handleCallback = () => {
    setStagesValue({ ...projectStagesPayload });
    handleTimelineCancel();
  };

  const handleAddtimelineSubmit = () => {
    const payload = { ...stagesValue, project_id: selectedProject.id };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addDeadlineStagesUpload(payload, selectedProject.id, token, handleCallback));
    });
  };

  const handleAddTimelineCancel = () => {
    setStagesValue({ ...projectStagesPayload });
    handleTimelineCancel();
  };

  const handleDateChange = (name: string, date: string) => {
    setStagesValue({ ...stagesValue, [name]: date });
  };

  return (
    <>
      <div className="main-project-stage">
        <ProjectStage
          value={
            stagesValue.creation_date
              ? moment(stagesValue.creation_date)
              : moment(projectCreatedDate)
          }
          label={ProjectStagesList.ProjectCreation}
          name="creation_date"
          handleOnChange={handleDateChange}
          isDisable={false}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={moment(projectCreatedDate)}
        />
        <ProjectStage
          value={stagesValue.defining_coordinates ? moment(stagesValue.defining_coordinates) : null}
          label={ProjectStagesList.DefiningCoordinates}
          name="defining_coordinates"
          handleOnChange={handleDateChange}
          isDisable={false}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={moment(projectCreatedDate)}
        />
        <ProjectStage
          value={stagesValue.AOI_request ? moment(stagesValue.AOI_request) : null}
          label={ProjectStagesList.AOIRequest}
          name="AOI_request"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.defining_coordinates}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.defining_coordinates
              ? moment(stagesValue.defining_coordinates)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={
            stagesValue.LULC_classification_low ? moment(stagesValue.LULC_classification_low) : null
          }
          label={ProjectStagesList.LULCLow}
          name="LULC_classification_low"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.AOI_request}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.AOI_request ? moment(stagesValue.AOI_request) : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={stagesValue.AOI_creation ? moment(stagesValue.AOI_creation) : null}
          label={ProjectStagesList.AOIGeneration}
          name="AOI_creation"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.LULC_classification_low}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.LULC_classification_low
              ? moment(stagesValue.LULC_classification_low)
              : moment(projectCreatedDate)
          }
        />

        <ProjectStage
          value={stagesValue.AOI_finalization ? moment(stagesValue.AOI_finalization) : null}
          label={ProjectStagesList.AOIFinalization}
          name="AOI_finalization"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.AOI_creation}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.AOI_creation ? moment(stagesValue.AOI_creation) : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={
            stagesValue.tasking_high_resolution ? moment(stagesValue.tasking_high_resolution) : null
          }
          label={ProjectStagesList.TaskingHigh}
          name="tasking_high_resolution"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.AOI_finalization}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.AOI_finalization
              ? moment(stagesValue.AOI_finalization)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={
            stagesValue.procurement_high_resolution
              ? moment(stagesValue.procurement_high_resolution)
              : null
          }
          label={ProjectStagesList.ProcurementHigh}
          name="procurement_high_resolution"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.tasking_high_resolution}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.tasking_high_resolution
              ? moment(stagesValue.tasking_high_resolution)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={
            stagesValue.pre_processing_high_resolution
              ? moment(stagesValue.pre_processing_high_resolution)
              : null
          }
          label={ProjectStagesList.PreProcessingHigh}
          name="pre_processing_high_resolution"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.procurement_high_resolution}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.procurement_high_resolution
              ? moment(stagesValue.procurement_high_resolution)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={stagesValue.DEM_generation ? moment(stagesValue.DEM_generation) : null}
          label={ProjectStagesList.DEMGeneration}
          name="DEM_generation"
          isDisable={!stagesValue.pre_processing_high_resolution}
          handleOnChange={handleDateChange}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.pre_processing_high_resolution
              ? moment(stagesValue.pre_processing_high_resolution)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={
            stagesValue.LULC_classification_high
              ? moment(stagesValue.LULC_classification_high)
              : null
          }
          label={ProjectStagesList.LULCHigh}
          name="LULC_classification_high"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.DEM_generation}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.DEM_generation
              ? moment(stagesValue.DEM_generation)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={stagesValue.preliminary_route ? moment(stagesValue.preliminary_route) : null}
          label={ProjectStagesList.PreliminaryRoute}
          name="preliminary_route"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.LULC_classification_high}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.LULC_classification_high
              ? moment(stagesValue.LULC_classification_high)
              : moment(projectCreatedDate)
          }
        />
        <ProjectStage
          value={stagesValue.route_finalization ? moment(stagesValue.route_finalization) : null}
          label={ProjectStagesList.RouteFinalization}
          name="route_finalization"
          handleOnChange={handleDateChange}
          isDisable={!stagesValue.preliminary_route}
          defaultDate={moment(projectCreatedDate)}
          defaultPickerDate={
            stagesValue.preliminary_route
              ? moment(stagesValue.preliminary_route)
              : moment(projectCreatedDate)
          }
        />
      </div>
      <div className="add-timeline-btn">
        <Button loading={isDeadlineStageUpload} type="primary" onClick={handleAddtimelineSubmit}>
          Add Timeline
        </Button>
        <Button type="ghost" className="cancel-btn" onClick={handleAddTimelineCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default AddTimeLineModal;
