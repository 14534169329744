import { Typography } from 'antd';

const { Text } = Typography;

interface RequiredLabelProps {
  label: string;
  isRequired?: boolean;
}

const RequiredLabel = ({ label, isRequired }: RequiredLabelProps) => {
  return (
    <Text className="select-title">
      {label}
      {isRequired && <Text>*</Text>}
    </Text>
  );
};

RequiredLabel.defaultProps = {
  isRequired: false
};

export default RequiredLabel;
