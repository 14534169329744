import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal, PageHeader, Empty, Spin, Typography } from 'antd';
import { filter, isEmpty, toNumber } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { BsArrowLeft, BsArrowUpShort } from 'react-icons/bs';
import DataTable, { TableColumn } from 'react-data-table-component';
import { red } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import AvatarList from 'components/Avatar/AvatarList';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import AddProjectMember from 'components/Modal/AddProjectMember/AddProjectMemberModal';
import AddProject from 'components/Modal/AddProject/AddProjectModal';
import StatusTitle from 'components/core/StatusTitle';
import StartEndPoint from 'components/core/StartEndPoint';
import ProjectFilter from 'components/core/ProjectFilter';
import { ACCESS_TOKEN, DateFormat2, TimeFormat, USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { getAvailableProjectMembers, getProjects } from 'store/projects/actions';
import { Project, ProjectStatus } from 'store/projects/types';
import { getOrganizationMembers } from 'store/organizations/actions';
import { UserRole } from 'store/users/types';
import { selectOrganizationMember } from 'store/organizations';
import { setAoi, setAoiPointsCoords } from 'store/aoi';
import {
  setSelectedProject,
  getProjectCountByStatus,
  getProjectCountByManager,
  setProject
} from 'store/projects';
import { setFiles } from 'store/repositoryfile';
import { setAnalytics } from 'store/analytics';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { isLoadingProjects, projects } = useAppSelector((state) => state.projects);
  const { selectedOrganization } = useAppSelector((state) => state.organizations);
  const allOrganizationMember = useAppSelector(selectOrganizationMember);
  const [isOpenAddProjectModal, setAddProjectModal] = useState<boolean>(false);
  const [isOpenAddProjectMemberModal, setAddProjectMemberModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Project | null>(null);
  const [filterText, setFilterText] = useState<string>('');

  useEffect(() => {
    if (selectedOrganization) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getProjects(selectedOrganization.id, token));
        dispatch(getOrganizationMembers(selectedOrganization.id, token));
        dispatch(setProject(null));
        dispatch(setAoi(null));
        dispatch(setAoiPointsCoords(null));
        dispatch(setFiles([]));
        dispatch(setAnalytics(null));
        dispatch(setSelectedProject(null));
      });
    }
  }, []);

  const handleAddProjectMemberForm = (row: Project) => {
    if (userRole !== UserRole.Manager) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(row.id.toString(), token));
      });
    }
    setAddProjectMemberModal(!isOpenAddProjectMemberModal);
    setSelectedRow(row);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModal(false);
  };

  const handleOpenAddProjectModal = () => {
    setAddProjectModal(true);
  };

  const handleCloseAddProjectMemberModal = () => {
    setSelectedRow(null);
    setAddProjectMemberModal(false);
  };

  const handleRowClick = (row: Project) => {
    dispatch(setSelectedProject(row));
    navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
  };

  const navigateUserManagement = () => {
    navigate(routes.UserManagement, { replace: true });
  };

  const handleGoBack = () => {
    navigate(routes.Organization, { replace: true });
  };

  const sortFunctionForLastUpdatedDate = (rowA: Project, rowB: Project) => {
    const a1 = new Date(rowA.last_updated || new Date()).getTime();
    const b1 = new Date(rowB.last_updated || new Date()).getTime();
    if (a1 < b1) {
      return 1;
    }
    if (a1 > b1) {
      return -1;
    }
    return 0;
  };

  const columns: TableColumn<Project>[] = [
    {
      name: 'PROJECT ID',
      width: '150px',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: 'PROJECT NAME',
      width: '230px',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: 'START POINT',
      width: '130px',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      cell: (row) =>
        useMemo(
          () => (row.start_point ? <StartEndPoint point={row.start_point} /> : '-'),
          [row.start_point]
        )
    },
    {
      name: 'END POINT',
      width: '130px',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      cell: (row) =>
        useMemo(
          () => (row.end_point ? <StartEndPoint point={row.end_point} /> : '-'),
          [row.end_point]
        )
    },
    {
      name: <StatusTitle />,
      width: '300px',
      style: {
        paddingRight: '60px',
        paddingLeft: '0px'
      },
      cell: (row) =>
        useMemo(
          () => (
            <ProgressBar
              percent={row.status_percentage}
              statusText={row.status!}
              project={row}
              handleOnClick={handleRowClick}
            />
          ),
          [row.tagline]
        )
    },
    {
      name: 'LAST UPDATED',
      width: '160px',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      sortable: true,
      sortFunction: sortFunctionForLastUpdatedDate,
      cell: (row) =>
        useMemo(
          () =>
            !isEmpty(row.last_updated) ? (
              <div className="last-updated">
                <Text className="project-dateTime">
                  {moment(row.last_updated).format(DateFormat2)}
                </Text>
                <Text className="project-dateTime">
                  {moment(row.last_updated).format(TimeFormat)} IST
                </Text>
              </div>
            ) : (
              <div className="last-updated">
                <Text className="project-dateTime">-</Text>
              </div>
            ),
          [row.last_updated]
        )
    },
    {
      name: 'TEAM MEMBERS',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      cell: (row) =>
        useMemo(
          () =>
            row.team_members && row.team_members.length > 0 ? (
              <AvatarList record={row} handleAddProjectMemberForm={handleAddProjectMemberForm} />
            ) : (
              '-'
            ),
          [row.team_members]
        )
    },
    {
      name: 'ACCESS',
      selector: (row) => row.access,
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      cell: (row) =>
        useMemo(
          () =>
            row.access ? (
              <Text className="editAccess">Editor</Text>
            ) : (
              <Text className="editAccess">Viewer</Text>
            ),
          []
        )
    }
  ];

  const conditionalRowStyles = [
    {
      when: (row: Project) =>
        row.status === ProjectStatus.InProgress || row.status === ProjectStatus.NotStarted,
      style: {
        border: '1px solid #ff0000',
        borderLeft: '6px solid #ff0000'
      }
    },
    {
      when: (row: Project) => !row.access,
      style: {
        border: '1.2px solid #e4e4e4'
      }
    },
    {
      when: (row: Project) =>
        row.status === ProjectStatus.Closed || row.status === ProjectStatus.Completed,
      style: {
        paddingLeft: '5px'
      }
    }
  ];

  const customStyles = {
    noData: {
      style: {
        backgroundColor: 'unset !important',
        height: '100% !important',
        display: 'flex',
        alignItem: 'center'
      }
    }
  };

  const projectDataFilter: Array<Project> = filter(projects, (item) => {
    if (filterText && filterText?.length >= 1) {
      return (
        item.id === toNumber(filterText) ||
        item.name.toLowerCase().includes(filterText.toLowerCase())
      );
    }
    return projects;
  });

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <ProjectFilter
        onFilter={(e: React.FormEvent<HTMLInputElement>) => {
          const filterValue = (e.target as HTMLInputElement).value;
          setFilterText(filterValue);
        }}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const handleBackMapView = () => {
    navigate(routes.AdminMapPage, { replace: true });
  };

  return (
    <>
      <HeaderLayout />
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoadingProjects}
        indicator={<LoadingOutlined color={red} style={{ fontSize: 24 }} spin />}
      >
        <div className="main-project-content">
          <PageHeader
            className="main-title-content"
            onBack={handleGoBack}
            backIcon={
              userRole === UserRole.SuperAdmin ? <BsArrowLeft size={20} className="backIcon" /> : ''
            }
            ghost={false}
            title="Projects"
            extra={[
              userRole === UserRole.Admin && (
                <Button
                  key="0"
                  type="primary"
                  className="topContentBtn"
                  onClick={handleBackMapView}
                >
                  Map View
                </Button>
              ),
              userRole !== UserRole.Manager && (
                <Button
                  key="1"
                  type="primary"
                  className="topContentBtn"
                  onClick={navigateUserManagement}
                >
                  User Management
                </Button>
              ),
              userRole === UserRole.Admin && (
                <Button
                  key="2"
                  type="primary"
                  className="topContentBtn"
                  onClick={handleOpenAddProjectModal}
                >
                  Create project
                </Button>
              )
            ]}
          />
          <div className="maintableContent">
            <DataTable
              fixedHeader
              responsive
              fixedHeaderScrollHeight="500px"
              columns={columns}
              data={projectDataFilter}
              onRowClicked={(row) => handleRowClick(row)}
              sortIcon={<BsArrowUpShort />}
              conditionalRowStyles={conditionalRowStyles}
              subHeader
              subHeaderWrap
              subHeaderComponent={subHeaderComponentMemo}
              noDataComponent={
                <Empty
                  imageStyle={{
                    height: 0
                  }}
                  description={<Text className="descriptionText">NO PROJECTS FOUND</Text>}
                />
              }
              customStyles={customStyles}
            />
            <div className="mainProjectTLFooter">
              <div className="companyProjectContent">
                <Text>Company’s Projects</Text>
                <Text>{projects?.length > 0 ? projects.length : 0}</Text>|
              </div>
              <div className="notStartedContent">
                <Text>Not Started</Text>
                <Text>{getProjectCountByStatus(projects, ProjectStatus.NotStarted)}</Text>
              </div>
              <div className="inProgressContent">
                <Text>In Progress</Text>
                <Text>{getProjectCountByStatus(projects, ProjectStatus.InProgress)}</Text>
              </div>
              <div className="completeContent">
                <Text>Completed</Text>
                <Text>{getProjectCountByStatus(projects, ProjectStatus.Completed)}</Text>
              </div>
              <div className="closeContent">
                <Text>Closed</Text>
                <Text>{getProjectCountByStatus(projects, ProjectStatus.Closed)}</Text>
              </div>
              {userRole === UserRole.Manager && (
                <>
                  <div className="verticle-line" />
                  <div className="companyProjectContent manager-propject">
                    <Text>My Projects</Text>
                    <Text>{filter(projects, { access: true }).length}</Text>
                  </div>
                  <div className="notStartedContent">
                    <Text>Not Started</Text>
                    <Text>{getProjectCountByManager(projects, ProjectStatus.NotStarted)}</Text>
                  </div>
                  <div className="inProgressContent">
                    <Text>In Progress</Text>
                    <Text>{getProjectCountByManager(projects, ProjectStatus.InProgress)}</Text>
                  </div>
                  <div className="completeContent">
                    <Text>Completed</Text>
                    <Text>{getProjectCountByManager(projects, ProjectStatus.Completed)}</Text>
                  </div>
                  <div className="closeContent">
                    <Text>Closed</Text>
                    <Text>{getProjectCountByManager(projects, ProjectStatus.Closed)}</Text>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Spin>
      {isOpenAddProjectMemberModal && selectedRow && (
        <Modal
          open={isOpenAddProjectMemberModal}
          okText="Create"
          cancelText="Cancel"
          centered
          width={697}
          className="newModal"
          closable={false}
          footer={null}
        >
          <AddProjectMember
            handleCloseAddProjectMemberModal={handleCloseAddProjectMemberModal}
            selectedRow={selectedRow}
            selectedOrganization={selectedOrganization}
          />
        </Modal>
      )}
      <Modal
        open={isOpenAddProjectModal}
        okText="Create"
        cancelText="Cancel"
        centered
        width={697}
        className="newModal"
        closable={false}
        footer={null}
      >
        <AddProject
          allOrganizationMember={allOrganizationMember}
          handleCloseAddProjectModal={handleCloseAddProjectModal}
          selectedOrganization={selectedOrganization}
        />
      </Modal>
    </>
  );
};

export default Projects;
