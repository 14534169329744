import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography } from 'antd';
import { includes, isEmpty, remove } from 'lodash';
import { toast } from 'react-toastify';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import AoiTabs from 'components/AoiSidebar/AoiTabs';
import AoiMap from 'components/AoiMap/AoiMap';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  getAdminMangerAoiFiles,
  getAllAoiFiles,
  getFinalizedAoiFile,
  getSuperAdminAoifiles
} from 'store/aoi';
import {
  addAOISelectionForApproval,
  getAoiPointsCoords,
  getAOISelection,
  getExtractAOIFiles,
  updateAOISelectionForApproval
} from 'store/aoi/actions';
import { addProjectStageChangeStatus, getProject } from 'store/projects/actions';
import { getGeoserverLayerStyle, getRepositoryFiles } from 'store/repositoryfile/actions';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getExtractVectorFiles } from 'store/vectorfile/actions';
import { ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { UserRole } from 'store/users/types';
import { BothPointLatLngType, LatLongType } from 'types/aoi.types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

const Aoi = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const childRef: any = useRef();
  const { selectedProject, project } = useAppSelector((state) => state.projects);
  const {
    aoiPointsCoords,
    aoi,
    isRequestingAddAOISelection,
    isRequestingUpdateAOISelection,
    isRequestingExtractAoiFiles
  } = useAppSelector((state) => state.aoi);
  const finalAoi: RepositoryFile | null = useAppSelector(getFinalizedAoiFile);
  const allAois: RepositoryFile[] | [] = useAppSelector(getAllAoiFiles);
  const superAdminAOIs: RepositoryFile[] = useAppSelector(getSuperAdminAoifiles);
  const adminManagerAOIs: RepositoryFile[] = useAppSelector(getAdminMangerAoiFiles);
  const [isApprove, setApprove] = useState(false);
  const [approveMssg, setApproveMssg] = useState('');
  const [bothPointLatLng, setBothPointLatLng] = useState<BothPointLatLngType | null>(null);
  const [aoiPointsLatLng, setAoiPointsLatLng] = useState<BothPointLatLngType | null>(null);
  const [selectedAOI, setSelectedAOI] = useState<RepositoryFile | null>(null);
  const [selectedAoiFileId, setSelectedAoiFileId] = useState<number>(0);
  const [selectedAoiIds, setSelectedAoiIds] = useState<number[]>([]);
  const userRole = getStorageValue(USER_ROLE);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getAoiPointsCoords(selectedProject?.id, token));
      dispatch(getProject(selectedProject?.id, token));
      dispatch(getAOISelection(selectedProject?.id, token));
      dispatch(
        getRepositoryFiles(selectedProject?.id, token, () => {
          dispatch(getExtractAOIFiles(token));
          dispatch(getExtractVectorFiles(token));
          dispatch(getGeoserverLayerStyle(token));
        })
      );
    });
  }, []);

  useEffect(() => {
    if (allAois.length > 0) {
      const aoiIds = allAois.map((r) => r.id);
      setSelectedAoiIds([...aoiIds]);
    }
  }, [allAois]);

  useEffect(() => {
    if (isEmpty(aoiPointsCoords)) {
      setAoiPointsLatLng(null);
      return;
    }

    if (aoiPointsCoords && aoiPointsCoords.bee_line) {
      setAoiPointsLatLng({
        start: aoiPointsCoords?.start_point.coordinates,
        end: aoiPointsCoords?.end_point.coordinates,
        is_bee_line: true
      });
    } else {
      setAoiPointsLatLng({
        start: aoiPointsCoords?.start_point.coordinates,
        end: aoiPointsCoords?.end_point.coordinates,
        is_bee_line: false
      });
    }
  }, [aoiPointsCoords]);

  const handleShowHideSelectedAoi = (fileId: number) => {
    if (includes(selectedAoiIds, fileId)) {
      remove(selectedAoiIds, (route: number) => route === fileId);
    } else {
      selectedAoiIds.push(fileId);
    }

    setSelectedAoiIds([...selectedAoiIds]);
  };

  const handleAoiSelect = (data: RepositoryFile | null) => {
    setSelectedAOI(data);
  };

  const handleChangeProjectStagesStatus = (
    stageName: ProjectStagesList,
    projectStageStatus: ProposedTimelineStatus
  ) => {
    const payload = {
      stage: stageName,
      project_id: selectedProject.id.toString(),
      status: projectStageStatus
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addProjectStageChangeStatus(payload, token));
    });
  };

  const handleApprovalStatusCallBack = () => {
    setApproveMssg('We will notify you once the data is ready');
    setTimeout(() => {
      setApprove(!isApprove);
      setApproveMssg('');
      navigate(`${routes.Projects}/${routes.ProjectTimelineUrl}`);
    }, 3000);
  };

  const handleApprovalCallback = (status: boolean) => {
    if (status) {
      handleApprovalStatusCallBack();
      if (userRole === UserRole.Manager) {
        handleChangeProjectStagesStatus(
          ProjectStagesList.AOIFinalization,
          ProposedTimelineStatus.OnGoing
        );
      }
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleUpdateApprovalCallback = (status: boolean) => {
    if (status) {
      handleApprovalStatusCallBack();
    }
  };

  const handleApproveYes = () => {
    if (!isEmpty(aoi)) {
      const payload = {
        aoi_file_id: selectedAoiFileId.toString()
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          updateAOISelectionForApproval(
            payload,
            selectedProject?.id,
            token,
            handleUpdateApprovalCallback
          )
        );
      });
    } else {
      const payload = {
        aoi_file_id: selectedAoiFileId.toString()
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          addAOISelectionForApproval(payload, selectedProject?.id, token, handleApprovalCallback)
        );
      });
    }
  };

  const handleApproveNo = () => {
    setApprove(!isApprove);
  };

  const handlePointsData = (data: BothPointLatLngType) => {
    setBothPointLatLng(data);
  };

  const handleFileApproval = (fileId: number) => {
    setSelectedAoiFileId(fileId);
    setApprove(!isApprove);
  };

  const handleApproveAoiByAdmin = (fileId: number) => {
    setSelectedAoiFileId(fileId);
    setApprove(!isApprove);
  };

  return (
    <div className="main-aoi-content">
      <AoiTabs
        ref={childRef}
        loading={isRequestingExtractAoiFiles}
        allAois={allAois}
        superAdminAOIs={superAdminAOIs}
        adminManagerAOIs={adminManagerAOIs}
        aoiPointsCoords={aoiPointsCoords}
        selectedAoiIds={selectedAoiIds}
        projectStageData={
          project && project.stages && project.stages.length > 0 ? project.stages : []
        }
        handleFileApproval={handleFileApproval}
        handleApproveAoiByAdmin={handleApproveAoiByAdmin}
        handlePointsData={handlePointsData}
        handleAoiSelect={handleAoiSelect}
        handleShowHideSelectedAoi={handleShowHideSelectedAoi}
      />
      <AoiMap
        aoiData={aoi}
        finalAoi={finalAoi}
        allAoiFiles={allAois}
        aoiPointsCoords={aoiPointsCoords}
        points={bothPointLatLng}
        aoiPoints={aoiPointsLatLng}
        selectedAOI={selectedAOI}
        isApproved={aoiPointsCoords && aoiPointsCoords.is_approved}
        selectedAoiIds={selectedAoiIds}
        handleStartPoints={(latLng: LatLongType) => {
          if (childRef.current) childRef.current.handleReverseChangeStartPoint(latLng);
        }}
        handleEndPoints={(latLng: LatLongType) => {
          if (childRef.current) childRef.current.handleReverseChangEndPoint(latLng);
        }}
      />
      <Modal
        title=""
        className="approve-modal"
        width={722}
        closable={false}
        open={isApprove}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title">
            Do you want to request AOI {selectedAoiFileId} for generating route?
          </Text>
          {approveMssg ? (
            <Text className="notify-content">{approveMssg}</Text>
          ) : (
            <div className="modal-content-btn">
              <Button
                type="primary"
                onClick={handleApproveYes}
                loading={isRequestingUpdateAOISelection || isRequestingAddAOISelection}
              >
                Yes
              </Button>
              <Button type="ghost" onClick={handleApproveNo}>
                No
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Aoi;
