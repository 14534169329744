import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import rootReducer from './rootReducer';

const storeSelectedUser = createWhitelistFilter('user', ['rsaKey', 'user']);
const storeSelectedOrganization = createWhitelistFilter('organizations', [
  'selectedOrganization',
  'orginsationMembers'
]);
const storeSelectedProject = createWhitelistFilter('projects', ['selectedProject', 'project']);
const storeSelectedCategories = createWhitelistFilter('repofiles', [
  'repositoryFiles',
  'categories'
]);
const storeSelectedAoi = createWhitelistFilter('aoi', ['aoi', 'aoiPointsCoords', 'aoiFiles']);
const storeSelectedaRoute = createWhitelistFilter('route', ['routes']);

const persistConfig = {
  key: 'root:htl',
  storage,
  blacklist: ['analytics'],
  transforms: [
    storeSelectedUser,
    storeSelectedOrganization,
    storeSelectedProject,
    storeSelectedCategories,
    storeSelectedAoi,
    storeSelectedaRoute
  ]
};

const middlewares: any = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
