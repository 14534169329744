export const organizationSteps = [
  {
    id: 1,
    title: 'Organization Onboarding'
  },
  {
    id: 2,
    title: 'Organization Logo'
  }
];

export const userRoleText: any = {
  super_admin: 'Super Admin',
  admin: 'Admin',
  manager: 'Manager'
};
