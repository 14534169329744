import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col, Input } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { routes } from 'constants/pageRoutes.constants';
import { managerMemberSync } from 'utils/yupsync';
import HeaderLayout from 'layouts/HeaderLayout';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { decryptValue, encryptValue } from 'utils/decodeEncodeData';
import { addUserDetail } from 'store/users/actions';
import { getAsyncStorageValue } from 'utils/localStorage';

const ManagerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [form] = Form.useForm();

  useEffect(() => {
    if (user?.email) {
      form.setFieldsValue({
        userEmail: decryptValue(user?.email)
      });
    }
  }, [user]);

  const handleCallback = () => {
    navigate(routes.ProjectList);
  };

  const onSubmitForm = () => {
    let payload = form.getFieldsValue([
      'first_name',
      'last_name',
      'emp_id',
      'phone_no',
      'department'
    ]);
    payload.user_id = user?.id;
    payload = {
      ...payload,
      first_name: encryptValue(payload.first_name),
      last_name: encryptValue(payload.last_name),
      phone_no: encryptValue(payload.phone_no)
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addUserDetail(payload, token, handleCallback));
    });
  };

  return (
    <>
      <HeaderLayout />
      <Row justify="center">
        <Col xxl={8} xl={10} lg={12} className="mainContentDiv">
          <p className="titleContent">Enter Your Details</p>
          <Form
            className="mainContentForm"
            form={form}
            onFinish={onSubmitForm}
            name="member_detail_form"
            autoComplete="off"
          >
            <div className="mainFormDiv">
              <h3>
                Enter Details <span>*</span>
              </h3>
              <div className="inlineContent">
                <Form.Item name="first_name" className="inputFirstName" rules={[managerMemberSync]}>
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item name="last_name" className="inputFirstName" rules={[managerMemberSync]}>
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>
              <div className="inlineContent">
                <Form.Item name="emp_id" className="inputFirstName" rules={[managerMemberSync]}>
                  <Input placeholder="Employee ID" />
                </Form.Item>
                <Form.Item name="department" className="inputFirstName" rules={[managerMemberSync]}>
                  <Input placeholder="Department" />
                </Form.Item>
              </div>
              <Form.Item name="userEmail" rules={[managerMemberSync]}>
                <Input disabled type="email" placeholder="karthik@powerline.in" />
              </Form.Item>
              <div className="inlineContent">
                <Form.Item name="phone_no" className="inputPhone" rules={[managerMemberSync]}>
                  <Input placeholder="Phone Number" maxLength={10} />
                </Form.Item>
              </div>
              <Row justify="end">
                <Form.Item className="addMemberBtn">
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ManagerDetail;
