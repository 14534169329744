import { useState, useEffect } from 'react';
import { ACCESS_TOKEN, AES_KEY, USER_ROLE } from 'constants/common.constant';
import { UserRole } from 'store/users/types';
import { getStorageValue } from 'utils/localStorage';
import { useAppSelector } from './useReduxHook';

interface UserDataType {
  isCurrentUser: boolean;
  userId?: string;
  role?: UserRole;
}

export function useUserDetail() {
  const { user } = useAppSelector((state) => state.user);
  const [userDetail, setUserDetail] = useState<UserDataType | null>(null);

  useEffect(() => {
    const userId = user?.id;
    const aesKey = getStorageValue(AES_KEY);
    const accessToken = getStorageValue(ACCESS_TOKEN);
    const userRole = getStorageValue(USER_ROLE);

    if (aesKey && accessToken) {
      setUserDetail({
        userId,
        isCurrentUser: true,
        role: userRole
      });
    } else {
      setUserDetail({ isCurrentUser: false });
    }
  }, []);

  return userDetail;
}
