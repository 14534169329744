import { LoadingOutlined } from '@ant-design/icons';
import { Space, Switch, Tooltip, Typography } from 'antd';
import DeleteIcon from 'assets/svg/DeleteIcon';
import DownloadIcon from 'assets/svg/DownloadIcon';
import EditFileIcon from 'assets/svg/EditFileIcon';
import { primary, white } from 'constants/theme.constants';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';

interface SuperAdminFileActionsProps {
  record: RepositoryFile;
  handleEditRepoFile: Function;
  handleDownloadFile: Function;
  handleEnableforMap: Function;
  handleDeleteRouteFeatureList: Function;
  isRequestingDownloadFile: boolean;
  downloadFileId: number | null;
  userRole: UserRole;
  userEmail: string;
}

const { Text } = Typography;

const SuperAdminFileActions = ({
  record,
  handleEditRepoFile,
  handleDownloadFile,
  handleEnableforMap,
  handleDeleteRouteFeatureList,
  userRole,
  userEmail,
  isRequestingDownloadFile,
  downloadFileId
}: SuperAdminFileActionsProps) => {
  return (
    <Space size="middle">
      <button onClick={() => handleEditRepoFile(record)} type="button" className="edit-file-btn">
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={<Text>Edit</Text>}
        >
          <EditFileIcon color={primary} />
        </Tooltip>
      </button>
      <Tooltip
        className="switchAction"
        placement="bottom"
        color={white}
        title={<Text>Download</Text>}
      >
        <a onClick={() => handleDownloadFile(record.id, record.wms_layer_name)}>
          {isRequestingDownloadFile && downloadFileId === record.id ? (
            <LoadingOutlined />
          ) : (
            <DownloadIcon />
          )}
        </a>
      </Tooltip>
      {record.category === FilesCategory.RoutesFeatureList && (
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={userRole === UserRole.SuperAdmin ? <Text>Delete</Text> : ''}
        >
          <div
            onClick={() => handleDeleteRouteFeatureList(record)}
            style={{ cursor: 'pointer', marginTop: '5px' }}
          >
            <DeleteIcon height={20} width={20} />
          </div>
        </Tooltip>
      )}
      {record.category !== FilesCategory.RoutesFeatureList && (
        <Tooltip
          className="switchAction"
          placement="bottomRight"
          color={white}
          title={<Text>Add or remove layer from map</Text>}
        >
          <Switch
            defaultChecked={record.enabled_for_map}
            size="small"
            className="cheakedBtn"
            onChange={(check) => handleEnableforMap(check, record.id)}
          />
        </Tooltip>
      )}
      {record.category !== FilesCategory.RoutesFeatureList &&
        userRole === UserRole.SuperAdmin &&
        userEmail === record.user_email && (
          <Tooltip
            className="switchAction"
            placement="bottom"
            color={white}
            title={userRole === UserRole.SuperAdmin ? <Text>Delete</Text> : ''}
          >
            <div
              onClick={() => handleDeleteRouteFeatureList(record)}
              style={{ cursor: 'pointer', marginTop: '5px' }}
            >
              <DeleteIcon height={20} width={20} />
            </div>
          </Tooltip>
        )}
    </Space>
  );
};

export default SuperAdminFileActions;
