import { Typography } from 'antd';
import statusFilter from 'assets/svg/StatusFilterProjectPage.svg';

const { Text } = Typography;

const StatusTitle = () => {
  return (
    <>
      <Text>STATUS</Text>
      <img className="statusFilterIcon" src={statusFilter} alt="Status Filter" />
    </>
  );
};

export default StatusTitle;
