export const routes = {
  LoginUrl: '/',
  Organization: '/organization',
  AddOrganization: '/add-organization',
  EditOrganization: '/organization/edit',
  MapPage: '/map-view',
  AdminMapPage: '/view-map',
  ProjectList: '/organization/project-list',
  UserManagement: '/user-management',
  MemberDetail: '/member-detail',
  TeamOnboarding: '/team-onboarding',
  ManagerMemberDetail: '/manager-detail',
  Projects: '/projects',
  ProjectTimelineUrl: 'project-timeline',
  FileRepository: 'file-repository',
  AOI: 'aoi',
  Routes: 'routes',
  Analytics: 'analytics'
  // Message: 'messages'
};
