import { useState } from 'react';
import { capitalize, difference } from 'lodash';
import { Col, Row, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import DownArrowIcon from 'assets/svg/DownArrowIcon';
import UpArrowIcon from 'assets/svg/UpArrowIcon';
import { lightBlack } from 'constants/theme.constants';
import { FeatureParameter, fixedParameter } from 'constants/analytics.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { getSelectedAnalyticsRouteData } from 'store/analytics';
import { RepositoryFile } from 'store/repositoryfile/types';
import { fromateFeatureKey } from 'utils/util';

interface RouteAnalyticsDataProps {
  selectedAnalyticsRouteData: RepositoryFile;
  handleCloseRouteData: Function;
}

const { Text } = Typography;

const RouteAnalyticsData = ({
  selectedAnalyticsRouteData,
  handleCloseRouteData
}: RouteAnalyticsDataProps) => {
  const selectedAnalyticsRoute: any = useAppSelector((state) =>
    getSelectedAnalyticsRouteData(state, Number(selectedAnalyticsRouteData.id))
  );
  const [isExpandDataDiv, setExpandDataDiv] = useState<boolean>(false);

  const handleExpandRouteData = (isExpandRoute: boolean) => {
    setExpandDataDiv(!isExpandRoute);
  };

  if (!selectedAnalyticsRoute) return null;

  const allRouteKeys = Object.keys(selectedAnalyticsRoute);
  const fromatedKeys = allRouteKeys.map((key) => fromateFeatureKey(key));
  const dynamicRouteParameter: string[] = difference(fromatedKeys, fixedParameter);

  return (
    <div className="route-analytics-data">
      <Row className="route-analytics-main">
        <Col span={24} className="parameters-txt">
          <Text>Parameters (Route {selectedAnalyticsRouteData.id})</Text>
          <CloseIcon onClick={() => handleCloseRouteData()} />
        </Col>
        <Col span={24} className="route-data">
          <Row>
            <Col span={3} className="route-length">
              <Text>Total Length</Text>
              <Text>{selectedAnalyticsRoute[FeatureParameter.TotalLength] || '00'}</Text>
            </Col>
            <Col span={21}>
              <Row className="route-params" style={{ height: !isExpandDataDiv ? '82px' : '150px' }}>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.AngularTowers] || '00'}</Text>
                  <Text>Angular Towers</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.TowerA] || '00'}</Text>
                  <Text>Tower Type A</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.TowerB] || '00'}</Text>
                  <Text>Tower Type B</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.TowerC] || '00'}</Text>
                  <Text>Tower Type C</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.TowerD] || '00'}</Text>
                  <Text>Tower Type D</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.TowerE] || '00'}</Text>
                  <Text>Tower Type E</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>
                    {selectedAnalyticsRoute[FeatureParameter.TlTotal] ||
                      selectedAnalyticsRoute[FeatureParameter.TlcTotal] ||
                      '00'}
                  </Text>
                  <Text>Transmission line crossing (Total)</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.Tl132KV] || '00'}</Text>
                  <Text>Transmission line crossing (132 KV)</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.Tl220KV] || '00'}</Text>
                  <Text>Transmission line crossing (220 KV)</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.Tl400KV] || '00'}</Text>
                  <Text>Transmission line crossing (400 KV)</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>
                    {selectedAnalyticsRoute[FeatureParameter.Tl756KV] ||
                      selectedAnalyticsRoute[FeatureParameter.Tl765KV] ||
                      '00'}
                  </Text>
                  <Text>Transmission line crossing (765 KV)</Text>
                </Col>
                <Col span={4} className="route-item">
                  <Text>{selectedAnalyticsRoute[FeatureParameter.TlUnKV] || '00'}</Text>
                  <Text>Transmission line crossing (Unknown KV)</Text>
                </Col>
                {dynamicRouteParameter.map((key) => {
                  return (
                    <Col span={4} className="route-item" key={key}>
                      <Text>{selectedAnalyticsRoute[key] || '00'}</Text>
                      <Text>{capitalize(key)}</Text>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="expand-icon" onClick={() => handleExpandRouteData(isExpandDataDiv)}>
        {isExpandDataDiv ? (
          <DownArrowIcon color={lightBlack} />
        ) : (
          <UpArrowIcon color={lightBlack} />
        )}
      </div>
    </div>
  );
};

export default RouteAnalyticsData;
