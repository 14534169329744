import { Button } from 'antd';
import { useMap } from 'react-leaflet';
import MinusIcon from 'assets/svg/MinusIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import RecenterIcon from 'assets/svg/RecenterIcon';
import Control from 'react-leaflet-custom-control';
import { AoiPointsCords } from 'store/aoi/types';

interface MapActionControlProps {
  isShowRecenter?: boolean;
  points?: AoiPointsCords | null;
}

const MapActionControl = ({ isShowRecenter, points }: MapActionControlProps) => {
  const map = useMap();

  const handleZoomIn = () => map.zoomIn();
  const handleZoomOut = () => map.zoomOut();
  const handleCenter = () => {
    if (points) {
      const startPoint = points.start_point.coordinates;
      const endPoint = points.end_point.coordinates;
      const lat = (Number(startPoint?.lat) + Number(endPoint?.lat)) / 2;
      const lng = (Number(startPoint?.lng) + Number(endPoint?.lng)) / 2;
      map.flyTo([lat, lng], 9, {
        animate: true,
        duration: 2
      });
    }
  };

  return (
    <Control position="topright">
      <Button className="zoom-plus" onClick={handleZoomIn}>
        <PlusIcon />
      </Button>
      <Button className="zoom-minus" onClick={handleZoomOut}>
        <MinusIcon />
      </Button>
      {isShowRecenter && (
        <Button className="map-icon" onClick={handleCenter}>
          <RecenterIcon />
        </Button>
      )}
      {/* <Button className="move-icon">
          <MoveIcon />
        </Button>
        <Button className="polygon-icon">
          <PolygonIcon />
        </Button> */}
    </Control>
  );
};

MapActionControl.defaultProps = {
  isShowRecenter: true,
  points: null
};

export default MapActionControl;
