import { userSlice } from './slice';

export const {
  setRsaRequest,
  setRSA,
  setLoginRequest,
  setUser,
  setLogoutRequest,
  setAddUserDetaiRequest,
  setRequestingRefreshToken
} = userSlice.actions;
export default userSlice.reducer;
