import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import { ProjectStartEndPoint } from 'store/projects/types';

interface StartEndPointProps {
  point: ProjectStartEndPoint;
}
const { Text } = Typography;

const StartEndPoint = ({ point }: StartEndPointProps) => {
  // if (isEmpty(point.coordinates) || isEmpty(point.name)) return <Text>-</Text>;
  if (isEmpty(point.coordinates)) return <Text>-</Text>;

  return (
    <div className="start-end-point">
      <Text className="point-name">{point.name}</Text>
      <Text className="point-coord point-name">{Number(point.coordinates.lat).toFixed(6)} ,</Text>
      <Text className="point-coord point-name">{Number(point.coordinates.lng).toFixed(6)}</Text>
    </div>
  );
};

export default StartEndPoint;
