import { useState } from 'react';
import moment from 'moment';
import { Button, Modal, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { toast } from 'react-toastify';
import { find, isEmpty, orderBy, upperFirst } from 'lodash';
import { ACCESS_TOKEN, DateFormat5, USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  Category,
  DeleteRepositoryFile,
  FileMapAccessPayload,
  RepositoryFile
} from 'store/repositoryfile/types';
import {
  addFileMapAccess,
  deleteRepositoryFile,
  getDownloadLULCFile,
  getDownloadRepoFile
} from 'store/repositoryfile/actions';
import { UserRole } from 'store/users/types';
import { getAdminManagerCategory } from 'store/repositoryfile';
import { decryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import SuperAdminFileActions from './SuperAdminFileActions';
import AdminFileActions from './AdminFileActions';
import ManagerFileActions from './ManagerFileActions';
import EditFileModal from './EditFileModal';
import './styles.less';

const { Text } = Typography;

const FileReposataryTable = () => {
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.user);
  const {
    isRequestingFiles,
    isRequestingEditRepoFile,
    repositoryFiles,
    categories,
    isRequestingDownloadFile,
    isRequestingDeleteFile
  } = useAppSelector((state) => state.repofiles);
  const adminManagerCategories = useAppSelector(getAdminManagerCategory);
  const [isOpenEditModal, setEditModal] = useState(false);
  const [isOpenDeleteModal, setDeleteFileModal] = useState(false);
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<RepositoryFile | null>();
  const [deleteFile, setDeleteFile] = useState<RepositoryFile | null>();

  const handleEditRepoFile = (record: RepositoryFile) => {
    setSelectedRow(record);
    setEditModal(true);
  };

  const handleEditFileCloseModal = () => {
    setSelectedRow(null);
  };

  const handleEnableforMap = (checked: boolean, repofileId: number) => {
    const payload: FileMapAccessPayload = {
      user_id: user.id,
      enable: checked
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addFileMapAccess(payload, selectedProject.id, repofileId, token));
    });
  };

  const handleDownloadFile = (repoFileId: number, wmsLayer: string | null) => {
    setDownloadFileId(repoFileId);
    if (wmsLayer) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getDownloadLULCFile(repoFileId, token));
      });
    } else {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getDownloadRepoFile(selectedProject?.id, repoFileId, token));
      });
    }
  };

  const handleDeleteRouteFeatureList = (record: RepositoryFile) => {
    setDeleteFile(record);
    setDeleteFileModal(true);
  };

  const handleDeleteFileNo = () => {
    setDeleteFile(null);
    setDeleteFileModal(false);
  };

  const handleDeleteCallback = (status: boolean) => {
    if (status) {
      handleDeleteFileNo();
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleDeleteFileYes = () => {
    if (deleteFile) {
      const payload: DeleteRepositoryFile = {
        user_id: user.id,
        category_id: find(categories, (cat: Category) => cat.category_name === deleteFile.category)
          ?.id
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          deleteRepositoryFile(
            payload,
            selectedProject?.id,
            deleteFile.id,
            token,
            handleDeleteCallback
          )
        );
      });
    } else {
      toast.error('Something went wrong');
    }
  };

  const columns: ColumnsType<RepositoryFile> = [
    {
      title: 'Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: '10%',
      render: (_, record) => <Text>{`${record.file_name} (${record.id})`}</Text>
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '15%',
      render: (_, record) => {
        const cat: Category | null = find(categories, (cat: Category) => {
          return cat.category_name === record.category;
        });
        return cat ? <Text>{cat?.category_name}</Text> : '-';
      }
    },
    {
      title: 'Buffer',
      dataIndex: 'buffer',
      key: 'buffer',
      width: '7%',
      render: (_, record) => (record.buffer ? <Text>{record.buffer} m</Text> : '0 m')
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
      width: '28%',
      render: (_, record) => (record.comment ? <Text>{record.comment}</Text> : '-')
    },
    {
      title: 'UPLOADED BY',
      dataIndex: 'created_by',
      key: 'created_by',
      width: '15%',
      render: (_, record) => {
        let firstname;
        let lastname;
        if (record.first_name && record.first_name) {
          firstname = upperFirst(decryptValue(record.first_name));
          lastname = upperFirst(decryptValue(record.last_name));
        } else {
          firstname = upperFirst(decryptValue(record.user_email));
          lastname = '';
        }

        return (
          <div className="uploadedByColumn">
            {record.user_role === UserRole.SuperAdmin ? (
              <Text>SatSure</Text>
            ) : (
              <Text>{`${firstname} ${lastname}`}</Text>
            )}
            {record.modified_at && <Text className="editedText">(Edited)</Text>}
          </div>
        );
      }
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedOn',
      key: 'updatedOn',
      width: '10%',
      render: (_, record) => (
        <div className="updatedOn">
          <Text>{moment(record.created_at).format(DateFormat5)} IST</Text>
        </div>
      )
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <>
            {userRole === UserRole.SuperAdmin && (
              <SuperAdminFileActions
                record={record}
                handleEditRepoFile={handleEditRepoFile}
                handleDownloadFile={handleDownloadFile}
                handleEnableforMap={handleEnableforMap}
                handleDeleteRouteFeatureList={handleDeleteRouteFeatureList}
                userRole={userRole}
                userEmail={user?.email}
                isRequestingDownloadFile={isRequestingDownloadFile}
                downloadFileId={downloadFileId}
              />
            )}
            {userRole === UserRole.Admin && (
              <AdminFileActions
                record={record}
                handleEditRepoFile={handleEditRepoFile}
                handleDownloadFile={handleDownloadFile}
                handleEnableforMap={handleEnableforMap}
                handleDeleteRouteFeatureList={handleDeleteRouteFeatureList}
                userRole={userRole}
                isRequestingDownloadFile={isRequestingDownloadFile}
                downloadFileId={downloadFileId}
              />
            )}
            {userRole === UserRole.Manager && (
              <ManagerFileActions
                record={record}
                handleEditRepoFile={handleEditRepoFile}
                handleDownloadFile={handleDownloadFile}
                handleEnableforMap={handleEnableforMap}
                handleDeleteRouteFeatureList={handleDeleteRouteFeatureList}
                userRole={userRole}
                isRequestingDownloadFile={isRequestingDownloadFile}
                downloadFileId={downloadFileId}
                user={user}
              />
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className="filerepo-table">
        <Table
          loading={isRequestingFiles}
          className="fileReposataryTable"
          columns={columns}
          dataSource={orderBy(repositoryFiles, ['created_at'], ['desc'])}
          pagination={false}
          sticky
          rowKey={(row) => row.id}
          rowClassName={(record) =>
            record.user_role === UserRole.SuperAdmin ? 'admin-upload' : ''
          }
        />
        {selectedRow && !isEmpty(selectedRow) && (
          <EditFileModal
            projectId={selectedProject?.id}
            isRequestingEditRepoFile={isRequestingEditRepoFile}
            isOpenModal={isOpenEditModal}
            handleEditFileCloseModal={handleEditFileCloseModal}
            selectedRow={selectedRow}
            repoCategory={userRole === UserRole.SuperAdmin ? categories : adminManagerCategories}
          />
        )}
      </div>
      <Modal
        title=""
        className="approve-modal"
        width={722}
        closable={false}
        open={isOpenDeleteModal}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title">
            Do you want to delete &quot;{deleteFile ? deleteFile.file_name : ''}&quot; ?
          </Text>
          <div className="modal-content-btn">
            <Button type="primary" onClick={handleDeleteFileYes} loading={isRequestingDeleteFile}>
              Yes
            </Button>
            <Button type="ghost" onClick={handleDeleteFileNo}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FileReposataryTable;
