import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from './types';

const initialState: UserState = {
  isLoadingRSA: false,
  rsaKey: null,
  isLoadingLogin: false,
  user: null,
  isLoadingLogout: false,
  isAddUserDetaiRequest: false,
  isRefreshToken: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRsaRequest: (state, action: PayloadAction<boolean>) => {
      state.isLoadingRSA = action.payload;
    },
    setRSA: (state, action) => {
      state.rsaKey = action.payload;
    },
    setLoginRequest: (state, action) => {
      state.isLoadingLogin = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogoutRequest: (state, action) => {
      state.isLoadingLogout = action.payload;
    },
    setAddUserDetaiRequest: (state, action) => {
      state.isAddUserDetaiRequest = action.payload;
    },
    setRequestingRefreshToken: (state, action) => {
      state.isRefreshToken = action.payload;
    }
  }
});
