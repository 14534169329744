import { Avatar, Button, Modal, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { userRoleText } from 'constants/organization.constants';
import { MemberT } from 'store/organizations/types';
import { Project } from 'store/projects/types';
import { shortLabel } from 'utils/util';
import './styles.less';

const { Text } = Typography;

interface MemberListModalProps {
  isOpen: boolean;
  selectedProject: Project | null;
  adminMember: MemberT | null | undefined;
  managers: MemberT[] | [];
  handleCloseModal: Function;
}

const MemberListModal = ({
  isOpen,
  handleCloseModal,
  selectedProject,
  adminMember,
  managers
}: MemberListModalProps) => {
  const columns: ColumnsType<MemberT> = [
    {
      title: 'NAME OF EMPLOYEE',
      dataIndex: 'label',
      key: 'name',
      render: (_, record) => {
        return (
          <div className="manager-avatar">
            <Avatar className="name-avatar">{shortLabel(record.label)}</Avatar>
            <Text>{record.label}</Text>
          </div>
        );
      }
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => {
        return record.role ? <Text className="manager-role">{userRoleText[record.role]}</Text> : '';
      }
    }
  ];

  return (
    <Modal
      className="member-list-modal"
      open={isOpen}
      title={`${selectedProject ? selectedProject.name : ''} Team Members`}
      centered
      closable
      onCancel={() => handleCloseModal()}
      onOk={() => handleCloseModal()}
      width={600}
      footer={[
        <Button
          key="submit"
          type="primary"
          style={{ padding: '4px 44px' }}
          onClick={() => handleCloseModal()}
        >
          Okay
        </Button>
      ]}
    >
      {adminMember && (
        <Table
          rowKey={(record) => record.value}
          className="member-list-table"
          // @ts-ignore
          dataSource={[adminMember].concat(managers)}
          columns={columns}
          pagination={false}
        />
      )}
    </Modal>
  );
};

export default MemberListModal;
