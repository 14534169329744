import { toast } from 'react-toastify';
import { find } from 'lodash';
import {
  ADD_REPOSITORY_FILE_URL,
  DELETE_REPOSITORY_FILE_URL,
  DOWNLOAD_LULC_REPO_FILE_URL,
  DOWNLOAD_REPO_FILE_URL,
  EDIT_REPOSITORY_FILE_URL,
  FILE_MAP_ACCESS_URL,
  GET_ALL_CATEGORIES_URL,
  GET_ALL_FILES_URL,
  GET_GEOSERVER_LAYER_STYLE_URL
} from 'utils/apiUrls';
import { apiCall, downloadReadableStreamFile, fileUploadApicall } from 'utils/connect';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { getAsyncStorageValue } from 'utils/localStorage';
import { Response } from 'types/common.types';
import { AppDispatch, RootState } from '..';
import {
  RepositoryFile,
  FilePayload,
  UploadFilePayload,
  FileMapAccessPayload,
  Category,
  UploadFileResponse,
  DownloadFileResponse,
  DeleteRepositoryFile,
  FilesCategory
} from './types';
import {
  setAddRequestingRepositoryFile,
  setCategories,
  setDeleteRequestingRepositoryFile,
  setEditRequestRepositoryFile,
  setFiles,
  setGeoserverLayerStyles,
  setRequestingCategories,
  setRequestingDownloadFile,
  setRequestingFileMapAccess,
  setRequestingFiles,
  setRequestingGeoserverLayerStyles
} from '.';

export const getRepositoryFiles =
  (projectId: string, token: string, callback?: (files: any) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingFiles(true));

      const onSuccess = (response: Response<Array<RepositoryFile>>) => {
        dispatch(setFiles(response.data));
        dispatch(setRequestingFiles(false));
        if (callback) callback(response.data);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getRepositoryFiles(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingFiles(false));
        }
      };

      apiCall('GET', GET_ALL_FILES_URL(Number(projectId)), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingFiles(false));
      toast.error(error.message);
    }
  };

export const editRepositoryFile =
  (
    payload: FilePayload,
    projectId: string,
    repofileId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setEditRequestRepositoryFile(true));

      const onSuccess = () => {
        // toast.success('Repository file edit successfully');
        dispatch(getRepositoryFiles(projectId, token));
        dispatch(setEditRequestRepositoryFile(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editRepositoryFile(payload, projectId, repofileId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setEditRequestRepositoryFile(false));
        }
      };

      apiCall(
        'POST',
        EDIT_REPOSITORY_FILE_URL(Number(projectId), repofileId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setEditRequestRepositoryFile(false));
      toast.error(error.message);
    }
  };

export const addRepositoryFile =
  (
    payload: UploadFilePayload,
    token: string,
    callback: Function,
    file?: File,
    lulcStyleFile?: File
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setAddRequestingRepositoryFile(true));

      const onSuccess = (response: Response<UploadFileResponse>) => {
        // toast.success('Repository file added successfully');
        dispatch(getRepositoryFiles(payload.project_id.toString(), token));
        dispatch(setAddRequestingRepositoryFile(false));
        callback(response.data);
      };
      const onFailure = (error: any) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addRepositoryFile(payload, reToken, callback, file));
          });
        } else {
          if (error && error.message) {
            toast.error(error.message);
          } else {
            toast.error('Something Went Wrong');
          }
          dispatch(setAddRequestingRepositoryFile(false));
          callback();
        }
      };

      fileUploadApicall(
        ADD_REPOSITORY_FILE_URL(payload),
        onSuccess,
        onFailure,
        file,
        lulcStyleFile,
        token
      );
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setAddRequestingRepositoryFile(false));
    }
  };

export const addFileMapAccess =
  (payload: FileMapAccessPayload, projectId: string, repofileId: number, token: string) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingFileMapAccess(true));

      const onSuccess = () => {
        // toast.success('File map access change successfully');
        dispatch(getRepositoryFiles(projectId, token));
        dispatch(setRequestingFileMapAccess(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addFileMapAccess(payload, projectId, repofileId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingFileMapAccess(false));
        }
      };

      apiCall(
        'POST',
        FILE_MAP_ACCESS_URL(Number(projectId), repofileId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingFileMapAccess(false));
      toast.error(error.message);
    }
  };

export const getCategories = (token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingCategories(true));

    const onSuccess = (response: Response<Array<Category>>) => {
      dispatch(setCategories(response.data));
      dispatch(setRequestingCategories(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getCategories(reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingCategories(false));
      }
    };

    apiCall('GET', GET_ALL_CATEGORIES_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    toast.error(error.message);
    dispatch(setRequestingCategories(false));
  }
};

export const getDownloadRepoFile =
  (projectId: number, repoFileId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDownloadFile(true));

      const onSuccess = (response: Response<DownloadFileResponse>) => {
        const link = document.createElement('a');
        link.href = response.data.url;
        link.setAttribute('download', response.data.file_name);
        document.body.appendChild(link);
        link.click();

        dispatch(setRequestingDownloadFile(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDownloadRepoFile(projectId, repoFileId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDownloadFile(false));
        }
      };

      apiCall(
        'GET',
        DOWNLOAD_REPO_FILE_URL(projectId, repoFileId),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDownloadFile(false));
      toast.error(error.message);
    }
  };

export const getDownloadLULCFile =
  (repoFileId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingDownloadFile(true));

      const onSuccess = (blob: any, filename: string) => {
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename || 'lulc.zip');
        document.body.appendChild(link);
        link.click();

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setRequestingDownloadFile(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDownloadLULCFile(repoFileId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDownloadFile(false));
        }
      };

      downloadReadableStreamFile(
        'GET',
        DOWNLOAD_LULC_REPO_FILE_URL(repoFileId.toString()),
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDownloadFile(false));
      toast.error(error.message);
    }
  };

export const deleteRepositoryFile =
  (
    payload: DeleteRepositoryFile,
    projectId: number,
    repoFileId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setDeleteRequestingRepositoryFile(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getRepositoryFiles(projectId.toString(), token));
        dispatch(setDeleteRequestingRepositoryFile(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(deleteRepositoryFile(payload, projectId, repoFileId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setDeleteRequestingRepositoryFile(false));
        }
      };

      apiCall(
        'DELETE',
        DELETE_REPOSITORY_FILE_URL(projectId, repoFileId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setDeleteRequestingRepositoryFile(false));
      toast.error(error.message);
    }
  };

export const getGeoserverLayerStyle =
  (token: string) => (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const { repositoryFiles } = state.repofiles;

    const isLULCfile = find(
      repositoryFiles,
      (file: RepositoryFile) => file.category === FilesCategory.LULC && file.enabled_for_map
    );

    if (!isLULCfile) return;

    try {
      dispatch(setRequestingGeoserverLayerStyles(true));

      const onSuccess = (response: Response<Array<Category>>) => {
        dispatch(setGeoserverLayerStyles(response.data));
        dispatch(setRequestingGeoserverLayerStyles(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getGeoserverLayerStyle(reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingGeoserverLayerStyles(false));
        }
      };

      apiCall(
        'GET',
        GET_GEOSERVER_LAYER_STYLE_URL(isLULCfile.wms_layer_name),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingGeoserverLayerStyles(false));
    }
  };
