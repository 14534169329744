import { useMemo } from 'react';
import { Button, Space, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { Project, ProjectStatus } from 'store/projects/types';
import AvatarList from 'components/Avatar/AvatarList';
import { statusClass } from 'utils/util';

interface ProjectManagementProps {
  projects: Array<Project>;
  isLoading: boolean;
  handleAddProjectMemberCallback: Function;
  handleRemoveProjectMemberCallback: Function;
}

const { Text } = Typography;

const ProjectManagement = ({
  projects,
  isLoading,
  handleAddProjectMemberCallback,
  handleRemoveProjectMemberCallback
}: ProjectManagementProps) => {
  const projectColumns: ColumnsType<Project> = useMemo(
    () => [
      {
        title: 'Project ID',
        dataIndex: 'project_id',
        key: 'id',
        render: (_, record) => <Text key={record.id}>{record.id}</Text>
      },
      {
        title: 'Name of the project',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => <Text key={record.id}>{record.name}</Text>
      },
      {
        title: 'Assigned team members',
        dataIndex: 'team_members',
        key: 'team_members',
        render: (_, record) => (
          <AvatarList
            className="p-r-8"
            record={record}
            handleAddProjectMemberForm={handleAddProjectMemberCallback}
          />
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <Text key={record.id} className={statusClass(record.status)}>
            {record.status}
          </Text>
        )
      },
      {
        title: 'Project Stage',
        dataIndex: 'in_progress_stage',
        key: 'in_progress_stage',
        render: (_, record) => {
          let stageName = record.in_progress_stage;
          if (record.status === ProjectStatus.NotStarted) {
            stageName = 'Adding Timeline ';
          }
          if (record.status === ProjectStatus.Closed) {
            stageName = '-';
          }

          return <Text key={record.id}>{stageName}</Text>;
        }
      },
      {
        title: 'Actions',
        align: 'center',
        key: 'y',
        render: (_, record) => (
          <Space size="middle" key={record.id}>
            <Button
              type="link"
              style={{ padding: '0' }}
              onClick={() => handleRemoveProjectMemberCallback(record)}
            >
              <u>remove member from project</u>
            </Button>
          </Space>
        )
      }
    ],
    []
  );
  return (
    <Table
      loading={isLoading}
      columns={projectColumns}
      dataSource={projects}
      pagination={false}
      className="userMangementTabel"
      showHeader
      sticky
      rowKey={(row: Project) => row.id}
    />
  );
};

export default ProjectManagement;
