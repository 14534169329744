import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { toast } from 'react-toastify';
import { Response } from 'types/common.types';
import { ANALYTICS_UNIT_COST_URL, GET_PROJECT_ANALYTICS_URL } from 'utils/apiUrls';
import { apiCall } from 'utils/connect';
import { getAsyncStorageValue } from 'utils/localStorage';
import {
  setAnalytics,
  setRequestingAddAnalyticsCost,
  setRequestingAnalytics,
  setRequestingEditAnalyticsCost
} from '.';
import { AppDispatch } from '..';
import { AnalyticsType } from './types';

export const getProjectAnalytics =
  (projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAnalytics(true));

      const onSuccess = (response: Response<AnalyticsType>) => {
        dispatch(setAnalytics(response.data));
        dispatch(setRequestingAnalytics(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getProjectAnalytics(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAnalytics(false));
        }
      };

      apiCall('GET', GET_PROJECT_ANALYTICS_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingAnalytics(false));
    }
  };

export const addAnalyticsUnitCost =
  (payload: any, projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAddAnalyticsCost(true));

      const onSuccess = (response: Response<any>) => {
        dispatch(getProjectAnalytics(projectId, token));
        dispatch(setRequestingAddAnalyticsCost(false));
        if (callback) callback(response.status);
        toast.success('Added successfully');
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addAnalyticsUnitCost(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAddAnalyticsCost(false));
        }
      };

      apiCall('POST', ANALYTICS_UNIT_COST_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingAddAnalyticsCost(false));
      toast.error(error.message);
    }
  };

export const editAnalyticsUnitCost =
  (payload: any, projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingEditAnalyticsCost(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getProjectAnalytics(projectId, token));
        dispatch(setRequestingEditAnalyticsCost(false));
        if (callback) callback(response.status);
        toast.success('Updated successfully');
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editAnalyticsUnitCost(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingEditAnalyticsCost(false));
        }
      };

      apiCall('PUT', ANALYTICS_UNIT_COST_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingEditAnalyticsCost(false));
      toast.error(error.message);
    }
  };
