import { useState } from 'react';
import { Button, Dropdown, Modal, Radio, RadioChangeEvent, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import DownArrowIcon from 'assets/svg/DownArrowIcon';
import { lightDarkGrey2 } from 'constants/theme.constants';
import { DateFormat3, DateFormat2, ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch } from 'hooks/useReduxHook';
import {
  ProjectStage,
  ProjectStagesList,
  ProjectTimeline,
  ProposedTimelineStatus
} from 'store/projects/types';
import { addProjectStageChangeStatus } from 'store/projects/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import AddTimeLineModal from '../Modal/SuperAdmin/ProposedTimeline/AddTimeLineModal';

const { Text, Link } = Typography;
interface SuperAdminProposedTimelineProps {
  project: ProjectTimeline;
  isRequestProject: boolean;
}

const SuperAdminProposedTimeline = ({
  project,
  isRequestProject
}: SuperAdminProposedTimelineProps) => {
  const dispatch = useAppDispatch();
  const [isOpenAddTimeLineModal, setAddTimeLineModal] = useState(false);
  const isDisabledChangeStatus = project
    ? project.stages.find((stage) => stage.stage_name === ProjectStagesList.AOIFinalization)
        ?.status !== ProposedTimelineStatus.Completed
    : false;

  const handleChangeStatus = (e: RadioChangeEvent, record: ProjectStage) => {
    const statusName = e.target.value;
    const payload = {
      stage: record.stage_name,
      project_id: project.id.toString(),
      status: statusName
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addProjectStageChangeStatus(payload, token));
    });
  };

  const statusDropdown = (record: ProjectStage) => {
    return (
      <Radio.Group
        onChange={(e) => handleChangeStatus(e, record)}
        value={record.status}
        className="project-status-dd"
      >
        <Space direction="vertical">
          <Radio value={ProposedTimelineStatus.OnGoing}>{ProposedTimelineStatus.OnGoing}</Radio>
          <Radio value={ProposedTimelineStatus.Completed}>{ProposedTimelineStatus.Completed}</Radio>
        </Space>
      </Radio.Group>
    );
  };

  const statusStartedDropdown = (record: ProjectStage) => {
    return (
      <Radio.Group
        onChange={(e) => handleChangeStatus(e, record)}
        value={record.status}
        className="project-status-dd"
      >
        <Space direction="vertical">
          <Radio value={ProposedTimelineStatus.NotStarted}>
            {ProposedTimelineStatus.NotStarted}
          </Radio>
          <Radio value={ProposedTimelineStatus.Completed}>{ProposedTimelineStatus.Completed}</Radio>
        </Space>
      </Radio.Group>
    );
  };

  const columns: ColumnsType<ProjectStage> = [
    {
      title: 'Scheduled on',
      dataIndex: 'deadline',
      key: 'date',
      align: 'right',
      width: '100px',
      render: (text, record) => (
        <Text key={record.id} className="scheduleColumn">
          {moment(new Date(text)).format(DateFormat2)}
        </Text>
      )
    },
    {
      title: '',
      className: 'mainEmptyCol',
      dataIndex: '',
      key: 'empty',
      width: '40px',
      render: (_, record) => {
        let conClass;
        if (record.status === ProposedTimelineStatus.Completed) {
          conClass = 'completeContent';
        } else if (
          record.status === ProposedTimelineStatus.InProgress ||
          record.status === ProposedTimelineStatus.OnGoing ||
          record.status === ProposedTimelineStatus.Started
        ) {
          conClass = '';
        } else if (record.status === ProposedTimelineStatus.NotStarted) {
          conClass = 'notStartedPendingCon';
        } else if (record.status === null) {
          conClass = 'completePendingCon';
        }
        if (project?.stages.length < 1) {
          conClass = 'completeContent row-empty-content';
        }
        if (record.stage_name === ProjectStagesList.RouteFinalization) {
          conClass += ' row-empty-content';
        }

        return (
          <div key={record.id} className="emptyColum">
            <div className={`roundContent ${conClass}`} />
          </div>
        );
      }
    },
    {
      title: 'Project Stages',
      dataIndex: 'stage_name',
      key: 'stage_name',
      width: '50%',
      render: (_, record) => {
        let changeClass = '';
        let stageName = record.stage_name;
        if (record.status) {
          changeClass = ' complete-stage';
        }

        if (record.stage_name === ProjectStagesList.DefiningCoordinates) {
          stageName = 'Defining coordinates';
        }

        return (
          <Link key={record.id} className={`projectStage${changeClass}`}>
            {stageName}
          </Link>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '180px',
      render: (_, record) => {
        let statusName = record.status;
        let data;

        if (record.id === 0) {
          statusName = 'Completed';

          data = (
            <Text italic key={record.id} className="approvalStatus">
              {statusName}
            </Text>
          );
        }

        if (record.status === ProposedTimelineStatus.Completed) {
          data = (
            <Text italic key={record.id} className="approvalStatus">
              {statusName}
            </Text>
          );
        }

        if (
          record.stage_name === ProjectStagesList.DefiningCoordinates &&
          record.status === ProposedTimelineStatus.NotStarted
        ) {
          data = (
            <Text key={record.id} className="approvalStatus">
              {' '}
            </Text>
          );
        }

        if (
          record.stage_name === ProjectStagesList.DefiningCoordinates &&
          record.status === ProposedTimelineStatus.Completed
        ) {
          data = (
            <Text italic key={record.id} className="approvalStatus">
              Approved By Admin
            </Text>
          );
        }

        if (
          record.stage_name === ProjectStagesList.AOIRequest &&
          record.status === ProposedTimelineStatus.NotStarted
        ) {
          data = (
            <Text italic key={record.id} className="approvalStatus">
              Waiting for Approval
            </Text>
          );
        }

        if (
          record.stage_name === ProjectStagesList.AOIFinalization &&
          record.status === ProposedTimelineStatus.Completed
        ) {
          data = (
            <Text italic key={record.id} className="approvalStatus">
              Approved By Admin
            </Text>
          );
        }

        if (
          (record.stage_name === ProjectStagesList.LULCLow ||
            record.stage_name === ProjectStagesList.AOIGeneration ||
            record.stage_name === ProjectStagesList.TaskingHigh ||
            record.stage_name === ProjectStagesList.ProcurementHigh ||
            record.stage_name === ProjectStagesList.PreProcessingHigh ||
            record.stage_name === ProjectStagesList.DEMGeneration ||
            record.stage_name === ProjectStagesList.LULCHigh ||
            record.stage_name === ProjectStagesList.PreliminaryRoute ||
            record.stage_name === ProjectStagesList.RouteFinalization) &&
          record.status === ProposedTimelineStatus.NotStarted
        ) {
          data = (
            <Dropdown overlay={statusDropdown(record)} trigger={['click']}>
              <a className="ant-dropdown-link change-status-dd">
                <Text className="change-status-title" italic>
                  Change Status
                </Text>
                <DownArrowIcon color={lightDarkGrey2} width={7.5} height={4.5} />
              </a>
            </Dropdown>
          );
        }

        if (
          (record.stage_name === ProjectStagesList.LULCLow ||
            record.stage_name === ProjectStagesList.AOIGeneration) &&
          isEmpty(record.status)
        ) {
          data = (
            <Dropdown overlay={statusDropdown(record)} trigger={['click']}>
              <a className="ant-dropdown-link change-status-dd">
                <Text className="change-status-title" italic>
                  Change Status
                </Text>
                <DownArrowIcon color={lightDarkGrey2} width={7.5} height={4.5} />
              </a>
            </Dropdown>
          );
        }

        if (
          isEmpty(record.status) &&
          (record.stage_name === ProjectStagesList.TaskingHigh ||
            record.stage_name === ProjectStagesList.ProcurementHigh ||
            record.stage_name === ProjectStagesList.PreProcessingHigh ||
            record.stage_name === ProjectStagesList.DEMGeneration ||
            record.stage_name === ProjectStagesList.LULCHigh ||
            record.stage_name === ProjectStagesList.PreliminaryRoute ||
            record.stage_name === ProjectStagesList.RouteFinalization)
        ) {
          data = (
            <Dropdown
              disabled={isDisabledChangeStatus}
              overlay={statusDropdown(record)}
              trigger={['click']}
            >
              <a className="ant-dropdown-link change-status-dd">
                <Text className="change-status-title" italic>
                  Change Status
                </Text>
                <DownArrowIcon color={lightDarkGrey2} width={7.5} height={4.5} />
              </a>
            </Dropdown>
          );
        }

        if (
          record.status === ProposedTimelineStatus.OnGoing ||
          record.status === ProposedTimelineStatus.Started
        ) {
          data = (
            <Dropdown overlay={statusStartedDropdown(record)} trigger={['click']}>
              <a className="ant-dropdown-link change-status-dd">
                <Text
                  italic
                  className={`change-status-title ${record.status ? 'status-change-clr' : ''}`}
                >
                  {record.status}
                </Text>
                <DownArrowIcon color={lightDarkGrey2} width={7.5} height={4.5} />
              </a>
            </Dropdown>
          );
        }

        if (
          record.stage_name === ProjectStagesList.DefiningCoordinates &&
          record.status === ProposedTimelineStatus.OnGoing
        ) {
          data = (
            <Text italic key={record.id} className="approvalStatus">
              {statusName}
            </Text>
          );
        }

        if (
          isEmpty(record.status) &&
          (record.stage_name === ProjectStagesList.AOIFinalization ||
            record.stage_name === ProjectStagesList.AOIRequest)
        ) {
          data = (
            <Text key={record.id} className="approvalStatus" italic>
              {' '}
            </Text>
          );
        }
        return <div>{data}</div>;
      }
    },
    {
      title: 'Delays',
      dataIndex: 'delays',
      key: 'delays',
      width: '180px',
      render: (_, record) => {
        let delayClass = '';
        let text = '';

        if (record.status === ProposedTimelineStatus.Completed && record.completion_date) {
          if (new Date(record.completion_date).getTime() === new Date(record.deadline).getTime()) {
            delayClass = 'completeDelayContent';
            text = 'On Time';
          } else if (
            new Date(record.completion_date).getTime() <= new Date(record.deadline).getTime()
          ) {
            delayClass = 'completeDelayContent';
            text = 'On Time';
          } else if (
            new Date(record.completion_date).getTime() > new Date(record.deadline).getTime()
          ) {
            const differenceInTime =
              new Date(record.completion_date).getTime() - new Date(record.deadline).getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            delayClass = 'pendingDelayCon';
            text = `Delayed by ${differenceInDays} days`;
          }
        } else if (
          record.status !== ProposedTimelineStatus.Completed &&
          new Date().getTime() > new Date(record.deadline).getTime()
        ) {
          const differenceInTime = new Date().getTime() - new Date(record.deadline).getTime();
          const differenceInDays = (differenceInTime / (1000 * 3600 * 24)).toFixed(0);
          if (Number(differenceInDays) > 0) {
            delayClass = 'pendingDelayCon';
            text = `Delayed by ${differenceInDays} days`;
          }
        } else {
          delayClass = '';
          text = '';
        }

        return (
          <Text key={record.id} className={delayClass}>
            {text}
          </Text>
        );
      }
    }
  ];

  const initialStage: Array<ProjectStage> = [
    {
      completion_date: project.created_at ? moment(project.created_at).format(DateFormat3) : null,
      deadline: project.created_at ? moment(project.created_at).format(DateFormat3) : '',
      id: 0,
      stage_name: 'Project Creation',
      status: ProposedTimelineStatus.Completed
    }
  ];

  const handleAddTimeline = () => {
    setAddTimeLineModal(true);
  };

  const handleTimelineCancel = () => {
    setAddTimeLineModal(false);
  };

  return (
    <>
      <div className="mainTimeLine">
        <div className="table-content">
          <Table
            loading={isRequestProject}
            className="timelineTable"
            columns={columns}
            dataSource={initialStage.concat(orderBy(project?.stages, ['id'], ['asc']))}
            pagination={false}
            sticky
            tableLayout="fixed"
            rowClassName="mainRowContent"
            rowKey={(row) => row.id}
          />
        </div>
      </div>
      {project?.stages.length <= 0 && (
        <Button className="addtimeline-btn" type="primary" onClick={handleAddTimeline}>
          Add Timeline
        </Button>
      )}
      <Modal
        className="add-timeline-modal"
        title="Add Timeline"
        centered
        width={837}
        open={isOpenAddTimeLineModal}
        footer={null}
        onCancel={() => setAddTimeLineModal(false)}
      >
        <AddTimeLineModal handleTimelineCancel={handleTimelineCancel} />
      </Modal>
    </>
  );
};

export default SuperAdminProposedTimeline;
