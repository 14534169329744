import { createSelector } from '@reduxjs/toolkit';
import { filter, find, orderBy } from 'lodash';
import { LulcDataType, LulcStyleType, superAdminFileCategories } from 'constants/map.constants';
import { RootState } from '..';
import { repofileSlice } from './slice';
import { Category, RepositoryFile, FilesCategory } from './types';

export const {
  setRequestingFiles,
  setFiles,
  setEditRequestRepositoryFile,
  setAddRequestingRepositoryFile,
  setRequestingFileMapAccess,
  setRequestingCategories,
  setCategories,
  setRequestingDownloadFile,
  setDeleteRequestingRepositoryFile,
  setRequestingGeoserverLayerStyles,
  setGeoserverLayerStyles
} = repofileSlice.actions;
export default repofileSlice.reducer;

export const selectStartRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return filter(repositoryFiles, {
    category: FilesCategory.Start,
    enabled_for_map: true
  });
};

export const selectEndRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return filter(repositoryFiles, {
    category: FilesCategory.End,
    enabled_for_map: true
  });
};

export const selectBeeRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return filter(repositoryFiles, {
    category: FilesCategory.Bee,
    enabled_for_map: true
  });
};

export const getLULCRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) => file.category === FilesCategory.LULC && file.enabled_for_map
  );
};

export const getDEMRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) => file.category === FilesCategory.DEM && file.enabled_for_map
  );
};

export const getDSMRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) => file.category === FilesCategory.DSM && file.enabled_for_map
  );
};

export const getHighResolutionSataliteImagesRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) =>
      file.category === FilesCategory.HighResolutionSataliteImages && file.enabled_for_map
  );
};

export const getAdminManagerCategory = (state: RootState) => {
  const { categories } = state.repofiles;

  return categories.filter((cat: Category) => {
    return !superAdminFileCategories.includes(cat.category_name);
  });
};

const selectedGeoserverLayerStyles = (state: RootState) => state.repofiles.geoserverLayerStyles;
export const getLulcStyleLayers = createSelector(
  [selectedGeoserverLayerStyles],
  (geoserverLayerStyles): LulcDataType[] => {
    if (!geoserverLayerStyles) return [];
    const layers = geoserverLayerStyles.map((style: LulcStyleType) => {
      return { title: style.label, value: style.env, color: style.color };
    });

    return orderBy(layers, ['title'], ['asc']);
  }
);
