import { createSlice } from '@reduxjs/toolkit';
import { RepositoryFileState } from './types';

const initialState: RepositoryFileState = {
  isRequestingFiles: false,
  repositoryFiles: [],
  isRequestingEditRepoFile: false,
  isRequestingAddRepoFile: false,
  isRequestingFileMapAccess: false,
  isRequestingCategory: false,
  isRequestingDownloadFile: false,
  isRequestingDeleteFile: false,
  categories: [],
  isRequestingGeoserverLayerStyles: false,
  geoserverLayerStyles: []
};

export const repofileSlice = createSlice({
  name: 'repofiles',
  initialState,
  reducers: {
    setRequestingFiles: (state, action) => {
      state.isRequestingFiles = action.payload;
    },
    setFiles: (state, action) => {
      state.repositoryFiles = action.payload;
    },
    setEditRequestRepositoryFile: (state, action) => {
      state.isRequestingEditRepoFile = action.payload;
    },
    setAddRequestingRepositoryFile: (state, action) => {
      state.isRequestingAddRepoFile = action.payload;
    },
    setRequestingFileMapAccess: (state, action) => {
      state.isRequestingFileMapAccess = action.payload;
    },
    setRequestingCategories: (state, action) => {
      state.isRequestingCategory = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setRequestingDownloadFile: (state, action) => {
      state.isRequestingDownloadFile = action.payload;
    },
    setDeleteRequestingRepositoryFile: (state, action) => {
      state.isRequestingDeleteFile = action.payload;
    },
    setRequestingGeoserverLayerStyles: (state, action) => {
      state.isRequestingGeoserverLayerStyles = action.payload;
    },
    setGeoserverLayerStyles: (state, action) => {
      state.geoserverLayerStyles = action.payload;
    }
  }
});
