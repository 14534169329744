import { Input, Typography } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { analyticsFormKey, getCost, getNumber } from 'utils/util';

interface SoftCostTableProps {
  featureCostRoute: any;
  unitCost: any;
  softCostKey: any;
}

const { Text } = Typography;

const SoftCostTable = ({ featureCostRoute, unitCost, softCostKey }: SoftCostTableProps) => {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <Text className="title-span">Features</Text>
          </th>
          <th>
            <Text className="title-span">Unit cost / km</Text>
          </th>
          <th>
            <Text className="title-span">Feature Length</Text>
          </th>
          <th>
            <Text className="title-span">Total Cost</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {!isEmpty(softCostKey) &&
          softCostKey.map((featureRoute: string) => {
            const unitCostKey: string = analyticsFormKey(featureRoute);
            const unitCostValue =
              !isEmpty(unitCost) && unitCost[unitCostKey] ? unitCost[unitCostKey] : 0;
            const featureValue =
              featureCostRoute && featureCostRoute[featureRoute]
                ? getNumber(featureCostRoute[featureRoute])
                : 0;

            return (
              <tr key={unitCostKey}>
                <td>
                  <Text>{capitalize(featureRoute)}</Text>
                </td>
                <td>
                  <Text>{unitCostValue}</Text>
                </td>
                <td>
                  <Text>{featureValue}</Text>
                </td>
                <td>
                  <Text>
                    <Input value={getCost(unitCostValue, featureValue).toFixed(2)} readOnly />
                  </Text>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default SoftCostTable;
