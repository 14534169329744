/* eslint-disable */
import 'leaflet-kml';
import { useEffect, memo, useRef, useMemo } from 'react';
import { CircleMarker, Polygon, Polyline, useMap } from 'react-leaflet';
import L from 'leaflet';
import { filter, includes } from 'lodash';
import { RepositoryFile } from 'store/repositoryfile/types';

interface RenderVectorLayerProps {
  vectorRepoFiles: Array<RepositoryFile>;
  vectorLayerIds: Array<number>;
}

const RenderVectorLayer = ({ vectorRepoFiles, vectorLayerIds }: RenderVectorLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const vectorLayerGroup = L.featureGroup();

  useEffect(() => {
    // if (currentLayer.current) currentLayer.current.clearLayers();
    // if (vectorLayerIds.length === 0) return;
    // const vectorLayerFiles = filter(vectorRepoFiles, (file: RepositoryFile) =>
    //   includes(vectorLayerIds, file.id)
    // );
    // vectorLayerFiles.forEach((file: RepositoryFile) => {
    //   fetch(file.url)
    //     .then((res) => res.text())
    //     .then((kmltext) => {
    //       const parser = new DOMParser();
    //       const kml = parser.parseFromString(kmltext, 'text/xml');
    //       // @ts-ignore
    //       const track = new L.KML(kml);
    //       vectorLayerGroup.addLayer(track);
    //       track.setStyle({ color: file.color, fillColor: file.color, fillOpacity: 0.4 });
    //     })
    //     .then(() => {
    //       vectorLayerGroup.addTo(map);
    //     });
    // });
    // currentLayer.current = vectorLayerGroup;
  }, [vectorLayerIds]);

  const allVectorLayers = useMemo(() => {
    if (vectorLayerIds.length === 0) return null;

    const vectorLayerFiles = filter(vectorRepoFiles, (file: RepositoryFile) =>
      includes(vectorLayerIds, file.id)
    );

    return vectorLayerFiles.map((file, i) => {
      if (file.shapType === 'circle') {
        return (
          file.coordinates &&
          file.coordinates.map((coord, j) => {
            return (
              <CircleMarker
                key={j}
                center={coord[0]}
                pane="shadowPane"
                radius={3}
                pathOptions={{
                  color: file.color,
                  fillColor: file.color
                }}
              />
            );
          })
        );
      } else if (file.shapType === 'polygon') {
        return (
          <Polygon
            key={i}
            positions={file.coordinates ? file.coordinates : []}
            pathOptions={{
              color: file.color,
              fillColor: file.color,
              fillOpacity: 0.3,
              weight: 1
            }}
            pane="shadowPane"
          />
        );
      }

      return (
        <Polyline
          key={i}
          pane="shadowPane"
          positions={file.coordinates ? file.coordinates : []}
          pathOptions={{ color: file.color, fillColor: file.color }}
        />
      );
    });
  }, [vectorLayerIds]);

  return <>{allVectorLayers}</>;
};

export default memo(RenderVectorLayer);
