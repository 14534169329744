import { Button, Divider, Typography } from 'antd';
import { useAppSelector } from 'hooks/useReduxHook';
import eyeOpen from 'assets/svg/EyeOpenIcon.svg';
import eyeClose from 'assets/svg/EyeCloseIcon.svg';
import CloseIcon from 'assets/svg/CloseIcon';
import { DEMDataType, dsmStyleLayers, DSM_JET, DSM_MONOCHROME } from 'constants/route.constant';
import MonochromeLegend from 'components/core/MapLegend/MonochromeLegend';
import JetLegend from 'components/core/MapLegend/JetLegend';
import { selectDsmElevetion } from 'store/route';

interface DemLegendProps {
  selectedDEMStyle: string | null;
  handleDemStyle: Function;
  handleDSMLegend: Function;
}

const { Text } = Typography;

const DsmLegend = ({ handleDemStyle, selectedDEMStyle, handleDSMLegend }: DemLegendProps) => {
  const dsmElevetion: number[] = useAppSelector(selectDsmElevetion);

  return (
    <div className="dem-main-content">
      <div className="dem-header">
        <Text>DSM</Text>
        <CloseIcon height={12} width={12} onClick={() => handleDSMLegend(false)} />
      </div>
      <Divider className="dem-divider" />
      {dsmStyleLayers.length > 0 &&
        dsmStyleLayers.map((layer: DEMDataType) => {
          return (
            <div className="inr-dem-content" key={layer.value}>
              <div>
                <Text>{layer.title}</Text>
                <Button
                  className="show-dem-btn"
                  type="ghost"
                  onClick={() => handleDemStyle(layer.value)}
                >
                  <img
                    src={selectedDEMStyle === layer.value ? eyeOpen : eyeClose}
                    alt="eyeopen"
                    width={18}
                    height={15}
                  />
                </Button>
              </div>
              {selectedDEMStyle &&
                selectedDEMStyle === layer.value &&
                selectedDEMStyle === DSM_MONOCHROME && (
                  <MonochromeLegend elevetion={dsmElevetion} />
                )}
              {selectedDEMStyle &&
                selectedDEMStyle === layer.value &&
                selectedDEMStyle === DSM_JET && <JetLegend elevetion={dsmElevetion} />}
            </div>
          );
        })}
    </div>
  );
};

export default DsmLegend;
