import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const SandWatchIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22039 5.90596L3.32965 6.00009L3.22039 6.09423C2.06806 7.08707 1.10997 8.28513 0.394798 9.62757C0.114152 10.1544 0.462578 10.7967 1.0572 10.8486L1.74176 10.9085C3.24433 11.0399 4.75549 11.0399 6.25806 10.9085L6.94262 10.8486C7.53724 10.7967 7.88567 10.1544 7.60502 9.62757C6.88985 8.28513 5.93176 7.08707 4.77943 6.09423L4.67017 6.00009L4.77943 5.90596C5.93176 4.91312 6.88985 3.71506 7.60502 2.37262C7.88567 1.84583 7.53724 1.20353 6.94262 1.15155L6.25806 1.09169C4.75549 0.960321 3.24433 0.960321 1.74176 1.09169L1.0572 1.15155C0.462578 1.20353 0.114152 1.84583 0.394798 2.37262C1.10997 3.71506 2.06806 4.91312 3.22039 5.90596ZM3.99988 4.68759C3.88385 4.68759 3.77257 4.6415 3.69052 4.55945L2.52385 3.39279C2.39873 3.26766 2.3613 3.07949 2.42902 2.916C2.49673 2.75252 2.65626 2.64593 2.83321 2.64593H5.16655C5.3435 2.64593 5.50303 2.75252 5.57074 2.916C5.63846 3.07949 5.60103 3.26766 5.47591 3.39279L4.30924 4.55945C4.22719 4.6415 4.11591 4.68759 3.99988 4.68759ZM3.99988 7.31259C3.88385 7.31259 3.77257 7.35869 3.69052 7.44073L2.52385 8.6074C2.39873 8.73253 2.3613 8.9207 2.42902 9.08418C2.49673 9.24767 2.65626 9.35426 2.83321 9.35426H5.16655C5.3435 9.35426 5.50303 9.24767 5.57074 9.08418C5.63846 8.9207 5.60103 8.73253 5.47591 8.6074L4.30924 7.44073C4.22719 7.35869 4.11591 7.31259 3.99988 7.31259Z"
        fill={color}
      />
    </svg>
  );
};
SandWatchIcon.defaultProps = {
  color: white,
  height: 16,
  width: 16
};

export default SandWatchIcon;
