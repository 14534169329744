import { EditStagePayload } from 'types/project.types';

export const projectStages: any = {
  'Defining Start and End Points': 'defining_coordinates',
  'Request for AOI': 'AOI_request',
  'LULC classification with low resolution data': 'LULC_classification_low',
  'AOI Generation': 'AOI_creation',
  'AOI finalization': 'AOI_finalization',
  'Tasking for High resolution satellite images': 'tasking_high_resolution',
  'Procurement of High resolution satellite images': 'procurement_high_resolution',
  'Pre-processing of High resolution satellite images': 'pre_processing_high_resolution',
  'DEM generation': 'DEM_generation',
  'LULC classification with high resolution data': 'LULC_classification_high',
  'Preliminary route Generation': 'preliminary_route',
  'Final Route Generation': 'route_finalization'
};

export const projectStagesPayload = {
  creation_date: '',
  defining_coordinates: '',
  AOI_request: '',
  LULC_classification_low: '',
  AOI_creation: '',
  AOI_finalization: '',
  tasking_high_resolution: '',
  procurement_high_resolution: '',
  pre_processing_high_resolution: '',
  DEM_generation: '',
  LULC_classification_high: '',
  preliminary_route: '',
  route_finalization: ''
};

export const editStagesPayload: Array<EditStagePayload> = [
  { id: 0, name: 'creation_date' },
  { id: 1, name: 'defining_coordinates' },
  { id: 2, name: 'AOI_request' },
  { id: 3, name: 'LULC_classification_low' },
  { id: 4, name: 'AOI_creation' },
  { id: 5, name: 'AOI_finalization' },
  { id: 6, name: 'tasking_high_resolution' },
  { id: 7, name: 'procurement_high_resolution' },
  { id: 8, name: 'pre_processing_high_resolution' },
  { id: 9, name: 'DEM_generation' },
  { id: 10, name: 'LULC_classification_high' },
  { id: 11, name: 'preliminary_route' },
  { id: 12, name: 'route_finalization' }
];

export const taglineColors: any = {
  default: '#d60000',
  'Add Project Timeline': '#d60000',
  'Initiate low res LULC Classification': '#d60000',
  'Initiate AOI generation': '#d60000',
  'Initiate tasking of images': '#d60000',
  'Define start-end points': '#d60000',
  'Approval for coordinates pending': '#d60000',
  'Coordinates declined. Define new coordinates': '#d60000',
  'Coordinates Approved': '#d60000',
  'Select AOI': '#d60000',
  'Approval for AOI pending': '#d60000',
  'AOI declined. Select new AOI': '#d60000',
  'AOI Approved': '#d60000',
  'Satellite Images Procured': '#d60000',
  'Routes Submitted': '#10af46'
};
