import { Layout } from 'antd';
import ScreenLoader from 'components/FullScreenLoader/ScreenLoader';
import MainHeader from 'components/MainHeader/MainHeader';
import { useAppSelector } from 'hooks/useReduxHook';
import './styles.less';

const { Header } = Layout;

const HeaderLayout = () => {
  const { isLoadingLogout } = useAppSelector((state) => state.user);
  return (
    <Header className="mainHeaderContent">
      <MainHeader />
      {isLoadingLogout && <ScreenLoader isLoading={isLoadingLogout} />}
    </Header>
  );
};

export default HeaderLayout;
