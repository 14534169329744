import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { RepositoryFile } from 'store/repositoryfile/types';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';
import 'scripts/wmts';

interface SateliteImageTileLayerProps {
  repoFile: RepositoryFile;
}

const SateliteImageTileLayer = ({ repoFile }: SateliteImageTileLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    const wmtsParams = {
      layer: `skies_htl:${repoFile.wms_layer_name!}`,
      style: '',
      format: 'image/png8',
      transparent: true,
      maxZoom: 15,
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);
    currentLayer.current = wmsLayerGroup;

    // eslint-disable-next-line
    return () => {
      currentLayer.current.clearLayers();
    };
  }, [repoFile]);

  return null;
};

export default SateliteImageTileLayer;
