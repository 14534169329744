import { decode } from 'js-base64';
import keypair from 'keypair';
import { JSEncrypt } from 'jsencrypt';
import { toast } from 'react-toastify';
import { AES_KEY } from 'constants/common.constant';
import { AES_KEY_URL } from 'utils/apiUrls';
import { getStorageValue } from 'utils/localStorage';
import { setStorageValue } from './localStorage';

const crypto = require('crypto');
const CryptoJS = require('crypto-js');

let header: any = {
  'Content-Type': 'application/json',
  accept: 'application/json',
  'access-control-allow-credentials': true
};

export async function decryptRsa(token: string) {
  const pair = keypair();
  const publicKey = {
    key: encodeURIComponent(pair.public)
  };

  header = { ...header, Authorization: token };

  const formData = {
    method: 'POST',
    headers: header,
    body: JSON.stringify(publicKey)
  };

  return fetch(AES_KEY_URL, formData)
    .then((response) => {
      return response
        .json()
        .then((responseJson) => {
          const dataEnc = responseJson.data;
          const decrypt = new JSEncrypt();
          decrypt.setPrivateKey(pair.private);
          const plainText = decrypt.decrypt(dataEnc) || 'DECRYPTION FAILED';
          setStorageValue(AES_KEY, plainText);
          return plainText;
        })
        .catch((error) => {
          toast.error(error.message);
        });
    })
    .catch((error) => {
      toast.error(error.message);
    });
}

export function decryptStatic(bs4Text: string, plainText: string) {
  const ciphertext = CryptoJS.enc.Base64.parse(bs4Text);

  // split iv and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  const key = CryptoJS.enc.Utf8.parse(plainText);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
    iv
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function decrypt(bs4Text: string, plainText: string) {
  const ciphertext = CryptoJS.enc.Base64.parse(bs4Text);

  // split iv and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  const key = CryptoJS.enc.Utf8.parse(plainText);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
    iv
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encryptStatic(text: string, plainText: string) {
  const key = CryptoJS.enc.Utf8.parse(plainText);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv
  });

  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export function encrypt(text: string, plainText: string) {
  const key = CryptoJS.enc.Utf8.parse(plainText);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv
  });

  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export const rsaEnc = (data: any, key: string | null) => {
  const publicKeyValue = decode(key!);
  const encryptedData = crypto.publicEncrypt(
    {
      key: publicKeyValue,
      padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
      oaepHash: 'sha256'
    },
    // We convert the data string to a buffer using `Buffer.from`
    Buffer.from(data)
  );

  return encryptedData.toString('base64');
};

export const encryptValue = (string: string) => {
  const aesKey = getStorageValue(AES_KEY);
  return encryptStatic(string, aesKey);
};

export const decryptValue = (string: string) => {
  const aesKey = getStorageValue(AES_KEY);
  return decryptStatic(string, aesKey);
};
