import { LoadingOutlined } from '@ant-design/icons';
import { Space, Switch, Tooltip, Typography } from 'antd';
import DeleteIcon from 'assets/svg/DeleteIcon';
import DownloadIcon from 'assets/svg/DownloadIcon';
import EditFileIcon from 'assets/svg/EditFileIcon';
import { grey, lightDarkGrey, primary, white } from 'constants/theme.constants';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';

interface AdminFileActionsProps {
  record: RepositoryFile;
  handleEditRepoFile: Function;
  handleDownloadFile: Function;
  handleEnableforMap: Function;
  handleDeleteRouteFeatureList: Function;
  isRequestingDownloadFile: boolean;
  downloadFileId: number | null;
  userRole: UserRole;
}

const { Text } = Typography;

const AdminFileActions = ({
  record,
  handleEditRepoFile,
  handleDownloadFile,
  handleEnableforMap,
  handleDeleteRouteFeatureList,
  userRole,
  isRequestingDownloadFile,
  downloadFileId
}: AdminFileActionsProps) => {
  return (
    <Space size="middle">
      <button
        onClick={() => handleEditRepoFile(record)}
        type="button"
        className="edit-file-btn"
        disabled={!(record.user_role === userRole || record.user_role === UserRole.Manager)}
      >
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={<Text>Edit</Text>}
        >
          <EditFileIcon
            color={
              !(record.user_role === userRole || record.user_role === UserRole.Manager)
                ? `${lightDarkGrey}`
                : primary
            }
          />
        </Tooltip>
      </button>
      <Tooltip
        className="switchAction"
        placement="bottom"
        color={white}
        title={<Text>Download</Text>}
      >
        <a onClick={() => handleDownloadFile(record.id, record.wms_layer_name)}>
          {isRequestingDownloadFile && downloadFileId === record.id ? (
            <LoadingOutlined />
          ) : (
            <DownloadIcon />
          )}
        </a>
      </Tooltip>
      {record.category === FilesCategory.RoutesFeatureList && (
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={userRole === UserRole.SuperAdmin ? <Text>Delete</Text> : ''}
        >
          {userRole === UserRole.SuperAdmin ? (
            <div
              onClick={() => handleDeleteRouteFeatureList(record)}
              style={{ cursor: 'pointer', marginTop: '5px' }}
            >
              <DeleteIcon height={20} width={20} />
            </div>
          ) : (
            <div style={{ cursor: 'not-allowed', marginTop: '5px' }}>
              <DeleteIcon height={20} width={20} color={grey} />
            </div>
          )}
        </Tooltip>
      )}
      {record.category !== FilesCategory.RoutesFeatureList && (
        <Tooltip
          className="switchAction"
          placement="bottomRight"
          color={white}
          title={<Text>Add or remove layer from map</Text>}
        >
          <Switch
            disabled={!(record.user_role === userRole || record.user_role === UserRole.Manager)}
            defaultChecked={record.enabled_for_map}
            size="small"
            className={`cheakedBtn${
              !(record.user_role === userRole || record.user_role === UserRole.Manager)
                ? ' disable-check-btn'
                : ''
            }`}
            onChange={(check) => handleEnableforMap(check, record.id)}
          />
        </Tooltip>
      )}
    </Space>
  );
};

export default AdminFileActions;
