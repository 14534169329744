import { createSlice } from '@reduxjs/toolkit';
import { RouteState } from './types';

export const initialState: RouteState = {
  isRequestingRouteFiles: false,
  routes: [],
  isRequestingDemElevetion: false,
  demElevetion: null,
  isRequestingDsmElevetion: false,
  dsmElevetion: null
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setRequestingRouteFiles: (state, action) => {
      state.isRequestingRouteFiles = action.payload;
    },
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
    setRequestingDemElevetion: (state, action) => {
      state.isRequestingDemElevetion = action.payload;
    },
    setDemElevetion: (state, action) => {
      state.demElevetion = action.payload;
    },
    setRequestingDsmElevetion: (state, action) => {
      state.isRequestingDsmElevetion = action.payload;
    },
    setDsmElevetion: (state, action) => {
      state.dsmElevetion = action.payload;
    }
  }
});
