import moment from 'moment';
import { DatePicker, Typography } from 'antd';
import type { DatePickerProps } from 'antd';
import { DateFormat1 } from 'constants/common.constant';

const { Text } = Typography;

interface ProjectStageProps {
  label: string;
  value: moment.Moment | null;
  name: string;
  isDisable: boolean;
  defaultDate: moment.Moment;
  defaultPickerDate: moment.Moment;
  handleOnChange: (name: string, date: string) => void;
}

const ProjectStage = ({
  label,
  value = null,
  name,
  isDisable,
  defaultDate,
  defaultPickerDate,
  handleOnChange
}: ProjectStageProps) => {
  const onChange: DatePickerProps['onChange'] = (date) => {
    if (date) {
      handleOnChange(name, moment(date).format('YYYY-MM-DD'));
    } else {
      handleOnChange(name, '');
    }
  };

  return (
    <div className="project-stage">
      <Text>{label}</Text>
      {defaultDate ? (
        <DatePicker
          allowClear={false}
          className="project-date"
          disabled={isDisable}
          format={DateFormat1}
          value={value ? moment(value, DateFormat1) : null}
          onChange={onChange}
          defaultValue={moment(defaultDate, DateFormat1)}
          defaultPickerValue={moment(defaultPickerDate, DateFormat1)}
        />
      ) : (
        <DatePicker
          allowClear={false}
          className="project-date"
          disabled={isDisable}
          format={DateFormat1}
          onChange={onChange}
          value={value ? moment(value, DateFormat1) : null}
          defaultPickerValue={moment(defaultPickerDate, DateFormat1)}
        />
      )}
    </div>
  );
};

export default ProjectStage;
