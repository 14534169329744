import { Typography } from 'antd';
import { black } from 'constants/theme.constants';
import { isEmpty } from 'lodash';
import { AoiPointsCords } from 'store/aoi/types';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getDistance } from 'utils/geoCode';

interface AOILengthProps {
  aoiPointsCoords: AoiPointsCords | null;
  finalAoi: RepositoryFile | null;
  points?: any;
}
const { Text } = Typography;

const AOILength = ({ points, aoiPointsCoords, finalAoi }: AOILengthProps) => {
  if (isEmpty(aoiPointsCoords) && isEmpty(points)) return null;
  if (isEmpty(aoiPointsCoords) && !isEmpty(points) && (!points.start || !points.end)) return null;

  return (
    <div className="bee-line-distance">
      {!isEmpty(finalAoi) && (
        <Text className="finalized-aoi">
          <div style={{ backgroundColor: finalAoi.color }} />
          <Text style={{ fontSize: 14, fontFamily: 'ManropeRegular' }}>
            Finalized AOI : <Text strong>{finalAoi.length} sq.km</Text>
          </Text>
        </Text>
      )}
      {!isEmpty(aoiPointsCoords) &&
        isEmpty(points) &&
        aoiPointsCoords?.start_point &&
        aoiPointsCoords?.start_point?.coordinates &&
        aoiPointsCoords?.end_point &&
        aoiPointsCoords?.end_point?.coordinates && (
          <Text className="bee-line-aoi">
            <div style={{ backgroundColor: `${black}` }} />

            <Text style={{ fontSize: 14, fontFamily: 'ManropeRegular' }}>
              Bee Line Length :{' '}
              <Text strong>
                {getDistance(
                  Number(aoiPointsCoords?.start_point?.coordinates.lat),
                  Number(aoiPointsCoords?.start_point?.coordinates.lng),
                  Number(aoiPointsCoords?.end_point?.coordinates.lat),
                  Number(aoiPointsCoords?.end_point?.coordinates.lng)
                )}{' '}
                km
              </Text>
            </Text>
          </Text>
        )}
      {!isEmpty(points) && points.start && points.end && (
        <Text className="bee-line-aoi">
          <div style={{ backgroundColor: `${black}` }} />

          <Text>
            Bee Line Length :{' '}
            <Text strong>
              {getDistance(points?.start.lat, points?.start.lng, points?.end.lat, points?.end.lng)}{' '}
              km
            </Text>
          </Text>
        </Text>
      )}
    </div>
  );
};

AOILength.defaultProps = {
  points: null
};

export default AOILength;
