export const primary = '#6E3BD1';
export const red = '#ff0000';
export const orange = '#ff8719';
export const green = '#10af46';
export const black = '#000000';
export const lightBlack = '#333333';
export const white = '#ffffff';
export const grey = '#ddd';
export const lightGrey = '#d9d9d9';
export const lightDarkGrey = '#b0b0b0';
export const lightDarkGrey2 = '#bdbdbd';
export const greyShed = '#828282';
export const greyShed2 = '#727272';
export const greyShed3 = '#666666';
