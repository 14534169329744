import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistor, store } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/ttf/ManropeBold.ttf';
import './fonts/ttf/ManropeExtraBold.ttf';
import './fonts/ttf/ManropeExtraLight.ttf';
import './fonts/ttf/ManropeLight.ttf';
import './fonts/ttf/ManropeMedium.ttf';
import './fonts/ttf/ManropeRegular.ttf';
import './fonts/ttf/ManropeSemiBold.ttf';
import './fonts/ttf/ManropeVariableFontWeight.ttf';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer theme="colored" />
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
