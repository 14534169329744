import { createSlice } from '@reduxjs/toolkit';
import { AoiState } from './types';

export const initialState: AoiState = {
  isRequestingStartPoint: false,
  isRequestingEndPoint: false,
  isRequestingBeePoint: false,
  isRequestingAoiPointsCoords: false,
  aoiPointsCoords: null,
  isRequestingDeleteAoiPointsCoords: false,
  isRequestingUpdateAoiPointsCoords: false,
  isRequestingAddAOISelection: false,
  isRequestingApprovedAoi: false,
  isRequestingUpdateAOISelection: false,
  isRequestingDeclineAOISelection: false,
  aoi: null,
  aoiFiles: [],
  isRequestingExtractAoiFiles: false
};

export const aoiSlice = createSlice({
  name: 'aoi',
  initialState,
  reducers: {
    setRequestingStartPoint: (state, action) => {
      state.isRequestingStartPoint = action.payload;
    },
    setRequestingEndPoint: (state, action) => {
      state.isRequestingEndPoint = action.payload;
    },
    setRequestingBeePoint: (state, action) => {
      state.isRequestingBeePoint = action.payload;
    },
    setRequestingAoiPointsCoords: (state, action) => {
      state.isRequestingAoiPointsCoords = action.payload;
    },
    setAoiPointsCoords: (state, action) => {
      state.aoiPointsCoords = action.payload;
    },
    setRequestingDeleteAoiPointsCoords: (state, action) => {
      state.isRequestingDeleteAoiPointsCoords = action.payload;
    },
    setRequestingUpdateAoiPointsCoords: (state, action) => {
      state.isRequestingUpdateAoiPointsCoords = action.payload;
    },
    setRequestingAddAOISelection: (state, action) => {
      state.isRequestingAddAOISelection = action.payload;
    },
    setRequestingGetApprovedAoi: (state, action) => {
      state.isRequestingApprovedAoi = action.payload;
    },
    setAoi: (state, action) => {
      state.aoi = action.payload;
    },
    setRequestingUpdateAoiSelection: (state, action) => {
      state.isRequestingUpdateAOISelection = action.payload;
    },
    setRequestingDeclineAoiSelection: (state, action) => {
      state.isRequestingDeclineAOISelection = action.payload;
    },
    setRequestingExtractAoiFiles: (state, action) => {
      state.isRequestingExtractAoiFiles = action.payload;
    },
    setExtractAoiFiles: (state, action) => {
      state.aoiFiles = action.payload;
    }
  }
});
