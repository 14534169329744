import { Input, Typography } from 'antd';
import { FeatureParameter } from 'constants/analytics.constants';
import { capitalize } from 'lodash';
import { getCost, getNumber } from 'utils/util';

interface CableCostTableProps {
  cableCostRoute: any;
  unitCost: any;
}
const { Text } = Typography;

const CableCostTable = ({ cableCostRoute, unitCost }: CableCostTableProps) => {
  const cableUnitCost = unitCost && unitCost.powerline_cable ? unitCost.powerline_cable : 0;
  const cableValue =
    cableCostRoute && cableCostRoute[FeatureParameter.PowerlineCables]
      ? getNumber(cableCostRoute[FeatureParameter.PowerlineCables])
      : 0;

  return (
    <table>
      <thead>
        <tr>
          <th>
            <Text className="title-span">Cable</Text>
          </th>
          <th>
            <Text className="title-span">Unit cost / km</Text>
          </th>
          <th>
            <Text className="title-span">Length of cable (km)</Text>
          </th>
          <th>
            <Text className="title-span">Total Cost</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Text>{capitalize(FeatureParameter.PowerlineCables)}</Text>
          </td>
          <td>
            <Text>{cableUnitCost}</Text>
          </td>
          <td>
            <Text>{cableValue}</Text>
          </td>
          <td>
            <Text>
              <Input value={getCost(cableUnitCost, cableValue).toFixed(2)} readOnly />
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CableCostTable;
