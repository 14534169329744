import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import SideBar from 'components/SideBar/SideBar';
import SubHeader from 'components/SubHeader/SubHeader';
import MainHeader from 'components/MainHeader/MainHeader';
import './styles.less';

const { Header, Content } = Layout;

const SidebarLayout = () => {
  return (
    <Layout>
      <Header className="mainHeaderContent">
        <MainHeader />
      </Header>
      <Layout>
        <SideBar />
        <Layout className="mainLayoutContent">
          <SubHeader />
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;
