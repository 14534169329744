import { createSelector } from '@reduxjs/toolkit';
import { chain, filter, remove, upperFirst } from 'lodash';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { RootState } from '..';
import { organizationSlice } from './slice';
import { MapViewResponse, OrganizationMember } from './types';

export const {
  setSelectedOrganization,
  setOrganizations,
  setRequestingOrganizations,
  setRequestingOrganization,
  setRequestingAddOrganization,
  setOrganizationMembers,
  setRequestingOrganizationMember,
  setNewRequestOrganizationMembers,
  setRequestingOrganizationsMapView,
  setOrganizationsMapView,
  setRequestingEnableDisableUser
} = organizationSlice.actions;
export default organizationSlice.reducer;

export const selectOrganizationMember = (state: RootState) => {
  const { orginsationMembers } = state.organizations;

  return orginsationMembers
    ?.filter((m: OrganizationMember) => m.role !== UserRole.Admin)
    .map((member: OrganizationMember) => {
      if (member.first_name && member.last_name) {
        const firstname = upperFirst(decryptValue(member.first_name));
        const lastname = upperFirst(decryptValue(member.last_name));
        return {
          label: upperFirst(`${firstname} ${lastname}`),
          value: member.id
        };
      }

      return {
        label: decryptValue(member.email),
        value: member.id
      };
    });
};

export const selectOrganizationMapOption = (state: RootState) => {
  const { organizationsMapViewData } = state.organizations;
  if (!organizationsMapViewData) return [];

  return chain(organizationsMapViewData)
    .map((data: MapViewResponse) => {
      return {
        label: data.org_name,
        value: data.org_id
      };
    })
    .uniqBy('value')
    .orderBy(['label'], ['asc'])
    .value();
};

const selectMapViewData = (state: RootState) => state.organizations.organizationsMapViewData;
const selectOrgId = (state: RootState, id: number) => id;

export const getMapviewDataByOrg = createSelector(
  [selectMapViewData, selectOrgId],
  (mapData, orgid) => {
    if (orgid !== 0) {
      const points: MapViewResponse[] = filter(
        mapData,
        (data: MapViewResponse) => data.org_id === orgid
      );

      return points;
    }

    return mapData;
  }
);

const selectProjectId = (state: RootState, id: number) => id;

export const getMapviewDataByProject = createSelector(
  [selectMapViewData, selectProjectId],
  (mapData, projectId) => {
    if (projectId !== 0) {
      const points: MapViewResponse[] = filter(
        mapData,
        (data: MapViewResponse) => data.proj_id === projectId
      );

      return points;
    }

    return mapData;
  }
);

export const getOrganizationTeamMembers = (state: RootState) => {
  const { orginsationMembers } = state.organizations;

  if (!orginsationMembers || orginsationMembers.length === 0) return [];
  const members = chain(orginsationMembers)
    .map((data: OrganizationMember) => {
      return {
        ...data,
        email: decryptValue(data.email),
        first_name: data.first_name ? upperFirst(decryptValue(data.first_name)) : '',
        last_name: data.last_name ? upperFirst(decryptValue(data.last_name)) : '',
        phone_number: data.phone_number ? upperFirst(decryptValue(data.phone_number)) : ''
      };
    })
    .orderBy(['role', 'first_name', 'email'], ['asc', 'asc', 'asc'])
    .value();
  // @ts-ignore
  const adminUser = members.find((user: OrganizationMember) => user.role === UserRole.Admin);
  // @ts-ignore
  remove(members, (user: OrganizationMember) => user.role === UserRole.Admin);

  return [adminUser].concat(members);
};
