import { OrganizationPayload } from 'store/organizations/types';
import { UploadFilePayload } from 'store/repositoryfile/types';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// auth
export const RSA_URL = `${BASE_URL}/rsa`;
export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const RSA_ENCRYPT_URL = `${BASE_URL}/RSA-encrypt`;
export const RSA_DECRYPT_URL = `${BASE_URL}/RSA-decrypt`;
export const AES_ENCRYPT_URL = `${BASE_URL}/aes-encrypt`;
export const AES_DECRYPT_URL = `${BASE_URL}/aes-decrypt`;
export const AES_KEY_URL = `${BASE_URL}/encrypt/`;
export const REFRESH_BOTH_TOKEN = `${BASE_URL}/refresh_both_tokens`;

// user
export const ADD_USER_DETAIL_URL = `${BASE_URL}/add_user_details`;

// organization
export const GET_ALL_ORGANIZATION_URL = `${BASE_URL}/organisations`;
export const ADD_ORGANIZATION_URL = (payload: OrganizationPayload) =>
  `${BASE_URL}/add_organisation?organisation_name=${payload.organisation_name}&admin_email=${payload.admin_email}&admin_firstname=${payload.admin_firstname}&admin_lastname=${payload.admin_lastname}`;
export const GET_ORGANIZATION_MEMBERS_URL = (orgId: string) =>
  `${BASE_URL}/organisations/${orgId}/members`;
export const GET_ORGANIZATION_URL = (orgId: string) => `${BASE_URL}/organisations/${orgId}`;
export const ADD_ORGANIZATION_MEMBERS_URL = `${BASE_URL}/add_organisation_members`;
export const GET_ORGANIZATIONS_MAPVIEW_URL = `${BASE_URL}/orginsations/mapview`;
export const GET_ORGANIZATION_MAPVIEW_URL = (orgId: number) =>
  `${BASE_URL}/orginsations/${orgId}/mapview`;
export const EDIT_ORGANIZATION_URL = (orgId: number, orgName: string) =>
  `${BASE_URL}/organisations/${orgId}/edit?organisation_name=${orgName}`;
export const USER_DISABLE_ENABLE_URL = (orgId: number) =>
  `${BASE_URL}/organisations/${orgId}/status`;

// projects
export const GET_ALL_PROJECT_URL = (orgId: number) =>
  `${BASE_URL}/get_all_projects?organisation_id=${orgId}`;
export const ADD_PROJECT_URL = `${BASE_URL}/add_project`;
export const ADD_PROJECT_MEMBERS_URL = `${BASE_URL}/add_project_members`;
export const GET_PROJECT_URL = (projectId: number) => `${BASE_URL}/project/${projectId}`;
export const GET_AVAILABLE_PROJECT_MEMBERS_URL = (projectId: string) =>
  `${BASE_URL}/get_available_members?id=${projectId}`;
export const ADD_DEADLINE_STAGES_UPLOAD_URL = `${BASE_URL}/deadline_stages_upload`;
export const EDIT_DEADLINE_STAGES_UPDATE_URL = `${BASE_URL}/deadline_stages_update`;
export const ADD_PROJECT_STAGE_CHANGE_STATUS_URL = `${BASE_URL}/change_status`;
export const REMOVE_MEMBER_FROM_PROJECT_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/member`;
export const REMOVE_MEMBER_FROM_ORGANIZATION_URL = (orgId: number, memberId: string) =>
  `${BASE_URL}/organisations/${orgId}/members/${memberId}/access`;
export const GET_PROJECT_ACTIVITY_LOG_URL = (projectId: number) =>
  `${BASE_URL}/activitylogs/${projectId}`;
export const CLOSED_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/close`;
export const OPEN_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/open`;
export const GET_PROJECT_EXTRACT_COORDS_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/projects/${projectId}/files/${repoFileId}/geometry`;

// repository file
export const GET_ALL_FILES_URL = (projectId: number) => `${BASE_URL}/project/${projectId}/files`;
export const EDIT_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/edit`;
export const GET_ALL_CATEGORIES_URL = `${BASE_URL}/get_all_categories`;
export const ADD_REPOSITORY_FILE_URL = (payload: UploadFilePayload) =>
  `${BASE_URL}/project/${payload.project_id}/upload_file?user_id=${payload.user_id}&category_id=${payload.category_id}&buffer=${payload.buffer}&comment=${payload.comment}`;
export const FILE_MAP_ACCESS_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/map_access`;
export const DOWNLOAD_REPO_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/download`;
export const DOWNLOAD_LULC_REPO_FILE_URL = (repoFileId: string) =>
  `${BASE_URL}/geoserver/file-download?file_id=${repoFileId}`;
export const DELETE_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/delete`;
export const GET_DEM_ELEVETION_URL = (projectId: number, fileId: number) =>
  `${BASE_URL}/projects/${projectId}/files/${fileId}/min-max-elv`;

// aoi
export const AOI_COORDS_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/coords`;
export const AOI_SELECTION_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/aoi-selection`;

// analytics
export const GET_PROJECT_ANALYTICS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/analytics`;
export const ANALYTICS_UNIT_COST_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/analytics/unit-cost`;

// geoserver/wms
export const GET_GEOSERVER_WMS_URL = `${BASE_URL}/geoserver/wms`;
export const GET_GEOSERVER_WMTS_URL = `${BASE_URL}/geoserver/wmts`;
export const GET_GEOSERVER_LAYER_STYLE_URL = (layer: string) =>
  `${BASE_URL}/geoserver/layer-legend?layer_name=${layer}`;
