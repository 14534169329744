export const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;
  const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.asin(Math.sqrt(a));
  const r = 6371;
  const distance = c * r;

  return distance.toFixed(2);
};

export const convertToDms = (dd: number, isLng = false) => {
  // eslint-disable-next-line no-nested-ternary
  const dir = dd < 0 ? (isLng ? 'W' : 'S') : isLng ? 'E' : 'N';
  const absDd = Math.abs(dd);
  const deg: number = absDd || 0;
  const dms = String(deg).padStart(2, '0');
  const dmsFinal = Number(dms).toFixed(3);

  if (isLng) {
    return `0${dmsFinal} ° ${dir}`;
  }

  return `${dmsFinal} ° ${dir}`;
};
