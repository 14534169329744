import { Avatar, Checkbox, Col, Form, Row, Space, Typography } from 'antd';
import { getTeamMembers } from 'store/projects';
import { TeamMember } from 'store/projects/types';
import { shortLabel } from 'utils/util';
import './styles.less';

const { Text } = Typography;

interface RemoveProjectMemberModalProps {
  projectMembers: TeamMember[];
  handleRemoveMembersCallback: Function;
}

const RemoveProjectMemberModal = ({
  projectMembers,
  handleRemoveMembersCallback
}: RemoveProjectMemberModalProps) => {
  const [form] = Form.useForm();
  const members = getTeamMembers(projectMembers);

  const handleOnChange = (members: any) => {
    handleRemoveMembersCallback(members);
  };

  return (
    <div className="remove-member-content">
      <Form form={form} name="remove_member_form" autoComplete="off">
        <Space className="remove-member-title">
          <Text>NAME OF EMPLOYEE</Text>
        </Space>
        <Form.Item>
          <Checkbox.Group style={{ width: '100%' }} onChange={handleOnChange}>
            <Row>
              {members &&
                members?.map((item, index: number) => {
                  return (
                    <Col span={24} className="checkbox-content-col" key={index}>
                      <Checkbox value={item.value} className="checkbox-content">
                        <div className="checkbox-innercontent">
                          <Avatar className="name-avatar">{shortLabel(item.label)}</Avatar>
                          <Text className="member-name">{item.label}</Text>
                        </div>
                      </Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RemoveProjectMemberModal;
