import {
  ADD_PROJECT_MEMBERS_URL,
  ADD_PROJECT_URL,
  GET_ALL_PROJECT_URL,
  GET_AVAILABLE_PROJECT_MEMBERS_URL,
  GET_PROJECT_URL,
  ADD_DEADLINE_STAGES_UPLOAD_URL,
  EDIT_DEADLINE_STAGES_UPDATE_URL,
  ADD_PROJECT_STAGE_CHANGE_STATUS_URL,
  REMOVE_MEMBER_FROM_PROJECT_URL,
  REMOVE_MEMBER_FROM_ORGANIZATION_URL,
  GET_PROJECT_ACTIVITY_LOG_URL,
  CLOSED_PROJECT_URL,
  OPEN_PROJECT_URL
} from 'utils/apiUrls';
import { toast } from 'react-toastify';
import { apiCall } from 'utils/connect';
import type { Response, Error } from 'types/common.types';
import { getOrganizationMembers } from 'store/organizations/actions';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { getAsyncStorageValue } from 'utils/localStorage';
import type { AppDispatch, RootState } from '..';
import {
  AddProjectMemberPayload,
  AvailableProjectMember,
  Project,
  ProjectActivityData,
  ProjectPayload,
  ProjectStageChangeStatusPayload,
  RemoveOrganizationMemberPayload,
  RemoveProjectMembersPayload,
  StageUploadPayload
} from './types';
import {
  setRequestingProjects,
  setProjects,
  setProject,
  setAddProjectRequesting,
  setRequestProject,
  setProjectMemberRequest,
  setAvailableProjectMemberRequesting,
  setAvailableProjectMembers,
  setDeadlineStageUploadRequesting,
  setEditDeadlineStageUploadRequesting,
  setRequestingProjectStageChangeStatus,
  setRequestingRemoveMemberFromProject,
  setRequestingRemoveMemberFromOrganization,
  setSelectedProject,
  setProjectActivityLogs,
  setRequestingProjectActivityLogs,
  setRequestCloseProject
} from '.';

export const getProjects = (orgId: string, token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingProjects(true));

    const onSuccess = (response: Response<Array<Project>>) => {
      dispatch(setProjects(response.data));
      dispatch(setRequestingProjects(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getProjects(orgId, reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingProjects(false));
      }
    };

    apiCall('GET', GET_ALL_PROJECT_URL(Number(orgId)), '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingProjects(false));
    toast.error(error.message);
  }
};

export const addProject =
  (payload: ProjectPayload, token: string, callback: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setAddProjectRequesting(true));

      const onSuccess = () => {
        // toast.success('Project added successfully');
        dispatch(getProjects(payload.organisation_id, token));
        dispatch(setAddProjectRequesting(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProject(payload, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setAddProjectRequesting(false));
        }
      };

      apiCall('POST', ADD_PROJECT_URL, { ...payload }, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setAddProjectRequesting(false));
      toast.error(error.message);
    }
  };

export const addProjectMembers =
  (payload: AddProjectMemberPayload, orgId: string, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setProjectMemberRequest(true));

      const onSuccess = () => {
        // toast.success('Project members added successfully');

        dispatch(getProjects(orgId, token));
        dispatch(setProjectMemberRequest(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProjectMembers(payload, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setProjectMemberRequest(false));
        }
      };

      apiCall('POST', ADD_PROJECT_MEMBERS_URL, { ...payload }, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setProjectMemberRequest(false));
      toast.error(error.message);
    }
  };

export const getProject =
  (projectId: number, token: string) => (dispatch: AppDispatch, getState: RootState) => {
    try {
      const state = getState();
      dispatch(setRequestProject(true));

      const onSuccess = (response: Response<Project>) => {
        dispatch(setProject(response.data));
        if (response.data) {
          dispatch(
            setSelectedProject({
              ...state.projects.selectedProject,
              is_closed: response.data.is_closed,
              status: response.data.status,
              status_percentage: response.data.status_percentage
            })
          );
        }
        dispatch(setRequestProject(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getProject(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestProject(false));
        }
      };

      apiCall('GET', GET_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestProject(false));
      toast.error(error.message);
    }
  };

export const getProjectInSilentMode =
  (projectId: number, token: string) => (dispatch: AppDispatch, getState: RootState) => {
    try {
      const state = getState();

      const onSuccess = (response: Response<Project>) => {
        dispatch(setProject(response.data));
        if (response.data) {
          dispatch(
            setSelectedProject({
              ...state.projects.selectedProject,
              is_closed: response.data.is_closed,
              status: response.data.status,
              status_percentage: response.data.status_percentage
            })
          );
        }
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getProjectInSilentMode(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestProject(false));
        }
      };

      apiCall('GET', GET_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const getAvailableProjectMembers =
  (projectId: string, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setAvailableProjectMemberRequesting(true));

      const onSuccess = (response: Response<AvailableProjectMember>) => {
        dispatch(setAvailableProjectMembers(response.data));
        dispatch(setAvailableProjectMemberRequesting(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getAvailableProjectMembers(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setAvailableProjectMemberRequesting(false));
        }
      };

      apiCall('GET', GET_AVAILABLE_PROJECT_MEMBERS_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setAvailableProjectMemberRequesting(false));
      toast.error(error.message);
    }
  };

export const addDeadlineStagesUpload =
  (payload: StageUploadPayload, projectId: string, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setDeadlineStageUploadRequesting(true));

      const onSuccess = () => {
        // toast.success('Deadline stages upload successfully');
        dispatch(getProject(Number(projectId), token));
        dispatch(setDeadlineStageUploadRequesting(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addDeadlineStagesUpload(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setDeadlineStageUploadRequesting(false));
        }
      };

      apiCall('POST', ADD_DEADLINE_STAGES_UPLOAD_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setDeadlineStageUploadRequesting(false));
      toast.error(error.message);
    }
  };

export const editDeadlineStagesUpload =
  (payload: StageUploadPayload, projectId: string, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setEditDeadlineStageUploadRequesting(true));

      const onSuccess = () => {
        // toast.success('Deadline stages update successfully');
        dispatch(getProject(Number(projectId), token));
        dispatch(setEditDeadlineStageUploadRequesting(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(editDeadlineStagesUpload(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setEditDeadlineStageUploadRequesting(false));
        }
      };

      apiCall('PUT', EDIT_DEADLINE_STAGES_UPDATE_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setEditDeadlineStageUploadRequesting(false));
      toast.error(error.message);
    }
  };

export const addProjectStageChangeStatus =
  (payload: ProjectStageChangeStatusPayload, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingProjectStageChangeStatus(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Project stages status add successfully');
        dispatch(getProject(Number(payload.project_id), token));
        dispatch(setRequestingProjectStageChangeStatus(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProjectStageChangeStatus(payload, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingProjectStageChangeStatus(false));
        }
      };

      apiCall('POST', ADD_PROJECT_STAGE_CHANGE_STATUS_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingProjectStageChangeStatus(false));
    }
  };

export const removeMemberFromProject =
  (
    payload: RemoveProjectMembersPayload,
    projectId: number,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRemoveMemberFromProject(true));

      const onSuccess = () => {
        // toast.success('Remove members from project successfully');
        dispatch(getProjects(orgId.toString(), token));
        dispatch(getOrganizationMembers(orgId.toString(), token));
        dispatch(setRequestingRemoveMemberFromProject(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(removeMemberFromProject(payload, projectId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRemoveMemberFromProject(false));
        }
      };

      apiCall(
        'DELETE',
        REMOVE_MEMBER_FROM_PROJECT_URL(projectId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingRemoveMemberFromProject(false));
      toast.error(error.message);
    }
  };

export const removeAddMemberFromOrganization =
  (
    payload: RemoveOrganizationMemberPayload,
    memberId: string,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRemoveMemberFromOrganization(true));

      const onSuccess = (response: Response<boolean>) => {
        // toast.success('Remove member from organization successfully');
        dispatch(getProjects(orgId.toString(), token));
        dispatch(getOrganizationMembers(orgId.toString(), token));
        dispatch(setRequestingRemoveMemberFromOrganization(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(removeAddMemberFromOrganization(payload, memberId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRemoveMemberFromOrganization(false));
        }
      };

      apiCall(
        'PUT',
        REMOVE_MEMBER_FROM_ORGANIZATION_URL(orgId, memberId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingRemoveMemberFromOrganization(false));
      toast.error(error.message);
    }
  };

export const getProjectActivityLogs =
  (projectId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingProjectActivityLogs(true));

      const onSuccess = (response: Response<Array<ProjectActivityData>>) => {
        dispatch(setProjectActivityLogs(response.data));
        dispatch(setRequestingProjectActivityLogs(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getProjectActivityLogs(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingProjectActivityLogs(false));
        }
      };

      apiCall('GET', GET_PROJECT_ACTIVITY_LOG_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingProjectActivityLogs(false));
      toast.error(error.message);
    }
  };

export const closeProject =
  (projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestCloseProject(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getProjectInSilentMode(projectId, token));
        dispatch(setRequestCloseProject(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(closeProject(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestCloseProject(false));
        }
      };

      apiCall('POST', CLOSED_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestCloseProject(false));
    }
  };

export const openProject =
  (projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestCloseProject(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getProject(projectId, token));
        dispatch(setRequestCloseProject(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(openProject(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestCloseProject(false));
        }
      };

      apiCall('POST', OPEN_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestCloseProject(false));
    }
  };
