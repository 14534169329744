import { createSlice } from '@reduxjs/toolkit';
import { RouteState } from './types';

export const initialState: RouteState = {
  isRequestingVectorFiles: false,
  vectorFiles: []
};

export const vectorfileSlice = createSlice({
  name: 'vectorfile',
  initialState,
  reducers: {
    setRequestingVectorFiles: (state, action) => {
      state.isRequestingVectorFiles = action.payload;
    },
    setVectorFiles: (state, action) => {
      state.vectorFiles = action.payload;
    }
  }
});
