import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN, REFRESH_TOKEN, AES_KEY, USER_ROLE } from 'constants/common.constant';
import { JWT } from 'types/common.types';
import { refreshAccessToken } from './connect';
import { REFRESH_BOTH_TOKEN } from './apiUrls';
// import { encrypt, decrypt } from './crypto';

const cookies = new Cookies();

export const removeAllCookies = async () => {
  document.cookie = `${ACCESS_TOKEN}=; Path=/; Expires=${new Date().toUTCString()};`;
  document.cookie = `${REFRESH_TOKEN}=; Path=/; Expires=${new Date().toUTCString()};`;
  document.cookie = `${AES_KEY}=; Path=/; Expires=${new Date().toUTCString()};`;
  document.cookie = `${USER_ROLE}=; Path=/; Expires=${new Date().toUTCString()};`;

  await cookies.remove(ACCESS_TOKEN);
  await cookies.remove(REFRESH_TOKEN);
  await cookies.remove(AES_KEY);
  await cookies.remove(USER_ROLE);
  localStorage.clear();

  return null;
};

export const setAsyncStorageValue = async (key: string, value: string) => {
  document.cookie = `${key}=; Path=/; Expires=${new Date().toUTCString()};`;

  await cookies.set(key, value);

  return value;
};

export const getStorageValue = (storageKey: string) => {
  const value = cookies.get(storageKey);

  return value;
};

export const setStorageValue = (key: string, value: string) => {
  // cookies.set(key, encrypt(value));
  cookies.set(key, value);
};

export const getAsyncStorageValue = async (storageKey: string) => {
  if (storageKey === ACCESS_TOKEN) {
    const currentTime = Math.round(new Date().getTime() / 1000);

    const aesKey = getStorageValue(AES_KEY);
    const refreshToken = cookies.get(REFRESH_TOKEN);
    const accessToken = cookies.get(ACCESS_TOKEN);
    const decodeRefreshToken: JWT = jwtDecode(refreshToken);
    const decodeAccessToken: JWT = jwtDecode(accessToken);

    if (decodeRefreshToken.exp <= currentTime) {
      await removeAllCookies();
      window.location.reload();
      return null;
    }

    if (decodeAccessToken.exp <= currentTime) {
      return refreshAccessToken('POST', REFRESH_BOTH_TOKEN, refreshToken)
        .then(async (response) => {
          if (response.status === false) {
            await removeAllCookies();
            window.location.reload();
            return null;
          }

          const decodeAccessToken: JWT = jwtDecode(response.data.accessToken);
          await setAsyncStorageValue(ACCESS_TOKEN, response.data.accessToken);
          await setAsyncStorageValue(REFRESH_TOKEN, response.data.refreshToken);
          await setAsyncStorageValue(USER_ROLE, decodeAccessToken.user_claims.role);
          await setAsyncStorageValue(AES_KEY, aesKey);

          return response.data.accessToken;
        })
        .catch(() => {
          toast.error('Access token expired');
          return null;
        });
    }

    return cookies.get(storageKey);
  }

  return cookies.get(storageKey);
};
