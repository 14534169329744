import { useMemo } from 'react';
import { Avatar, Button, Popconfirm, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { capitalize, sortBy } from 'lodash';
import { greyShed2, primary, red, white } from 'constants/theme.constants';
import { USER_ROLE } from 'constants/common.constant';
import { OrganizationMember } from 'store/organizations/types';
import { shortLabel } from 'utils/util';
import { UserRole } from 'store/users/types';
import { getStorageValue } from 'utils/localStorage';

interface TeamManagementProps {
  orginsationMembers: Array<OrganizationMember>;
  addLoading: boolean;
  handleRemoveTeamMemberCallback: Function;
  handleAddTeamMemberCallback: Function;
  handleChangeTeamMemberCallback: Function;
}

const { Text } = Typography;

const TeamManagement = ({
  orginsationMembers,
  addLoading,
  handleRemoveTeamMemberCallback,
  handleAddTeamMemberCallback,
  handleChangeTeamMemberCallback
}: TeamManagementProps) => {
  const userRole = getStorageValue(USER_ROLE);
  const teamColumns: ColumnsType<OrganizationMember> = useMemo(
    () => [
      {
        title: 'Name of the employee',
        key: 'first_name',
        width: '22%',
        render: (_, record) => {
          let firstname;
          let lastname;
          let compressName;
          if (record.first_name && record.last_name) {
            firstname = record.first_name;
            lastname = record.last_name;
            compressName = shortLabel(`${firstname} ${lastname}`);
          } else {
            firstname = record.email;
            lastname = '';
            compressName = capitalize(record.email.charAt(0));
          }

          return (
            <div>
              <Avatar className="name-avatar">{compressName}</Avatar>
              <Text>{`${firstname} ${lastname}`}</Text>
            </div>
          );
        }
      },
      {
        title: 'Username',
        key: 'email',
        render: (_, record) => {
          return <Text>{record.email}</Text>;
        }
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (_, record) => {
          if (!record.role) {
            return <Text style={{ color: `${greyShed2}` }}>-</Text>;
          }

          return <Text style={{ color: `${greyShed2}` }}>{capitalize(record.role)}</Text>;
        }
      },
      {
        title: 'Phone number',
        key: 'phone_number',
        render: (_, record) => {
          if (!record.phone_number) {
            return <Text>-</Text>;
          }

          return <Text>{record.phone_number}</Text>;
        }
      },
      {
        title: 'No of Assigned project',
        key: 'project_list',
        render: (_, record) => {
          return (
            <div className="assign-project-row" key={record.id}>
              <Text>{record.project_list?.length}</Text>
              {record.project_list?.length > 0 && (
                <Tooltip
                  placement="bottom"
                  color={white}
                  title={record.project_list.map((item: string) => (
                    <Text className="assignRecord" key={item}>
                      {item}
                    </Text>
                  ))}
                >
                  <AiOutlineInfoCircle style={{ fontSize: '20px', marginLeft: '5px' }} />
                </Tooltip>
              )}
            </div>
          );
        }
      },
      {
        title: 'Actions',
        key: 'x',
        render: (_, record) => (
          <>
            {record.role !== UserRole.Admin && (
              <Space size="middle" key={record.id} className="action-content">
                {record.is_active ? (
                  <Button
                    type="link"
                    key="remove-from-org"
                    onClick={() => handleRemoveTeamMemberCallback(record)}
                    style={{ color: primary, padding: '0' }}
                  >
                    <u>remove from org.</u>
                  </Button>
                ) : (
                  <>
                    <Text italic style={{ color: `${red}` }} className="remove-title">
                      removed from org.
                    </Text>
                    <Popconfirm
                      placement="top"
                      title="You want to add this member organization"
                      onConfirm={() => handleAddTeamMemberCallback(record)}
                      okText="Yes"
                      okButtonProps={{ loading: addLoading }}
                      cancelText="No"
                    >
                      <Button type="link" key="add-again" style={{ color: primary, padding: '0' }}>
                        <u>add again</u>
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </Space>
            )}
            {record.role === UserRole.Admin && userRole === UserRole.SuperAdmin && (
              <Space size="middle" key={record.id}>
                <Button
                  type="link"
                  key="change admin"
                  onClick={() => handleChangeTeamMemberCallback(record)}
                  style={{ color: primary, padding: '0' }}
                >
                  <u>change</u>
                </Button>
              </Space>
            )}
          </>
        )
      }
    ],
    []
  );
  return (
    <Table
      loading={addLoading}
      columns={teamColumns}
      dataSource={sortBy(orginsationMembers, ['role'])}
      pagination={false}
      className="userMangementTabel"
      showHeader
      sticky
      bordered={false}
      tableLayout="fixed"
      rowKey={(row: OrganizationMember) => row.email}
    />
  );
};

export default TeamManagement;
