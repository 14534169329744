import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const DownArrowIcon = ({ color, height, width }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99998 4.66657C4.84798 4.66657 4.69665 4.61524 4.57331 4.51191L0.573313 1.17857C0.290647 0.943241 0.25198 0.522574 0.48798 0.239908C0.723313 -0.0427589 1.14331 -0.0807589 1.42665 0.154574L5.00731 3.13857L8.58198 0.261908C8.86865 0.0312411 9.28865 0.0765745 9.51931 0.363241C9.74998 0.649908 9.70465 1.06924 9.41798 1.30057L5.41798 4.51924C5.29598 4.61724 5.14798 4.66657 4.99998 4.66657Z"
        fill={color}
      />
    </svg>
  );
};
DownArrowIcon.defaultProps = {
  color: white,
  height: 5,
  width: 10
};

export default DownArrowIcon;
