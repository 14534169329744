import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DateFormat2 } from 'constants/common.constant';
import { useAppSelector } from 'hooks/useReduxHook';
import { isEmpty, orderBy, upperFirst } from 'lodash';
import moment from 'moment';
import { getUserFromId } from 'store/aoi';
import { AoiPointsCords } from 'store/aoi/types';
import {
  ProjectStage,
  ProjectStagesList,
  ProjectTimeline,
  ProposedTimelineStatus,
  TeamMember
} from 'store/projects/types';
import { decryptValue } from 'utils/decodeEncodeData';

interface AdminProposedTimelineProps {
  project: ProjectTimeline;
  isRequestProject: boolean;
  aoiPointsCoords: AoiPointsCords | null;
  selectedAoi: any;
}
const { Text, Link } = Typography;

const AdminProposedTimeline = ({
  project,
  isRequestProject,
  aoiPointsCoords,
  selectedAoi
}: AdminProposedTimelineProps) => {
  let selectedUser: TeamMember | null;
  let selectedAoiUser: TeamMember | null;

  if (aoiPointsCoords && !aoiPointsCoords.is_approved) {
    const startPointUploadedBy =
      aoiPointsCoords.bee_line && aoiPointsCoords.bee_line.uploaded_by
        ? aoiPointsCoords.bee_line.uploaded_by
        : aoiPointsCoords.start_point.uploaded_by;
    if (startPointUploadedBy) {
      selectedUser = useAppSelector((state) => getUserFromId(state, startPointUploadedBy));
    }
  }

  if (!isEmpty(selectedAoi) && !selectedAoi.is_approved) {
    const submittedAoiByUser = selectedAoi.submitted_by;

    if (submittedAoiByUser) {
      selectedAoiUser = useAppSelector((state) => getUserFromId(state, submittedAoiByUser));
    }
  }

  const columns: ColumnsType<ProjectStage> = [
    {
      title: 'Scheduled on',
      dataIndex: 'deadline',
      key: 'date',
      align: 'right',
      width: '100px',
      render: (text, record) => (
        <Text key={record.id} className="scheduleColumn">
          {moment(new Date(text)).format(DateFormat2)}
        </Text>
      )
    },
    {
      title: '',
      className: 'mainEmptyCol',
      dataIndex: '',
      key: 'empty',
      width: '40px',
      render: (_, record) => {
        let conClass;
        if (record.status === ProposedTimelineStatus.Completed) {
          conClass = 'completeContent';
        } else if (
          record.status === ProposedTimelineStatus.InProgress ||
          record.status === ProposedTimelineStatus.OnGoing ||
          record.status === ProposedTimelineStatus.Started
        ) {
          conClass = '';
        } else if (record.status === ProposedTimelineStatus.NotStarted) {
          conClass = 'notStartedPendingCon';
        } else if (record.status === null) {
          conClass = 'completePendingCon';
        }
        if (project?.stages.length < 1) {
          conClass = 'completeContent row-empty-content';
        }
        if (record.stage_name === ProjectStagesList.RouteFinalization) {
          conClass += ' row-empty-content';
        }

        return (
          <div key={record.id} className="emptyColum">
            <div className={`roundContent ${conClass}`} />
          </div>
        );
      }
    },
    {
      title: 'Project Stages',
      dataIndex: 'stage_name',
      key: 'stage_name',
      width: '50%',
      render: (_, record) => {
        let changeClass = '';
        if (record.status) {
          changeClass = 'complete-stage';
        }

        return (
          <Link key={record.id} className={`projectStage ${changeClass}`}>
            {record.stage_name}
          </Link>
        );
      }
    },
    {
      title: 'Approval Status',
      dataIndex: 'status',
      key: 'status',
      width: '180px',
      render: (_, record) => {
        let statusName = record.status;
        let statusClass = '';

        if (record.id === 0) {
          statusName = 'Completed';
        }

        if (
          (record.stage_name === ProjectStagesList.DefiningCoordinates ||
            record.stage_name === ProjectStagesList.AOIRequest) &&
          statusName === ProposedTimelineStatus.NotStarted
        ) {
          statusName = ' ';
        }

        if (
          record.stage_name === ProjectStagesList.DefiningCoordinates &&
          aoiPointsCoords &&
          !aoiPointsCoords.is_approved
        ) {
          statusName = `Requested for Approval by ${
            selectedUser
              ? `${upperFirst(decryptValue(selectedUser.first_name))} ${upperFirst(
                  decryptValue(selectedUser.last_name)
                )}`
              : ''
          }`;
          statusClass = ' waiting-approve-mng';
        }

        if (
          record.stage_name === ProjectStagesList.DefiningCoordinates &&
          aoiPointsCoords &&
          aoiPointsCoords.is_approved
        ) {
          statusName = 'Approved by Admin';
          statusClass = '';
        }

        if (
          record.stage_name === ProjectStagesList.AOIFinalization &&
          !isEmpty(selectedAoi) &&
          !selectedAoi.is_approved
        ) {
          statusName = `Requested for Approval by ${
            selectedAoiUser
              ? `${upperFirst(decryptValue(selectedAoiUser.first_name))} ${upperFirst(
                  decryptValue(selectedAoiUser.last_name)
                )}`
              : ''
          }`;
          statusClass = ' waiting-approve-mng';
        }

        if (
          record.stage_name === ProjectStagesList.AOIFinalization &&
          selectedAoi &&
          selectedAoi.is_approved
        ) {
          statusName = 'Approved by Admin';
          statusClass = '';
        }

        return (
          <Text key={record.id} className={`approvalStatus${statusClass}`} italic>
            {statusName}
          </Text>
        );
      }
    },
    {
      title: 'Delays',
      dataIndex: 'delays',
      key: 'delays',
      width: '180px',
      render: (_, record) => {
        let delayClass = '';
        let text = '';

        if (record.status === ProposedTimelineStatus.Completed && record.completion_date) {
          if (new Date(record.completion_date).getTime() === new Date(record.deadline).getTime()) {
            delayClass = 'completeDelayContent';
            text = 'On Time';
          } else if (
            new Date(record.completion_date).getTime() <= new Date(record.deadline).getTime()
          ) {
            delayClass = 'completeDelayContent';
            text = 'On Time';
          } else if (
            new Date(record.completion_date).getTime() > new Date(record.deadline).getTime()
          ) {
            const differenceInTime =
              new Date(record.completion_date).getTime() - new Date(record.deadline).getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            delayClass = 'pendingDelayCon';
            text = `Delayed by ${differenceInDays} days`;
          }
        } else if (
          record.status !== ProposedTimelineStatus.Completed &&
          new Date().getTime() >= new Date(record.deadline).getTime()
        ) {
          const differenceInTime = new Date().getTime() - new Date(record.deadline).getTime();
          const differenceInDays = (differenceInTime / (1000 * 3600 * 24)).toFixed(0);
          if (Number(differenceInDays) > 0) {
            delayClass = 'pendingDelayCon';
            text = `Delayed by ${differenceInDays} days`;
          }
        } else {
          delayClass = '';
          text = '';
        }

        return (
          <Text key={record.id} className={delayClass}>
            {text}
          </Text>
        );
      }
    }
  ];

  const initialStage: Array<ProjectStage> = [
    {
      completion_date: project.created_at ? moment(project.created_at).format('YYYY-MM-DD') : null,
      deadline: project.created_at ? moment(project.created_at).format('YYYY-MM-DD') : '',
      id: 0,
      stage_name: 'Project Creation',
      status: ProposedTimelineStatus.Completed
    }
  ];

  return (
    <>
      <div className="mainTimeLine">
        <div className="table-content">
          <Table
            loading={isRequestProject}
            className="timelineTable"
            columns={columns}
            dataSource={initialStage.concat(orderBy(project?.stages, ['id'], ['asc']))}
            pagination={false}
            sticky
            tableLayout="fixed"
            rowClassName="mainRowContent"
            rowKey={(row) => row.id}
          />
        </div>
      </div>
      {project?.stages.length === 0 ? (
        <Text className="empty-timeline">No Timeline added yet</Text>
      ) : (
        ''
      )}
    </>
  );
};

export default AdminProposedTimeline;
