import { red } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const DeleteIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33317 0.875C4.98799 0.875 4.70817 1.15482 4.70817 1.5V2.125H1.1665C0.821326 2.125 0.541504 2.40482 0.541504 2.75C0.541504 3.09518 0.821326 3.375 1.1665 3.375H12.8332C13.1783 3.375 13.4582 3.09518 13.4582 2.75C13.4582 2.40482 13.1783 2.125 12.8332 2.125H9.2915V1.5C9.2915 1.15482 9.01168 0.875 8.6665 0.875H5.33317Z"
        fill={color}
      />
      <path
        d="M5.33317 7.875C5.67835 7.875 5.95817 8.15482 5.95817 8.5L5.95817 14.3333C5.95817 14.6785 5.67835 14.9583 5.33317 14.9583C4.98799 14.9583 4.70817 14.6785 4.70817 14.3333L4.70817 8.5C4.70817 8.15482 4.98799 7.875 5.33317 7.875Z"
        fill={color}
      />
      <path
        d="M9.2915 8.5C9.2915 8.15482 9.01168 7.875 8.6665 7.875C8.32133 7.875 8.0415 8.15482 8.0415 8.5V14.3333C8.0415 14.6785 8.32133 14.9583 8.6665 14.9583C9.01168 14.9583 9.2915 14.6785 9.2915 14.3333V8.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99269 5.59765C2.02786 5.28113 2.2954 5.04167 2.61387 5.04167H11.3858C11.7043 5.04167 11.9718 5.28113 12.007 5.59765L12.1738 7.09877C12.4761 9.81987 12.4761 12.5661 12.1738 15.2872L12.1573 15.435C12.0373 16.5151 11.2002 17.3764 10.1239 17.5271C8.05131 17.8172 5.94836 17.8172 3.87574 17.5271C2.79946 17.3764 1.96233 16.5151 1.84232 15.435L1.8259 15.2872C1.52356 12.5661 1.52356 9.81987 1.8259 7.09877L1.99269 5.59765ZM3.17327 6.29167L3.06825 7.23681C2.7761 9.86617 2.7761 12.5198 3.06825 15.1492L3.08467 15.2969C3.14159 15.8092 3.53861 16.2177 4.04905 16.2891C6.00669 16.5632 7.99298 16.5632 9.95062 16.2891C10.4611 16.2177 10.8581 15.8092 10.915 15.2969L10.9314 15.1492C11.2236 12.5198 11.2236 9.86617 10.9314 7.23681L10.8264 6.29167H3.17327Z"
        fill={color}
      />
    </svg>
  );
};
DeleteIcon.defaultProps = {
  color: red,
  height: 20,
  width: 20
};

export default DeleteIcon;
