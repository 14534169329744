import { Typography } from 'antd';
import './styles.less';

interface JetLegendProps {
  elevetion: number[];
}
const { Text } = Typography;

const JetLegend = ({ elevetion }: JetLegendProps) => {
  return (
    <>
      <div className="dem-jet-linear" />
      <div className="reading">
        {elevetion.map((elv: number, i: number) => (
          <Text key={i}>{elv.toFixed(2)} m</Text>
        ))}
      </div>
    </>
  );
};

export default JetLegend;
