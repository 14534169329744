import { Input, Typography } from 'antd';
import DownloadIcon from 'assets/svg/DownloadIcon';
import disableDownloadIcon from 'assets/svg/DisableDownloadIcon.svg';
import { primary } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { BsDot } from 'react-icons/bs';
import { getFileFromId } from 'store/aoi';
import { AoiPointsStartEnd } from 'store/aoi/types';

interface PointsRequestDetailProps {
  point?: AoiPointsStartEnd;
}

const { Text } = Typography;

const PointInputDownload = ({ point }: PointsRequestDetailProps) => {
  let selectedFile = null;
  if (point) {
    selectedFile = useAppSelector((state) => getFileFromId(state, point.file_id));
  }

  return (
    <>
      <Input
        className="start-inp"
        readOnly
        disabled={!selectedFile}
        value={selectedFile ? selectedFile.file_name : ''}
        suffix={
          selectedFile ? (
            <a href={selectedFile.url}>
              <DownloadIcon width={15} height={15} />
            </a>
          ) : (
            <img src={disableDownloadIcon} alt="download" width={15} height={15} />
          )
        }
      />
      {selectedFile && (
        <div className="approve-dwnld">
          <BsDot size={20} color={primary} />
          <Text className="approved" italic>
            Approved by Admin
          </Text>
        </div>
      )}
    </>
  );
};

PointInputDownload.defaultProps = {
  point: null
};

export default PointInputDownload;
