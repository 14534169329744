import { Input, Typography } from 'antd';
import { capitalize, has } from 'lodash';
import { FeatureParameter, towerObjectKeys } from 'constants/analytics.constants';
import { analyticsFormKey, getCost, getNumber } from 'utils/util';

interface TechnicalCostTableProps {
  technicalCostRoute: any;
  towerKeys: any;
  unitCost: any;
}
const { Text } = Typography;

const TechnicalCostTable = ({
  technicalCostRoute,
  unitCost,
  towerKeys
}: TechnicalCostTableProps) => {
  return (
    <table>
      <thead>
        <tr>
          <th>
            <Text className="title-span">Tower Type</Text>
          </th>
          <th>
            <Text className="title-span">Unit cost</Text>
          </th>
          <th>
            <Text className="title-span">No. of Towers</Text>
          </th>
          <th>
            <Text className="title-span">Total Cost</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Text>{towerObjectKeys[FeatureParameter.AngularTowers]}</Text>
          </td>
        </tr>
        {towerKeys.length > 0 &&
          towerKeys.map((towerkey: string) => {
            const towerCostKey: string = analyticsFormKey(towerkey);
            const towerValue =
              technicalCostRoute && technicalCostRoute[towerkey]
                ? getNumber(technicalCostRoute[towerkey])
                : 0;
            const towerUnitCost = unitCost && unitCost[towerCostKey] ? unitCost[towerCostKey] : 0;

            return (
              <tr key={towerCostKey}>
                <td>
                  <Text className="tower-type-title">{towerObjectKeys[towerkey]}</Text>
                </td>
                <td>
                  <Text>{towerUnitCost}</Text>
                </td>
                <td>
                  <Text>{towerValue}</Text>
                </td>
                <td>
                  <Text>
                    <Input value={getCost(towerUnitCost, towerValue)} readOnly />
                  </Text>
                </td>
              </tr>
            );
          })}

        {has(technicalCostRoute, FeatureParameter.SpecialTowers) && (
          <tr>
            <td>
              <Text>{capitalize(FeatureParameter.SpecialTowers)}</Text>
            </td>
            <td>
              <Text>{unitCost && unitCost.special_towers ? unitCost.special_towers : 0}</Text>
            </td>
            <td>
              <Text>
                {technicalCostRoute && technicalCostRoute[FeatureParameter.SpecialTowers]
                  ? technicalCostRoute[FeatureParameter.SpecialTowers]
                  : 0}
              </Text>
            </td>
            <td>
              <Text>
                <Input
                  value={
                    technicalCostRoute &&
                    unitCost &&
                    unitCost.special_towers &&
                    technicalCostRoute[FeatureParameter.SpecialTowers]
                      ? getCost(
                          unitCost.special_towers,
                          technicalCostRoute[FeatureParameter.SpecialTowers]
                        )
                      : 0
                  }
                  readOnly
                />
              </Text>
            </td>
          </tr>
        )}
        {has(technicalCostRoute, FeatureParameter.SpanTowers) && (
          <tr>
            <td>
              <Text>{capitalize(FeatureParameter.SpanTowers)}</Text>
            </td>
            <td>
              <Text>{unitCost && unitCost.span_towers ? unitCost.span_towers : 0}</Text>
            </td>
            <td>
              <Text>
                {technicalCostRoute && technicalCostRoute[FeatureParameter.SpanTowers]
                  ? technicalCostRoute[FeatureParameter.SpanTowers]
                  : 0}
              </Text>
            </td>
            <td>
              <Text>
                <Input
                  value={
                    technicalCostRoute &&
                    unitCost &&
                    unitCost.span_towers &&
                    technicalCostRoute[FeatureParameter.SpanTowers]
                      ? getCost(
                          unitCost.span_towers,
                          technicalCostRoute[FeatureParameter.SpanTowers]
                        )
                      : 0
                  }
                  readOnly
                />
              </Text>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TechnicalCostTable;
