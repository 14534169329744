import { Button } from 'antd';

interface CloseProjectModalProps {
  handleConfirm: Function;
  handleCancel: Function;
  isLoading: boolean;
  isClose: boolean;
}

const CloseProjectModal = ({
  handleConfirm,
  handleCancel,
  isLoading,
  isClose
}: CloseProjectModalProps) => {
  const handleConfirmClosedProject = () => {
    handleConfirm();
  };

  const handleCancelClosedProject = () => {
    handleCancel();
  };

  return (
    <div className="closed-project-main">
      <div className="closed-project-title">{`Notify all the members that the project is ${
        isClose ? 'open' : 'closed'
      }`}</div>
      <div className="closed-project-buttons">
        <Button
          className="closed-project-btn closed-project-confirm"
          type="primary"
          onClick={handleConfirmClosedProject}
          loading={isLoading}
        >
          Confirm
        </Button>
        <Button
          className="closed-project-btn closed-project-cancel"
          type="ghost"
          onClick={handleCancelClosedProject}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CloseProjectModal;
