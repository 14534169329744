import L from 'leaflet';
import PointRed from 'assets/png/PointRed.png';
import PointYellow from 'assets/png/LocationIcon.png';
import PointLightRed from 'assets/png/PointLightRed.png';
import { FilesCategory } from 'store/repositoryfile/types';
import type { PointInputOptionType } from 'types/aoi.types';

export const startPointInputOption: Array<PointInputOptionType> = [
  { label: 'Enter co-ordinates', value: 'start-coord' },
  { label: 'Start point from file repository', value: 'start-point-from-file-repository' }
];

export const endPointInputOption: Array<PointInputOptionType> = [
  { label: 'Enter co-ordinates', value: 'end-coord' },
  { label: 'End point from file repository', value: 'end-point-from-file-repository' }
];

export const beelinePointInputOption: Array<PointInputOptionType> = [
  {
    label: 'Start & End point and Bee Line from file repository',
    value: 'start-end-point-bee-line-from-file-repository'
  }
];

export const PointYellowIcon = L.icon({
  iconUrl: PointYellow,
  iconSize: [26, 28],
  iconAnchor: [13, 25]
});

export const PointRedIcon = L.icon({
  iconUrl: PointRed,
  iconSize: [26, 28],
  iconAnchor: [13, 25]
});

export const PointLightRedIcon = L.icon({
  iconUrl: PointLightRed,
  iconSize: [26, 28],
  iconAnchor: [13, 25]
});

export const vectorCategories = [
  FilesCategory.TL1200KV,
  FilesCategory.TL765KV,
  FilesCategory.TL400KV,
  FilesCategory.TL220KV,
  FilesCategory.TL132KV,
  FilesCategory.DistributionLines,
  FilesCategory.Telecommunication,
  FilesCategory.Other,
  FilesCategory.NoGoZone,
  FilesCategory.Buffer,
  FilesCategory.TelecomTowers,
  FilesCategory.Airports,
  FilesCategory.DistributionTowers,
  FilesCategory.Forests,
  FilesCategory.Railways,
  FilesCategory.Roadways,
  FilesCategory.SolarPlant,
  FilesCategory.TransmissionTowers,
  FilesCategory.WindTurbines,
  FilesCategory.WaterBodies,
  FilesCategory.WildlifeSanctuary
];

export const superAdminFileCategories: any = [
  FilesCategory.LULC,
  FilesCategory.RoutesFeatureList,
  FilesCategory.DEM,
  FilesCategory.DSM,
  FilesCategory.HighResolutionSataliteImages
];

export interface LulcDataType {
  title: string;
  value: string;
  color: string;
}

export interface LulcStyleType {
  label: string;
  env: string;
  color: string;
}
