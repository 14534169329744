import { Card, Col, Radio, Row, Typography } from 'antd';
import { isEmpty, upperFirst } from 'lodash';
import DownloadIcon from 'assets/svg/DownloadIcon';
import { RepositoryFile } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { getStorageValue } from 'utils/localStorage';
import { USER_ROLE } from 'constants/common.constant';
import EyeOpenIcon from 'assets/svg/EyeOpenIcon';
import EyeCloseIcon from 'assets/svg/EyeCloseIcon';
import { AoiSelectionResponse } from 'store/aoi/types';

const { Text } = Typography;

interface AoiListProps {
  data: RepositoryFile;
  selectedAoiIds: number[];
  handleShowHideSelectedAoi: Function;
  selectedAoi: AoiSelectionResponse | null;
}

const AoiList = ({
  data,
  handleShowHideSelectedAoi,
  selectedAoiIds,
  selectedAoi
}: AoiListProps) => {
  const userRole = getStorageValue(USER_ROLE);
  const firstname = upperFirst(decryptValue(data.first_name));
  const lastname = upperFirst(decryptValue(data.last_name));
  const isShowAoi = selectedAoiIds.includes(data.id);

  const handleAoiShowOnMap = (aoiId: number) => {
    handleShowHideSelectedAoi(aoiId);
  };

  return (
    <Card
      className={`aoi-card ${isShowAoi ? 'aoi-card-enable' : ''}`}
      style={
        isShowAoi
          ? { border: `1px solid ${data.color}`, borderLeft: `5px solid ${data.color}` }
          : { border: `1px solid ${data.color}`, borderLeft: `1px solid ${data.color}` }
      }
    >
      <Row className="inner-card-content">
        <Col span={2}>
          <Radio
            value={data.id}
            disabled={
              !isEmpty(selectedAoi) &&
              selectedAoi.is_approved &&
              data.id !== selectedAoi?.aoi_file_id
            }
            className="list-aoi"
          />
        </Col>
        <Col span={15} className="inner-card-content-col">
          <div>
            <Text>Aoi {data.id}</Text> <Text>( {data.length} sq.km)</Text>
          </div>
          {data.user_role === UserRole.SuperAdmin ? (
            <Text className="upload-name">
              Uploaded by <Text>SatSure</Text>
            </Text>
          ) : (
            <Text className="upload-name">
              Uploaded by{' '}
              <Text>
                {firstname} {lastname}
              </Text>
            </Text>
          )}
        </Col>
        <Col
          span={6}
          className={`inner-card-content-icon ${
            userRole === UserRole.SuperAdmin && 'remove-download'
          }`}
        >
          <div>
            {userRole !== UserRole.SuperAdmin && (
              <a href={data.url}>
                <DownloadIcon height={20} width={20} />
              </a>
            )}
            <Text onClick={() => handleAoiShowOnMap(data.id)}>
              {isShowAoi ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </Text>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default AoiList;
