interface TooltipProps {
  title: string;
  className?: string;
}

const TooltipTitle = ({ title, className }: TooltipProps) => {
  return <h4 className={`tooltipTitle ${className}`}>{title}</h4>;
};
TooltipTitle.defaultProps = {
  className: ''
};
export default TooltipTitle;
