import { useState } from 'react';
import { Avatar, Card, List, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { upperFirst } from 'lodash';
import { white } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { MemberT } from 'store/organizations/types';
import { getMember, getProjectActivityData } from 'store/projects';
import { Project, ProjectActivityData, TeamMember } from 'store/projects/types';
import { shortLabel } from 'utils/util';
import { decryptValue } from 'utils/decodeEncodeData';

interface ProjectTeamMemberProps {
  selectedProject: Project;
  loading: boolean;
}
const { Text } = Typography;

const TeamMemberAndRecentActivity = ({ selectedProject, loading }: ProjectTeamMemberProps) => {
  const [currentAvtar, setCurrentAvtar] = useState<MemberT | null>(null);
  const activityData: Array<ProjectActivityData> | [] = useAppSelector((state) =>
    getProjectActivityData(state, currentAvtar?.value)
  );

  const handleOnClick = (item: MemberT) => {
    if (currentAvtar && currentAvtar.value === item.value) {
      setCurrentAvtar(null);
    } else {
      setCurrentAvtar(item);
    }
  };

  return (
    <>
      <Card title="Team Members" className="mainMemberContent">
        {selectedProject?.team_members.map((m: TeamMember) => {
          const member = getMember(m);
          return (
            <Tooltip
              color={white}
              overlayClassName="tootltipMember"
              title={member.label}
              placement="bottom"
              key={member.value}
            >
              <Avatar
                className={currentAvtar?.value === member.value ? 'changecolor' : 'memberAvatar'}
                onClick={() => handleOnClick(member)}
              >
                {shortLabel(member.label)}
              </Avatar>
            </Tooltip>
          );
        })}
      </Card>
      <Card
        title="Recent Activity"
        extra={
          <span>
            {currentAvtar ? (
              <Avatar className="avtarchangecolor">{shortLabel(currentAvtar.label)}</Avatar>
            ) : (
              'All'
            )}
          </span>
        }
        className="mainActivityContent"
      >
        <List
          className="listMemberActivity"
          loading={loading}
          dataSource={activityData}
          renderItem={(item: ProjectActivityData) => (
            <List.Item
              extra={
                <Text className="activity-time">{moment(item.created_at).format('D MMM')}</Text>
              }
              key={item.id}
              className="memberListContent"
            >
              <List.Item.Meta
                avatar={
                  <Avatar className="itemAvatar">
                    {shortLabel(`${decryptValue(item.first_name)} ${decryptValue(item.last_name)}`)}
                  </Avatar>
                }
                title={`${upperFirst(decryptValue(item.first_name))} ${upperFirst(
                  decryptValue(item.last_name)
                )}`}
                description={item.message}
                className="memberListItem"
              />
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};

export default TeamMemberAndRecentActivity;
