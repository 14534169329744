import { IoIosClose } from 'react-icons/io';
import { Button, Input, Select, Tooltip, Upload, UploadProps } from 'antd';
import { isEmpty } from 'lodash';
import { lightGrey, primary, white } from 'constants/theme.constants';
import { USER_ROLE } from 'constants/common.constant';
import TooltipTitle from 'components/core/TooltipTitle';
import { AoiPointsCords } from 'store/aoi/types';
import { UserRole } from 'store/users/types';
import type { LatLongType, PointInputOptionType } from 'types/aoi.types';
import { getStorageValue } from 'utils/localStorage';

interface PointInputSelectionProps {
  isLoading: boolean;
  isDisabledButton: boolean;
  coordinate: LatLongType | null;
  handleChange: Function;
  handleSubmit: Function;
  handleFile: Function;
  handleClearPoints: Function;
  pointInputSelection: Array<PointInputOptionType>;
  dropDownValue: string | null;
  aoiPoints: AoiPointsCords;
  onInputClick: () => void;
  isDisabledInput?: boolean;
}

const { Option } = Select;

const PointInputSelection = ({
  isLoading,
  coordinate,
  isDisabledButton,
  handleChange,
  handleSubmit,
  pointInputSelection,
  handleFile,
  handleClearPoints,
  dropDownValue,
  aoiPoints,
  isDisabledInput = false,
  onInputClick
}: PointInputSelectionProps) => {
  const userRole = getStorageValue(USER_ROLE);
  const isBeeLine = aoiPoints ? Boolean(aoiPoints.bee_line) : false;
  const isApproved = aoiPoints ? aoiPoints.is_approved : false;
  const isManagerDisableButton = !isEmpty(aoiPoints) && userRole === UserRole.Manager;
  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      handleFile(file);
      return false;
    }
  };

  return (
    <>
      {!coordinate ? (
        <Select
          className="aoi-tab-select"
          placeholder="Select"
          onChange={(value) => handleChange(value)}
          allowClear
          value={dropDownValue}
          disabled={isDisabledInput || isLoading || isBeeLine || isApproved}
          loading={isLoading}
        >
          {pointInputSelection &&
            pointInputSelection.map((o: PointInputOptionType) => (
              <Option value={o.value} key={o.value}>
                {o.label}
              </Option>
            ))}
          <Option
            style={{
              color: primary,
              borderTop: `1px solid ${lightGrey}`,
              backgroundColor: `${white}`
            }}
            value="upload-file"
          >
            <Upload {...uploadProps} showUploadList={false}>
              <Button className="upload-file-btn">Upload File</Button>
            </Upload>
          </Option>
        </Select>
      ) : (
        <Tooltip
          color={white}
          title={<TooltipTitle title={`${coordinate.lat}, ${coordinate.lng}`} />}
        >
          <Input
            allowClear
            readOnly
            style={{ width: '100%' }}
            value={`${Number(coordinate.lat).toFixed(6)}, ${Number(coordinate.lng).toFixed(6)}`}
            onClick={!isApproved ? onInputClick : () => {}}
            suffix={
              !isApproved && (
                <IoIosClose
                  onClick={() => handleClearPoints()}
                  size={16}
                  style={{ cursor: 'pointer', fill: primary }}
                />
              )
            }
          />
        </Tooltip>
      )}
      <Button
        disabled={isDisabledButton || isApproved || isManagerDisableButton}
        onClick={() => handleSubmit()}
        className="start-point-btn"
        type="primary"
      >
        Submit
      </Button>
    </>
  );
};

PointInputSelection.defaultProps = {
  isDisabledInput: false
};

export default PointInputSelection;
