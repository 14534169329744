import { FilesCategory } from 'store/repositoryfile/types';

// Async variable names.
export const ACCESS_TOKEN = 'HTL_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'HTL_REFRESH_TOKEN';
export const AES_KEY = 'HTL_AES_KEY';
export const USER_ROLE = 'HTL_USER_ROLE';

// error
export const TOKEN_EXPIRE_ERROR = 'Token Already Expired';

// Date format
export const DateFormat1 = 'Do MMMM YYYY';
export const DateFormat2 = 'D MMM YYYY';
export const DateFormat3 = 'YYYY-MM-DD';
export const DateFormat4 = 'D MMM YYYY, HH:MM:SS';
export const DateFormat5 = 'MM/DD/YYYY HH:MM:SS';
export const TimeFormat = 'HH:MM:SS';

// Google map
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const defaultCenter = {
  lat: '20.5937',
  lng: '78.9629'
};
export const mapStyleWithoutLabel = [{ elementType: 'labels', stylers: [{ visibility: 'off' }] }];

export const vectorColors: any = {
  [FilesCategory.TL1200KV]: '#043E95',
  [FilesCategory.TL765KV]: '#1F76B5',
  [FilesCategory.TL400KV]: '#1DA7C6',
  [FilesCategory.TL220KV]: '#67C9D7',
  [FilesCategory.TL132KV]: '#A7E1EA',
  [FilesCategory.Airports]: '#909090',
  [FilesCategory.DistributionLines]: '#9C27DD',
  [FilesCategory.DistributionTowers]: '#9C27DD',
  [FilesCategory.Forests]: '#267300',
  [FilesCategory.Railways]: '#0070D7',
  [FilesCategory.Roadways]: '#C25D00',
  [FilesCategory.SolarPlant]: '#C98544',
  [FilesCategory.TelecomTowers]: '#CD00B9',
  [FilesCategory.Telecommunication]: '#CD00B9',
  [FilesCategory.TransmissionTowers]: '#0066FF',
  [FilesCategory.WaterBodies]: '#6398FE',
  [FilesCategory.WildlifeSanctuary]: '#00DE9D',
  [FilesCategory.WindTurbines]: '#00FFFF',
  [FilesCategory.Other]: '#D20000',
  [FilesCategory.NoGoZone]: '#A10000',
  [FilesCategory.Buffer]: '#D20000'
};

export const circleShapVectors = [
  FilesCategory.DistributionTowers,
  FilesCategory.TelecomTowers,
  FilesCategory.TransmissionTowers,
  FilesCategory.WindTurbines
];

export const polygonShapVectors = [
  FilesCategory.Airports,
  FilesCategory.Forests,
  FilesCategory.Buffer,
  FilesCategory.NoGoZone,
  FilesCategory.SolarPlant,
  FilesCategory.WaterBodies,
  FilesCategory.WildlifeSanctuary
];

export const routeColors = [
  '#FF0A0A',
  '#00AA6D',
  '#140FFF',
  '#FFFF00',
  '#FF15CC',
  '#C6E09F',
  '#7AF5AA',
  '#547FDE',
  '#7BB899',
  '#CF7C1B',
  '#F1035C',
  '#FB5FDB',
  '#EB5F12',
  '#E1679B'
];

export const aoiColors = [
  '#FFEA2E',
  '#AF2EFF',
  '#FFAA2C',
  '#7F5E8F',
  '#D46861',
  '#74AA87',
  '#4930DB',
  '#CD9D73',
  '#C6E09F',
  '#7AF5AA',
  '#547FDE',
  '#7BB899',
  '#CF7C1B',
  '#F1035C',
  '#FB5FDB',
  '#EB5F12',
  '#E1679B'
];
