import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { defaultCenter } from 'constants/common.constant';
import { LatLongType } from 'types/aoi.types';

interface ControlMapCenterProps {
  zoomLevel: number;
  center?: LatLongType | null | undefined;
}

const ControlMapCenter = ({ zoomLevel, center = defaultCenter }: ControlMapCenterProps) => {
  const map = useMap();
  const [isBound, setBound] = useState<boolean>(false);

  useEffect(() => {
    if (isBound) return;
    if (!center) return;
    map.setView({ lat: Number(center?.lat), lng: Number(center?.lng) }, zoomLevel);
    setBound(true);
  }, [center, zoomLevel, isBound]); // eslint-disable-line

  return null;
};

ControlMapCenter.defaultProps = {
  center: defaultCenter
};

export default ControlMapCenter;
