import { Button, Tabs, Typography } from 'antd';
import { useAppSelector } from 'hooks/useReduxHook';
import AnalyticsRouteItem from './AnalyticsRouteItem';
import UnitCostForm from './UnitCostForm';
import './styles.less';

const { Text } = Typography;

const CostTab = () => {
  const { analyticsData } = useAppSelector((state) => state.analytics);

  return (
    <div className="cost-tab-inr">
      <Tabs tabPosition="left" defaultActiveKey="0" className="main-tabs-inr">
        <Tabs.TabPane
          tab={
            <Button className="unit-cost-btn" size="small">
              Unit Cost
            </Button>
          }
          key="0"
          className="unit-cost-tab"
        >
          <Text className="unit-cost-title-inr">Enter unit cost to know costing for routes</Text>
          <UnitCostForm />
        </Tabs.TabPane>

        {analyticsData &&
          analyticsData.routes_analytics &&
          analyticsData.unit_cost &&
          Object.entries(analyticsData.routes_analytics).map((route, index) => {
            return (
              <Tabs.TabPane
                tab={
                  <Button className={`route${index + 1}-btn`} size="small">
                    {route[0]}
                  </Button>
                }
                key={route[0]}
                className="route3-tab"
              >
                <AnalyticsRouteItem
                  key={route[0]}
                  analyticsRoute={route[1]}
                  analyticsRouteKey={route[0]}
                  unitCost={analyticsData.unit_cost}
                />
              </Tabs.TabPane>
            );
          })}
      </Tabs>
    </div>
  );
};

export default CostTab;
