import { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { find } from 'lodash';
import { Layout, PageHeader, Tooltip, Typography } from 'antd';
import { TbRoute } from 'react-icons/tb';
import { FaRegFolderOpen } from 'react-icons/fa';
import { HiOutlineChartSquareBar } from 'react-icons/hi';
import { AiOutlinePieChart } from 'react-icons/ai';
import SandWatchIcon from 'assets/svg/SandWatchIcon';
import AoiWhite from 'assets/svg/AOIwhite.svg';
import AoiBlack from 'assets/svg/AOI.svg';
import backIcon from 'assets/svg/backIcon.svg';
import { greyShed, white } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import RedDot from 'components/core/RedDot';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { clearProjectData } from 'store/users/actions';
import { ProjectStage, ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import './styles.less';

const { Sider } = Layout;
const { Text } = Typography;

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { project } = useAppSelector((state) => state.projects);
  const [collapsed, setCollapsed] = useState(false);

  const projectStageData = project?.stages;

  const defStageData: ProjectStage = projectStageData
    ? find(projectStageData, { stage_name: ProjectStagesList.DefiningCoordinates })
    : '';
  const finalAoiStageData: ProjectStage = projectStageData
    ? find(projectStageData, { stage_name: ProjectStagesList.AOIFinalization })
    : '';

  const isShowRedDot: boolean =
    (defStageData && defStageData.status !== ProposedTimelineStatus.Completed) ||
    (finalAoiStageData && finalAoiStageData.status !== ProposedTimelineStatus.Completed);

  const handleBackNavigation = () => {
    dispatch(clearProjectData());
    navigate(routes.ProjectList, { replace: true });
  };

  return (
    <Sider
      className="mainSideBar"
      collapsible
      width={250}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 8px' }}
    >
      <PageHeader
        className="back-button"
        title={
          !collapsed ? (
            <div className="back-title-main" onClick={handleBackNavigation}>
              <img className="back-image" src={backIcon} alt="backIcon" />
              <Text className="back-text">back</Text>
            </div>
          ) : (
            <div className="back-title-main" onClick={handleBackNavigation}>
              <img className="back-image" src={backIcon} alt="backIcon" />
            </div>
          )
        }
        backIcon={null}
      />
      <div className="main-divider">
        <div className="inner-divider" />
      </div>
      <ul>
        <div>
          <NavLink
            to={routes.ProjectTimelineUrl}
            className={({ isActive }) => (isActive ? 'activeLink' : undefined)}
          >
            {collapsed ? (
              <Tooltip
                title="Project Timeline"
                placement="right"
                overlayClassName="menu-tooltip-card"
                color={white}
                overlayInnerStyle={{ color: `${greyShed}` }}
              >
                <HiOutlineChartSquareBar size={20} />
              </Tooltip>
            ) : (
              <HiOutlineChartSquareBar size={20} />
            )}
            {!collapsed && <Text className="link-title">Project Timeline</Text>}
          </NavLink>
          <NavLink
            to={routes.FileRepository}
            className={({ isActive }) => (isActive ? 'activeLink' : undefined)}
          >
            {collapsed ? (
              <Tooltip
                title="File Repository"
                placement="right"
                overlayClassName="menu-tooltip-card"
                color={white}
                overlayInnerStyle={{ color: `${greyShed}` }}
              >
                <FaRegFolderOpen size={20} />
              </Tooltip>
            ) : (
              <FaRegFolderOpen size={20} />
            )}

            {!collapsed && <Text className="link-title">File Repository</Text>}
          </NavLink>
          <NavLink
            to={routes.AOI}
            className={({ isActive }) => (isActive ? 'activeLink ' : undefined)}
          >
            {({ isActive }: any) =>
              collapsed ? (
                <div style={{ width: 'unset', position: 'relative' }}>
                  <Tooltip
                    title="AOI"
                    placement="right"
                    overlayClassName="menu-tooltip-card"
                    color={white}
                    overlayInnerStyle={{ color: `${greyShed}` }}
                  >
                    <img
                      className="aoi-image"
                      src={isActive ? AoiWhite : AoiBlack}
                      alt="AOI"
                      style={{ position: 'relative' }}
                    />
                  </Tooltip>
                  {isShowRedDot && <RedDot Right="0px" Top="0px" />}
                </div>
              ) : (
                <>
                  <img className="aoi-image" src={isActive ? AoiWhite : AoiBlack} alt="AOI" />
                  <div>
                    <Text className="link-title" style={{ position: 'relative' }}>
                      AOI
                      {isShowRedDot && <RedDot Right="-11px" Top="8px" />}
                    </Text>
                    <div
                      className="notification"
                      style={{ marginRight: '-9px', marginBottom: '-5px' }}
                    >
                      {isShowRedDot && (
                        <SandWatchIcon color={isActive ? `${white}` : `${greyShed}`} />
                      )}
                    </div>
                  </div>
                </>
              )
            }
          </NavLink>
          <NavLink
            to={routes.Routes}
            className={({ isActive }) => (isActive ? 'activeLink' : undefined)}
          >
            {collapsed ? (
              <Tooltip
                title="Route"
                placement="right"
                overlayClassName="menu-tooltip-card"
                color={white}
                overlayInnerStyle={{ color: `${greyShed}` }}
              >
                <TbRoute size={20} />
              </Tooltip>
            ) : (
              <TbRoute size={20} />
            )}
            {!collapsed && <Text className="link-title">Route</Text>}
          </NavLink>
          <NavLink
            to={routes.Analytics}
            className={({ isActive }) => (isActive ? 'activeLink' : undefined)}
          >
            {collapsed ? (
              <Tooltip
                title="Analytics"
                placement="right"
                overlayClassName="menu-tooltip-card"
                color={white}
                overlayInnerStyle={{ color: `${greyShed}` }}
              >
                <AiOutlinePieChart size={20} />
              </Tooltip>
            ) : (
              <AiOutlinePieChart size={20} />
            )}
            {!collapsed && <Text className="link-title">Analytics</Text>}
          </NavLink>
          {/* <NavLink
            to={routes.Message}
            className={({ isActive }) => (isActive ? 'activeLink' : undefined)}
          >
            <AiOutlineMessage size={20} />
            {!collapsed && (
              <div>
                <div>
                  <Text className='link-title'>Messages</Text>
                  <div className="dot" />
                </div>
                <div className="notification">2</div>
              </div>
            )}
          </NavLink> */}
        </div>
      </ul>
    </Sider>
  );
};

export default SideBar;
