import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Input, Row, Col } from 'antd';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { AddOrganizationMemberPayload } from 'store/organizations/types';
import { addOrganizationMembers } from 'store/organizations/actions';
import { encryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const TeamOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedOrganization, isNewOrganizationMember } = useAppSelector(
    (state) => state.organizations
  );
  const [form] = Form.useForm();

  const handleCallBack = (status: boolean) => {
    if (status) {
      form.resetFields();
      navigate(routes.ProjectList, { replace: true });
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleSkipMember = () => {
    handleCallBack(true);
  };

  const handleSubmitForm = (values: AddOrganizationMemberPayload) => {
    const emails = values.members.map((item) => encryptValue(item.email));
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        addOrganizationMembers(
          {
            members_email_list: emails,
            organisation_id: selectedOrganization?.id!
          },
          selectedOrganization?.id!,
          token,
          handleCallBack
        )
      );
    });
  };

  return (
    <>
      <HeaderLayout />
      <Row justify="center">
        <Col xxl={9} xl={10} lg={12} className="mainContentDiv">
          <p className="titleContent">Team Onboarding</p>
          <Form
            className="mainContentForm"
            form={form}
            onFinish={handleSubmitForm}
            name="add_member_form"
            autoComplete="off"
            initialValues={{ members: [{ email: '' }] }}
          >
            <div className="mainFormDiv">
              <h3>Add Team Members to Organization</h3>
              <Form.List name="members">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field) => (
                        <Row key={field.key} justify="space-between" className="inputMainContent">
                          <Form.Item
                            {...field}
                            name={[field.name, 'email']}
                            rules={[{ required: true, message: 'Required' }]}
                            className="emailInputContent"
                          >
                            <Input
                              type="email"
                              placeholder="Email address of member"
                              className="innerInputContent"
                            />
                          </Form.Item>

                          <AiOutlineMinusCircle onClick={() => remove(field.name)} />
                        </Row>
                      ))}
                      <Form.Item className="memberBtn">
                        <Button onClick={() => add()} icon={<AiOutlinePlusCircle />}>
                          Add another Member
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
              <Row justify="end">
                <Form.Item className="skip-member-btn">
                  <Button type="ghost" htmlType="button" onClick={handleSkipMember}>
                    Skip
                  </Button>
                </Form.Item>
                <Form.Item className="addMemberBtn">
                  <Button type="primary" htmlType="submit" loading={isNewOrganizationMember}>
                    Add Members
                  </Button>
                </Form.Item>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default TeamOnboarding;
