import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Tabs, Typography } from 'antd';
import { USER_ROLE } from 'constants/common.constant';
import { find, isEmpty } from 'lodash';
import { RepositoryFile } from 'store/repositoryfile/types';
import { BothPointLatLngType, LatLongType } from 'types/aoi.types';
import { AoiPointsCords } from 'store/aoi/types';
import { UserRole } from 'store/users/types';
import { ProjectStage, ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { getStorageValue } from 'utils/localStorage';
import PointsTab from './PointsTab';
import AOITab from './AOITab';
import './styles.less';

interface AoitabProps {
  loading: boolean;
  handleFileApproval: Function;
  handleApproveAoiByAdmin: Function;
  selectedAoiIds: number[];
  projectStageData: any;
  aoiPointsCoords: AoiPointsCords | null;
  allAois: RepositoryFile[];
  superAdminAOIs: RepositoryFile[];
  adminManagerAOIs: RepositoryFile[];
  handlePointsData: (data: BothPointLatLngType) => void;
  handleAoiSelect: (data: RepositoryFile | null) => void;
  handleShowHideSelectedAoi: Function;
}

const { Text } = Typography;

const AoiTabs = forwardRef(
  (
    {
      loading,
      handlePointsData,
      handleAoiSelect,
      handleFileApproval,
      handleApproveAoiByAdmin,
      handleShowHideSelectedAoi,
      selectedAoiIds,
      projectStageData,
      aoiPointsCoords,
      allAois,
      superAdminAOIs,
      adminManagerAOIs
    }: AoitabProps,
    ref
  ) => {
    const childRef: any = useRef();
    const userRole = getStorageValue(USER_ROLE);

    const defStageData: ProjectStage = projectStageData
      ? find(projectStageData, { stage_name: ProjectStagesList.DefiningCoordinates })
      : '';
    const requestOfAoiStageData: ProjectStage = projectStageData
      ? find(projectStageData, { stage_name: ProjectStagesList.AOIRequest })
      : '';
    const finalAoiStageData: ProjectStage = projectStageData
      ? find(projectStageData, { stage_name: ProjectStagesList.AOIFinalization })
      : '';

    const isShowPoint: boolean =
      requestOfAoiStageData &&
      requestOfAoiStageData.status === ProposedTimelineStatus.Completed &&
      finalAoiStageData &&
      finalAoiStageData.status !== ProposedTimelineStatus.Completed;

    useImperativeHandle(ref, () => ({
      handleReverseChangeStartPoint: (latLng: LatLongType) => {
        if (childRef.current) childRef.current.handleReverseChangeStartPoint(latLng);
      },
      handleReverseChangEndPoint: (latLng: LatLongType) => {
        if (childRef.current) childRef.current.handleReverseChangEndPoint(latLng);
      }
    }));

    return (
      <div className="left-content">
        <Tabs className="main-tab" defaultActiveKey="point">
          {/* Point tab */}
          <Tabs.TabPane
            tab={
              <>
                <Text className="tab-title-inr">POINTS</Text>
                {userRole !== UserRole.SuperAdmin &&
                  defStageData &&
                  defStageData.status !== ProposedTimelineStatus.Completed && (
                    <div className="dot-point" style={{ right: '23px' }} />
                  )}
                {userRole === UserRole.SuperAdmin &&
                  !isEmpty(aoiPointsCoords) &&
                  !aoiPointsCoords.is_approved && (
                    <div className="dot-point" style={{ right: '23px' }} />
                  )}
              </>
            }
            key="point"
            className="point-tab"
          >
            <PointsTab ref={childRef} handlePointsData={handlePointsData} />
          </Tabs.TabPane>
          {/* AOI tab */}
          <Tabs.TabPane
            tab={
              <>
                <Text className="tab-title-inr">AOI</Text>
                {isShowPoint && <div className="dot-point" style={{ right: '35px' }} />}
              </>
            }
            key="aoi"
            className="aoi-tab"
          >
            <AOITab
              loading={loading}
              allAois={allAois}
              superAdminAOIs={superAdminAOIs}
              adminManagerAOIs={adminManagerAOIs}
              handleApprove={handleFileApproval}
              handleApproveAoiByAdmin={handleApproveAoiByAdmin}
              handleAoiSelect={handleAoiSelect}
              handleShowHideSelectedAoi={handleShowHideSelectedAoi}
              selectedAoiIds={selectedAoiIds}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
);

export default AoiTabs;
