import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { red } from 'constants/theme.constants';
import './styles.less';

const ScreenLoader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div className="full-screen-loader">
      <div className="loader-content">
        <Spin
          size="large"
          tip="Loading..."
          spinning={isLoading}
          indicator={<LoadingOutlined color={red} style={{ fontSize: 24 }} spin />}
        />
      </div>
    </div>
  );
};

export default ScreenLoader;
