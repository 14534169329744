import { Button, Form, Input, Modal, Typography } from 'antd';
import { useEffect } from 'react';
import { coordSync } from 'utils/yupsync';
import type { LatLongType } from 'types/aoi.types';
import './styles.less';

const { Text } = Typography;

interface PointFormModalProps {
  coordinate: LatLongType | null;
  isOpenModal: boolean;
  handleCloseModal: Function;
  handleChangeLatLng: Function;
}

const PointFormModal = ({
  isOpenModal,
  handleCloseModal,
  handleChangeLatLng,
  coordinate
}: PointFormModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (coordinate) {
      form.setFieldsValue({
        lat: coordinate.lat,
        lng: coordinate.lng
      });
    }
  }, [coordinate]);

  const handleSubmitCoords = (value: LatLongType) => {
    handleChangeLatLng(value);
    form.resetFields();
  };

  const handleCancel = () => {
    handleCloseModal();
    if (!coordinate) {
      form.resetFields();
    }
  };

  return (
    <Modal
      title={<Text className="modal-title">Enter Co-ordinates</Text>}
      centered
      forceRender
      width={350}
      open={isOpenModal}
      className="coord-modal-content"
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" className="coord-form" onFinish={handleSubmitCoords}>
        <Form.Item
          className="latitude-item"
          label={<Text className="latitude-label">LATITUDE</Text>}
          name="lat"
          rules={[coordSync]}
        >
          <Input className="lat-lng-inp" />
        </Form.Item>
        <Form.Item
          className="longitude-item"
          label={<Text className="longitude-label">LONGITUDE</Text>}
          name="lng"
          rules={[coordSync]}
        >
          <Input className="lat-lng-inp" />
        </Form.Item>
        <div className="coord-btn">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button type="ghost" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PointFormModal;
