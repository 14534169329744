import { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  UploadProps
} from 'antd';
import { find, isEmpty, orderBy } from 'lodash';
import { primary } from 'constants/theme.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addRepositoryFile } from 'store/repositoryfile/actions';
import {
  Category,
  UploadFilePayload,
  UploadRepoFile,
  FilesCategory
} from 'store/repositoryfile/types';
import { getAdminManagerCategory } from 'store/repositoryfile';
import { UserRole } from 'store/users/types';
import { fileRepositorySync } from 'utils/yupsync';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Option } = Select;
const { Text } = Typography;

const FileRepositoryForm = () => {
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.user);
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { isRequestingCategory, categories, isRequestingAddRepoFile } = useAppSelector(
    (state) => state.repofiles
  );
  const adminManagerCategories = useAppSelector(getAdminManagerCategory);
  const [file, setFile] = useState<File>();
  const [lulcStyleFile, setLulcStyleFile] = useState<File>();
  const [lulcStylePayload, setLulcStylePayload] = useState<UploadFilePayload | null>(null);

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      setFile(file);
      return false;
    }
  };

  const lulcUploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      setLulcStyleFile(file);
      return false;
    }
  };

  const handleUploadCallback = () => {
    setFile(undefined);
    form.resetFields();
  };

  const handleUploadFile = (values: UploadRepoFile) => {
    const foundCategory = find(
      categories,
      (cat: Category) => cat.id === values.category_id
    )?.category_name;
    const payload: UploadFilePayload = {
      ...values,
      category_id: values.category_id,
      buffer: values.buffer ? values.buffer.toString() : '0',
      user_id: user.id,
      project_id: selectedProject?.id
    };

    if (foundCategory === FilesCategory.LULC) {
      setLulcStylePayload(payload);
      return;
    }

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addRepositoryFile(payload, token, handleUploadCallback, file));
    });
  };

  const handleClearFile = () => {
    setFile(undefined);
  };

  const handleUploadLulcStyleFile = () => {
    if (lulcStyleFile && lulcStylePayload) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          addRepositoryFile(lulcStylePayload, token, handleUploadCallback, file, lulcStyleFile)
        );
      });
    }
    setLulcStylePayload(null);
  };

  const handleCancelLulcStyleFile = () => {
    setLulcStyleFile(undefined);
    setLulcStylePayload(null);
  };

  return (
    <>
      <div className="repositoryForm">
        <Form layout="vertical" form={form} onFinish={handleUploadFile}>
          <Row justify="space-between">
            <Col xxl={6} xl={6} lg={4} md={4}>
              <Form.Item className="uplodeFile" label={<Text>Upload File</Text>} labelAlign="left">
                {file === undefined ? (
                  <Upload {...uploadProps} showUploadList={false}>
                    <Button className="uplodeFileBtn" size="large">
                      Select File
                    </Button>
                  </Upload>
                ) : (
                  <Input
                    className="file-input"
                    size="large"
                    value={file.name}
                    readOnly
                    suffix={
                      <IoIosClose
                        size={20}
                        style={{ fill: primary, cursor: 'pointer' }}
                        onClick={handleClearFile}
                      />
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xxl={10} xl={10} lg={11} md={9}>
              <Form.Item
                className="uplodeFile"
                label={
                  <Text>
                    Category<Text>*</Text>
                  </Text>
                }
                labelAlign="left"
                name="category_id"
                rules={[fileRepositorySync]}
              >
                <Select
                  className="selectCategory"
                  size="large"
                  showSearch
                  allowClear
                  disabled={isRequestingCategory}
                  loading={isRequestingCategory}
                  placeholder="Select Category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {userRole === UserRole.SuperAdmin
                    ? categories &&
                      categories.length > 0 &&
                      orderBy(categories, ['category_name'], ['asc']).map((cat: Category) => {
                        return (
                          <Option key={cat.id} value={cat.id}>
                            {cat.category_name}
                          </Option>
                        );
                      })
                    : adminManagerCategories &&
                      adminManagerCategories.length > 0 &&
                      orderBy(adminManagerCategories, ['category_name'], ['asc']).map(
                        (cat: Category) => {
                          return (
                            <Option key={cat.id} value={cat.id}>
                              {cat.category_name}
                            </Option>
                          );
                        }
                      )}
                </Select>
              </Form.Item>
            </Col>
            <Col xxl={4} xl={4} lg={6} md={5}>
              <Form.Item
                className="uplodeFile"
                name="buffer"
                label={<Text>Add Buffer</Text>}
                labelAlign="left"
              >
                <div className="innerBufferContent">
                  <Input type="number" size="large" placeholder="0 (default)" />
                  <Text className="bufferM"> m</Text>
                </div>
              </Form.Item>
            </Col>
            <Col xxl={17} xl={17} lg={17} md={16}>
              <Form.Item
                name="comment"
                className="uplodeFile"
                label={
                  <Text>
                    Add Comments<Text>*</Text>
                  </Text>
                }
                labelAlign="left"
                rules={[fileRepositorySync]}
              >
                <Input className="addCommentsInput" size="large" placeholder="Type here" />
              </Form.Item>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} className="uplodeBtnCol">
              <Button
                loading={isRequestingAddRepoFile}
                disabled={isEmpty(file)}
                htmlType="submit"
                type="primary"
                size="large"
              >
                Upload
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <Modal
        title=""
        className="approve-modal"
        width={722}
        closable={false}
        open={!!lulcStylePayload}
        footer={null}
        centered
      >
        <div className="approve-modal-content">
          <Text className="modal-title" style={{ marginBottom: '20px' }}>
            Please Upload LULC style file.
          </Text>
          {lulcStyleFile ? (
            <Text style={{ marginBottom: '20px', color: primary, fontSize: '18px' }}>
              {lulcStyleFile.name}
            </Text>
          ) : (
            <Upload {...lulcUploadProps} showUploadList={false} accept=".sld">
              <Button style={{ marginBottom: '20px' }}>Select file</Button>
            </Upload>
          )}
          <div className="modal-content-btn">
            <Button type="primary" disabled={!lulcStyleFile} onClick={handleUploadLulcStyleFile}>
              Upload
            </Button>
            <Button type="ghost" onClick={handleCancelLulcStyleFile}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FileRepositoryForm;
