export enum FeatureParameter {
  AngularTowers = 'angular towers',
  PowerlineCables = 'powerline cables',
  SpecialTowers = 'special towers',
  SpanTowers = 'span towers',
  TotalLength = 'total length',
  TowerA = 'tower type a',
  TowerB = 'tower type b',
  TowerC = 'tower type c',
  TowerD = 'tower type d',
  TowerE = 'tower type e',
  Tl132KV = 'transmission line crossing (132 kv)',
  Tl220KV = 'transmission line crossing (220 kv)',
  Tl400KV = 'transmission line crossing (400 kv)',
  Tl756KV = 'transmission line crossing (756 kv)',
  Tl765KV = 'transmission line crossing (765 kv)',
  TlUnKV = 'transmission line crossing (unknown kv)',
  TlTotal = 'transmission line crossing (total)',
  TlcTotal = 'transmission line crossing'
}

export const towerObjectKeys: any = {
  [FeatureParameter.AngularTowers]: 'Angular towers',
  [FeatureParameter.TowerA]: 'Tower type A',
  [FeatureParameter.TowerB]: 'Tower type B',
  [FeatureParameter.TowerC]: 'Tower type C',
  [FeatureParameter.TowerD]: 'Tower type D',
  [FeatureParameter.TowerE]: 'Tower type E'
};

export const fixedParameter = [
  FeatureParameter.TotalLength,
  FeatureParameter.AngularTowers,
  FeatureParameter.TowerA,
  FeatureParameter.TowerB,
  FeatureParameter.TowerC,
  FeatureParameter.TowerD,
  FeatureParameter.TowerE,
  FeatureParameter.SpanTowers,
  FeatureParameter.SpecialTowers,
  FeatureParameter.TlTotal,
  FeatureParameter.TlcTotal,
  FeatureParameter.Tl132KV,
  FeatureParameter.Tl220KV,
  FeatureParameter.Tl400KV,
  FeatureParameter.Tl756KV,
  FeatureParameter.Tl765KV,
  FeatureParameter.TlUnKV
];

export const lengthParameter = [
  FeatureParameter.AngularTowers,
  FeatureParameter.TlTotal,
  FeatureParameter.TlcTotal
];

export const towerParameter = [
  FeatureParameter.TowerA,
  FeatureParameter.TowerB,
  FeatureParameter.TowerC,
  FeatureParameter.TowerD,
  FeatureParameter.TowerE
];

export const transmissionLineParameter = [
  FeatureParameter.Tl132KV,
  FeatureParameter.Tl220KV,
  FeatureParameter.Tl400KV,
  FeatureParameter.Tl756KV,
  FeatureParameter.Tl765KV,
  FeatureParameter.TlUnKV
];

export const specialtowerParameter = [FeatureParameter.SpanTowers, FeatureParameter.SpecialTowers];

export const costFeatureNotShowParameter = [
  FeatureParameter.TotalLength,
  FeatureParameter.AngularTowers,
  FeatureParameter.TowerA,
  FeatureParameter.TowerB,
  FeatureParameter.TowerC,
  FeatureParameter.TowerD,
  FeatureParameter.TowerE,
  FeatureParameter.SpanTowers,
  FeatureParameter.SpecialTowers,
  FeatureParameter.PowerlineCables,
  FeatureParameter.TlTotal,
  FeatureParameter.TlcTotal
];
