import { toast } from 'react-toastify';
import { filter, find } from 'lodash';
import { ACCESS_TOKEN, routeColors, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { GET_DEM_ELEVETION_URL } from 'utils/apiUrls';
import { Response } from 'types/common.types';
import { ReformatPoints } from 'types/project.types';
import { apiCall, getRepoFileCoordinates } from 'utils/connect';
import { reformatCoordinates } from 'utils/xml';
import { AppDispatch, RootState } from '..';
import { DemElevetionType } from './types';
import {
  setDemElevetion,
  setDsmElevetion,
  setRequestingDemElevetion,
  setRequestingDsmElevetion,
  setRequestingRouteFiles,
  setRoutes
} from '.';

export const getExtractRouteFiles =
  (token: string) => async (dispatch: AppDispatch, getState: RootState) => {
    try {
      dispatch(setRequestingRouteFiles(true));

      const state = getState();
      const { repositoryFiles } = state.repofiles;
      const { project } = state.projects;
      const files = filter(repositoryFiles, {
        category: FilesCategory.Routes,
        enabled_for_map: true
      });
      const routes: RepositoryFile[] = [];

      // eslint-disable-next-line
      for await (const [i, file] of files.entries()) {
        // const fileFromUrl = await getFileFromUrl(file.url);
        // const extractFile = await readFileAsyncAsText(fileFromUrl);
        // const length = await extractLengthFromKML(extractFile);
        // const coordinates = await extractCoordinatesFromKML(extractFile);

        const response = await getRepoFileCoordinates(project.id, file.id, token);
        let coordinate: ReformatPoints | null = null;
        let length = 0;

        if (response.length > 0) {
          coordinate = await reformatCoordinates(response);
          // length = await extractLengthFromCoords(coordinate);
          length = coordinate?.length;
        }

        routes.push({
          ...file,
          length: (length / 1000).toFixed(3),
          coordinates: coordinate?.points || [],
          color: routeColors[i]
        });
      }

      dispatch(setRoutes(routes));
      dispatch(setRequestingRouteFiles(false));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestingRouteFiles(false));
    }
  };

export const getDemElevetionValue =
  (projectId: number, token: string) => (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const { repositoryFiles } = state.repofiles;
    const isDemFile = find(
      repositoryFiles,
      (file: RepositoryFile) => file.category === FilesCategory.DEM && file.enabled_for_map
    );

    if (!isDemFile) return;

    try {
      dispatch(setRequestingDemElevetion(true));

      const onSuccess = (response: Response<DemElevetionType>) => {
        dispatch(setDemElevetion(response.data));
        dispatch(setRequestingDemElevetion(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDemElevetionValue(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDemElevetion(false));
        }
      };

      apiCall(
        'GET',
        GET_DEM_ELEVETION_URL(projectId, isDemFile.id),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDemElevetion(false));
      toast.error(error.message);
    }
  };

export const getDsmElevetionValue =
  (projectId: number, token: string) => (dispatch: AppDispatch, getState: RootState) => {
    const state = getState();
    const { repositoryFiles } = state.repofiles;
    const isDsmFile = find(
      repositoryFiles,
      (file: RepositoryFile) => file.category === FilesCategory.DSM && file.enabled_for_map
    );

    if (!isDsmFile) return;

    try {
      dispatch(setRequestingDsmElevetion(true));

      const onSuccess = (response: Response<DemElevetionType>) => {
        dispatch(setDsmElevetion(response.data));
        dispatch(setRequestingDsmElevetion(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getDsmElevetionValue(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingDsmElevetion(false));
        }
      };

      apiCall(
        'GET',
        GET_DEM_ELEVETION_URL(projectId, isDsmFile.id),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingDsmElevetion(false));
      toast.error(error.message);
    }
  };
