import { useMemo } from 'react';
import 'scripts/wmts';
import { LulcDataType } from 'constants/map.constants';
import { RepositoryFile } from 'store/repositoryfile/types';
import WMTSLULCTileLayer from './WMTSLULCTileLayer';

interface LULCTileLayersProps {
  selectedLulcLayer: string[];
  geoserverLayerStyles: LulcDataType[];
  lulcRepoFile: RepositoryFile;
}

const LULCTileLayers = ({
  selectedLulcLayer,
  geoserverLayerStyles,
  lulcRepoFile
}: LULCTileLayersProps) => {
  const layers = useMemo(() => {
    return geoserverLayerStyles.map((style: LulcDataType) => {
      return (
        <WMTSLULCTileLayer
          key={style.title}
          selectedLulcLayer={selectedLulcLayer}
          lulcRepoFile={lulcRepoFile}
          env={style.value}
        />
      );
    });
  }, [geoserverLayerStyles, selectedLulcLayer]);
  // eslint-disable-next-line
  return <>{layers}</>;
};

export default LULCTileLayers;
