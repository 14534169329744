import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { ACCESS_TOKEN, REFRESH_TOKEN, AES_KEY, USER_ROLE } from 'constants/common.constant';
import type { Response, Error, JWT } from 'types/common.types';
import { apiCall } from 'utils/connect';
import { RSA_URL, LOGIN_URL, LOGOUT_URL, ADD_USER_DETAIL_URL } from 'utils/apiUrls';
import { decryptRsa } from 'utils/decodeEncodeData';
import { getStorageValue, setStorageValue, removeAllCookies } from 'utils/localStorage';
import { getOrganization, getOrganizationMembers } from 'store/organizations/actions';
import { getProjects } from 'store/projects/actions';
import { setAnalytics } from 'store/analytics';
import {
  setOrganizationMembers,
  setOrganizations,
  setSelectedOrganization
} from 'store/organizations';
import {
  setAvailableProjectMembers,
  setProject,
  setProjectActivityLogs,
  setProjects,
  setSelectedProject
} from 'store/projects';
import { setAoi, setAoiPointsCoords, setExtractAoiFiles } from 'store/aoi';
import { setCategories, setFiles } from 'store/repositoryfile';
import { setRoutes } from 'store/route';
import { AppDispatch, RootState } from '..';
import { User, Rsa, LoginPayload, UserDetailPayload } from './types';
import {
  setRsaRequest,
  setRSA,
  setLoginRequest,
  setUser,
  setLogoutRequest,
  setAddUserDetaiRequest
} from '.';

export const getRSA = () => (dispatch: AppDispatch) => {
  try {
    dispatch(setRsaRequest(true));

    const onSuccess = (response: Response<Rsa>) => {
      dispatch(setRSA(response.data.rsa));
      dispatch(setRsaRequest(false));
    };
    const onFailure = (error: Error) => {
      toast.error(error.message);
      dispatch(setRsaRequest(false));
    };

    apiCall('GET', RSA_URL, '', onSuccess, onFailure);
  } catch (error: any) {
    dispatch(setRsaRequest(false));
    toast.error(error.message);
  }
};

export const userLogin = (payload: LoginPayload, callback: Function) => (dispatch: AppDispatch) => {
  try {
    dispatch(setLoginRequest(true));

    const onSuccess = (response: Response<User>) => {
      decryptRsa(response.data.accessToken).then((aesKey: any) => {
        const decodeAccessToken: JWT = jwtDecode(response.data.accessToken);
        dispatch(setUser(response.data));
        setStorageValue(AES_KEY, aesKey);
        setStorageValue(ACCESS_TOKEN, response.data.accessToken);
        setStorageValue(REFRESH_TOKEN, response.data.refreshToken);
        setStorageValue(USER_ROLE, decodeAccessToken.user_claims.role);
        if (response.data.organisationid) {
          const orgId = response.data.organisationid.toString();
          dispatch(getOrganization(orgId, response.data.accessToken));
          dispatch(getProjects(orgId, response.data.accessToken));
          dispatch(getOrganizationMembers(orgId, response.data.accessToken));
        }
        callback(decodeAccessToken.user_claims.role, response.data);

        dispatch(setLoginRequest(false));
        // toast.success('Logged in successfully');
      });
    };
    const onFailure = (error: Error) => {
      dispatch(setLoginRequest(false));
      toast.error(error.message);
    };

    apiCall(
      'POST',
      LOGIN_URL,
      { ...payload, encryptionDetails: 'PKCS1_OAEP' },
      onSuccess,
      onFailure
    );
  } catch (error: any) {
    dispatch(setLoginRequest(false));
    toast.error(error.message);
  }
};

export const addUserDetail =
  (payload: UserDetailPayload, token: string, callback: Function) =>
  (dispatch: AppDispatch, getState: RootState) => {
    try {
      const state = getState();
      dispatch(setAddUserDetaiRequest(true));

      const onSuccess = () => {
        // toast.success('User updated successfully');
        dispatch(
          setUser({
            ...state.user.user,
            firstname: payload.first_name,
            lastname: payload.last_name,
            phone: payload.phone_no,
            department: payload.department,
            empid: payload.emp_id
          })
        );
        dispatch(setAddUserDetaiRequest(false));
        callback();
      };
      const onFailure = (error: Error) => {
        dispatch(setAddUserDetaiRequest(false));
        toast.error(error.message);
      };

      apiCall('POST', ADD_USER_DETAIL_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setAddUserDetaiRequest(false));
      toast.error(error.message);
    }
  };

const clearTokens = (callback: Function) => (dispatch: AppDispatch) => {
  removeAllCookies().then(() => {
    callback();
  });
  dispatch(setRSA(null));
  dispatch(setUser(null));
  dispatch(setSelectedOrganization(null));
  dispatch(setOrganizations([]));
  dispatch(setOrganizationMembers([]));
  dispatch(setSelectedProject(null));
  dispatch(setProject(null));
  dispatch(setProjects([]));
  dispatch(setAvailableProjectMembers([]));
  dispatch(setFiles([]));
  dispatch(setCategories([]));
  dispatch(setAoi(null));
  dispatch(setExtractAoiFiles([]));
  dispatch(setAoiPointsCoords(null));
  dispatch(setRoutes([]));
};

export const userLogout = (callback: Function) => (dispatch: AppDispatch) => {
  try {
    dispatch(setLogoutRequest(true));

    const onSuccess = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };
    const onFailure = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };

    const refreshToken = getStorageValue(REFRESH_TOKEN);
    if (refreshToken !== undefined && refreshToken !== null) {
      apiCall('DELETE', LOGOUT_URL, '', onSuccess, onFailure, refreshToken);
    }
  } catch (error: any) {
    dispatch(clearTokens(callback));
    dispatch(setLogoutRequest(false));
  }
};

export const clearProjectData = () => (dispatch: AppDispatch) => {
  dispatch(setSelectedProject(null));
  dispatch(setProject(null));
  dispatch(setAvailableProjectMembers([]));
  dispatch(setProjectActivityLogs([]));
  dispatch(setFiles([]));
  dispatch(setCategories([]));
  dispatch(setAoi(null));
  dispatch(setExtractAoiFiles([]));
  dispatch(setAoiPointsCoords(null));
  dispatch(setRoutes([]));
  dispatch(setAnalytics([]));
};
