import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { DSM_HILL_SHADE, DSM_JET } from 'constants/route.constant';
import { RepositoryFile } from 'store/repositoryfile/types';
import { GET_GEOSERVER_WMTS_URL } from 'utils/apiUrls';
import 'scripts/wmts';

interface DSMTileLayerProps {
  repoFile: RepositoryFile;
  selectedDSMStyle: string | null;
}

const DSMTileLayer = ({ repoFile, selectedDSMStyle }: DSMTileLayerProps) => {
  const map = useMap();
  const currentLayer: any = useRef(null);
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    if (!selectedDSMStyle) return;

    let wmtsParams = {
      layer: `skies_htl:${repoFile.wms_layer_name!}`,
      style: '',
      format: 'image/png8',
      transparent: true,
      maxZoom: 15,
      pane: 'overlayPane',
      tilematrixset: 'EPSG:900913',
      height: 256,
      width: 256
    };

    if (selectedDSMStyle === DSM_HILL_SHADE) {
      wmtsParams = {
        ...wmtsParams,
        layer: `skies_htl:${repoFile.wms_layer_name!}_${selectedDSMStyle}`
      };
    }

    if (selectedDSMStyle === DSM_JET) {
      wmtsParams = {
        ...wmtsParams,
        style: `skies_htl:${selectedDSMStyle}_${repoFile.wms_layer_name!}`
      };
    }

    const wmtslayer = L.tileLayer
      // @ts-ignore
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);
    currentLayer.current = wmsLayerGroup;

    // eslint-disable-next-line
    return () => {
      currentLayer.current.clearLayers();
    };
  }, [repoFile, selectedDSMStyle]);

  return null;
};

export default DSMTileLayer;
