import { MemberT } from 'store/organizations/types';
import { UserRole } from 'store/users/types';
import { LatLongType } from 'types/aoi.types';

export enum ProjectStagesList {
  ProjectCreation = 'Project Creation',
  DefiningCoordinates = 'Defining Start and End Points',
  AOIRequest = 'Request for AOI',
  LULCLow = 'LULC classification with low resolution data',
  AOIGeneration = 'AOI Generation',
  AOIFinalization = 'AOI finalization',
  TaskingHigh = 'Tasking for High resolution satellite images',
  ProcurementHigh = 'Procurement of High resolution satellite images',
  PreProcessingHigh = 'Pre-processing of High resolution satellite images',
  DEMGeneration = 'DEM generation',
  LULCHigh = 'LULC classification with high resolution data',
  PreliminaryRoute = 'Preliminary route Generation',
  RouteFinalization = 'Final Route Generation'
}

export enum ProjectStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Closed = 'Closed'
}

export enum ProposedTimelineStatus {
  OnGoing = 'On Going',
  Started = 'Started',
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Completed = 'Completed'
}

export interface TeamMember {
  id: string;
  first_name: string;
  last_name: string;
  role: UserRole;
  email: string;
}

export interface ProjectStartEndPoint {
  coordinates: LatLongType | null;
  name: string | null;
  file_id?: number;
  uploaded_by?: string;
}

export interface Project {
  id: number;
  name: string;
  start_point: ProjectStartEndPoint;
  end_point: ProjectStartEndPoint;
  status: ProjectStatus;
  status_percentage: number;
  last_updated: string | null;
  team_members: Array<TeamMember>;
  access: boolean;
  created_at: string;
  in_progress_stage: string;
  is_closed: boolean;
  tagline: string | null;
}

export interface ProjectDataT {
  id: number;
  name: string;
  start_point: ProjectStartEndPoint;
  end_point: ProjectStartEndPoint;
  status: ProjectStatus;
  status_percentage: number;
  last_updated: string | null;
  team_members: Array<MemberT>;
  access: boolean;
}

export interface AvailableProjectMember {
  department: string | null;
  email: string;
  employee_id: string | null;
  first_name: string | null;
  id: string;
  last_name: string | null;
  phone_number: string | null;
  role: UserRole;
}

export interface ProjectStage {
  completion_date: string | null;
  deadline: string;
  id: number;
  stage_name: string;
  status: string | null;
}

export interface ProjectTimeline extends Project {
  stages: Array<ProjectStage>;
}

export type ProjectPayload = {
  admin_id: string;
  organisation_id: string;
  project_title: string;
  project_member_ids: string[];
};

export interface AddProjectMemberPayload {
  project_id: string;
  project_member_ids: string[];
}

export interface ProjectActivityData {
  created_at: string;
  email: string;
  employee_id: string;
  first_name: string;
  id: number;
  last_name: string;
  message: string;
  user_id: string;
}

export interface ProjectStagePayload {
  creation_date: string;
  defining_coordinates: string;
  AOI_request: string;
  LULC_classification_low: string;
  AOI_creation: string;
  AOI_finalization: string;
  tasking_high_resolution: string;
  procurement_high_resolution: string;
  pre_processing_high_resolution: string;
  DEM_generation: string;
  LULC_classification_high: string;
  preliminary_route: string;
  route_finalization: string;
}

export interface StageUploadPayload extends ProjectStagePayload {
  project_id: string;
}

export interface ProjectStageChangeStatusPayload {
  stage: string;
  project_id: string;
  status: string;
}

export interface RemoveProjectMembersPayload {
  member_list: string[];
}

export interface RemoveOrganizationMemberPayload {
  is_active: boolean;
}

export interface ProjectState {
  isLoadingProjects: boolean;
  projects: Array<Project>;
  project: Project | null;
  isAddProjectRequesting: boolean;
  selectedProject: Project | null;
  isRequestProject: boolean;
  isAddProjectMemberRequest: boolean;
  isAvailableProjectMemberRequesting: boolean;
  availableProjectMembers: Array<AvailableProjectMember>;
  isDeadlineStageUpload: boolean;
  isEditDeadlineStageUpload: boolean;
  isRequestingProjectStageChangeStatus: boolean;
  isRequestingRemoveMemberFromProject: boolean;
  isRequestingRemoveMemberFromOrganization: boolean;
  isRequestingProjectActivityLogs: boolean;
  isRequestingCloseProject: boolean;
  projectActivityLogs: Array<ProjectActivityData> | [];
}
